import * as React from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

type CustomButtonProps = {
  label: string;
  variant: 'text' | 'outlined' | 'contained';
  color: 'primary' | 'secondary' | 'info' | 'error';
  loading?: boolean;
} & ButtonProps;

export default function Button(props: CustomButtonProps) {
  return (
    <>
      <LoadingButton {...props}>{props.label}</LoadingButton>
    </>
  );
}
