import { Box, BoxProps, ClickAwayListener, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const copyClipboardStyle = {
  position: 'absolute',
  right: '0.5rem',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  border: 'none',
  color: 'gray',
  '&:hover': {
    backgroundColor: 'lightgrey',
    color: '#000',
  },
};

export type Props = BoxProps & {
  text: string;
  children: string;
  component?: string;
};

export const CopyToClipboard = ({ text, children, ...restProps }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    handleTooltipOpen();
  };

  const handleTooltipClose = () => {
    setCopied(false);
  };

  const handleTooltipOpen = () => {
    setCopied(true);
  };

  return (
    <Box {...restProps}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={copied}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied!"
          >
            <Box
              component="button"
              sx={copyClipboardStyle}
              onClick={handleCopyClick}
            >
              <ContentCopyIcon />
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>

      {children}
    </Box>
  );
};
