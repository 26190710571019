import { useMutation, useQuery } from 'react-query';
import { Play } from '../types/Play';
import axios from '../config/http-common';
import { Production } from '../types/Production';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { PlayContribution } from '../types/PlayContribution';
import { getExtFromFileName } from '../utils/media';
import {
  UploadMediaType,
  uploadMediaToS3,
  uploadMediaToS3_File,
} from './utils';
import { QueuedFiles } from '../types/Global';

export function useGetAllPlays(searchText?: string) {
  return useQuery(['allPlays'], () =>
    axios
      .get(`plays/admin/?searchText=${searchText}`)
      .then((response) => response.data),
  );
}
export function useGetPlay(id: string, onSuccess, options) {
  return useQuery<Play, Error>(
    ['selectedPlay'],
    () => axios.get(`plays/${id}`).then((response) => response.data),
    {
      onSuccess: onSuccess,
      ...options,
    },
  );
}

export function usePostPlay() {
  return useMutation((data) =>
    axios.post('/plays', data).then((response) => response.data),
  );
}
export function usePutPlay(playId: string) {
  return useMutation((data) =>
    axios.put(`/plays/${playId}`, data).then((response) => response.data),
  );
}
export function useDeletePlay(playId: string) {
  return useMutation<Production[], Error>(() =>
    axios.delete(`/plays/${playId}`).then((response) => response.data),
  );
}

export async function getPlayContribution(
  playId: string,
  contributionId: string,
): Promise<PlayContribution> {
  return axios
    .get(`plays/${playId}/contributions/${contributionId}`)
    .then((response) => response.data);
}

export async function getPlayMedia(playId: string, mediaType: UploadMediaType) {
  return await axios.get(`/plays/${playId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(playId: string, mediaId: string) {
  return await axios.delete(`/plays/${playId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  playId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  const associatedMedia = await getPlayMedia(playId, uploadMediaType);

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.playId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('plays/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const PlayMediaResponse = await axios.post(`/plays/${playId}/process-media`, {
    mediaName: mediaData.name,
    fileName: fileName,
    uploadMediaType: uploadMediaType,
  });

  return PlayMediaResponse.data;
}

export async function uploadMediaMultiple(
  playId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  const { signedUrl, fileName } = (
    await axios.get('plays/uploadMedia', {
      params: {
        contentType: mediaData.fileType,
        fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_File(
    signedUrl,
    mediaData,
  );

  const PlayMediaResponse = await axios.post(`/plays/${playId}/process-media`, {
    mediaName: mediaData.fileInfo.fileName,
    fileName: fileName,
    uploadMediaType: uploadMediaType,
  });

  return PlayMediaResponse.data;
}

export interface PlayProductionSearchResult {
  productionId: string;
  productionYear: string;
  studioName: string;
  city: string;
  state: string;
  venueName: string;
  production: Production;
}

export async function queryPlayProductions(queryBody: {
  studioType?: string;
  studioState?: string;
  playId?: string;
  studioId?: string;
  venueId?: string;
}): Promise<{
  productions: PlayProductionSearchResult[];
  proudctionCountByStudioType: { [key: number]: number };
}> {
  return axios
    .post('productions/search', queryBody)
    .then((response) => response.data);
}

export function useCreatePlayFeaturedProduction() {
  return useMutation((args: { playId: string; productionId: string }) => {
    return axios
      .post(`/plays/${args.playId}/featured/production`, args)
      .then((response) => response.data);
  });
}
export function useCreatePlayFeaturedContribution() {
  return useMutation(
    (args: {
      playId: string;
      productionContributionId: string;
      artistId: string;
    }) => {
      return axios
        .post(`/plays/${args.playId}/featured/artist`, args)
        .then((response) => response.data);
    },
  );
}

export function useDeleteFeaturedPlayProduction({
  playId,
  productionId,
}: {
  playId: string;
  productionId: string;
}) {
  return useMutation<Production[], Error>(() =>
    axios
      .delete(`/plays/${playId}/featured/production/${productionId}`)
      .then((response) => response.data),
  );
}
export function useDeleteFeaturedPlayContribution({
  playId,
  productionContributionId: contributionId,
}: {
  playId: string;
  productionContributionId: string;
}) {
  return useMutation<Production[], Error>(() =>
    axios
      .delete(`/plays/${playId}/featured/artist/${contributionId}`)
      .then((response) => response.data),
  );
}

export async function uploadFeaturedMedia(playId: string, mediaId: string) {
  return await axios
    .post(`/plays/${playId}/featured-media/${mediaId}`)
    .then((response) => response.data);
}

export async function deleteFeaturedMedia(playId: string, mediaId: string) {
  return await axios
    .delete(`/plays/${playId}/featured-media/${mediaId}`)
    .then((response) => response.data);
}

export async function getPlayFeaturedMedia(playId: string) {
  return await axios
    .get(`/plays/${playId}/featured-media`)
    .then((response) => response.data);
}

export async function queryPlays() {
  return await axios.post('/plays/query').then((response) => response.data);
}
export async function queryStudios() {
  return await axios.get('/studios').then((response) => response.data);
}
