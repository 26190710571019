import {
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { MuiDialog, MuiDialogTitle } from '../Dialog';
import { useErrorModal } from './ErrorModalProvider';
import { CopyToClipboard } from '../CopyToClipboard';
import CloseIcon from '@mui/icons-material/Close';
import { toEllipsis } from '../../utils/text';

const preStyles = {
  textAlign: 'left',
  overflow: 'scroll',
  border: '1px solid #ddd',
  borderRadius: '0.5rem',
  padding: '0.5rem',
  position: 'relative',
  minHeight: '8rem',
};

export default function ApiErrorModal() {
  const { toggleModal, modalState, closeModal } = useErrorModal();

  return (
    <MuiDialog
      open={modalState.openState}
      maxWidth={'md'}
      onClose={toggleModal}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <MuiDialogTitle id="error-dialog-title">
        <Typography variant="h1" sx={{ wordBreak: 'break-all' }}>
          {toEllipsis(decodeURIComponent(modalState.title), 150)}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">
          <Typography
            variant="h3"
            sx={{ color: '#000', textAlign: 'left' }}
            my={2}
          >
            Request
          </Typography>
          <CopyToClipboard
            text={JSON.stringify(modalState.request)}
            component="pre"
            sx={preStyles}
          >
            {JSON.stringify(modalState.request, null, 2)}
          </CopyToClipboard>

          <Typography
            variant="h3"
            sx={{ color: '#000', textAlign: 'left' }}
            my={2}
          >
            Response
          </Typography>
          <CopyToClipboard
            text={JSON.stringify(modalState.response)}
            component="pre"
            sx={preStyles}
          >
            {JSON.stringify(modalState.response, null, 2)}
          </CopyToClipboard>
        </DialogContentText>
      </DialogContent>
    </MuiDialog>
  );
}
