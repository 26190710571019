import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import UploadFileInput from './UploadFileInput';
import Button from '../Button';
import { base64ToBlob, getExtFromFileName } from '../../utils/media';
import { bytesToMB } from '../../utils/units';
import {
  UploadFileInfo,
  UploadFileInfoWithSignedURL,
} from '../../views/plays/CreatePlays/CreatePlay';
import { useGetSignedURL } from '../../api/utils';
import ConfirmDialog, { useConfirmModal } from '../Modals/ConfirmDialog';

export type QueueFileInfo = UploadFileInfo & { file: File };

export const formFileInfoWithSignedURL = async (
  base64Img: string,
  file: File,
  prefix: string,
): Promise<UploadFileInfoWithSignedURL> => {
  if (!base64Img || !file) return;

  const ext = file.name.split('.').pop();
  const { data: signedURL } = await useGetSignedURL(`${prefix}/${ext}`);
  const binaryImg = base64ToBlob(base64Img, file.type);

  return {
    blob: binaryImg,
    name: file.name,
    mimeType: file.type,
    signedURL: signedURL,
  };
};

interface SingleImageUploadProps {
  title: string;
  id?: string;
  message?: string;
  coverTitle?: string;
  photoTitle?: string;
  initialUpload?: string;
  uploadCallback?: (blob: string, file: File) => void;
  deleteCallback?: () => void;
  validator?: (fileName: string, fileSizeInMB: number) => boolean;
  hintText?: string;
  maxFileSizeInMB?: number;
}

export default function SingleImageUpload(props: SingleImageUploadProps) {
  const {
    id,
    title,
    message = 'Only JPGs and PNGs supported',
    // coverTitle = 'Cover Photo',
    photoTitle = '',
    initialUpload,
    uploadCallback = () => console.log('uploading'),
    deleteCallback,
    validator = () => true,
    maxFileSizeInMB = null,
    hintText = '',
  } = props;
  const { showDeleteModal, setShowDeleteModal } = useConfirmModal();

  const [fileSrc, setFileSrc] = useState('');
  const [allFiles, setAllFiles] = useState({
    name: '',
    size: 0,
  });
  const [hoverState, setHoverState] = useState(0);

  useEffect(() => {
    setFileSrc(initialUpload);
  }, [initialUpload]);

  const fileUploadComplete = (blob: string, file: any) => {
    const fileSizeInMB = bytesToMB(file.size);
    if (maxFileSizeInMB && fileSizeInMB > maxFileSizeInMB) {
      alert(`Please provide a file with size less than ${maxFileSizeInMB}MB`);
      return;
    }
    const isValidFile = validator(
      getExtFromFileName(file.name),
      bytesToMB(file.size),
    );
    if (!isValidFile) {
      alert('Please provide a valid file');
      return;
    }
    setFileSrc(blob);
    setAllFiles(file);
    uploadCallback(blob, file);
  };

  const subText =
    hintText ||
    (maxFileSizeInMB
      ? `File size should be less than ${maxFileSizeInMB}MB`
      : '');

  return (
    <>
      <Box>
        <Divider sx={{ marginBottom: '20px' }} />
        <Box sx={{ marginBottom: '24px' }}>
          <Typography variant="h1">{title}</Typography>
          <p style={{ margin: 0 }}>{message}</p>
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Typography variant="h5" sx={{ marginBottom: '4px' }}>
            {photoTitle}
          </Typography>
          <UploadFileInput
            imageData={fileUploadComplete}
            id={id}
            uploadBtnLabel="Choose Photo"
          />
          <p>
            <i style={{ color: '#999' }}>{subText}</i>
          </p>
        </Box>
        {fileSrc && (
          <Box>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                marginBottom: 1,
              }}
              onMouseEnter={() => setHoverState(1)}
              onMouseLeave={() => setHoverState(0)}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  background: '#eee',
                  ':hover': {
                    opacity: 0.3,
                  },
                }}
                component="img"
                src={fileSrc}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(0, 0, 0, 0.75)',
                  width: '100%',
                  height: '100%',
                  opacity: hoverState,
                }}
              >
                <Button
                  label={'Delete Photo'}
                  variant={'contained'}
                  color={'error'}
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ fontWeight: 'bold' }}>
              {allFiles?.name ? (
                <Typography variant="h5" sx={{ color: '#111827' }}>
                  File Name: {allFiles?.name}
                </Typography>
              ) : (
                ''
              )}
              {allFiles?.size != 0 ? (
                <Typography variant="h5" sx={{ color: '#6B7280' }}>
                  {allFiles?.size / 1024 / 1024 <= 0.5 && (
                    <Typography variant="h5" sx={{ color: '#6B7280' }}>
                      {(allFiles?.size / 1024).toFixed(0)} KB
                    </Typography>
                  )}
                  {allFiles?.size / 1024 / 1024 > 0.5 && (
                    <Typography variant="h5" sx={{ color: '#6B7280' }}>
                      {(allFiles?.size / 1024 / 1024).toFixed(2)} MB
                    </Typography>
                  )}
                </Typography>
              ) : (
                ''
              )}
            </Box>
            <Box
              id="detailsStack"
              sx={{
                display: 'flex',
                width: 'min-content',
                gap: '6px',
                left: '25%',
                top: '-20vw',
                position: 'relative',
                textAlign: 'center',
                '-webkit-transform': 'translate(-50%, -50%)',
                '-ms-transform': 'translate(-50%, -50%)',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '7px',
                opacity: `${hoverState}`,
                ':hover': {
                  opacity: 1,
                },
              }}
            >
              {/* <Button
              label={'Delete'}
              variant={'contained'}
              color={'error'}
              sx={{ maxWidth: '50%' }}
              onClick={()=>{
                setFileSrc(null)
                deleteCallback()
              }}
            /> */}
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmDialog
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirmDelete={() => {
          setFileSrc(null);
          deleteCallback();
          setShowDeleteModal(false);
        }}
      />
    </>
  );
}
