import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Institution } from '../../../types/Institution';
import { useCustomForm } from '../../plays/CreatePlays/useCustomForm';

export const CreateInstitutionValidation = (
  defaultValues: Partial<Institution> = {
    type: null,
    name: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    website: '',
  },
) => {
  const Schema = yup.object().shape({
    type: yup.string().required('Institution Type is required'),
    name: yup.string().min(2).max(100).required('Institution Name is required'),
    street: yup.string().max(100).nullable().notRequired(),
    city: yup.string().max(100).nullable().notRequired(),
    state: yup.string().max(100).nullable().notRequired(),
    country: yup.string().max(100).nullable().notRequired(),
    zipcode: yup.string().nullable().notRequired(),
    website: yup.string().max(100).nullable().notRequired(),
  });

  return useCustomForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(Schema),
    shouldFocusError: false,
  });
};
