import React, { createContext, useState } from 'react';

export interface Config {
  play: Play;
  studio: Studio;
  venue: Venue;
  artist: Artist;
}

export interface Artist {
  artistTypes: string[];
}

export interface Play {
  castTypeDescriptions: string[];
  playScopes: string[];
  licenseVersions: string[];
  licenseHouses: string[];
  vocalRequirements: string[];
  playTypes: string[];
  genres: string[];
  duration: Duration;
  themes: string[];
  principle: [number, number];
  ensemble: boolean;
  targetInstitutionTypes?: any;
}

export interface Duration {
  min: number;
  max: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Studio {}

export interface Venue {
  stageConfigurations: string[];
  capacity: Duration;
}

interface SearchFilterContextType {
  config?: Config | undefined;
  setConfig: (config?: Config | undefined) => void;
}

export const SearchFilterConfigContext = createContext<
  SearchFilterContextType | undefined
>(undefined);

const SearchFilterConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [config, setConfig] = useState<Config | undefined>();

  return (
    <SearchFilterConfigContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </SearchFilterConfigContext.Provider>
  );
};

export default SearchFilterConfigProvider;
