export function base64ToBlob(base64Data: string | Blob, fileMimeType: string) {
  let binary;
  if (base64Data instanceof Blob) {
    binary = base64Data;
  } else {
    binary = atob(base64Data.split(',')[1]);
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    binary = new Blob([array]);
  }

  const blobData = new Blob([binary], {
    type: fileMimeType,
  });
  return blobData;
}

export function getExtFromFileName(fileName: string) {
  return fileName.split('.').pop();
}

export function getRawFileName(fileName: string) {
  return fileName.split('.').shift();
}

export function extractNumberFromString(str: string): number {
  const match = str?.match(/\d+(\.\d+)?/);
  return match ? parseFloat(match[0]) : 0;
}
