import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DropDownMenu } from './index';
import { useAuth } from '../hooks/useAuth';
import { useEffect } from 'react';
import { Role, User } from '../types/User';
import { Typography } from '@mui/material';

const MuiTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#6366F1',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'capitalize',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#6B7280',
  '&:hover': {
    color: '#111827',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#111827',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

interface StyledTabProps {
  label: string;
  to: string;
  component: any;
  onClick?: () => void;
}

export interface RouteItem {
  slug: string;
  label: string;
}

type NavTabsProps = {
  openRoutes: RouteItem[];
  operatorRoutes: RouteItem[];
  protectedRoutes: RouteItem[];
  create?: boolean;
  user: User;
};

export default function NavTabs({
  openRoutes,
  protectedRoutes,
  operatorRoutes,
  create,
  user,
}: NavTabsProps) {
  const { isAuthenticated, logout } = useAuth();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1] || null;
  const IS_OPERATOR = user?.primaryRole === Role.spectraOperator;
  const roleBasedProtectedRoutes = IS_OPERATOR
    ? [protectedRoutes[0], ...operatorRoutes, ...protectedRoutes.slice(1)]
    : protectedRoutes;

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
    setValue(
      (isAuthenticated ? roleBasedProtectedRoutes : openRoutes).findIndex(
        (route) => route.slug === currentPath,
      ),
    );
  }, [location.pathname]);

  const tabs = (isAuthenticated ? roleBasedProtectedRoutes : openRoutes).map(
    (item: RouteItem, index: number) => {
      if (item.slug == 'user') {
        return (
          <Typography key={index}>
            <Typography
              flexGrow="1"
              variant="h6"
              sx={{ textTransform: 'capitalize' }}
            >
              <b>
                &nbsp; &nbsp; {user.primaryRole.split(':').pop()}{' '}
                {user.studioName == 'No Studio Associated' ||
                user.studioName == undefined
                  ? '@ Spectra'
                  : '@ ' + user.studioName ?? 'Spectra'}
              </b>
            </Typography>
            <Typography flexGrow="1" variant="h6" sx={{ marginLeft: '5px' }}>
              &nbsp; {user.email.toLowerCase()}
            </Typography>
          </Typography>
        );
      } else if (item.slug == 'artists') {
        return (
          <AntTab
            label={`${IS_OPERATOR ? 'All Artists' : ' Studio Artists'}`}
            key={index}
            component={Link}
            to={`/${item.slug}`}
            onClick={() => setValue(index)}
          />
        );
      } else if (item.slug == 'productions') {
        return (
          <AntTab
            label={`${
              !IS_OPERATOR ? ' Studio Productions' : 'All Productions'
            }`}
            key={index}
            component={Link}
            to={`/${item.slug}`}
            onClick={() => setValue(index)}
          />
        );
      } else {
        return (
          <AntTab
            label={item.label}
            key={index}
            component={Link}
            to={`/${item.slug}`}
            onClick={() => setValue(index)}
          />
        );
      }
    },
  );

  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backgroundColor: '#fff',
        borderBottom: '2px solid #E5E7EB',
      }}
    >
      <Box sx={{ maxWidth: 1440, margin: 'auto' }}>
        <MuiTabs value={value} onChange={handleChange} aria-label="ant example">
          {tabs}
          <Box
            sx={{
              backgroundColor: '#fff',
              ml: '0',
              mr: 2,
            }}
          >
            {create && (
              <DropDownMenu
                label="Create New"
                id="create-new"
                options={
                  IS_OPERATOR
                    ? [
                        {
                          label: 'Play',
                          id: 'create-plays',
                          onClick: () => navigate('/plays/create'),
                        },
                        {
                          label: 'Studio',
                          id: 'create-studios',
                          onClick: () => navigate('/studios/create'),
                        },
                        {
                          label: 'Institution',
                          id: 'create-institution',
                          onClick: () => navigate('/institutions/create'),
                        },
                        {
                          label: 'Production',
                          id: 'create-productions',
                          onClick: () => navigate('/productions/create'),
                        },
                        {
                          label: 'Artist',
                          id: 'create-artists',
                          onClick: () => navigate('/artists/create'),
                        },
                        {
                          label: 'Venue',
                          id: 'create-venues',
                          onClick: () => navigate('/venues/create'),
                        },
                      ]
                    : user.primaryRole == Role.studioAdmin
                    ? [
                        {
                          label: 'Production',
                          id: 'create-productions',
                          onClick: () => navigate('/productions/create'),
                        },
                        {
                          label: 'Artist',
                          id: 'create-artists',
                          onClick: () => navigate('/artists/create'),
                        },
                        {
                          label: 'Venue',
                          id: 'create-venues',
                          onClick: () => navigate('/venues/create'),
                        },
                      ]
                    : []
                }
              />
            )}
          </Box>
        </MuiTabs>
      </Box>
    </Box>
  );
}
