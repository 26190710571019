import { useMutation } from 'react-query';
import axios from '../config/http-common';
import { Artist } from '../types/Artist';

import { getExtFromFileName } from '../utils/media';
import { UploadMediaType, uploadMediaToS3_File } from './utils';
import { QueuedFiles } from '../types/Global';

export function usePostPlayContribution(playId: string) {
  return useMutation((data) =>
    axios
      .post(`/plays/${playId}/contributions/`, data)
      .then((response) => response.data),
  );
}

export function usePutPlayContribution(playId: string, contributionId: string) {
  return useMutation((data) =>
    axios
      .put(`/plays/${playId}/contributions/${contributionId}`, data)
      .then((response) => response.data),
  );
}

export function useDeletePlayContribution(
  playId: string,
  contributionId: string,
) {
  return useMutation<Artist[], Error>(() =>
    axios
      .delete(`/plays/${playId}/contributions/${contributionId}`)
      .then((response) => response.data),
  );
}

export function deletePlayContribution({
  playId,
  contributionId,
}: {
  playId: string;
  contributionId: string;
}) {
  return axios
    .delete(`/plays/${playId}/contributions/${contributionId}`)
    .then((response) => response.data);
}

export async function deleteMedia(
  playId: string,
  playContributionId: string,
  mediaId: string,
) {
  return await axios.delete(
    `/plays/${playId}/contributions/${playContributionId}/${mediaId}/delete`,
  );
}

export async function uploadMediaMultiple(
  playId,
  playContributionId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  const { signedUrl, fileName } = (
    await axios.get(`plays/${playId}/contributions/uploadMedia`, {
      params: {
        contentType: mediaData.fileType,
        fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_File(
    signedUrl,
    mediaData,
  );

  const PlayContributionMediaResponse = await axios.post(
    `/plays/${playId}/contributions/${playContributionId}/process-media`,
    {
      mediaName: mediaData.fileInfo.fileName,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return PlayContributionMediaResponse.data;
}
