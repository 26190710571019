import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Studio, StudioInvite } from '../../../types/Studio';
import { useCustomForm } from '../../plays/CreatePlays/useCustomForm';

const CreateStudioValidation = (
  defaultValues: Partial<Studio> & {
    selectedInstitution?: { id: string | number; label: string };
  } = {
    type: null,
    selectedInstitution: null,
    institutionId: null,
    name: null,
    street: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    website: null,
  },
) => {
  const Schema = yup.object().shape({
    type: yup.string().nullable().required('Studio Type is required'),
    selectedInstitution: yup.object().nullable(),
    street: yup.string().min(2).max(100).nullable().notRequired(),
    name: yup
      .string()
      .min(2)
      .max(100)
      .nullable()
      .required('Please provide a valid name'),
    city: yup.string().min(2).max(100).nullable().notRequired(),
    state: yup.string().min(2).max(100).nullable().notRequired(),
    country: yup.string().min(2).max(100).nullable().notRequired(),
    zipcode: yup.string().nullable().notRequired(),
    website: yup.string().min(2).max(100).nullable().notRequired(),
  });

  return useCustomForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(Schema),
  });
};

const inviteUserValidationSchema = (
  defaultValues: Partial<StudioInvite> = {
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    permissions: null,
  },
) => {
  const Schema = yup.object().shape({
    firstName: yup
      .string()
      .nullable()
      .min(2)
      .max(100)
      .required('First Name is required'),
    lastName: yup
      .string()
      .nullable()
      .min(2)
      .max(100)
      .required('Last Name is required'),
    email: yup.string().nullable().required('Email is required'),
    phoneNumber: yup.string().nullable().notRequired(),
    permissions: yup
      .string()
      .nullable()
      .required('permission level is required'),
  });

  return useCustomForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(Schema),
  });
};

export { CreateStudioValidation, inviteUserValidationSchema };
