import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  FormGroup,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
} from '@mui/material';
import { Controller } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, options: readonly string[], theme: Theme) {
  return {
    fontWeight:
      options.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultiSelectProps {
  options: string[];
  placeholder: string;
  control: any;
  name: string;
  label?: string;
  multiple?: boolean;
}

export default function Select(props: MultiSelectProps) {
  const {
    options,
    placeholder,
    control,
    name,
    label,
    multiple = false,
  } = props;
  const theme = useTheme();

  return (
    <Box>
      <FormGroup>
        {label && (
          <InputLabel
            id={label.toLowerCase().replace(' ', '-')}
            aria-label={label}
            variant="standard"
          >
            {label}
          </InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <>
              <SelectBox
                {...field}
                multiple={multiple}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                error={!!error}
                options={options}
              />
              {error ? (
                <FormHelperText error>{error.message}</FormHelperText>
              ) : null}
            </>
          )}
        />
      </FormGroup>
    </Box>
  );
}
export function SelectBox({
  multiple = false,
  value,
  onChange,
  placeholder,
  error = false,
  options,
  label = '',
  name = '',
  ...props
}: {
  multiple?: boolean;
  value?: any;
  onChange: (...event: any[]) => void;
  placeholder: string;
  error?: boolean;
  options: string[];
  label?: string;
  name?: string;
}) {
  const theme = useTheme();

  return (
    <>
      {label && (
        <InputLabel
          id={label.toLowerCase().replace(' ', '-')}
          aria-label={label}
          variant="standard"
        >
          {label}
        </InputLabel>
      )}

      <MuiSelect
        {...props}
        style={{ width: '100%' }}
        multiple={multiple}
        displayEmpty
        value={value || (multiple ? [] : '')}
        onChange={(e) => {
          onChange(e);
        }}
        input={<OutlinedInput name={name} />}
        renderValue={(selected: string[]) => {
          if (selected.length === 0) {
            return <span style={{ color: 'lightgray' }}>{placeholder}</span>;
          }
          return Array.isArray(selected) ? selected.join(', ') : selected;
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'multi select' }}
        error={!!error}
      >
        <MenuItem disabled value="">
          <span>{placeholder}</span>
        </MenuItem>
        {options.map((item) => (
          <MenuItem
            key={item}
            value={item}
            style={getStyles(item, options, theme)}
          >
            {item}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
}
