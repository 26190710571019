import React, { useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../types/User';
import { PaginationTable } from '../../components/Table/PaginationTable';

export default function InstitutionsView() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'City',
        accessorKey: 'city',
      },
      {
        header: 'State',
        accessorKey: 'state',
      },
      {
        header: 'Zipcode',
        accessorKey: 'zipcode',
      },
      {
        header: 'Country',
        accessorKey: 'country',
      },
      {
        header: 'Updated At',
        accessorKey: 'updatedAt',
        cell: (info: any) => moment.utc(info.getValue()).format('MM/DD/YYYY'),
      },
    ],
    [],
  );

  return (
    <>
      <Box
        sx={{
          maxWidth: '1440px',
          mx: { xs: 2, md: 3, lg: 6, xl: 'auto' },
          my: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Typography flexGrow="1" variant="h1" textAlign="center">
            Spectra Institutions
          </Typography>
        </Box>
        <PaginationTable
          columns={columns}
          onEdit={(institutionId: string) =>
            navigate(`/institutions/${institutionId}/edit`)
          }
          tableName="Institutions"
          route="institutions"
          requiredEditScopes={[
            Role.spectraOperator,
            Role.studioAdmin,
            Role.studioManager,
          ]}
          studioIdKey="studioId"
          searchStudioPlaceholder={null}
          searchPlaceholder="Search Institutions by Name..."
        />
      </Box>
    </>
  );
}
