import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SideDrawer } from '../../../components';
import Details from '../../common/Details';
import { MarqueeProductionResponse } from '../../../types/SummaryPage';
import ProductionPicker from './shared/ProductionPicker';
import { updateMarqueeProduction } from '../../../api/summaryPage';
import { ProductionRow } from './shared/ProductionRow';

export type Props = {
  marqueeProduction: MarqueeProductionResponse;
};

const MarqueeProductions = (props: Props) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<{
    open: boolean;
    activeProduction: MarqueeProductionResponse | null;
  }>({ open: false, activeProduction: null });
  const [marqueeProduction, setMarqueProduction] = useState(
    props.marqueeProduction,
  );

  const onMarqueeEdit = () => {
    setOpenDrawerState({
      open: true,
      activeProduction: marqueeProduction,
    });
  };

  return (
    <Box>
      <Details
        title="Marquee Productions"
        onAddButton={() => {
          setOpenDrawerState({
            open: true,
            activeProduction: null,
          });
        }}
      >
        <>
          <p>Choose a productions to display at the top of the home page</p>
          {marqueeProduction &&
            marqueeProduction.productionId &&
            [marqueeProduction].map(
              ({
                city,
                state,
                studioName,
                productionName,
                productionYear,
                productionId,
                featuredProductionMediaId,
              }) => (
                <ProductionRow
                  key={productionId}
                  productionId={productionId}
                  productionName={productionName}
                  studioName={studioName}
                  productionYear={productionYear}
                  city={city}
                  state={state}
                  featuredProductionMediaId={featuredProductionMediaId}
                  onEdit={onMarqueeEdit}
                />
              ),
            )}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState.open}
        toggleDrawer={() =>
          setOpenDrawerState({ open: false, activeProduction: null })
        }
        styles={{
          width: '550px',
        }}
      >
        <ProductionPicker
          title="Marquee Production"
          currentMarqueeProduction={openAddDrawerState.activeProduction}
          handleSave={({ productionId, featuredProductionMediaId }) =>
            updateMarqueeProduction({
              productionId,
              featuredProductionMedia: featuredProductionMediaId,
            })
          }
          handleClose={(production) => {
            setOpenDrawerState({
              open: false,
              activeProduction: null,
            });
            if (production) {
              setMarqueProduction({
                city: production.city,
                state: production.state,
                featuredProductionMediaId: production.featuredProductionMediaId,
                productionId: production.id,
                productionName: production.productionName,
                productionYear: production.productionYear,
                studioName: production.studioName,
              });
            }
          }}
          mediaLimit={1}
        />
      </SideDrawer>
    </Box>
  );
};

export default MarqueeProductions;
