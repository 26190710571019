import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import React, { Component } from 'react';

interface CopyToClipboardButtonProps {
  id?: string;
}

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(props.id);
  };

  return (
    <div>
      <Button onClick={handleClick}>{props.id}</Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </div>
  );
};

export default CopyToClipboardButton;
