import axios from '../config/http-common';
import { Play } from '../types/Play';
import { Studio } from '../types/Studio';
import { Venue } from '../types/Venue';
export interface PageFeaturedPlay extends Play {
  featuredProductionMediaId?: string;
}
export interface PageFeaturedStudio extends Studio {
  featuredProductionMediaId?: string;
}
export interface PageFeaturedVenue extends Venue {
  featuredProductionMediaId?: string;
}
export async function getHomeSummaryInfo() {
  return await axios.get('/home/admin').then((response) => response.data);
}

export async function updateMarqueeProduction(DtoO: {
  productionId: string;
  featuredProductionMedia: string;
}) {
  return await axios
    .post('/home/marquee-production', DtoO)
    .then((response) => response.data);
}

export async function addHomeFeaturedProduction(DtoO: {
  productionId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .post('/home/featured-production', DtoO)
    .then((response) => response.data);
}

export async function editHomeFeaturedProduction(DtoO: {
  productionId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .put(`/home/featured-production/${DtoO.productionId}`, DtoO)
    .then((response) => response.data);
}

export async function deleteHomeFeaturedProduction(productionId: string) {
  return await axios
    .delete(`/home/featured-production/${productionId}`)
    .then((response) => response.data);
}

export async function addFeaturedPlayInHomePage(playId) {
  return await axios
    .post(`/home/featured-play/${playId}`)
    .then((response) => response.data);
}

export async function removeFeaturedPlay(playId) {
  return await axios
    .delete(`/home/featured-play/${playId}`)
    .then((response) => response.data);
}
export async function addFeaturedStudio(studioId) {
  return await axios
    .post(`/home/featured-studio/${studioId}`)
    .then((response) => response.data);
}

export async function removeFeaturedStudio(studioId) {
  return await axios
    .delete(`/home/featured-studio/${studioId}`)
    .then((response) => response.data);
}

export async function addFeaturedVenue(venueId) {
  return await axios
    .post(`/home/featured-venue/${venueId}`)
    .then((response) => response.data);
}

export async function removeFeaturedVenue(venueId) {
  return await axios
    .delete(`/home/featured-venue/${venueId}`)
    .then((response) => response.data);
}

export async function getPlaySummaryInfo() {
  return await axios
    .get('/home/plays/featured-plays')
    .then((response) => response.data);
}

export async function addFeaturedPlayInPlaysPage(addFeaturedPlayDto: {
  playId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .post('/home/plays/featured-plays', addFeaturedPlayDto)
    .then((response) => response.data);
}
export async function updateFeaturedPlayInPlaysPage(updateFeaturedPlayDto: {
  playId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .put('/home/plays/featured-plays', updateFeaturedPlayDto)
    .then((response) => response.data);
}
export async function deleteFeaturedPlayInPlaysPage(playId) {
  return await axios
    .delete(`/home/plays/featured-plays/${playId}`)
    .then((response) => response.data);
}

export async function getFeatuedStudiosInSummaryPage() {
  return await axios
    .get('/home/studios/featured-studios')
    .then((response) => response.data);
}

export async function addFeaturedStudioInSummaryPage(addFeaturedStudioInSummaryPageDto: {
  studioId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .post('/home/studios/featured-studios', addFeaturedStudioInSummaryPageDto)
    .then((response) => response.data);
}

export async function updateFeaturedStudioInSummaryPage(updateFeauredStudioInSummaryPageDto: {
  studioId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .put('/home/studios/featured-studios', updateFeauredStudioInSummaryPageDto)
    .then((response) => response.data);
}

export async function deleteFeaturedStudiosInSummaryPage(studioId) {
  return await axios
    .delete(`/home/studios/featured-studios/${studioId}`)
    .then((response) => response.data);
}
export async function getFeatuedVenuesInSummaryPage() {
  return await axios
    .get('/home/venues/featured-venues')
    .then((response) => response.data);
}

export async function addFeaturedVenueInSummaryPage(addFeaturedVenueInSummaryPageDto: {
  venueId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .post('/home/venues/featured-venues', addFeaturedVenueInSummaryPageDto)
    .then((response) => response.data);
}

export async function updateFeaturedVenueInSummaryPage(updateFeauredVenueInSummaryPageDto: {
  venueId: string;
  featuredProductionMediaId: string;
}) {
  return await axios
    .put('/home/venues/featured-venues', updateFeauredVenueInSummaryPageDto)
    .then((response) => response.data);
}

export async function deleteFeaturedVenuesInSummaryPage(venueId) {
  return await axios
    .delete(`/home/venues/featured-venues/${venueId}`)
    .then((response) => response.data);
}
