import { Play } from './Play';
import { Artist } from './Artist';

export type PlayContribution = {
  draftId?: string;
  contributionFileURLs: ContributionFiles[];
  id: string;
  playId: string;
  artistId: string;
  contributionType: PlayContributionType;
  contributionName: PlayContributionName;
  customContributionName?: string;
  award?: string;
  play: Play;
  artist: Artist;
};

interface ContributionFiles {
  fileName: string;
  fileUrl: string;
  fileSize: string;
}

export enum PlayContributionType {
  author = 'Author',
  creator = 'Creator',
  // administrative = 'Administrative',
  // artistic = 'Artistic',
  // crew = 'Crew',
  // faculty = 'Faculty',
  // musician = 'Musician',
  // performer = 'Performer',
  // studio = 'Studio',
  // venue = 'Venue',
}

export enum PlayContributionName {
  custom = 'Custom',
  additionalIncidentalMusic = 'Additional Incidental Music',
  additionalLyrics = 'Additional Lyrics',
  additionalMaterial = 'Additional Material',
  additionalMusic = 'Additional Music',
  basedOnANovel = 'Based on a Novel',
  basedOnStories = 'Based on Stories',
  basedOnABook = 'Based on the Book',
  basedOnAComicStrip = 'Based on the Comic Strip',
  basedOnTheFilm = 'Based on the Film',
  basedOnTheOriginalScreenplay = 'Based on the Original Screenplay',
  basedOnThePlay = 'Based on the Play',
  basedOnThePoem = 'Based on the Poem',
  basedOnTheStory = 'Based on the Story',
  book = 'Book',
  englishVersion = 'English Version',
  lyrics = 'Lyrics',
  music = 'Music',
  orchestrations = 'Orchestrations',
  originalAdaptation = 'Original Adaptation',
  originalBook = 'Original Book',
  originalBookAndConcept = 'Original Book & Concept',
  originalConcept = 'Original Concept',
  originalFrenchText = 'Original French Text',
  playwright = 'Playwright',
  story = 'Story',
  titleSongLyrics = 'Title Song Lyrics',
  vignettes = 'Vignettes',
  adaptations = 'Adaptation',
  arrangements = 'Arrangements',
  concieved = 'Conceived',
  danceMusicArrangements = 'Dance Music Arrangements',
  dramaturgy = 'Dramaturgy',
  musicialArrangements = 'Musical Arrangements',
  originalChoreography = 'Original Choreography',
  originalDirection = 'Original Direction',
  originallyProduced = 'Originally Produced',
  originallyProducedOnBroadway = 'Originally Produced on Broadway',
  produced = 'Produced',
  schoolEditionAdaptation = 'School Edition Adaptation',
  scriptConsultant = 'Script Consultant',
  stageAdaptation = 'Stage Adaptation',
  vocalMusicArrangements = 'Vocal Music Arrangements',

  // accompanist = 'Accompanist',
  // acknowledgements = 'Acknowledgements',
  // acting = 'Acting',
  // actingForTheCamera = 'Acting for the Camera',
  // actingMusicalTheatre = 'Acting/Musical Theatre',
  // additionalPropsCostumes = 'Additional Props & Costumes',
  // adminCoodinator = 'Admin Coodinator',
  // administrativeAssistant = 'Administrative Assistant',
  // administrativeAssistantStaff = 'Administrative Assistant - Staff',
  // administrativeAssistantToTheDean = 'Administrative Assistant to the Dean',
  // altoSaxophone = 'Alto Saxophone',
  // artDirection = 'Art Direction',
  // artisticDirector = 'Artistic Director',
  // assistantBusinessManager = 'Assistant Business Manager',
  // assistantChoreographer = 'Assistant Choreographer',
  // assistantCostumeDesigner = 'Assistant Costume Designer',
  // assistantDirector = 'Assistant Director',
  // assistantDramaturg = 'Assistant Dramaturg',
  // assistantHairAndMakeupDesigner = 'Assistant Hair and Makeup Designer',
  // assistantLightingDesigner = 'Assistant Lighting Designer',
  // assistantMasterElectrician = 'Assistant Master Electrician',
  // assistantMusicDirector = 'Assistant Music Director',
  // assistantOrphanWranglers = 'Assistant Orphan Wranglers',
  // assistantProductionManager = 'Assistant Production Manager',
  // assistantScenicDesigner = 'Assistant Scenic Designer',
  // assistantSetDesigner = 'Assistant Set Designer',
  // assistantSoundDesigner = 'Assistant Sound Designer',
  // assistantStageManager = 'Assistant Stage Manager',
  // assistantToTheArtisticDirector = 'Assistant to the Artistic Director',
  // assistantToTheBookWriter = 'Assistant to the Book Writer',
  // assistantToTheChoreographer = 'Assistant to the Choreographer',
  // assistantToTheCostumeDesigner = 'Assistant to the Costume Designer',
  // assistantToTheDirector = 'Assistant to the Director',
  // assistantToTheHairMakeupDesigner = 'Assistant to the Hair & Makeup Designer',
  // assistantToTheLightingDesigner = 'Assistant to the Lighting Designer',
  // assistantToTheMusicDirector = 'Assistant to the Music Director',
  // assistantToTheScenicDesigner = 'Assistant to the Scenic Designer',
  // assistantToTheTechnicalDirector = 'Assistant to the Technical Director',
  // assitantDogCatcher = 'Assitant Dog Catcher',
  // associateChair = 'Associate Chair',
  // associateCostumeDesigner = 'Associate Costume Designer',
  // associateDirector = 'Associate Director',
  // associateHead = 'Associate Head',
  // associateProducer = 'Associate Producer',
  // associateProducingDirector = 'Associate Producing Director',
  // backstageCrew = 'Backstage Crew',
  // backstageLead = 'Backstage Lead',
  // baritoneSaxophone = 'Baritone Saxophone',
  // bass = 'Bass',
  // bassClarinet = 'Bass Clarinet',
  // bassGuitar = 'Bass Guitar',
  // bassTrombone = 'Bass Trombone',
  // bassoon = 'Bassoon',
  // boardOfDirectors = 'Board of Directors',
  // boardOfDirectorsPresident = 'Board of Directors - President',
  // boardOfDirectorsSecretary = 'Board of Directors - Secretary',
  // boardOfDirectorsTreasurer = 'Board of Directors - Treasurer',
  // boardOfDirectorsVicePresident = 'Board of Directors - Vice President',
  // boardOfDirectorsYouthPresident = 'Board of Directors - Youth President',
  // boardOfDirectorsYouthSecretary = 'Board of Directors - Youth Secretary',
  // boardOfDirectorsYouthTreasurer = 'Board of Directors - Youth Treasurer',
  // boardOfDirectorsYouthVP = 'Board of Directors - Youth VP',
  // bookkeeper = 'Bookkeeper',
  // boxOffice = 'Box Office',
  // boxOfficeStudent = 'Box Office - Student',
  // boxOfficeUsherStaff = 'Box Office & Usher Staff',
  // boxOfficeCoordinator = 'Box Office Coordinator',
  // boxOfficeCrew = 'Box Office Crew',
  // boxOfficeManager = 'Box Office Manager',
  // boxOfficeSupervisor = 'Box Office Supervisor',
  // businessManager = 'Business Manager',
  // businessManagerStudio = 'Business Manager - Studio',
  // carpenter = 'Carpenter',
  // cello = 'Cello',
  // clarinet = 'Clarinet',
  // coachingConsultant = 'Coaching Consultant',
  // communication = 'Communication',
  // communicationsCoordinator = 'Communications Coordinator',
  // concessionsCoordinator = 'Concessions Coordinator',
  // concessionsSales = 'Concessions Sales',
  // conductor = 'Conductor',
  // conductorStudent = 'Conductor - Student',
  // conductorSub = 'Conductor Sub',
  // corporateLogo = 'Corporate Logo',
  // costume = 'Costume',
  // costumeVolunteer = 'Costume - Volunteer',
  // costumeAndMakeupCrew = 'Costume and Makeup Crew',
  // costumeAssistant = 'Costume Assistant',
  // costumeBuildCrew = 'Costume Build Crew',
  // costumeConstructionCrew = 'Costume Construction Crew',
  // costumeCoordinator = 'Costume Coordinator',
  // costumeCrew = 'Costume Crew',
  // costumeDesign = 'Costume Design',
  // costumeDesignFaculty = 'Costume Design - Faculty',
  // costumeDesignConsultant = 'Costume Design Consultant',
  // costumeDesigner = 'Costume Designer',
  // costumeHead = 'Costume Head',
  // costumeIntern = 'Costume Intern',
  // costumeRun = 'Costume Run',
  // costumeRunCrew = 'Costume Run Crew',
  // costumeShopCrew = 'Costume Shop Crew',
  // costumeShopStaff = 'Costume Shop Staff',
  // costumeShopTechnician = 'Costume Shop Technician',
  // costumeStaff = 'Costume Staff',
  // costumeStitcher = 'Costume Stitcher',
  // costumeStudioStudent = 'Costume Studio - Student',
  // costumeStudioManager = 'Costume Studio Manager',
  // costumeStudioSupervisor = 'Costume Studio Supervisor',
  // costumeStudioSupervisorStaff = 'Costume Studio Supervisor - Staff',
  // costumeTechnician = 'Costume Technician ',
  // costumeTechnicianStaff = 'Costume Technician - Staff',
  // costumer = 'Costumer',
  // costumes = 'Costumes',
  // costumesVolunteer = 'Costumes - Volunteer ',
  // coverDesign = 'Cover Design',
  // coverIllustration = 'Cover Illustration',
  // creativeAssistantToTheDirector = 'Creative Assistant to the Director',
  // creativeConsultant = 'Creative Consultant',
  // customsAssistant = 'Customs Assistant',
  // customsOfficial = 'Customs Official',
  // dance = 'Dance',
  // danceCaptain = 'Dance Captain',
  // dean = 'Dean',
  // deanInterim = 'Dean - Interim',
  // deckHead = 'Deck Head',
  // departmentAssistant = 'Department Assistant',
  // departmentChair = 'Department Chair',
  // departmentManager = 'Department Manager',
  // design = 'Design',
  // developmentDirector = 'Development Director',
  // dialectConsultant = 'Dialect Consultant',
  // dialogueCoach = 'Dialogue Coach',
  // directing = 'Directing',
  // director = 'Director',
  // directorOfConservatoryPrograms = 'Director of Conservatory Programs',
  // directorOfStageServices = 'Director of Stage Services',
  // directorOfTheatre = 'Director of Theatre',
  // directorOfUndergraduateStudies = 'Director of Undergraduate Studies',
  // dishwashers = 'Dishwashers',
  // doubleBass = 'Double Bass',
  // dramaticWriting = 'Dramatic Writing',
  // dramaturg = 'Dramaturg',
  // draper = 'Draper',
  // drums = 'Drums',
  // drumsPercussion = 'Drums/Percussion',
  // drumset = 'Drumset',
  // educationDirector = 'Education Director',
  // educationProgramCoodinator = 'Education Program Coodinator',
  // electrician = 'Electrician',
  // electrics = 'Electrics',
  // electricsStudent = 'Electrics - Student',
  // englishHorn = 'English Horn',
  // englishHornOboe = 'English Horn/Oboe',
  // executiveArtisticDirector = 'Executive Artistic Director',
  // executiveDirector = 'Executive Director',
  // executiveProducer = 'Executive Producer',
  // executiveVicePresident = 'Executive Vice President',
  // facilitiesCoodinator = 'Facilities Coodinator',
  // facultyAssistantStudent = 'Faculty Assistant - Student',
  // fightCaptain = 'Fight Captain',
  // fightChoreographer = 'Fight Choreographer',
  // firstHand = 'First Hand',
  // flute = 'Flute',
  // followSpot = 'Follow spot',
  // followSpotOperator = 'Follow Spot Operator',
  // frenchHorn = 'French Horn',
  // frontOfHouseAssistant = 'Front of House Assistant',
  // frontOfficeStudent = 'Front Office - Student',
  // galaCrew = 'Gala Crew',
  // generalManager = 'General Manager',
  // grantWriter = 'Grant Writer',
  // guitar = 'Guitar',
  // guitars = 'Guitars',
  // hair = 'Hair',
  // hairMakeup = 'Hair & Makeup',
  // hairMakeupCoordinatorStudent = 'Hair & Makeup Coordinator -Student',
  // hairAndMakeupDesigner = 'Hair and Makeup Designer',
  // hairConsultant = 'Hair Consultant',
  // hairCrew = 'Hair Crew',
  // hairDesign = 'Hair Design',
  // hairDesigner = 'Hair Designer',
  // harp = 'Harp',
  // headOrphanWrangler = 'Head Orphan Wrangler',
  // headshotsAndCandidPhotography = 'Headshots and Candid Photography',
  // historyPerformance = 'History/Performance',
  // homeschoolCoordinator = 'Homeschool Coordinator',
  // houseManager = 'House Manager',
  // instrumentals = 'Instrumentals',
  // intimacyDirector = 'Intimacy Director',
  // jobLead = 'Job Lead',
  // keyboardPageTurner = 'Keyboard - Page Turner',
  // keyboards = 'Keyboards',
  // lecturerFaculty = 'Lecturer - Faculty',
  // lightBoardOperator = 'Light Board Operator',
  // lightBoardProgrammer = 'Light Board Programmer',
  // lightCrew = 'Light Crew',
  // lightHangCrew = 'Light Hang Crew',
  // lighting = 'Lighting',
  // lightingStudent = 'Lighting - Student',
  // lightingAssistant = 'Lighting Assistant',
  // lightingDesign = 'Lighting Design',
  // lightingDesignFaculty = 'Lighting Design - Faculty',
  // lightingDesigner = 'Lighting Designer',
  // lightingIntern = 'Lighting Intern',
  // lobbyArtist = 'Lobby Artist',
  // makeup = 'Makeup',
  // makeupDesign = 'Makeup Design',
  // malletPercussion = 'Mallet Percussion',
  // managingDirector = 'Managing Director',
  // mandolin = 'Mandolin',
  // marketing = 'Marketing',
  // marketingDirector = 'Marketing Director',
  // masterCarpenter = 'Master Carpenter',
  // masterElectrician = 'Master Electrician',
  // microphoneWrangler = 'Microphone Wrangler',
  // mixer = 'Mixer',
  // movement = 'Movement',
  // movingLightProgrammer = 'Moving Light Programmer',
  // musicDirector = 'Music Director',
  // musicDirectorIntern = 'Music Director Intern',
  // musicalTheatre = 'Musical Theatre',
  // musicalTheatreDanceFaculty = 'Musical Theatre Dance - Faculty',
  // oboe = 'Oboe',
  // ochestraManager = 'Ochestra Manager',
  // office = 'Office',
  // officeAdministrator = 'Office Administrator',
  // officeAssistant = 'Office Assistant',
  // officeAssistantStudent = 'Office Assistant - Student',
  // officeManager = 'Office Manager',
  // outreachCoordinator = 'Outreach Coordinator',
  // paint = 'Paint',
  // paintShopSupervisor = 'Paint Shop Supervisor',
  // patronServicesManager = 'Patron Services Manager',
  // percussion = 'Percussion',
  // performance = 'Performance',
  // performanceFaculty = 'Performance - Faculty',
  // personalAssistantToTheDirector = 'Personal Assistant to the Director',
  // photographer = 'Photographer',
  // piano = 'Piano',
  // piccolo = 'Piccolo',
  // pitOrchestraCoordinator = 'Pit Orchestra Coordinator',
  // playbillCoordinators = 'Playbill Coordinators',
  // playbillDesign = 'Playbill Design',
  // playwriting = 'Playwriting',
  // playwritingScriptAnalysis = 'Playwriting, Script Analysis',
  // posterDesign = 'Poster Design',
  // producer = 'Producer',
  // producingArtisticDirector = 'Producing Artistic Director',
  // productionAdministrator = 'Production Administrator',
  // productionAssistant = 'Production Assistant',
  // productionCoordinator = 'Production Coordinator',
  // productionManager = 'Production Manager',
  // productionStageManager = 'Production Stage Manager',
  // productionTechnician = 'Production Technician',
  // productionTechnicianStaff = 'Production Technician - Staff',
  // productionTechnologyAndManagement = 'Production Technology and Management',
  // productionsTechnician = 'Productions Technician',
  // programAndPosterDesign = 'Program and Poster Design',
  // programCoodinator = 'Program Coodinator',
  // programDesign = 'Program Design',
  // projectManager = 'Project Manager',
  // projectionDesigner = 'Projection Designer',
  // promotionalVideoVolunteer = 'Promotional Video - Volunteer ',
  // propDesign = 'Prop Design',
  // properties = 'Properties',
  // propertiesArtisan = 'Properties Artisan',
  // propertiesBuild = 'Properties Build',
  // propertiesBuildCrew = 'Properties Build Crew',
  // propertiesBuildCrewPosters = 'Properties Build Crew - Posters',
  // propertiesManager = 'Properties Manager',
  // propertiesMaster = 'Properties Master',
  // propertiesRunCrew = 'Properties Run Crew',
  // propertiesStaff = 'Properties Staff',
  // propertyAssistant = 'Property Assistant',
  // propertyAssistantStudent = 'Property Assistant - Student',
  // propertyCrew = 'Property Crew',
  // props = 'Props',
  // propsStudent = 'Props - Student',
  // propsShopCrew = 'Props Shop Crew',
  // publicity = 'Publicity',
  // publicityStudent = 'Publicity - Student',
  // publicityPhotographs = 'Publicity Photographs',
  // reeds = 'Reeds',
  // registrar = 'Registrar',
  // rehearsalAccompanist = 'Rehearsal Accompanist',
  // residentCostumeDesigner = 'Resident Costume Designer',
  // residentLightingDesigner = 'Resident Lighting Designer',
  // residentMusicDirector = 'Resident Music Director',
  // residentScenicDesigner = 'Resident Scenic Designer',
  // residentStageManager = 'Resident Stage Manager',
  // runningCrew = 'Running Crew',
  // saxophone = 'Saxophone',
  // sceneryRunCrew = 'Scenery Run Crew',
  // scenePaintingCharge = 'Scene Painting Charge',
  // sceneShopCrew = 'Scene Shop Crew',
  // sceneShopStaff = 'Scene Shop Staff',
  // scenery = 'Scenery',
  // sceneryCrewHead = 'Scenery Crew Head',
  // sceneryHead = 'Scenery Head',
  // scenicLightingDesign = 'Scenic & Lighting Design',
  // scenicAdvisor = 'Scenic Advisor',
  // scenicArtist = 'Scenic Artist',
  // scenicArtistStudent = 'Scenic Artist - Student',
  // scenicArtistCharge = 'Scenic Artist Charge',
  // scenicAssistant = 'Scenic Assistant',
  // scenicDesignFaculty = 'Scenic Design - Faculty',
  // scenicDesigner = 'Scenic Designer',
  // scenicForeman = 'Scenic Foreman',
  // scenicPaintCharge = 'Scenic Paint Charge',
  // scenicPainter = 'Scenic Painter',
  // scenicRun = 'Scenic Run',
  // scenicShopCrew = 'Scenic Shop Crew',
  // scenicStudioStudent = 'Scenic Studio - Student',
  // scenicStudioSupervisor = 'Scenic Studio Supervisor',
  // scenicStudioSupervisorStaff = 'Scenic Studio Supervisor - Staff',
  // scenographyFaculty = 'Scenography - Faculty',
  // schoolMatineeCoordinator = 'School Matinee Coordinator',
  // scriptAnalysis = 'Script Analysis',
  // scriptLibraryStudent = 'Script Library - Student',
  // scriptLibraryPublicityStudent = 'Script Library & Publicity - Student',
  // seasonSubscriptionManager = 'Season Subscription Manager',
  // seniorAcademicAdvisor = 'Senior Academic Advisor',
  // seniorAssociateDean = 'Senior Associate Dean',
  // setVolunteer = 'Set - Volunteer ',
  // setDressingRoomsVolunteer = 'Set & Dressing Rooms - Volunteer ',
  // setBuildCrew = 'Set Build Crew',
  // setConstruction = 'Set Construction',
  // setDecoration = 'Set Decoration',
  // setDesigner = 'Set Designer',
  // setPaintingCrew = 'Set Painting Crew',
  // shopSupervisor = 'Shop Supervisor',
  // snackCoordinator = 'Snack Coordinator',
  // snackProvider = 'Snack Provider',
  // sopranoSaxophone = 'Soprano Saxophone',
  // sound = 'Sound  ',
  // soundStudent = 'Sound - Student',
  // soundAssistant = 'Sound Assistant',
  // soundAssociate = 'Sound Associate',
  // soundBoardOperator = 'Sound Board Operator',
  // soundCoordinator = 'Sound Coordinator',
  // soundCrew = 'Sound Crew',
  // soundDesignFaculty = 'Sound Design - Faculty',
  // soundDesigner = 'Sound Designer',
  // soundEngineer = 'Sound Engineer',
  // soundFX = 'Sound F/X ',
  // soundIntern = 'Sound Intern',
  // soundMixer = 'Sound Mixer',
  // specialEventsCoordinator = 'Special Events Coordinator',
  // spotlightOperator = 'Spotlight Operator',
  // stageCombat = 'Stage Combat',
  // stageConstructionConsultant = 'Stage Construction Consultant',
  // stageConstructionCrew = 'Stage Construction Crew',
  // stageCrew = 'Stage Crew',
  // stageEngineer = 'Stage Engineer',
  // stageHand = 'Stage Hand',
  // stageHandIntern = 'Stage Hand Intern',
  // stageMakeup = 'Stage Makeup',
  // stageMakeupFaculty = 'Stage Makeup - Faculty',
  // stageManagement = 'Stage Management',
  // stageManagementAdvisor = 'Stage Management Advisor',
  // stageManager = 'Stage Manager',
  // stagemanagementAdvisor = 'Stagemanagement Advisor',
  // stateThespianFestivalChaperone = 'State Thespian Festival Chaperone',
  // studentMarketingManager = 'Student Marketing Manager',
  // subscriptionAssistant = 'Subscription Assistant',
  // surveyOfTheatre = 'Survey of Theatre',
  // surveyOfTheatreFaculty = 'Survey of Theatre - Faculty',
  // synthesizer = 'Synthesizer',
  // technicalAdviser = 'Technical Adviser',
  // technicalDirector = 'Technical Director',
  // technicalDirectorFaculty = 'Technical Director - Faculty',
  // technicalProduction = 'Technical Production',
  // tenorSaxophone = 'Tenor Saxophone',
  // theaterSupervisor = 'Theater Supervisor',
  // theatre = 'Theatre',
  // theatreComputerLabStudent = 'Theatre Computer Lab - Student',
  // theatreHistory = 'Theatre History',
  // theatreManagement = 'Theatre Management',
  // theatreDance = 'Theatre/Dance',
  // trombone = 'Trombone  ',
  // trumpet = 'Trumpet',
  // trumpetFlugelhorn = 'Trumpet/Flugelhorn',
  // trumpets = 'Trumpets',
  // tuba = 'Tuba',
  // ukulele = 'Ukulele',
  // usher = 'Usher',
  // usherCrew = 'Usher Crew',
  // video = 'Video',
  // videoAndMediaTechnician = 'Video and Media Technician',
  // videoEngineer = 'Video Engineer',
  // videoOperator = 'Video Operator',
  // videoTechnologyVolunteer = 'Video Technology -  Volunteer ',
  // videographer = 'Videographer',
  // viola = 'Viola',
  // violin = 'Violin',
  // vocalCoach = 'Vocal Coach',
  // vocalDirector = 'Vocal Director',
  // voiceAndSpeech = 'Voice and Speech',
  // voiceover = 'Voiceover',
  // volunteerStudent = 'Volunteer - Student',
  // volunteerCoordinator = 'Volunteer Coordinator',
  // wardrobe = 'Wardrobe',
  // wardrobeDayCrew = 'Wardrobe Day Crew',
  // wardrobeStockSupervisor = 'Wardrobe Stock Supervisor',
  // wardrobeSupervisor = 'Wardrobe Supervisor',
  // webMistress = 'Web Mistress',
  // webmaster = 'Webmaster',
  // wigsHairStylist = 'Wigs & Hair Stylist',
  // woodwinds = 'Woodwinds'
}
