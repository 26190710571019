import React, { useEffect, useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import {
  PageFeaturedStudio,
  addFeaturedStudioInSummaryPage,
  deleteFeaturedStudiosInSummaryPage,
  updateFeaturedStudioInSummaryPage,
} from '../../../api/summaryPage';
import { Button, SideDrawer } from '../../../components';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';
import Details from '../../common/Details';
import { Studio } from '../../../types/Studio';
import StudioPicker from '../home/shared/StudioPicker';

const MAX_COUNT = 2;

const FeaturedStudios = ({
  studios: studios = [],
}: {
  studios: PageFeaturedStudio[];
}) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<boolean>(false);
  const [featuredStudios, setFeaturedStudios] = useState(studios);
  const [currentStudio, setCurrentStudio] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedStudioId?: string;
  }>({
    open: false,
    deleteFeaturedStudioId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteProductionCallback = async () => {
    try {
      const studioId = confirmDelete.deleteFeaturedStudioId;
      setIsDeleting(true);
      await deleteFeaturedStudiosInSummaryPage(studioId);
      setFeaturedStudios((studios) => studios.filter((p) => p.id !== studioId));
      setIsDeleting(false);
      setConfirmDelete({ open: false });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    setFeaturedStudios(studios);
  }, [studios]);

  return (
    <Box>
      <Details
        title="Featured Studios"
        onAddButton={() => {
          if (featuredStudios.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} studios can be featured.`);
            return;
          }
          setOpenDrawerState(true);
        }}
      >
        <>
          <p>
            Choose {MAX_COUNT} studios to display at the top of the Studios page
          </p>
          {featuredStudios.map((fp) => (
            <StudioRow
              key={fp.id}
              studio={fp}
              onEdit={(studio) => {
                setCurrentStudio(studio);
                setOpenDrawerState(true);
              }}
              onDelete={(studioId) => {
                setConfirmDelete({
                  open: true,
                  deleteFeaturedStudioId: studioId,
                });
              }}
            />
          ))}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState}
        toggleDrawer={() => setOpenDrawerState(false)}
        styles={{
          width: '550px',
        }}
      >
        <StudioPicker
          title="Featured Studio"
          handleClose={(studio, isEditMode) => {
            if (studio) {
              if (isEditMode) {
                setFeaturedStudios((studios) =>
                  studios.map((p) => {
                    if (p.id === studio.id) {
                      return studio;
                    }
                    return p;
                  }),
                );
              } else {
                setFeaturedStudios((studios) => [...studios, studio]);
              }
            }
            setOpenDrawerState(false);
          }}
          handleSave={(args) => {
            if (args.isEditMode) {
              return updateFeaturedStudioInSummaryPage({
                studioId: args.studioId,
                featuredProductionMediaId: args.featuredProductionMediaId,
              });
            } else {
              return addFeaturedStudioInSummaryPage({
                studioId: args.studioId,
                featuredProductionMediaId: args.featuredProductionMediaId,
              });
            }
          }}
          currentStudio={currentStudio}
          requireProductionMedia={true}
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteProductionCallback}
      />
    </Box>
  );
};

function StudioRow({
  studio,
  onDelete,
  onEdit,
}: {
  studio: Partial<Studio>;
  onDelete: (studioId: string) => void;
  onEdit?: (studio: Partial<Studio>) => void;
}): JSX.Element {
  const { name: studioName, city, state } = studio;
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={studio.id}>
          <b>{studioName}</b>
          {` - ${city || 'N/A'}, ${state || 'N/A'}`}
        </ListItem>
        {onEdit && (
          <Button
            label="Edit"
            variant={'outlined'}
            color={'secondary'}
            onClick={() => {
              onEdit(studio);
            }}
            sx={{ ml: 2 }}
          />
        )}
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(studio.id);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}

export default FeaturedStudios;
