import { useMutation, useQuery } from 'react-query';
import { Studio } from '../types/Studio';
import axios from '../config/http-common';
import { getExtFromFileName } from '../utils/media';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { inviteUsertoStudio } from '../views/studios/CreateStudios/InviteUser';
import {
  UploadMediaType,
  uploadMediaToS3,
  uploadMediaToS3_File,
} from './utils';

import { Production } from '../types/Production';
import { QueuedFiles } from '../types/Global';

export function useGetAllStudios() {
  return useQuery(['allStudios'], () =>
    axios.get('studios').then((response) => response.data as Studio[]),
  );
}

export function useGetStudio(id: string) {
  if (!id) {
    return { data: null, isSuccess: false, isLoading: false };
  }
  return useQuery<Studio, Error>(['selectedStudio'], () =>
    axios.get(`studios/${id}`).then((response) => response.data),
  );
}
export function usePostStudio() {
  return useMutation((data) =>
    axios.post('/studios', data).then((response) => response.data),
  );
}
export function usePutStudio(studioId: string) {
  return useMutation((data) =>
    axios.put(`/studios/${studioId}`, data).then((response) => response.data),
  );
}
export function useDeleteStudio(studioId: string) {
  return useMutation<Studio[], Error>(() =>
    axios.delete(`/studios/${studioId}`).then((response) => response.data),
  );
}

export function useInviteUserToStudio(data: inviteUsertoStudio) {
  return axios.post('/auth/invite-user', data);
}

export function useGetInvitedUsers(studioId: string) {
  return useQuery(['invitedUsers'], () =>
    axios
      .get(`/user-profiles/studio/${studioId}`)
      .then((response) => response.data),
  );
}

export function useGetUserGroup(users) {
  return users?.map(async (user) => {
    const groupResponse = await axios.get(
      `/user-profiles/studioUserGroup/${user.userId}`,
    );
    return {
      userId: user.userId,
      group: groupResponse,
    };
  });
}

export function useUpdateUserGroup(updateBody) {
  return axios
    .put('/user-profiles/updateStudioUserGroup', updateBody)
    .then((response) => response.data);
}

export function useUpdateUserProfile(updateUserProfileBody) {
  return axios
    .put('/user-profiles/updateUserProfile', updateUserProfileBody)
    .then((response) => response.data);
}

export function useDeleteUserProfile(deleteUserProfileBody) {
  console.log(deleteUserProfileBody);
  return axios
    .delete('/user-profiles/deleteUserProfile', { data: deleteUserProfileBody })
    .then((response) => response.data);
}

export async function useDeleteUser(deleteUserBody) {
  console.log(deleteUserBody);
  return await axios
    .delete('/user-profiles/deleteUser', { data: deleteUserBody })
    .then((response) => response.data);
}

export async function getStudioMedia(
  studioId: string,
  mediaType: UploadMediaType,
) {
  return await axios.get(`/studios/${studioId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(studioId: string, mediaId: string) {
  return await axios.delete(`/studios/${studioId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  studioId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  const associatedMedia = await getStudioMedia(studioId, uploadMediaType);

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.studioId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('studios/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const studioMediaResponse = await axios.post(
    `/studios/${studioId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return studioMediaResponse.data;
}

export async function uploadMediaMultiple(
  studioId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  try {
    const { signedUrl, fileName } = (
      await axios.get('studios/uploadMedia', {
        params: {
          contentType: mediaData.fileType,
          fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
        },
      })
    ).data;

    const uploadMediaToS3Response = await uploadMediaToS3_File(
      signedUrl,
      mediaData,
    );

    const studioMediaResponse = await axios.post(
      `/studios/${studioId}/process-media`,
      {
        mediaName: mediaData.fileInfo.fileName,
        fileName: fileName,
        uploadMediaType: uploadMediaType,
      },
    );

    return studioMediaResponse.data;
  } catch (error) {
    console.log(error);
  }
}

export function useDeleteFeaturedStudioProduction({
  institutionId,
  productionId,
}: {
  studioId: string;
  institutionId: string;
  productionId: string;
}) {
  return useMutation<Production[], Error>(() =>
    axios
      .delete(
        `/institutions/${institutionId}/featured/production/${productionId}`,
      )
      .then((response) => response.data),
  );
}

export function useCreateStudioFeaturedProduction() {
  return useMutation(
    (args: { institutionId: string; productionId: string }) => {
      return axios
        .post(`/institutions/${args.institutionId}/featured/production`, args)
        .then((response) => response.data);
    },
  );
}

export function useCreateStudioFeaturedContribution() {
  return useMutation(
    (args: { productionContributionId: string; institutionId: string }) => {
      return axios
        .post(
          `/studios/${args.institutionId}/featured/production-contribution`,
          args,
        )
        .then((response) => response.data);
    },
  );
}

export function useDeleteFeaturedStudioContribution({
  institutionId,
  studioId,
  productionContributionId,
}: {
  studioId: string;
  institutionId: string;
  productionContributionId: string;
}) {
  return useMutation<Production[], Error>(() =>
    axios
      .delete(
        `/studios/${studioId}/institution/${institutionId}/featured/production-contribution/${productionContributionId}`,
      )
      .then((response) => response.data),
  );
}

export async function uploadFeaturedMedia(studioId: string, mediaId: string) {
  return await axios
    .post(`/studios/${studioId}/featured-media/${mediaId}`)
    .then((response) => response.data);
}

export async function deleteFeaturedMedia(studioId: string, mediaId: string) {
  return await axios
    .delete(`/studios/${studioId}/featured-media/${mediaId}`)
    .then((response) => response.data);
}

export async function getStudioFeaturedMedia(studioId: string) {
  return await axios
    .get(`/studios/${studioId}/featured-media`)
    .then((response) => response.data);
}
