import React, { useEffect, useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import {
  PageFeaturedVenue,
  addFeaturedVenueInSummaryPage,
  deleteFeaturedVenuesInSummaryPage,
  updateFeaturedVenueInSummaryPage,
} from '../../../api/summaryPage';
import { Button, SideDrawer } from '../../../components';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';
import Details from '../../common/Details';
import { Venue } from '../../../types/Venue';
import VenuePicker from '../home/shared/VenuePicker';

const MAX_COUNT = 2;

const FeaturedVenues = ({ venues = [] }: { venues: PageFeaturedVenue[] }) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<boolean>(false);
  const [featuredVenues, setFeaturedVenues] = useState(venues);
  const [currentVenue, setCurrentVenue] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedVenueId?: string;
  }>({
    open: false,
    deleteFeaturedVenueId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteProductionCallback = async () => {
    try {
      const venueId = confirmDelete.deleteFeaturedVenueId;
      setIsDeleting(true);
      await deleteFeaturedVenuesInSummaryPage(venueId);
      setFeaturedVenues((venues) => venues.filter((p) => p.id !== venueId));
      setIsDeleting(false);
      setConfirmDelete({ open: false });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    setFeaturedVenues(venues);
  }, [venues]);

  return (
    <Box>
      <Details
        title="Featured Venues"
        onAddButton={() => {
          if (featuredVenues.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} venues can be featured.`);
            return;
          }
          setOpenDrawerState(true);
        }}
      >
        <>
          <p>
            Choose {MAX_COUNT} venues to display at the top of the Venues page
          </p>
          {featuredVenues.map((fp) => (
            <VenueRow
              key={fp.id}
              venue={fp}
              onEdit={(venue) => {
                setCurrentVenue(venue);
                setOpenDrawerState(true);
              }}
              onDelete={(venueId) => {
                setConfirmDelete({
                  open: true,
                  deleteFeaturedVenueId: venueId,
                });
              }}
            />
          ))}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState}
        toggleDrawer={() => setOpenDrawerState(false)}
        styles={{
          width: '550px',
        }}
      >
        <VenuePicker
          title="Featured Venue"
          handleClose={(venue, isEditMode) => {
            if (venue) {
              if (isEditMode) {
                setFeaturedVenues((venues) =>
                  venues.map((p) => {
                    if (p.id === venue.id) {
                      return venue;
                    }
                    return p;
                  }),
                );
              } else {
                setFeaturedVenues((venues) => [...venues, venue]);
              }
            }
            setOpenDrawerState(false);
          }}
          handleSave={(args) => {
            if (args.isEditMode) {
              return updateFeaturedVenueInSummaryPage({
                venueId: args.venueId,
                featuredProductionMediaId: args.featuredProductionMediaId,
              });
            } else {
              return addFeaturedVenueInSummaryPage({
                venueId: args.venueId,
                featuredProductionMediaId: args.featuredProductionMediaId,
              });
            }
          }}
          currentVenue={currentVenue}
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteProductionCallback}
      />
    </Box>
  );
};

function VenueRow({
  venue,
  onDelete,
  onEdit,
}: {
  venue: Partial<Venue>;
  onDelete: (venueId: string) => void;
  onEdit?: (venue: Partial<Venue>) => void;
}): JSX.Element {
  const { name: venueName, city, state } = venue;
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={venue.id}>
          <b>{venueName}</b>
          {` - ${city || 'N/A'}, ${state || 'N/A'}`}
        </ListItem>
        {onEdit && (
          <Button
            label="Edit"
            variant={'outlined'}
            color={'secondary'}
            onClick={() => {
              onEdit(venue);
            }}
            sx={{ ml: 2 }}
          />
        )}
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(venue.id);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}

export default FeaturedVenues;
