import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Artist } from '../../../types/Artist';
import { useCustomForm } from '../../plays/CreatePlays/useCustomForm';

const AddArtistsValidation = (
  defaultValues: Partial<Artist> = {
    firstName: null,
    lastName: null,
    studioId: null,
    dob: null,
    bio: null,
    email: null,
    hometown: null,
    homeState: null,
    homeCountry: null,
    unionAffiliation: null,
    professionalName: null,
    awards: null,
    resourceLocation: null,
    website: null,
    privacyEnabled: true,
    selectedStudio: null,
  },
) => {
  const Schema = yup.object().shape({
    firstName: yup
      .string()
      .nullable()
      .max(100)
      .required('First Name is required'),
    lastName: yup
      .string()
      .nullable()
      .max(100)
      .required('Last Name is required'),
    //studioId: yup.string().nullable().max(100).required('Studio ID is required'),
    privacyEnabled: yup.boolean().nullable().notRequired().default(true),
    dob: yup.date().nullable().notRequired(),
    bio: yup.string().max(500).nullable().notRequired(),
    email: yup.string().max(50).nullable().notRequired(),
    hometown: yup.string().max(100).nullable().notRequired(),
    homeState: yup.string().max(100).nullable().notRequired(),
    homeCountry: yup.string().max(100).nullable().notRequired(),
    unionAffiliation: yup.array().nullable().notRequired(),
    professionalName: yup.string().min(2).max(30).nullable().notRequired(),
    awards: yup.array().nullable().notRequired(),
    website: yup.string().min(2).max(100).nullable().notRequired(),
    selectedStudio: yup.object().nullable().notRequired(),
  });

  console.log('this is being hit');

  return useCustomForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(Schema),
  });
};

export { AddArtistsValidation };
