import React, { useState } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  FormGroup,
  FormHelperText,
  InputLabel,
  styled,
  TextField,
  TextFieldProps,
  CircularProgress,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { createFilterOptions } from '@mui/material/Autocomplete';

type SelectType = {
  id: string | number;
  label: string;
  onClick?: () => void;
};

export type AutocompleteValue = {
  id: string;
  label: string;
};

type AutocompleteCustomProps = {
  options: SelectType[];
  control: any;
  name: string;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  getOptionLabel?: (value: any) => string;
  getSelectedOption?: (value: any) => void;
  onValueChange?: (value: any) => void;
  loading?: boolean;
  getSearchValue?: any;
} & TextFieldProps;

const MuiTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    padding: '0 !important',
  },
}));

export default function Autocomplete(props: AutocompleteCustomProps) {
  const {
    label,
    options,
    control,
    name,
    placeholder = 'Select',
    getSelectedOption,
    disabled = false,
    onValueChange,
    loading,
    getSearchValue,
  } = props;
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
    ignoreCase: true,
  });

  const [inputValue, setInputValue] = useState('');

  return (
    <FormGroup>
      {label && (
        <InputLabel
          id={label.toLowerCase().replace(' ', '-')}
          aria-label={label}
          variant="standard"
        >
          {label}
        </InputLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...field }, fieldState: { error } }) => {
          return (
            <>
              <MuiAutocomplete
                {...field}
                options={options}
                //value=''
                disableClearable
                getOptionLabel={(option: any) =>
                  option.label == undefined ? '' : option.label
                }
                filterOptions={filterOptions}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option.id === value.id;
                }}
                onChange={(event: any, newValue: any | null) => {
                  onChange(newValue);
                  onValueChange && onValueChange(newValue);
                }}
                disabled={disabled}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  getSearchValue && getSearchValue(newInputValue);
                }}
                renderInput={(params) => (
                  <MuiTextField
                    error={!!error}
                    placeholder={placeholder}
                    fullWidth
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: loading ? (
                        <CircularProgress
                          size={24}
                          sx={{ marginRight: '8px' }}
                        />
                      ) : (
                        <></>
                      ),
                    }}
                  />
                )}
                loading={loading}
              />
              {error ? (
                <FormHelperText error>{error.message}</FormHelperText>
              ) : null}
            </>
          );
        }}
      />
    </FormGroup>
  );
}
