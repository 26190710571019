import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  Alert,
  Paper,
  Select,
  TextField,
  Autocomplete,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { CreateVenueValidation } from './VenuesValidationSchema';
import { useStyles } from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAllStudios } from '../../../api/studios';

import {
  deleteFeaturedMedia,
  deleteMedia,
  getVenueFeaturedMedia,
  uploadMediaSingle,
  useDeleteVenue,
  useGetVenue,
  usePostVenue,
  usePutVenue,
} from '../../../api/venues';
import { VenueStageConfiguration } from '../../../types/Venue';
import { AlertState } from '../../../types/Global';
import {
  alertAutoHideDuration,
  GlobalMessages,
  PosterMaxFileSizeInMB,
  PosterSupportedFormat,
} from '../../../utils/globalConfig';
import Loader from '../../../components/Loader';
import { Studio } from '../../../types/Studio';
import SingleImageUpload, {
  QueueFileInfo,
} from '../../../components/Upload/SingleImageUpload';
import { UploadMediaType, getGeoCoordinates } from '../../../api/utils';
import FeaturedVenueProductions from './featured/FeaturedVenueProductions';
import FeaturedVenueStudios from './featured/FeaturedVenueStudios';
import DisplaySelectedFeaturedMedia from './featured/DisplaySelectedFeaturedMediaVenues';

export default function CreateVenue() {
  const { id: venueId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleSubmit, control, reset } = CreateVenueValidation();
  const {
    data: venue,
    isSuccess: isVenueLoaded,
    isLoading: isVenueLoading,
  } = useGetVenue(venueId);
  const updateMutation = usePutVenue(venueId);
  const postMutation = usePostVenue();
  const deleteMutation = useDeleteVenue(venueId);
  const {
    data: studios,
    isSuccess: isStudiosLoaded,
    isLoading: isStudiosLoading,
  } = useGetAllStudios();
  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });

  const {
    isError: postError,
    isLoading: postLoading,
    isSuccess: postSuccess,
  } = postMutation;
  const {
    isError: updateError,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
  } = updateMutation;
  const {
    isError: deleteArtistsError,
    isSuccess: deleteArtistsSuccess,
    isLoading: deleteArtistsLoading,
  } = deleteMutation;
  const [fileOnQueue, setFileOnQueue] = useState<QueueFileInfo>();
  const [fileToBeDeleted, setFileToBeDeleted] = useState<{
    artistId: string;
    mediaId: string;
  }>();
  const [venueImage, setVenueImage] = useState<{
    artistId: string;
    mediaId: string;
  }>();
  const [loader, setLoader] = useState(false);

  const [displayFeaturedMedia, setDisplayFeaturedMedia] = useState([]);

  const deleteDisplayFeaturedMedia = async (mediaId) => {
    setDisplayFeaturedMedia([
      ...displayFeaturedMedia.filter((media) => media?.mediaId !== mediaId),
    ]);
    const deleteResponse = await deleteFeaturedMedia(venueId, mediaId);
    return deleteResponse;
  };

  const getInitFeaturedMedia = async () => {
    const allFeaturedMedia = await getVenueFeaturedMedia(venueId);
    setDisplayFeaturedMedia(allFeaturedMedia);
  };

  useEffect(() => {
    if (venue && isVenueLoaded && isStudiosLoaded) {
      setVenueImage(venue?.venueImageDetails);
      reset({
        ...venue,
        studioId: venue.studioId ? venue.studioId : null,
        selectedStudio: venue.studio
          ? {
              id: venue.studio?.id,
              label: venue.studio?.name,
            }
          : null,
      });
      getInitFeaturedMedia();
    }
  }, [isVenueLoaded, isStudiosLoaded]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['selectedVenue']);
    };
  }, []);

  async function updateFiles(venueId: string) {
    if (!venueId)
      return {
        venueImageUploadResponse: null,
        venueImageDeleteResponse: null,
        errors: ['No Venue is provided'],
      };

    const errors = [];

    let venueImageUploadPromise, venueImageDeletePromise;

    if (fileOnQueue) {
      venueImageUploadPromise = uploadMediaSingle(
        venueId,
        fileOnQueue,
        UploadMediaType.logo,
      ).catch((error) =>
        errors.push(`Error uploading venue image: ${error.message}`),
      );
    }

    if (fileToBeDeleted) {
      venueImageDeletePromise = deleteMedia(venueId, venueImage?.mediaId).catch(
        (error) => errors.push(`Error deleting venue image: ${error.message}`),
      );
    }

    try {
      const [venueImageUploadResponse, venueImageDeleteResponse] =
        await Promise.all([venueImageUploadPromise, venueImageDeletePromise]);
      setFileOnQueue(null);

      return {
        venueImageUploadResponse,
        venueImageDeleteResponse,
        errors,
      };
    } catch (error) {
      errors.push(`Error updating images: ${error.message}`);
      return {
        venueImageUploadResponse: null,
        venueImageDeleteResponse: null,
        errors,
      };
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const geoCoordinateValues = await getGeoCoordinates({
        street: data.street,
        city: data.city,
        state: data.state,
        country: data.country,
        zipcode: data.zipcode,
      });
      const formData = {
        ...data,
        studioId: data.selectedStudio ? data.selectedStudio.id : null,
        latitude: geoCoordinateValues?.lat,
        longitude: geoCoordinateValues?.long,
      };
      console.log(formData);
      setLoader(true);
      const venue = venueId
        ? await updateMutation.mutateAsync(formData)
        : await postMutation.mutateAsync(formData);
      const fileOpResults = await updateFiles(
        venueId || (venue ? venue.id : null),
      );
      setLoader(false);
      if (fileOpResults.errors.length > 0) {
        setAlertState({
          active: true,
          message:
            'File upload errors:\n• ' + fileOpResults.errors.join('\n• '),
          error: true,
        });
      } else {
        setAlertState({
          active: true,
          message: GlobalMessages.updateVenueSuccessMsg,
        });
        if (!venueId && venue && venue.id) {
          reset();
          navigate(`/venues/${venue.id}/edit`);
        }
      }
    } catch (e) {
      setLoader(false);
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };

  const handleDeleteVenue = async () => {
    try {
      await deleteMutation.mutateAsync().then(() => {
        setAlertState({
          active: true,
          message: GlobalMessages.deleteVenueSuccessMsg,
        });
        handleClose();
      });
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };
  const handleClose = () => {
    queryClient.invalidateQueries(['selectedVenue']).then(() => {
      reset();
      navigate('/venues');
    });
  };

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['selectedVenue']);
    };
  }, []);

  const successAlert = postSuccess || updateSuccess || deleteArtistsSuccess;
  const errorAlert = postError || updateError || deleteArtistsError;
  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;

  return (
    <>
      <Alert
        message={alertState.message}
        open={showAlert}
        severity={alertState.error ? 'error' : 'success'}
        autoHideDuration={alertState.error ? 5000 : alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
      <Loader
        isLoading={
          postLoading ||
          updateLoading ||
          deleteArtistsLoading ||
          isVenueLoading ||
          isStudiosLoading ||
          loader
        }
      />
      <Box className={classes.bodyBackground}>
        <DialogHeader
          title={venueId ? 'Edit Venue' : 'Create Venue'}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={venueId && handleDeleteVenue}
          cancel={() => handleClose()}
        />
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Paper>
                <Box className={classes.accountInfo}>
                  <Typography variant="h1">Venue Media</Typography>
                  <br />
                  <SingleImageUpload
                    title={'Set Poster'}
                    maxFileSizeInMB={PosterMaxFileSizeInMB}
                    validator={(fileExt) =>
                      PosterSupportedFormat.includes(fileExt)
                    }
                    initialUpload={
                      venue && venue.venueImageURL ? venue.venueImageURL : ''
                    }
                    uploadCallback={async (blob, file) => {
                      setFileOnQueue({
                        blob,
                        mimeType: file.type,
                        name: file.name,
                        type: 'image',
                        file: file,
                      });
                    }}
                    deleteCallback={() => {
                      setFileToBeDeleted(venueImage);
                    }}
                  />
                  <Box mt={3}>
                    <TextField
                      name="name"
                      label="Venue Name"
                      control={control}
                    />
                  </Box>
                  <Divider sx={{ marginBottom: '24px' }} />
                  <Divider sx={{ marginBottom: '24px', marginTop: '12px' }} />
                  <Typography variant="h1">Set Venue Details</Typography>
                  <Box mt={3}>
                    <TextField
                      name="locationName"
                      label="Location Name"
                      control={control}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', mt: 3 }}>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        label={'Studio'}
                        placeholder="Selected Studio"
                        name="selectedStudio"
                        control={control}
                        options={((studios as Studio[]) || []).map(
                          (studio: Studio) => {
                            return {
                              id: studio.id,
                              label: studio.name,
                            };
                          },
                        )}
                      />
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <TextField name="street" label="Street" control={control} />
                  </Box>
                  <Box mt={3}>
                    <TextField name="city" label="City" control={control} />
                  </Box>
                  <Box mt={3}>
                    <TextField label="State" name="state" control={control} />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Zip Code"
                      name="zipcode"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Country"
                      name="country"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Website"
                      name="website"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={(
                        Object.keys(VenueStageConfiguration) as Array<
                          keyof typeof VenueStageConfiguration
                        >
                      ).map((key) => VenueStageConfiguration[key])}
                      control={control}
                      name="stageConfiguration"
                      label="Stage Configuration"
                      placeholder={'Select Stage Configurations'}
                      multiple
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Special Features"
                      name="specialFeatures"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Venue Capacity"
                      name="venueCapacity"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Completion Year"
                      name="completionYear"
                      control={control}
                    />
                  </Box>
                  {venue && venueId && (
                    <>
                      <Box mt={3}>
                        <FeaturedVenueProductions
                          productionCountByStudioType={{}}
                          productions={venue?.featuredProductions}
                          venueId={venueId}
                          getDisplayFeaturedMedia={(medias) => {
                            setDisplayFeaturedMedia(medias);
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <FeaturedVenueStudios
                          studios={venue?.featuredStudios}
                          venueId={venueId}
                        />
                      </Box>
                      <Box>
                        <DisplaySelectedFeaturedMedia
                          featuredMedia={displayFeaturedMedia}
                          productionCountByStudioType={{}}
                          productions={venue?.featuredProductions}
                          venueId={venueId}
                          getDisplayFeaturedMedia={(medias) => {
                            setDisplayFeaturedMedia(medias);
                          }}
                          deleteDisplayFeaturedMedia={
                            deleteDisplayFeaturedMedia
                          }
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
