import { Play } from './Play';

export type Character = {
  id: string;
  playId: string;
  name: string;
  tier?: string;
  gender?: Gender;
  ageRangeTop?: number;
  ageRangeBottom?: number;
  race?: CharacterRace;
  vocalRangeTop?: string;
  vocalRangeBottom?: string;
  voiceStyles?: string[];
  voiceType?: VoiceType;
  castingNotes?: string;
  description?: string;
  ethnicity?: CharacterEthnicity;
  play?: Play;
};

export enum Gender {
  woman = 'Woman',
  man = 'Man',
  child = 'Child',
  femaleChild = 'Female Child',
  maleChild = 'Male Child',
  nonBinary = 'Non-binary',
  transWoman = 'Trans Woman',
  transMan = 'Trans Man',
  genderFluid = 'Genderfluid',
  genderQueer = 'Genderqueer',
  agender = 'Agender',
  notGendered = 'Not Gendered',
  unlisted = 'Unlisted',
}

export enum CharacterTier {
  leading = 'Leading',
  featured = 'Featured',
  ensemble = 'Ensemble',
}

export enum CharacterRace {
  americanIndianOrAlaskaNative = 'American Indian or Alaska Native',
  AsianOrAsianAmerican = 'Asian or Asian American',
  blackOrAfricanAmerican = 'Black or African American',
  nativeHawaiianOrPacificIslander = 'Native Hawaiian or Pacific Islander',
  white = 'White',
  otherRace = 'Other Race',
  multiRacial = 'Multi-Racial',
}

export enum CharacterEthnicity {
  hispanicLatino = 'Hispanic or Latino',
  notHispanicLatino = 'Not Hispanic or Latino',
}

export enum VoiceType {
  soprano = 'Soprano',
  collaturaSoprano = 'Collatura Soprano',
  mezzoSoprano = 'Mezzo Soprano',
  alto = 'Alto',
  tenor = 'Tenor',
  baritenor = 'Baritenor',
  baritone = 'Baritone',
  bass = 'Bass',
  nonSinger = 'Non-singer',
}

export enum VoiceStyles {
  belt = 'Belt',
  highBelt = 'High Belt',
  legit = 'Legit',
  character = 'Character',
  collatura = 'Collatura',
  latinxInfluence = 'Latinx influence',
  highNotes = 'High notes',
  lowNotes = 'Low notes',
  whistleTones = 'Whistle Tones',
  falsetto = 'Falsetto',
  highFalsetto = 'High Falsetto',
  boySoprano = 'Boy Soprano',
  rock = 'Rock',
  rockScream = 'Rock Scream',
  jazz = 'Jazz',
  rB = 'R&B',
  gospel = 'Gospel',
  riffing = 'Riffing',
  opera = 'Opera',
  wagnerian = 'Wagnerian',
  pop = 'Pop',
  contemporaryMT = 'Contemporary MT',
  indiePop = 'Indie Pop',
  folk = 'Folk',
  country = 'Country',
  grunge = 'Grunge',
  lyrical = 'Lyrical',
  patter = 'Patter',
  speedRap = 'Speed Rap',
  rap = 'Rap',
  rasp = 'Rasp',
  dirty = 'Dirty',
  versatility = 'Versatility',
  imitation = 'Imitation',
  impression = 'Impression',
  holdingParts = 'Holding parts',
  dialectSouthern = 'Dialect: Southern',
  dialectScottish = 'Dialect: Scottish',
  dialectIrish = 'Dialect: Irish',
  dialectBritish = 'Dialect: British',
  dialectMidAtlantic = 'Dialect: Mid-Atlantic',
  dialectGerman = 'Dialect: German',
  dialectFrench = 'Dialect: French',
  dialectEasternEuropean = 'Dialect: Eastern European',
  dialectGreek = 'Dialect: Greek',
  dialectStandard = 'Dialect: Standard',
  dialectAustralian = 'Dialect: Australian',
  dialectAmerican = 'Dialect: American',
  dialectNewYork = 'Dialect: New York',
  dialectSpanish = 'Dialect: Spanish',
  oldAge = 'Old age',
}

// export VoiceRange: Record<VoiceType, []string> {
//   soprano = ['C4', 'E6']
// }

// Soprano	C4-E6
// Mezzo-Soprano	A3-F5
// Alto	F3-D5
// Tenor	B2-G4
// Baritenor
// Baritone	G2-E4
// Bass	E2-C4
