import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  Alert,
  Autocomplete,
  Paper,
  Select,
  TextField,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { AddArtistsValidation } from './PlaysValidationSchema';
import { useStyles } from '../styles';
import { AddArtistsProps } from '../types';
import { usePostArtist } from '../../../api/artists';
import ErrorMessage from '../../../components/ErrorMessage';
import Loader from '../../../components/Loader';
import { AlertState } from '../../../types/Global';
import {
  alertAutoHideDuration,
  GlobalMessages,
} from '../../../utils/globalConfig';
import { useAuth } from '../../../hooks/useAuth';
import { useGetAllStudios } from '../../../api/studios';
import { Studio } from '../../../types/Studio';
import { Artist, UnionAffiliation } from '../../../types/Artist';

export default function AddArtists(props: AddArtistsProps<Artist>) {
  const { onClose } = props;
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { data: studios } = useGetAllStudios();

  const postMutation = usePostArtist();

  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });

  const { user } = useAuth();
  const { isLoading, isError, isSuccess } = postMutation;
  const [savingLoader, setSavingLoader] = useState(false);
  const needStudioInfo = user.isOperator;
  const { handleSubmit, control, reset } = AddArtistsValidation(needStudioInfo);

  const onSubmit = async (data: any) => {
    setSavingLoader(true);
    try {
      console.log('SENDING', user, data);
      //  the studio id needs to be added to the request if you're not an admin
      //  to check if you can upload a artist for the studio
      if (needStudioInfo && data.selectedStudio) {
        data.studioId = data.selectedStudio.id;
      }

      console.log(data);

      delete data['selectedStudio'];
      if (!data.studioId) delete data['studioId'];

      const formData = {
        ...data,
        unionAffiliation: (data.unionAffiliation || []).join(', '),
      };

      const artist: Artist = await postMutation.mutateAsync(formData);
      setAlertState({
        active: true,
        message: GlobalMessages.addArtistSuccessMsg,
      });
      await queryClient.invalidateQueries(['allArtists'], data);
      setSavingLoader(false);
      reset();
      onClose(artist ? artist : null);
    } catch (e) {
      setSavingLoader(false);
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: false,
      });
    }
  };

  if (isError) {
    return <ErrorMessage />;
  }

  const successAlert = isSuccess;
  const errorAlert = isError;
  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;
  return (
    <>
      <Alert
        message={alertState.message}
        open={showAlert}
        autoHideDuration={alertAutoHideDuration}
        severity={alertState.error ? 'error' : 'success'}
        onClose={() => setAlertState({ active: false })}
      />
      {(isLoading || savingLoader) && (
        <Loader isLoading={isLoading || savingLoader} />
      )}
      <Box>
        <DialogHeader
          title="Add Artists"
          handleSave={handleSubmit(onSubmit)}
          cancel={() => {
            reset();
            onClose();
          }}
        />
        <Box className={classes.content}>
          <Paper>
            <Box className={classes.subContent}>
              {/* <SingleImageUpload title="Artist Profile Photo"/> */}
              <Typography variant="h1">New Artist Details</Typography>
              <Typography variant="h6">
                Only first and last name are required.
              </Typography>
              <Divider style={{ marginBottom: '24px', marginTop: '14px' }} />
              <Box>
                <Grid
                  container
                  sx={
                    {
                      // marginTop: '20px',
                      // marginBotton: '20px'
                    }
                  }
                >
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sx={{
                      paddingRight: '20px',
                    }}
                  >
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      control={control}
                    />
                    <br />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sx={{
                      paddingleft: '20px',
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      control={control}
                    />
                    <br />
                  </Grid>
                </Grid>
              </Box>
              {needStudioInfo && (
                <Box mb={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Box style={{ width: '100%' }}>
                    <Autocomplete
                      placeholder="Select Artist's Studio"
                      name="selectedStudio"
                      label={
                        'Link Artist to Studio (Operators REQUIRED to assign Studio)'
                      }
                      control={control}
                      options={((studios as Studio[]) || []).map(
                        (studio: Studio) => {
                          return {
                            id: studio.id,
                            label: `${studio.name}  -  ${studio.city}, ${
                              studio.state ?? studio.country
                            } - ${studio.id}`,
                          };
                        },
                      )}
                      getSelectedOption={(field: any) => {
                        console.log(
                          'get selected option: ' + JSON.stringify(field),
                        );
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Box className={classes.mt}>
                <TextField
                  name="dob"
                  label="Date of Birth"
                  type="date"
                  control={control}
                />
              </Box>
              <Box className={classes.mt}>
                <TextField
                  name="hometown"
                  label="Home Town"
                  control={control}
                />
              </Box>
              <Box className={classes.mt}>
                <TextField
                  name="homeState"
                  label="Home State"
                  control={control}
                />
              </Box>
              <Box className={classes.mt}>
                <Select
                  label="UnionAffiliation"
                  placeholder="UnionAffiliation"
                  control={control}
                  name="unionAffiliation"
                  multiple
                  options={(
                    Object.keys(UnionAffiliation) as Array<
                      keyof typeof UnionAffiliation
                    >
                  )
                    .map((key) => UnionAffiliation[key])
                    .sort()}
                />
              </Box>
              <Box className={classes.mt}>
                <TextField
                  name="homeCountry"
                  label="Home Country"
                  control={control}
                />
              </Box>
              <Box className={classes.mt}>
                <TextField name="bio" label="Bio" control={control} />
              </Box>
              <Box className={classes.mt}>
                <TextField
                  name="professionalName"
                  label="Professional Name"
                  control={control}
                />
              </Box>
              <Box className={classes.mt}>
                <TextField name="award" label="Award" control={control} />
              </Box>
              <Box className={classes.mt}>
                <TextField name="website" label="Website" control={control} />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
