import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

export function useCustomForm(args) {
  const formFields = useForm(args);
  const errors = formFields.formState.errors;
  const errorFields = Object.keys(errors);
  const firstErrorFieldRef = useRef(null);

  useEffect(() => {
    if (errorFields.length > 0) {
      const firstErrorFieldName = errorFields[0];
      const firstErrorField =
        document.getElementsByName(firstErrorFieldName)[0];
      firstErrorFieldRef.current = firstErrorField;
    }
  }, [errors]);

  useEffect(() => {
    if (errorFields.length > 0 && firstErrorFieldRef.current) {
      firstErrorFieldRef.current.focus();
      firstErrorFieldRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [errorFields]);

  return { ...formFields };
}
