import { PaginationResponse } from '../schema';
import axios, { axiosS3 } from '../config/http-common';

import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { base64ToBlob } from '../utils/media';
import { QueuedFiles } from '../types/Global';

export function fetchPagination(
  route: string,
  page: number,
  pageLimit: number,
  searchText: string,
  secondarySearchText?: string,
): Promise<PaginationResponse<any>> {
  console.info('firing');

  if (secondarySearchText != undefined) {
    return axios
      .get(
        `${route}?searchText=${searchText}&secondarySearchText=${secondarySearchText}&page=${page}&pageLimit=${pageLimit}`,
      )
      .then((response) => response.data);
  }

  return axios
    .get(
      `${route}?searchText=${searchText}&page=${page}&pageLimit=${pageLimit}`,
    )
    .then((response) => response.data);
}

export async function useGetSignedURL(url: string) {
  return await axios.get(url);
}

export async function useDeleteFile(url: string) {
  return await axios.delete(url);
}

export async function uploadMediaToS3_Video(
  uploadURL: string,
  media: UploadFileInfo,
  updateProgress,
) {
  const config = {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      updateProgress(progress);
    },
    headers: {
      'Content-Type': media.mimeType,
    },
  };
  const binaryImg = base64ToBlob(media.blob, media.mimeType);
  return await axiosS3.put(uploadURL, binaryImg, config);
}

export async function uploadMediaToS3_File(
  uploadURL: string,
  media: QueuedFiles,
) {
  try {
    const headers = {
      'Content-Type': media.fileType,
    };
    const binaryImg = base64ToBlob(media.fileInfo.fileUrl, media.fileType);
    return await axiosS3.put(uploadURL, binaryImg, { headers });
  } catch (error) {
    console.log(error);
  }
}

export async function uploadMediaToS3(
  uploadURL: string,
  media: UploadFileInfo,
) {
  const headers = {
    'Content-Type': media.mimeType,
  };
  const binaryImg = base64ToBlob(media.blob, media.mimeType);
  return await axiosS3.put(uploadURL, binaryImg, { headers });
}

export async function getGeoCoordinates(address) {
  const addressComponents = [
    address?.street,
    address?.city,
    address?.state,
    address?.country,
    address?.zipcode,
  ];

  const addressComp = addressComponents.filter(Boolean).join(',');

  return (
    await axios.get('/location/geolocation', {
      params: {
        address: addressComp,
      },
    })
  )?.data;
}

export enum UploadMediaType {
  logo = 'logo',
  profilePicture = 'profile-picture',
  poster = 'poster',
  banner = 'banner',
  playbill = 'playbill',
  file = 'file',
  video = 'video',
  trailerVideo = 'trailer-video',
  carousel = 'carousel',
  featuredMedia = 'featured-media',
}
