import {
  Box,
  Divider,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import React from 'react';
import Button from '../Button';

export default function SelectionOfFeaturedMedia(props) {
  const {
    allFeaturedMedia,
    selectedMedia,
    onSelectMedia,
    onRemovingMedia,
    mediaLimit = 10,
  } = props;
  const subTitle =
    mediaLimit > 1
      ? `Choose upto ${mediaLimit} from this productions to feature on the page`
      : 'Choose one photo from this production to feature on the home page';
  return (
    <>
      <Box>
        <Typography variant="h4"> Featured Media </Typography>

        {allFeaturedMedia?.length !== 0 ? (
          <>
            <Typography variant="h6">
              {' '}
              {subTitle} ({selectedMedia?.length}/{mediaLimit} selected)
            </Typography>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            <ImageList
              sx={{ width: 500, height: 400 }}
              cols={3}
              rowHeight={164}
            >
              {allFeaturedMedia?.map((item) => {
                const isSelected = selectedMedia?.find(
                  (m) => m.mediaId === item.mediaId,
                );
                return (
                  <Box
                    key={item?.mediaId}
                    sx={{
                      position: 'relative',
                      '&:hover': {
                        '& div': {
                          opacity: 1,
                        },
                      },
                      '& img': {
                        border: !isSelected
                          ? '5px solid transparent'
                          : '5px solid #4F46E5',
                      },
                    }}
                  >
                    {isSelected && SelectedCircle()}
                    <ImageListItem>
                      <img
                        src={`https://spectra-media.s3.us-east-1.amazonaws.com/${item?.media?.renditions[0]?.path}`}
                        alt={'title'}
                        loading="lazy"
                        style={{ borderRadius: '10px' }}
                      />

                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: 'rgba(0, 0, 0, 0.75)',
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          transition: 'opacity 0.2s ease-in-out',
                        }}
                      >
                        <Button
                          label={isSelected ? 'Selected' : 'Select'}
                          variant={'contained'}
                          color={'info'}
                          onClick={() => {
                            if (isSelected) {
                              onRemovingMedia(item);
                            } else {
                              onSelectMedia(item);
                            }
                          }}
                          disabled={
                            selectedMedia?.length >= 0 &&
                            selectedMedia?.length < 10
                              ? false
                              : true
                          }
                        />
                      </Box>
                    </ImageListItem>
                  </Box>
                );
              })}
            </ImageList>
          </>
        ) : (
          <>
            <Typography variant="h6">
              {' '}
              There are no medias attached to the production
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}
function SelectedCircle(): React.ReactNode {
  return (
    <Box
      sx={{
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        backgroundColor: '#4F46E5',
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: 10,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '8px',
          height: '8px',
          border: '1px solid white',
          borderRadius: '50%',
          opacity: 1,
          background: 'transparent',
          animation: 'checkmark 0.3s ease-in-out 0.7s forwards',
        },
        '@keyframes checkmark': {
          '0%': {
            opacity: 0,
            transform: 'translate(-50%, -50%) scale(0.7)',
          },
          '100%': {
            opacity: 1,
            transform: 'translate(-50%, -50%) scale(1)',
          },
        },
      }}
    />
  );
}
