import React, { useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Paper, Select, TextArea, TextField } from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { AddCharacterValidation } from './PlaysValidationSchema';
import { useStyles } from '../styles';
import {
  Character,
  CharacterEthnicity,
  CharacterRace,
  CharacterTier,
  Gender,
  VoiceStyles,
  VoiceType,
} from '../../../types/Character';

export default function AddCharacters(props: {
  character: Character;
  onClose: () => void;
  onCharacterSubmit: (data: Character) => void;
  onCharacterDelete: () => void;
}) {
  const { onClose, character, onCharacterDelete, onCharacterSubmit } = props;
  const classes = useStyles();
  const { handleSubmit, control, reset } = AddCharacterValidation();

  useEffect(() => {
    if (character) {
      reset(character);
    }
  }, []);

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <Box>
      <DialogHeader
        title={character ? 'Edit Character' : 'Add Character'}
        handleSave={handleSubmit(onCharacterSubmit)}
        handleDelete={character && onCharacterDelete}
        cancel={onCancel}
      />
      <Box className={classes.content}>
        <Paper>
          <Box className={classes.subContent}>
            <Typography variant="h2">Details</Typography>
            <Divider style={{ marginBottom: '24px' }} />
            <Box>
              <TextField name="name" label="Name" control={control} />
            </Box>
            <Box className={classes.mt}>
              <Select
                label="Tier"
                placeholder="Select Tier"
                control={control}
                name="tier"
                options={(
                  Object.keys(CharacterTier) as Array<
                    keyof typeof CharacterTier
                  >
                )
                  .map((key) => CharacterTier[key])
                  .sort()}
              />
            </Box>
            <Box mt={3}>
              <Select
                label="Gender"
                placeholder="Gender"
                control={control}
                name="gender"
                options={(Object.keys(Gender) as Array<keyof typeof Gender>)
                  .map((key) => Gender[key])
                  .sort()}
              />
            </Box>
            <Box className={classes.mt}>
              <TextField
                name="ageRangeBottom"
                label="Age Range Bottom"
                control={control}
                type="number"
              />
            </Box>
            <Box className={classes.mt}>
              <TextField
                name="ageRangeTop"
                label="Age Range Top"
                control={control}
                type="number"
              />
            </Box>
            <Box className={classes.mt}>
              <TextField
                name="vocalRangeBottom"
                label="Vocal Range Bottom"
                control={control}
              />
            </Box>
            <Box className={classes.mt}>
              <TextField
                name="vocalRangeTop"
                label="Vocal Range Top"
                control={control}
              />
            </Box>
            <Box className={classes.mt}>
              <Select
                label="Voice Type"
                placeholder="Select Voice Type.."
                control={control}
                name="voiceType"
                options={(
                  Object.keys(VoiceType) as Array<keyof typeof VoiceType>
                )
                  .map((key) => VoiceType[key].toString())
                  .sort()}
              />
            </Box>
            <Box className={classes.mt}>
              <Select
                label="Voice Styles"
                placeholder="Select Voice Styles..."
                control={control}
                name="voiceStyles"
                options={(
                  Object.keys(VoiceStyles) as Array<keyof typeof VoiceStyles>
                )
                  .map((key) => VoiceStyles[key].toString())
                  .sort()}
                multiple
              />
            </Box>
            <Box className={classes.mt}>
              <TextField
                name="castingNotes"
                label="Casting Notes"
                control={control}
              />
            </Box>
            <Box className={classes.mt}>
              <TextArea
                maxRows={4}
                placeholder="Description"
                style={{ width: '100%', height: 133 }}
                label="Description"
                control={control}
                name="description"
              />
            </Box>
            {/* <Box className={classes.mt}>
              <Select
                label="Race"
                placeholder="Race"
                control={control}
                name="race"
                options={(
                  Object.keys(CharacterRace) as Array<
                    keyof typeof CharacterRace
                  >
                )
                  .map((key) => CharacterRace[key].toString())
                  .sort()}
              />
            </Box> */}
            {/* <Box className={classes.mt}>
              <Box className={classes.mt}>
                <Select
                  label="Ethnicity"
                  placeholder="Ethnicity"
                  control={control}
                  name="ethnicity"
                  options={(
                    Object.keys(CharacterEthnicity) as Array<
                      keyof typeof CharacterEthnicity
                    >
                  )
                    .map((key) => CharacterEthnicity[key].toString())
                    .sort()}
                />
              </Box>
            </Box> */}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
