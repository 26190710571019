import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Button, Paper } from '../../components';
import { makeStyles } from '@mui/styles';
import ConfirmDialog, {
  useConfirmModal,
} from '../../components/Modals/ConfirmDialog';

interface DialogHeaderProps {
  title: string;
  secondaryBtnLabel?: string;
  primaryBtnLabel?: string;
  handleSave?: any;
  cancel?: any;
  handleDelete?: any;
  primaryBtnDisable?: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: 16,
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1440,
    margin: 'auto',
  },
  actionBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '40px',
  },
  paper: {
    borderRadius: 0,
  },
}));

export default function DialogHeader(props: DialogHeaderProps) {
  const {
    title,
    secondaryBtnLabel = 'Exit',
    primaryBtnLabel = 'Save',
    handleSave,
    cancel,
    handleDelete,
    primaryBtnDisable = false,
  } = props;
  const classes = useStyles();
  const {
    setShowDeleteModal,
    setShowDeleteModalLoader,
    showDeleteModal,
    showDeleteModalLoader,
  } = useConfirmModal();

  return (
    <Paper className={classes.paper}>
      <Grid container className={classes.container}>
        <Typography variant="h1" sx={{ marginTop: '5px' }}>
          {title}
        </Typography>
        <Box className={classes.actionBtn}>
          {handleDelete ? (
            <Button
              variant="contained"
              color="info"
              label={'Delete'}
              sx={{ height: '40px', width: '90px' }}
              onClick={() => setShowDeleteModal(true)}
            />
          ) : null}
          <Button
            variant="outlined"
            color="secondary"
            label={secondaryBtnLabel}
            sx={{ height: '40px', width: '90px' }}
            onClick={() => cancel()}
          />
          <Button
            variant="contained"
            color="primary"
            label={primaryBtnLabel}
            onClick={() => handleSave()}
            sx={{ height: '40px', width: '90px' }}
            disabled={primaryBtnDisable}
          />
        </Box>
      </Grid>
      <ConfirmDialog
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        dialogOptions={{ isLoading: showDeleteModalLoader }}
        handleConfirmDelete={async () => {
          setShowDeleteModalLoader(true);
          await handleDelete();
          setShowDeleteModalLoader(false);
          setShowDeleteModal(false);
        }}
      />
    </Paper>
  );
}
