import React, { useContext, useEffect, useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import { Button, SideDrawer } from '../../../../components';
import Details from '../../../common/Details';
import { PlayContribution } from '../../../../types/PlayContribution';
import AddPlayContribution from '../AddPlayContribution';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from 'react-query';
import { deletePlayContribution } from '../../../../api/playContributions';
import { NotificationContext } from '../../../../utils/Notification/NotificationProvider';

export function PlayContributions({
  contributions,
  isLoading,
  onAdd,
}: {
  contributions: PlayContribution[];
  onAdd: (contributions: PlayContribution[]) => void;
  isLoading: boolean;
}) {
  const [_contributions, _setContributions] = useState(contributions);

  const [isDrawerActive, setDrawerActive] = useState(false);
  const [activeContribution, setActiveContribution] =
    useState<null | PlayContribution>(null);

  const { showToast } = useContext(NotificationContext);

  const deleteContributionMutation = useMutation({
    mutationFn: deletePlayContribution,
  });

  useEffect(() => {
    _setContributions(contributions);
  }, [contributions]);

  const getContributionTitle = (contribution: PlayContribution) => {
    const {
      artist,
      contributionType,
      contributionName,
      customContributionName,
    } = contribution;
    const { firstName, lastName } = artist;
    const artistName = `${firstName} ${lastName}`;
    const name =
      contributionName == 'Custom'
        ? `${contributionName} - ${customContributionName}`
        : contributionName;

    return `${artistName} - ${contributionType} - ${name}`;
  };

  const handleContributionSubmit = (data: any) => {
    if (data.id) {
      const newContributions = _contributions.map((c) => {
        if (c.id === data.id) {
          return {
            ...data,
            contributionName: data.contributionName
              ? data.contributionName.label
              : '',
            artistId: data.artist ? data.artist.id : null,
          };
        } else {
          return c;
        }
      });
      _setContributions(newContributions);
      onAdd(newContributions);
    } else {
      const newContributions = [
        ..._contributions,
        {
          ...data,
          contributionName: data.contributionName
            ? data.contributionName.label
            : '',
          artistId: data.artist ? data.artist.id : null,
          draftId: uuidv4(),
        },
      ];
      _setContributions(newContributions);
      onAdd(newContributions);
    }
    setDrawerActive(false);
  };

  const handleContributionDelete = async (data: PlayContribution) => {
    const contributionId = activeContribution?.id;
    const playId = activeContribution?.playId;
    if (data.draftId && !contributionId) {
      _setContributions((contributions) =>
        contributions.filter((c) => c.draftId !== data.draftId),
      );
      setDrawerActive(false);
      return;
    }
    try {
      await deleteContributionMutation.mutateAsync({
        playId,
        contributionId,
      });
      _setContributions((contributions) =>
        contributions.filter((c) => c.id !== contributionId),
      );
      setDrawerActive(false);
    } catch (e) {
      showToast("Couldn't delete the contribution. Try again later!", 'error');
    }
  };

  return (
    <Box>
      <Details
        title="Play Contributions"
        onAddButton={() => {
          setActiveContribution(null);
          setDrawerActive(true);
        }}
      >
        {isLoading ? (
          <div>Loading Characters...</div>
        ) : (
          _contributions.map((contribution) => (
            <>
              <Stack
                display="flex"
                direction="row"
                style={{ marginBottom: '24px' }}
              >
                <ListItem key={contribution.id}>
                  {getContributionTitle(contribution)}
                </ListItem>
                <Button
                  label="Edit"
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={() => {
                    setDrawerActive(true);
                    setActiveContribution(contribution);
                  }}
                />
              </Stack>
            </>
          ))
        )}
      </Details>
      <SideDrawer
        isActive={isDrawerActive}
        toggleDrawer={() => setDrawerActive((state) => !state)}
        styles={{
          width: '650px',
        }}
      >
        <AddPlayContribution
          onClose={() => setDrawerActive(false)}
          contribution={activeContribution}
          onSubmit={handleContributionSubmit}
          onDelete={handleContributionDelete}
        />
      </SideDrawer>
    </Box>
  );
}
