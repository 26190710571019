import React, { useEffect, useState } from 'react';
import { getPlaySummaryInfo } from '../../../api/summaryPage';
import FeaturedPlays from './FeaturedPlays';
import { Box, CircularProgress } from '@mui/material';

const PlaySummary = () => {
  const [plays, setFeaturedPlays] = useState([]);
  const [isLoading, setLoading] = useState(false);

  async function init() {
    setLoading(true);
    const featuredPlays = await getPlaySummaryInfo();
    setLoading(false);
    setFeaturedPlays(featuredPlays);
  }
  useEffect(() => {
    init();
  }, []);
  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <FeaturedPlays plays={plays}></FeaturedPlays>
    </>
  );
};

export default PlaySummary;
