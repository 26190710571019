import { useMutation, useQuery } from 'react-query';
import { Production } from '../types/Production';
import axios from '../config/http-common';
import { getExtFromFileName } from '../utils/media';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { ProductionContribution } from '../types/ProductionContribution';

import {
  UploadMediaType,
  uploadMediaToS3,
  uploadMediaToS3_File,
  uploadMediaToS3_Video,
} from './utils';
import { QueuedFiles } from '../types/Global';

export function useGetAllProductions() {
  return useQuery(['allProductions'], () =>
    axios.get('productions').then((response) => response.data),
  );
}
export function useGetProduction(id: string) {
  if (!id) {
    return { data: null, isSuccess: true, isLoading: false };
  }
  return useQuery<any, Error>(['selectedProduction'], () =>
    axios.get(`productions/${id}`).then((response) => response.data),
  );
}

export function usePostProduction() {
  return useMutation((data) =>
    axios.post('/productions', data).then((response) => response.data),
  );
}
export function usePutProduction(productionsId: string) {
  return useMutation((data) =>
    axios
      .put(`/productions/${productionsId}`, data)
      .then((response) => response.data),
  );
}
export function useDeleteProduction(productionId: string) {
  return useMutation<Production[], Error>(() =>
    axios
      .delete(`/productions/${productionId}`)
      .then((response) => response.data),
  );
}

export async function getProductionContribution(
  productionId: string,
  contributionId: string,
): Promise<ProductionContribution> {
  return axios
    .get(`productions/${productionId}/contributions/${contributionId}`)
    .then((response) => response.data);
}

export async function getAllProductionMedia(productionId: string) {
  return await axios
    .get(`/productions/${productionId}/all-media`)
    .then((response) => response.data);
}

export async function getProductionMedia(
  productionId: string,
  mediaType: UploadMediaType,
) {
  return await axios.get(`/productions/${productionId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(productionId: string, mediaId: string) {
  return await axios.delete(`/productions/${productionId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  productionId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  console.log(productionId);
  const associatedMedia = await getProductionMedia(
    productionId,
    uploadMediaType,
  );

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.productionId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('productions/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const ProductionMediaResponse = await axios.post(
    `/productions/${productionId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return ProductionMediaResponse.data;
}

export async function uploadMediaSingleVideo(
  productionId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
  updateProgress: any,
) {
  const associatedMedia = await getProductionMedia(
    productionId,
    uploadMediaType,
  );

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.productionId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('productions/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_Video(
    signedUrl,
    mediaData,
    updateProgress,
  );

  const ProductionMediaResponse = await axios.post(
    `/productions/${productionId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return ProductionMediaResponse.data;
}

export async function uploadMediaMultiple(
  productionId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  const { signedUrl, fileName } = (
    await axios.get('productions/uploadMedia', {
      params: {
        contentType: mediaData.fileType,
        fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_File(
    signedUrl,
    mediaData,
  );

  const productionMediaResponse = await axios.post(
    `/productions/${productionId}/process-media`,
    {
      mediaName: mediaData.fileInfo.fileName,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return productionMediaResponse.data;
}

export function useDeleteFeaturedProductionContribution({
  productionId,
  productionContributionId,
}: {
  productionId: string;
  productionContributionId: string;
}) {
  return useMutation<Production[], Error>(() =>
    axios
      .delete(
        `/productions/${productionId}/featured/production-contribution/${productionContributionId}`,
      )
      .then((response) => response.data),
  );
}

export function useCreateProductionFeaturedContribution() {
  return useMutation(
    (args: {
      productionContributionId: string;
      productionId: string;
      artistId: string;
    }) => {
      return axios
        .post(
          `productions/${args.productionId}/featured/production-contribution`,
          args,
        )
        .then((response) => response.data);
    },
  );
}
