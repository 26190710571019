import React, { useEffect, useState } from 'react';
import { Alert, Autocomplete } from '../../../../components';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import Loader from '../../../../components/Loader';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import DialogHeader from '../../../common/DialogHeader';
import { makeStyles } from '@mui/styles';
import SelectionOfFeaturedMedia from '../../../../components/FeaturedMedia/SelectionOfFeaturedMedia';
import { getAllProductionMedia } from '../../../../api/production';
import { MarqueeProductionResponse } from '../../../../types/SummaryPage';
import { AddFeaturedProductionValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import axios from '../../../../config/http-common';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '1px 16px 32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
  filterSection: {
    padding: '24px',
    background: '#eee',
    borderRadius: '6px',
  },
}));

type Props = {
  title: string;
  currentMarqueeProduction: MarqueeProductionResponse | null;
  handleClose: (production: any) => void;
  handleSave: (args: {
    productionId: string;
    featuredProductionMediaId: string;
    isEditMode?: boolean;
  }) => Promise<MarqueeProductionResponse>;
  mediaLimit?: number;
};
const ProductionPicker = ({
  title,
  currentMarqueeProduction,
  handleClose,
  handleSave,
  mediaLimit = 10,
}: Props) => {
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });

  const [productions, setProductions] = useState([]);
  const [isProductionsFetching, setProductionsFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [selectedProduction, setSelectedProduction] = useState<{
    id: string;
    label: string;
  }>();
  const [selectedMediaToFeature, setSelectedMediaToFeature] = useState([]);
  const [studioFilterList, setStudioFilterList] = useState([]);
  const [selectedStudioIdFilter, setStudioIdFilter] = useState();
  const isEditMode = Boolean(currentMarqueeProduction);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = AddFeaturedProductionValidation();
  const isError = alertState.active;

  const onSubmit = async (data) => {
    try {
      if (selectedMediaToFeature.length === 0) {
        alert('Please select a media to feature');
        return;
      }
      setLoading(true);
      await handleSave({
        productionId: data.production.id,
        featuredProductionMediaId: selectedMediaToFeature[0].id,
        isEditMode: isEditMode,
      });
      setLoading(false);
      handleClose({
        ...data.production,
        featuredProductionMediaId: selectedMediaToFeature[0].id,
      });
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message:
          'Something went wrong! Please ensure the production is not added already!',
      });
    }
  };

  async function fetchProductionFilters() {
    const result = await axios.post('/productions/filter-params');
    const { studios } = result.data;

    setStudioFilterList(
      studios.map((studio) => ({
        id: studio.id,
        label: studio.name,
      })),
    );
  }

  useEffect(() => {
    fetchProductionFilters();
  }, []);

  useEffect(() => {
    if (!currentMarqueeProduction) return;
    const currentProduction = {
      id: currentMarqueeProduction.productionId,
      label: `${currentMarqueeProduction.studioName} ${
        currentMarqueeProduction.productionYear
      } - ${currentMarqueeProduction.state || 'N/A'}`,
      city: currentMarqueeProduction.city,
      state: currentMarqueeProduction.state,
      productionYear: currentMarqueeProduction.productionYear,
    };
    reset({
      production: currentProduction,
    });
    setSelectedProduction(currentProduction);
  }, [currentMarqueeProduction]);

  const onStudioFilterChange = async ({ id: studioId }) => {
    setStudioIdFilter(studioId);
    setProductionsFetching(true);
    const result = await axios.post('/productions/filter', {
      studioId: studioId,
    });
    const productions = result.data;
    setProductions(
      productions.map((production) => {
        const productionYear = new Date(
          production.productionStartDate,
        ).getFullYear();
        const city = (production.venue && production.venue.city) || 'N/A';
        const state = (production.venue && production.venue.state) || 'N/A';
        return {
          id: production.id,
          label: `${production?.play?.licenseName}  - ${productionYear} - ${city} - ${state}`,
          city,
          state,
          productionYear,
          productionName: production?.play?.licenseName,
        };
      }),
    );
    reset({
      production: null,
    });
    setSelectedProduction(undefined);
    setProductionsFetching(false);
  };

  return (
    <Box>
      <DialogHeader
        title={`${isEditMode ? 'Edit' : 'Add'} ${title}`}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            {!isEditMode && (
              <Box className={classes.filterSection}>
                <Box sx={{ color: '#888' }}>Filter Productions By</Box>
                <Box my={3}>
                  <Autocomplete
                    label={'Studio'}
                    placeholder="Selected Studio"
                    name="selectedStudio"
                    control={control}
                    options={studioFilterList}
                    onValueChange={onStudioFilterChange}
                  />
                </Box>
              </Box>
            )}
            {(selectedStudioIdFilter || selectedProduction) && (
              <>
                <ProductionWithMediaPicker
                  isLoading={isProductionsFetching}
                  productions={productions}
                  onProductionChange={(option) => setSelectedProduction(option)}
                  onProductionMediaChange={(productionMedia) =>
                    setSelectedMediaToFeature(productionMedia)
                  }
                  defaultProduction={selectedProduction}
                  currentMarqueeProduction={currentMarqueeProduction}
                  mediaLimit={mediaLimit}
                  formFields={{
                    control: control,
                    errors: errors,
                  }}
                  isEditMode={isEditMode}
                />
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ProductionPicker;
export function ProductionWithMediaPicker({
  formFields,
  isEditMode,
  isLoading,
  mediaLimit,
  productions,
  defaultProduction: selectedProduction,
  onProductionChange: onProductionChange,
  onProductionMediaChange,
  currentMarqueeProduction,
}: {
  isLoading: boolean;
  isEditMode: boolean;
  productions: any[];
  formFields: {
    control;
    errors;
  };
  onProductionChange: (productionOption: { id: string; label: string }) => void;
  onProductionMediaChange: (productionMedia: any) => void;
  defaultProduction: {
    id: string;
    label: string;
  };
  mediaLimit: number;
  currentMarqueeProduction: MarqueeProductionResponse | null;
}) {
  const [selectedProductionMedia, setSelectedProductionMedia] = useState();
  const [selectedMediaToFeature, setSelectedMediaToFeature] = useState([]);
  const [mediaFetching, setMediaFetching] = useState(false);

  const { control, errors } = formFields;

  const onSelectionOfMedia = (media) => {
    setSelectedMediaToFeature([media]);
    onProductionMediaChange([media]);
  };

  const onRemovingOfMedia = (media) => {
    setSelectedMediaToFeature([
      ...selectedMediaToFeature.filter((m) => m?.mediaId !== media?.mediaId),
    ]);
  };

  const getProdMedia = async () => {
    if (!selectedProduction) return;
    setMediaFetching(true);
    const allMedia = await getAllProductionMedia(selectedProduction?.id);
    setSelectedProductionMedia(allMedia);
    setMediaFetching(false);

    if (
      currentMarqueeProduction &&
      selectedProduction.id === currentMarqueeProduction.productionId
    ) {
      const featuredMedia = allMedia.find(
        (media) =>
          media.id === currentMarqueeProduction.featuredProductionMediaId,
      );
      if (featuredMedia) {
        setSelectedMediaToFeature([featuredMedia]);
      }
    }
  };

  useEffect(() => {
    getProdMedia();
  }, [selectedProduction]);

  return (
    <Box my={3}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Autocomplete
            error={!!errors}
            label={`Select Production ${
              isEditMode ? '' : `(${productions?.length})`
            }`}
            placeholder="Select Production"
            name="production"
            control={control}
            onValueChange={(option) => {
              onProductionChange(option);
            }}
            disabled={isEditMode}
            options={productions}
          />
          {mediaFetching ? (
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress />
            </Box>
          ) : (
            <Box my={3}>
              {selectedProduction ? (
                <SelectionOfFeaturedMedia
                  allFeaturedMedia={selectedProductionMedia}
                  selectedMedia={selectedMediaToFeature}
                  onSelectMedia={onSelectionOfMedia}
                  onRemovingMedia={onRemovingOfMedia}
                  mediaLimit={mediaLimit}
                />
              ) : (
                <Box mb={4}>
                  <Typography variant="h4"> Featured Media </Typography>
                  <Typography variant="h6">
                    {' '}
                    Select a production to view media
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
