import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Paper } from '@mui/material';
import DialogHeader from '../../../common/DialogHeader';
import Loader from '../../../../components/Loader';
import { Alert, Autocomplete } from '../../../../components';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import { makeStyles } from '@mui/styles';
import axios from '../../../../config/http-common';
import { ProductionContributionType } from '../../../../types/ProductionContribution';
import { SelectBox } from '../../../../components/Select';
import { AddFeaturedContributionValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import { useCreateProductionFeaturedContribution } from '../../../../api/production';

type Props = {
  productionId: string;
  handleClose: (contribution: {
    artistName: string;
    contributionType: string;
    id: string;
  }) => void;
  featuredContributionsCount: number;
};

interface ProductionContributionResult {
  contributionId: string;
  productionId: string;
  artist: {
    firstName: string;
    lastName: string;
  };
  contributionType: string;
}

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
}));

const ALL_TYPE = 'All';
const AddFeaturedProductionContribution = (props: Props) => {
  const { productionId, handleClose } = props;
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });

  const [contributions, setContributions] = useState<
    ProductionContributionResult[]
  >([]);
  const [filteredContributions, setFilteredContributions] =
    useState<ProductionContributionResult[]>();
  const [contributionType, setContributionType] = useState('');
  const [isContributionsFetching, setContributionsFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const postMutation = useCreateProductionFeaturedContribution();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = AddFeaturedContributionValidation();
  const isError = alertState.active;

  const onSubmit = async (data) => {
    try {
      const contribution = data.contribution;
      setLoading(true);
      await postMutation.mutateAsync({
        productionId,
        productionContributionId: contribution.contributionId,
        artistId: contribution.artist.id,
      });
      setLoading(false);
      handleClose({
        artistName: `${contribution.artist.firstName} ${contribution.artist.lastName}`,
        contributionType: contribution.contributionType,
        id: contribution.contributionId,
      });
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message:
          'We were unable to add this contributor. Please ensure that the artist has not already been added.',
      });
    }
  };

  useEffect(() => {
    function init() {
      setContributionsFetching(true);
      axios
        .post('productions/search/production-contributions', {
          productionId,
        })
        .then((response) => {
          setContributions(response.data);
          setFilteredContributions(response.data);
          setContributionType(ALL_TYPE);
        })
        .finally(() => {
          setContributionsFetching(false);
        });
    }

    init();
  }, []);

  useEffect(() => {
    setFilteredContributions(
      contributionType === ALL_TYPE
        ? contributions
        : contributions.filter((c) => c.contributionType === contributionType),
    );
  }, [contributionType]);

  const contributionTypeOptions = (
    Object.keys(ProductionContributionType) as Array<
      keyof typeof ProductionContributionType
    >
  )
    .map((key) => ProductionContributionType[key])
    .sort();

  return (
    <Box>
      <DialogHeader
        title={'Featured Production Contributions'}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            Choose up to 12 production contributions to feature at the top of
            the page ({props.featuredContributionsCount}/12)
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <Box my={3}>
              <SelectBox
                onChange={(event) => setContributionType(event.target.value)}
                value={contributionType}
                label="Contribution Type"
                placeholder="Contribution Type"
                name="contributionType"
                options={[ALL_TYPE, ...contributionTypeOptions]}
              />
            </Box>
            <Box my={3}>
              {isContributionsFetching ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Autocomplete
                  error={!!errors}
                  label={'Choose Contribution'}
                  placeholder="Choose Contribution"
                  name="contribution"
                  control={control}
                  options={(filteredContributions || []).map((contribution) => {
                    return {
                      id: contribution.contributionId,
                      label: `${
                        contribution.artist
                          ? `${contribution.artist.firstName} ${contribution.artist.lastName}`
                          : ''
                      } - ${contribution.contributionType} `,
                      ...contribution,
                    };
                  })}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddFeaturedProductionContribution;
