import React, { useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import {
  addFeaturedStudio,
  removeFeaturedStudio,
} from '../../../api/summaryPage';
import { Button, SideDrawer } from '../../../components';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';
import Details from '../../common/Details';
import { Studio } from '../../../types/Studio';
import StudioPicker from './shared/StudioPicker';

const MAX_COUNT = 6;
const FeaturedStudios = ({ studios = [] }: { studios: Studio[] }) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<boolean>(false);
  const [featuredStudios, setFeaturedStudios] = useState(studios);
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedStudioId?: string;
  }>({
    open: false,
    deleteFeaturedStudioId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteStudioCallback = async () => {
    try {
      const studioId = confirmDelete.deleteFeaturedStudioId;
      setIsDeleting(true);
      await removeFeaturedStudio(studioId);
      setFeaturedStudios((plays) => plays.filter((p) => p.id !== studioId));
      setIsDeleting(false);
      setConfirmDelete({ open: false });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <Box>
      <Details
        title="Featured Studios"
        onAddButton={() => {
          if (featuredStudios.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} studios can be featured.`);
            return;
          }
          setOpenDrawerState(true);
        }}
      >
        <>
          <p>Choose {MAX_COUNT} studios to display on the home page</p>
          {featuredStudios.map((studio) => (
            <StudioRow
              key={studio.id}
              studio={studio}
              onDelete={(studioId) => {
                setConfirmDelete({
                  open: true,
                  deleteFeaturedStudioId: studioId,
                });
              }}
            />
          ))}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState}
        toggleDrawer={() => setOpenDrawerState(false)}
        styles={{
          width: '550px',
        }}
      >
        <StudioPicker
          currentStudio={null}
          handleClose={(studio) => {
            if (studio) {
              setFeaturedStudios((studios) => [...studios, studio]);
            }
            setOpenDrawerState(false);
          }}
          handleSave={(args) => addFeaturedStudio(args.studioId)}
          title="Featured Studio"
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteStudioCallback}
      />
    </Box>
  );
};

function StudioRow({
  studio,
  onDelete,
}: {
  studio: Partial<Studio>;
  onDelete: (studioId: string) => void;
}): JSX.Element {
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={studio.id}>
          <b>{studio.name}</b>
        </ListItem>
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(studio.id);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}

export default FeaturedStudios;
