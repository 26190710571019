export type User = {
  email: string;
  name: string;
  userId: string;
  studioId?: string;
  studioName?: string;
  artistId?: string;
  primaryRole?: Role;
  isOperator?: boolean;
};

export enum Role {
  spectraUser = 'spectra:user',
  studioMember = 'spectra:studio:member',
  studioManager = 'spectra:studio:manager',
  studioAdmin = 'spectra:studio:admin',
  spectraOperator = 'spectra:operator',
}
