import React, { useEffect, useState } from 'react';
import { Play } from '../../../types/Play';
import { Box, ListItem, Stack } from '@mui/material';
import {
  PageFeaturedPlay,
  addFeaturedPlayInPlaysPage,
  deleteFeaturedPlayInPlaysPage,
  updateFeaturedPlayInPlaysPage,
} from '../../../api/summaryPage';
import { Button, SideDrawer } from '../../../components';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';
import Details from '../../common/Details';
import PlayPicker from '../home/shared/PlayPicker';

const MAX_COUNT = 2;

const FeaturedPlays = ({ plays = [] }: { plays: PageFeaturedPlay[] }) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<boolean>(false);
  const [featuredPlays, setFeaturedPlays] = useState(plays);
  const [currentPlay, setCurrentPlay] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedPlayId?: string;
  }>({
    open: false,
    deleteFeaturedPlayId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteProductionCallback = async () => {
    try {
      const playId = confirmDelete.deleteFeaturedPlayId;
      setIsDeleting(true);
      await deleteFeaturedPlayInPlaysPage(playId);
      setFeaturedPlays((plays) => plays.filter((p) => p.id !== playId));
      setIsDeleting(false);
      setConfirmDelete({ open: false });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    setFeaturedPlays(plays);
  }, [plays]);

  return (
    <Box>
      <Details
        title="Featured Plays"
        onAddButton={() => {
          if (featuredPlays.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} productions can be featured.`);
            return;
          }
          setOpenDrawerState(true);
        }}
      >
        <>
          <p>
            Choose {MAX_COUNT} plays to display at the top of the Plays page
          </p>
          {featuredPlays.map((fp) => (
            <PlayRow
              key={fp.id}
              play={fp}
              onEdit={(play) => {
                setCurrentPlay(play);
                setOpenDrawerState(true);
              }}
              onDelete={(playId) => {
                setConfirmDelete({
                  open: true,
                  deleteFeaturedPlayId: playId,
                });
              }}
            />
          ))}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState}
        toggleDrawer={() => setOpenDrawerState(false)}
        styles={{
          width: '550px',
        }}
      >
        <PlayPicker
          currentPlay={currentPlay}
          handleClose={(play, isEditMode) => {
            if (play) {
              if (isEditMode) {
                setFeaturedPlays((plays) =>
                  plays.map((p) => {
                    if (p.id === play.id) {
                      return play;
                    }
                    return p;
                  }),
                );
              } else {
                setFeaturedPlays((plays) => [...plays, play]);
              }
            }
            setOpenDrawerState(false);
          }}
          handleSave={(args) => {
            if (args.isEditMode) {
              return updateFeaturedPlayInPlaysPage({
                playId: args.playId,
                featuredProductionMediaId: args.featuredProductionMediaId,
              });
            } else {
              return addFeaturedPlayInPlaysPage({
                playId: args.playId,
                featuredProductionMediaId: args.featuredProductionMediaId,
              });
            }
          }}
          title="Featured Play"
          requireProductionMedia={true}
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteProductionCallback}
      />
    </Box>
  );
};

function PlayRow({
  play,
  onDelete,
  onEdit,
}: {
  play: Partial<Play>;
  onDelete: (playId: string) => void;
  onEdit?: (play: Partial<Play>) => void;
}): JSX.Element {
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={play.id}>
          <b>{play.licenseName}</b>
        </ListItem>
        {onEdit && (
          <Button
            label="Edit"
            variant={'outlined'}
            color={'secondary'}
            onClick={() => {
              onEdit(play);
            }}
            sx={{ ml: 2 }}
          />
        )}
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(play.id);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}

export default FeaturedPlays;
