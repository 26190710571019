/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import axios from '../../../config/http-common';

import {
  Alert,
  Autocomplete,
  Checkbox,
  Paper,
  Select,
  TextField,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import AddIcon from '@mui/icons-material/Add';
import { CreatePlaysValidation } from './PlaysValidationSchema';
import AddBrands from './AddBrands';
import { useStyles } from '../styles';
import PlaySideDrawers from './PlaySideDrawers';
import { SideBarState } from '../types';
import { Brand } from '../../../types/Brand';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteMedia,
  uploadMediaMultiple,
  uploadMediaSingle,
  useGetAllBrands,
} from '../../../api/brands';
import {
  useGetPlay,
  usePostPlay,
  usePutPlay,
  useDeletePlay,
  getPlayFeaturedMedia,
  deleteFeaturedMedia,
  uploadMediaSingle as uploadMediaSingle_Play,
  deleteMedia as deleteMedia_Play,
  uploadMediaMultiple as uploadMediaMultiple_Play,
} from '../../../api/plays';
import Loader from '../../../components/Loader';
import {
  alertAutoHideDuration,
  GlobalMessages,
  PosterMaxFileSizeInMB,
  PosterSupportedFormat,
} from '../../../utils/globalConfig';
import { AlertState, QueuedFiles } from '../../../types/Global';
import SingleImageUpload, {
  QueueFileInfo,
} from '../../../components/Upload/SingleImageUpload';
import MultiFileUpload, {
  FileInfo,
} from '../../../components/Upload/MultiFileUpload';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import { UploadMediaType } from '../../../api/utils';
import { v4 as uuidv4 } from 'uuid';

import useErrorHandler from '../../../hooks/useErrorHandler';
import { AutocompleteValue } from '../../../components/Autocomplete';
import FeaturedPlayProductions from './featured/FeaturedPlayProductions';
import FeaturedPlayContributions from './featured/FeaturedPlayContributions';
import DisplaySelectedFeaturedMedia from '../../../components/FeaturedMedia/DisplaySelectedFeaturedMedia';
import { PlayContributionWithFiles } from './AddPlayContribution';
import { PlayCharacters } from './partials/PlayCharacters';
import { PlayContributions } from './partials/PlayContributions';
import { uploadMediaMultiple as uploadMediaMultiple_playContributions } from '../../../api/playContributions';
import ChipsInputField from '../../../components/ChipsInput';
import { SearchFilterConfigContext } from '../../../context/SearchFilterConfigContext';

const supportedPlayFileFormats = ['png', 'pdf', 'jpeg', 'jpg', 'svg', 'csv'];
const supportedBrandFileFormats = ['png', 'pdf', 'jpeg', 'jpg', 'svg', 'csv'];
export interface UploadFileInfo {
  type?:
    | 'poster'
    | 'brand-poster'
    | 'brand-banner'
    | 'banner'
    | 'logo'
    | 'profile-pic'
    | 'image'
    | 'video'
    | 'trailer-video'
    | null;
  blob: Blob | string;
  name: string;
  mimeType: string;
}
export type UploadFileInfoWithSignedURL = UploadFileInfo & {
  signedURL: string;
};

interface ContributionFileUpload {
  contributionId: string;
  contributionFiles: QueuedFiles[];
}

export default function CreatePlay() {
  const classes = useStyles();
  const { id: initialPlayId } = useParams();
  const [playId, setPlayId] = useState(initialPlayId);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const defaultSideBarState = {
    addPlayArtists: false,
    addCharacters: false,
    addSongs: false,
    addFeaturedArtists: false,
    addOrchestrations: false,
    addOrchestrationBooks: false,
  };

  const [state, setState] = useState({
    addBrandDialogState: false,
  });
  const [isSideDrawerOn, setSideDrawerOn] =
    useState<SideBarState>(defaultSideBarState);

  const { data: brands, isLoading: isBrandListLoading } = useGetAllBrands();
  const onPlaySuccess = (play) => {
    reset({
      ...play,
      licenseHouseAvailableDate: play.licenseHouseAvailableDate
        ? moment(play.licenseHouseAvailableDate).format('yyyy-MM-DD')
        : null,
      licenseHouseUnavailableDate: play.licenseHouseUnavailableDate
        ? moment(play.licenseHouseUnavailableDate).format('yyyy-MM-DD')
        : null,
      selectedBrand: play?.brand
        ? { id: play.brand.id, label: play.brand.name }
        : null,
    });
    setPlayRelatedPostersAndBanners((prevValue) => ({
      ...prevValue,
      brandPosterDetails: play?.brandPosterDetails,
      brandBannerDetails: play?.brandBannerDetails,
      playPosterDetails: play?.playPosterDetails,
      playBannerDetails: play?.playBannerDetails,
    }));
    setPlayFiles(play?.playFiles || []);
    setBrandFiles(play?.brandFiles || []);
  };
  let {
    data: play,
    isSuccess: isPlayLoaded,
    isLoading: isPlayLoading = false,
    refetch,
  } = useGetPlay(playId ? playId : null, onPlaySuccess, {
    enabled: !!playId,
  });
  const updateMutation = usePutPlay(playId);
  const { isSuccess: updatePlayMetaSuccess, isError: updatePlayMetaError } =
    updateMutation;
  const postMutation = usePostPlay();
  const { isSuccess: postPlayMetaSuccess, isError: postPlayMetaError } =
    postMutation;
  const deleteMutation = useDeletePlay(playId);
  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = CreatePlaysValidation();
  console.log(JSON.stringify(errors));

  const [selectedBrand, setSelectedBrand] = useState<AutocompleteValue | null>(
    null,
  );

  const [isSavingLoader, setSavingLoader] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);

  const [playRelatedPostersAndBanners, setPlayRelatedPostersAndBanners] =
    useState<{
      brandPosterDetails: { mediaId: string; brandId: string };
      brandBannerDetails: { mediaId: string; brandId: string };
      playPosterDetails: { mediaId: string; playId: string };
      playBannerDetails: { mediaId: string; playId: string };
    }>();
  const [
    playRelatedPostersandBannersOnQueue,
    setPlayRelatedPostersAndBannersOnQueue,
  ] = useState<{
    brandPosterOnQueue: QueueFileInfo;
    brandBannerOnQueue: QueueFileInfo;
    playPosterOnQueue: QueueFileInfo;
    playBanneronQueue: QueueFileInfo;
  }>();
  const [
    playRelatedPostersAndBannersToBeDeleted,
    setPlayRelatedPostersAndBannersToBeDeleted,
  ] = useState<{
    brandPosterToBeDeleted: { mediaId: string; brandId: string };
    brandBannerToBeDeleted: { mediaId: string; brandId: string };
    playPosterToBeDeleted: { mediaId: string; playId: string };
    playBannerToBeDeleted: { mediaId: string; playId: string };
  }>();
  const [brandFiles, setBrandFiles] = useState<FileInfo[]>([]);
  const [brandFilesToBeDeleted, setBrandFilesToBeDeleted] = useState([]);
  const [playFilesToBeDeleted, setPlayFilesToBeDeleted] = useState([]);
  const [playFiles, setPlayFiles] = useState<FileInfo[]>([]);

  const [playFilesOnQueue, setPlayFilesOnQueue] = useState<QueuedFiles[]>([]);
  const [brandFilesOnQueue, setBrandFilesOnQueue] = useState<QueuedFiles[]>([]);

  const [displayFeaturedMedia, setDisplayFeaturedMedia] = useState([]);
  const searchFilterConfigContext = React.useContext(SearchFilterConfigContext);
  const setConfig = searchFilterConfigContext?.setConfig;
  const config = searchFilterConfigContext?.config;
  const [filterParams, setFilterParams] = useState<any>();

  async function fetchSearchFilterConfig() {
    const { data: filterConfig } = await axios.get('/home/searches/filter');
    if (setConfig) {
      setConfig(filterConfig);
    }
  }

  const deleteDisplayFeaturedMedia = async (mediaId) => {
    setDisplayFeaturedMedia([
      ...displayFeaturedMedia.filter((media) => media?.mediaId !== mediaId),
    ]);
    const deleteResponse = await deleteFeaturedMedia(playId, mediaId);
    return deleteResponse;
  };
  async function updateFiles(playId: string, brandId: string) {
    if (!playId || !brandId)
      return { errors: ['No play Id or brand Id is provided'] };

    let brandPosterUploadPromise,
      brandPosterDeletePromise,
      brandBannerUploadPromise,
      brandBannerDeletePromise,
      playPosterUploadPromise,
      playPosterDeletePromise,
      playBannerUploadPromise,
      playBannerDeletePromise,
      brandFilesUploadPromise,
      brandFilesDeletePromise,
      playFilesUploadPromise,
      playFilesDeletePromise;

    const errors = [];
    if (playRelatedPostersandBannersOnQueue?.brandPosterOnQueue) {
      brandPosterUploadPromise = uploadMediaSingle(
        brandId,
        playRelatedPostersandBannersOnQueue?.brandPosterOnQueue,
        UploadMediaType.poster,
      ).catch((error) =>
        errors.push(`Error while uploading brandPoster: ${error.message}`),
      );
    }

    if (playRelatedPostersandBannersOnQueue?.brandBannerOnQueue) {
      brandBannerUploadPromise = uploadMediaSingle(
        brandId,
        playRelatedPostersandBannersOnQueue?.brandBannerOnQueue,
        UploadMediaType.banner,
      ).catch((error) =>
        errors.push(`Error while uploading brand banner: ${error.message}`),
      );
    }

    if (playRelatedPostersandBannersOnQueue?.playPosterOnQueue) {
      playPosterUploadPromise = uploadMediaSingle_Play(
        playId,
        playRelatedPostersandBannersOnQueue?.playPosterOnQueue,
        UploadMediaType.poster,
      ).catch((error) =>
        errors.push(`Error while uploading play poster: ${error.message}`),
      );
    }

    if (playRelatedPostersandBannersOnQueue?.playBanneronQueue) {
      playBannerUploadPromise = uploadMediaSingle_Play(
        playId,
        playRelatedPostersandBannersOnQueue?.playBanneronQueue,
        UploadMediaType.banner,
      ).catch((error) =>
        errors.push(`Error while uploading play banner: ${error.message}`),
      );
    }

    if (playRelatedPostersAndBannersToBeDeleted?.brandPosterToBeDeleted) {
      brandPosterDeletePromise = deleteMedia(
        brandId,
        playRelatedPostersAndBannersToBeDeleted?.brandPosterToBeDeleted
          ?.mediaId,
      ).catch((error) =>
        errors.push(`Error while deleting brand Poster: ${error.message}`),
      );
    }

    if (playRelatedPostersAndBannersToBeDeleted?.brandBannerToBeDeleted) {
      brandBannerDeletePromise = deleteMedia(
        brandId,
        playRelatedPostersAndBannersToBeDeleted?.brandBannerToBeDeleted
          ?.mediaId,
      ).catch((error) =>
        errors.push(`Error while deleting brand banner: ${error.message}`),
      );
    }

    if (playRelatedPostersAndBannersToBeDeleted?.playPosterToBeDeleted) {
      playPosterDeletePromise = deleteMedia_Play(
        playId,
        playRelatedPostersAndBannersToBeDeleted?.playPosterToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(`Error while deleting play Poster: ${error.message}`),
      );
    }

    if (playRelatedPostersAndBannersToBeDeleted?.playBannerToBeDeleted) {
      playBannerDeletePromise = deleteMedia_Play(
        playId,
        playRelatedPostersAndBannersToBeDeleted?.playBannerToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(`Error while deleting play banner: ${error.message}`),
      );
    }

    if (brandFilesOnQueue) {
      brandFilesUploadPromise = brandFilesOnQueue.map(async (file) => {
        const uploadResponse = await uploadMediaMultiple(
          brandId,
          file,
          UploadMediaType.file,
        );
        return uploadResponse;
      });
    }

    if (playFilesOnQueue) {
      playFilesUploadPromise = playFilesOnQueue.map(async (file) => {
        const uploadResponse = await uploadMediaMultiple_Play(
          playId,
          file,
          UploadMediaType.file,
        );
        return uploadResponse;
      });
    }

    if (brandFilesToBeDeleted) {
      brandFilesDeletePromise = brandFilesToBeDeleted.map(async (file) => {
        const deleteResponse = await deleteMedia(
          brandId,
          file?.mediaDetails?.id,
        );
        return deleteResponse;
      });
    }

    if (playFilesToBeDeleted) {
      playFilesDeletePromise = playFilesToBeDeleted.map(async (file) => {
        const deleteResponse = await deleteMedia_Play(
          playId,
          file?.mediaDetails?.id,
        );
        return deleteResponse;
      });
    }

    const [
      brandPosterUploadResponse,
      brandPosterDeleteResponse,
      brandBannerUploadResponse,
      brandBannerDeleteResponse,
      playPosterUploadResponse,
      playPosterDeleteResponse,
      playBannerUploadResponse,
      playBannerDeleteResponse,
      brandFilesUploadResponse,
      brandFilesDeleteResponse,
      playFilesUploadResponse,
      playFilesDeleteResponse,
    ] = await Promise.all([
      brandPosterUploadPromise,
      brandPosterDeletePromise,
      brandBannerUploadPromise,
      brandBannerDeletePromise,
      playPosterUploadPromise,
      playPosterDeletePromise,
      playBannerUploadPromise,
      playBannerDeletePromise,
      brandFilesUploadPromise,
      brandFilesDeletePromise,
      playFilesUploadPromise,
      playFilesDeletePromise,
    ]);

    brandFilesUploadResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading brand files ${index + 1}: ${response.error}`,
        );
      }
    });

    brandFilesDeleteResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error deleting brand files ${index + 1}: ${response.error}`,
        );
      }
    });

    playFilesUploadResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading play files ${index + 1}: ${response.error}`,
        );
      }
    });

    playFilesDeleteResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error deleting play files ${index + 1}: ${response.error}`,
        );
      }
    });

    setPlayFilesOnQueue([]);
    setBrandFilesOnQueue([]);
    setPlayFilesToBeDeleted([]);
    setBrandFilesToBeDeleted([]);

    return {
      brandPosterUploadResponse,
      brandPosterDeleteResponse,
      brandBannerUploadResponse,
      brandBannerDeleteResponse,
      playPosterUploadResponse,
      playPosterDeleteResponse,
      playBannerUploadResponse,
      playBannerDeleteResponse,
      brandFilesUploadResponse,
      brandFilesDeleteResponse,
      playFilesUploadResponse,
      playFilesDeleteResponse,
      errors,
    };
  }

  const onSubmit = async (data: any) => {
    const formData = {
      ...data,
      brandId: data.selectedBrand.id,
    };
    setSavingLoader(true);
    setSaveDisable(true);
    try {
      const {
        play: updatedPlay,
        contributionsDraftMap: draftIdToContributionIdMap = {},
      } = playId
        ? await updateMutation.mutateAsync(formData)
        : await postMutation.mutateAsync(formData);

      const fileOpResults = await updateFiles(playId, data.selectedBrand.id);
      if (fileOpResults.errors.length > 0) {
        setAlertState({
          active: true,
          message:
            'Media upload errors:\n• ' + fileOpResults.errors.join('\n• '),
          error: true,
        });
      } else {
        setAlertState({
          active: true,
          message: playId
            ? GlobalMessages.playUpdationSuccessMsg
            : GlobalMessages.playCreationSuccessMsg,
        });
      }

      let _playId = playId || (updatedPlay ? updatedPlay.id : null);
      await uploadProductionContributionFiles(
        _playId,
        formData.contributions,
        draftIdToContributionIdMap,
      );

      if (playId == undefined && updatedPlay.id != null) {
        handleNewPlayEdit(updatedPlay.id);
        return;
      } else {
        setPlayId(_playId);
        await refetch();
      }

      setSavingLoader(false);
      setSaveDisable(false);

      if (playId == undefined && updatedPlay.id != null) {
        navigate(`/plays/${updatedPlay.id}/edit`);
      }
    } catch (e) {
      setSavingLoader(false);
      setSaveDisable(false);
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };
  const handleDeletePlay = async () => {
    try {
      await deleteMutation.mutateAsync().then(() => {
        setAlertState({
          active: true,
          message: GlobalMessages.deleteVenueSuccessMsg,
        });
        handleClose();
      });
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };

  const handleClose = () => {
    reset();
    navigate('/plays');
  };

  const handleNewPlayEdit = (id: string) => {
    reset();
    navigate(`/plays/${id}/edit`);
  };

  async function uploadProductionContributionFiles(
    _playId: string,
    contributions: PlayContributionWithFiles[],
    draftIdToContributionIdMap: { [key: string]: string },
  ) {
    let idToContributionMap = {};
    if (contributions) {
      contributions.forEach((c) => {
        let { draftId, contributionFiles } = c;
        if (contributionFiles) {
          const contributionUniqueId = draftId ? draftId : c.id;
          idToContributionMap[contributionUniqueId] = c;
        } else {
          return c;
        }
      });
    }
    const contributionFilesToBeUpload: ContributionFileUpload[] = [];
    if (contributions) {
      contributions.map((c) => {
        const { draftId, id, contributionFiles } = c;
        if (contributionFiles) {
          const contributionUUID = draftIdToContributionIdMap[draftId]
            ? draftIdToContributionIdMap[draftId]
            : id;
          if (contributionUUID) {
            contributionFilesToBeUpload.push({
              contributionId: contributionUUID,
              contributionFiles: contributionFiles,
            });
          }
        }
      });
      await uploadAllPlayContributionFiles(
        _playId,
        contributionFilesToBeUpload,
      );
    }
  }

  async function uploadAllPlayContributionFiles(
    _playId: string,
    contributionFilesToBeUpload: any[],
  ) {
    const uploadPromises = contributionFilesToBeUpload.map(
      ({ contributionId, contributionFiles }) => {
        console.log(contributionFiles);
        const contributionFilesResponses = contributionFiles.map(
          async (file) => {
            const uploadResponse = await uploadMediaMultiple_playContributions(
              playId,
              contributionId,
              file,
              UploadMediaType.file,
            );
            return uploadResponse;
          },
        );

        return contributionFilesResponses;
      },
    );

    await Promise.allSettled(uploadPromises);
  }

  function afterBrandAdded(brand?: Brand) {
    if (brand) {
      setValue('selectedBrand', {
        id: brand.id,
        label: `${brand.name} - ${brand.id}`,
      });
      setValue('brandId', brand.id);
      setSelectedBrand({
        id: brand.id,
        label: `${brand.name} - ${brand.id}`,
      });
    }
    setState((prevState) => ({
      ...prevState,
      addBrandDialogState: !prevState.addBrandDialogState,
    }));
  }

  const successAlert = postPlayMetaSuccess || updatePlayMetaSuccess;
  const errorAlert = postPlayMetaError || updatePlayMetaError;
  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;

  useEffect(() => {
    if (!selectedBrand && isPlayLoaded && brands) {
      let brand = play?.brand
        ? { id: play.brand.id, label: play.brand.name }
        : null;
      setValue('selectedBrand', brand);
      getInitFeaturedMedia();
    }
  }, [selectedBrand, isPlayLoaded, brands]);

  useEffect(() => {
    fetchSearchFilterConfig();
    return () => {
      queryClient.removeQueries(['selectedPlay']);
    };
  }, []);

  const getInitFeaturedMedia = async () => {
    const allFeaturedMedia = await getPlayFeaturedMedia(playId);
    setDisplayFeaturedMedia(allFeaturedMedia);
  };
  const isLoading = Boolean(isPlayLoading && playId);

  return (
    <>
      {/* {isPullingPlay && play != null && <Navigate to={`plays/${playId}/edit`} replace={true} />} */}
      {(isLoading || isBrandListLoading) && (
        <Loader isLoading={isLoading || isBrandListLoading} />
      )}
      {isSavingLoader && (
        <Loader isLoading={isSavingLoader} message={GlobalMessages.savingMsg} />
      )}
      <Alert
        message={alertState.message}
        open={showAlert}
        severity={alertState.error ? 'error' : 'success'}
        autoHideDuration={alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
      <Box className={classes.bodyBackground}>
        <DialogHeader
          title={play != null && playId ? 'Edit Play' : 'Create Play'}
          handleSave={handleSubmit(onSubmit)}
          cancel={() => {
            reset();
            navigate('/plays');
          }}
          handleDelete={playId && handleDeletePlay}
          primaryBtnDisable={saveDisable}
        />
        <Grid
          container
          sx={{ justifyContent: 'center' }}
          className={classes.container}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Paper>
                <Box className={classes.accountInfo}>
                  <Typography variant="h1">Set Brand for Play</Typography>
                  <Box sx={{ display: 'flex', mt: '0px', marginBottom: '0px' }}>
                    <Divider sx={{ marginBottom: '14px', marginTop: '0px' }} />
                  </Box>
                  <Box
                    sx={{ display: 'flex', mt: '14px', marginBottom: '30px' }}
                  >
                    <Box>
                      <IconButton
                        color="primary"
                        aria-label="addBrand"
                        component="div"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            addBrandDialogState: !prevState.addBrandDialogState,
                          }))
                        }
                        sx={{ marginTop: '20px' }}
                      >
                        <AddIcon />
                      </IconButton>
                      {state.addBrandDialogState && (
                        <AddBrands
                          open={state.addBrandDialogState}
                          onCancel={afterBrandAdded}
                        />
                      )}
                      {/* <Typography variant="h6" lineHeight='20px'>
                    <ul>
                      <li>All plays must be linked to a &quot;Brand&quot; that associates the play to some top level IP</li>
                      <ul><li>ex: &quot;Seussical&quot; is the Brand associated with the play &quot;Seussical, Jr.&quot;</li> </ul>
                      <li>Select an existing Brand from the dropdown OR create one by clicking the plus button below.</li>
                      <li>This play will inherit default images/media from this Brand</li>
                      <li>You can set specific media for this play below this section</li>
                    </ul>
                  </Typography> */}
                    </Box>

                    <Box sx={{ width: '85%' }}>
                      <Autocomplete
                        label={'Select Brand'}
                        placeholder="Select a Brand"
                        name="selectedBrand"
                        control={control}
                        options={((brands as Brand[]) || []).map(
                          (brand: Brand) => {
                            return {
                              id: brand.id,
                              label: `${brand.name} - ${brand.id}`,
                            };
                          },
                        )}
                        getSelectedOption={(option) => {
                          console.log(option);
                          setSelectedBrand(option?.value);
                        }}
                      />
                      <br />
                      <CopyToClipboardButton
                        id={
                          isPlayLoaded
                            ? selectedBrand == null
                              ? play.brandId
                              : selectedBrand.id
                            : ''
                        }
                      />
                    </Box>
                  </Box>
                  <SingleImageUpload
                    title={'Set Brand Poster'}
                    maxFileSizeInMB={PosterMaxFileSizeInMB}
                    validator={(fileExt) =>
                      PosterSupportedFormat.includes(fileExt)
                    }
                    initialUpload={
                      play?.brandPosterURL ? play?.brandPosterURL : ''
                    }
                    uploadCallback={async (blob, file) => {
                      setPlayRelatedPostersAndBannersOnQueue((prevValue) => ({
                        ...prevValue,
                        brandPosterOnQueue: {
                          blob,
                          mimeType: file.type,
                          name: file.name,
                          type: 'brand-poster',
                          file: file,
                        },
                      }));
                    }}
                    deleteCallback={() => {
                      setPlayRelatedPostersAndBannersToBeDeleted(
                        (prevValue) => ({
                          ...prevValue,
                          brandPosterToBeDeleted:
                            playRelatedPostersAndBanners.brandPosterDetails,
                        }),
                      );
                    }}
                  />
                  <SingleImageUpload
                    title={'Set Brand Marquee'}
                    validator={(fileExt) =>
                      PosterSupportedFormat.includes(fileExt)
                    }
                    initialUpload={
                      play?.brandBannerURL ? play?.brandBannerURL : ''
                    }
                    uploadCallback={async (blob, file) => {
                      setPlayRelatedPostersAndBannersOnQueue((prevValue) => ({
                        ...prevValue,
                        brandBannerOnQueue: {
                          blob,
                          mimeType: file.type,
                          name: file.name,
                          type: 'brand-banner',
                          file: file,
                        },
                      }));
                    }}
                    deleteCallback={() => {
                      setPlayRelatedPostersAndBannersToBeDeleted(
                        (prevValue) => ({
                          ...prevValue,
                          brandBannerToBeDeleted:
                            playRelatedPostersAndBanners.brandBannerDetails,
                        }),
                      );
                    }}
                  />
                  <Divider sx={{ marginBottom: '14px', marginTop: '0px' }} />

                  <MultiFileUpload
                    id={uuidv4()}
                    title={'Manage Brand Files'}
                    files={brandFiles}
                    validator={(fileExt, sizeInMB) =>
                      supportedBrandFileFormats.includes(fileExt) &&
                      sizeInMB < 10
                    }
                    uploadCallback={(fileInfo, fileType) => {
                      setBrandFiles((existingFiles) => [
                        ...existingFiles,
                        fileInfo,
                      ]);
                      setBrandFilesOnQueue((existingFiles) => [
                        ...existingFiles,
                        { fileInfo, fileType },
                      ]);
                    }}
                    deleteCallback={(file) => {
                      setBrandFiles([
                        ...brandFiles.filter(
                          (f) => f.mediaDetails.id !== file.mediaDetails.id,
                        ),
                      ]);
                      setBrandFilesToBeDeleted((existingFiles) => [
                        ...existingFiles,
                        file,
                      ]);
                    }}
                  />
                  <Box mt={3}>
                    <SingleImageUpload
                      title={'Upload Play Poster'}
                      maxFileSizeInMB={PosterMaxFileSizeInMB}
                      validator={(fileExt) =>
                        PosterSupportedFormat.includes(fileExt)
                      }
                      initialUpload={
                        play?.playPosterURL ? play?.playPosterURL : ''
                      }
                      uploadCallback={async (blob, file) => {
                        setPlayRelatedPostersAndBannersOnQueue((prevValue) => ({
                          ...prevValue,
                          playPosterOnQueue: {
                            blob,
                            mimeType: file.type,
                            name: file.name,
                            type: 'poster',
                            file: file,
                          },
                        }));
                      }}
                      deleteCallback={() => {
                        setPlayRelatedPostersAndBannersToBeDeleted(
                          (prevValue) => ({
                            ...prevValue,
                            playPosterToBeDeleted:
                              playRelatedPostersAndBanners.playPosterDetails,
                          }),
                        );
                      }}
                    />
                    <SingleImageUpload
                      title={'Upload Play Marquee'}
                      validator={(fileExt) =>
                        PosterSupportedFormat.includes(fileExt)
                      }
                      initialUpload={
                        play?.playBannerURL ? play?.playBannerURL : ''
                      }
                      uploadCallback={async (blob, file) => {
                        setPlayRelatedPostersAndBannersOnQueue((prevValue) => ({
                          ...prevValue,
                          playBanneronQueue: {
                            blob,
                            mimeType: file.type,
                            name: file.name,
                            type: 'banner',
                            file: file,
                          },
                        }));
                      }}
                      deleteCallback={() => {
                        setPlayRelatedPostersAndBannersToBeDeleted(
                          (prevValue) => ({
                            ...prevValue,
                            playBannerToBeDeleted:
                              playRelatedPostersAndBanners.playBannerDetails,
                          }),
                        );
                      }}
                    />
                  </Box>
                  <Divider sx={{ margin: '0px', marginBottom: '15px' }} />
                  <MultiFileUpload
                    id={uuidv4()}
                    title={'Play Files'}
                    files={playFiles}
                    validator={(fileExt, sizeInMB) =>
                      supportedPlayFileFormats.includes(fileExt) &&
                      sizeInMB < 500
                    }
                    uploadCallback={(fileInfo, fileType) => {
                      setPlayFiles((existingFiles) => [
                        ...existingFiles,
                        fileInfo,
                      ]);
                      setPlayFilesOnQueue((existingFiles) => [
                        ...existingFiles,
                        { fileInfo, fileType },
                      ]);
                    }}
                    deleteCallback={(file) => {
                      setPlayFiles([
                        ...playFiles.filter(
                          (f) => f.mediaDetails.id !== file.mediaDetails.id,
                        ),
                      ]);
                      setPlayFilesToBeDeleted((existingFiles) => [
                        ...existingFiles,
                        file,
                      ]);
                    }}
                  />
                  {(isPlayLoaded || !playId) && (
                    <>
                      <PlayContributions
                        isLoading={isLoading}
                        contributions={play?.contributions || []}
                        onAdd={(contributions) => {
                          setValue('contributions', contributions);
                        }}
                      />
                    </>
                  )}
                  {(isPlayLoaded || !playId) && (
                    <PlayCharacters
                      isLoading={isLoading}
                      characters={play?.characters || []}
                      onAdd={(characters) => {
                        setValue('characters', characters);
                      }}
                    />
                  )}
                  {play && playId && (
                    <>
                      <Box my={3}>
                        <FeaturedPlayProductions
                          productions={play?.featuredPlayProductions || []}
                          productionCountByStudioType={
                            play?.productionCountByStudioType || {}
                          }
                          playId={playId}
                          getDisplayFeaturedMedia={(medias) => {
                            setDisplayFeaturedMedia(medias);
                          }}
                        />
                      </Box>
                      <Box my={3}>
                        <FeaturedPlayContributions
                          contributions={play?.featuredPlayContributions || []}
                          playId={playId}
                        />
                      </Box>
                    </>
                  )}
                  <Box>
                    <DisplaySelectedFeaturedMedia
                      featuredMedia={displayFeaturedMedia}
                      productions={play?.featuredPlayProductions || []}
                      productionCountByStudioType={
                        play?.productionCountByStudioType || {}
                      }
                      playId={playId}
                      getDisplayFeaturedMedia={(medias) => {
                        setDisplayFeaturedMedia(medias);
                      }}
                      deleteDisplayFeaturedMedia={deleteDisplayFeaturedMedia}
                    />
                  </Box>

                  <Typography variant="h1">Set Play Details</Typography>
                  <Typography variant="h5">
                    Brand, License Name, Play Type, and License House Available
                    Date are required to add/update a play.
                  </Typography>
                  <br />
                  <CopyToClipboardButton
                    id={isPlayLoaded ? play && play.id : ''}
                  />
                  <Box mt={3}>
                    <Select
                      options={config?.play?.playTypes || []}
                      placeholder="Play Type"
                      control={control}
                      name="playType"
                      label="Play Type"
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="licenseName"
                      label="License Name"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="License Master"
                      name="licenseMaster"
                      control={control}
                      type={'number'}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Other Names"
                      name="otherNames"
                      control={control}
                      type={'string'}
                    />
                  </Box>

                  <Box mt={3}>
                    <Select
                      options={config?.play?.licenseHouses || []}
                      placeholder="MTI, Concord, TRW..."
                      control={control}
                      name="licenseHouse"
                      label="License House"
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.licenseVersions || []}
                      placeholder="Select License Version..."
                      control={control}
                      name="licenseVersion"
                      label="License Version"
                    />
                  </Box>

                  <Box mt={3}>
                    <TextField
                      name="licenseWebsite"
                      label="License Website"
                      control={control}
                    />
                  </Box>

                  <Box mt={3}>
                    <TextField
                      maxRows={4}
                      multiline={true}
                      placeholder="Short Description"
                      onChange={(e) => console.log(e.target.value)}
                      label="Short Description"
                      control={control}
                      name="shortDescription"
                      styles={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      minRows={4}
                      multiline={true}
                      placeholder="Long Description"
                      onChange={(e) => console.log(e.target.value)}
                      style={{ width: '100%', height: 133 }}
                      label="Long Description"
                      control={control}
                      name="longDescription"
                      styles={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="synopsis"
                      label="Synopsis"
                      control={control}
                      minRows={4}
                      multiline={true}
                      placeholder="Enter the play Synopsis"
                      styles={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="history"
                      label="History"
                      control={control}
                      minRows={4}
                      multiline={true}
                      placeholder="Enter the play hostory"
                      styles={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      label="Original Production Year"
                      name="originalProductionYear"
                      control={control}
                      type={'number'}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="originalProductionWebsite"
                      label="Original Production Website"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.playScopes || []}
                      placeholder="Select Play Scope..."
                      control={control}
                      name="scope"
                      label="Scope"
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="duration"
                      label="Duration"
                      control={control}
                      type={'number'}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="numberOfActs"
                      label="Number of Acts"
                      control={control}
                      type={'number'}
                    />
                  </Box>
                  <Box mt={3}>
                    <InputLabel
                      id={'awards'}
                      aria-label={'awards'}
                      variant="standard"
                      sx={{ paddingBottom: '4px' }}
                    >
                      Awards
                    </InputLabel>
                    <ChipsInputField name="awards" control={control} />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.genres || []}
                      placeholder="Genres"
                      control={control}
                      name="genres"
                      label="Genres"
                      multiple
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.themes || []}
                      placeholder="Themes"
                      control={control}
                      name="themes"
                      label="Themes"
                      multiple
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="playSetting"
                      label="Play Setting"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="principle"
                      label="Principle"
                      control={control}
                      type={'number'}
                    />
                  </Box>
                  <Box mt={3}>
                    <Checkbox
                      name="ensemble"
                      label="Ensemble"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      maxRows={10}
                      multiline={true}
                      placeholder="Add Notes"
                      onChange={(e) => console.log(e.target.value)}
                      label="Casting Notes"
                      control={control}
                      name="castingNotes"
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.castTypeDescriptions || []}
                      placeholder="Select Cast Type Description..."
                      control={control}
                      name="castTypeDescription"
                      label="Cast Type Description"
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.vocalRequirements || []}
                      placeholder="Vocal Requirements"
                      control={control}
                      name="vocalRequirements"
                      label="Vocal Requirements"
                      multiple
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="danceRequirements"
                      label="Dance Requirements"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={config?.play?.targetInstitutionTypes || []}
                      placeholder="Select Target Institution Type(s)"
                      control={control}
                      name="targetInstitutionType"
                      label="Target Institution Type"
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="audienceRating"
                      label="Audience Rating"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="licenseHouseAvailableDate"
                      label="License House Available Date"
                      type="date"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="licenseHouseUnavailableDate"
                      label="License House Unavailable Date"
                      type="date"
                      control={control}
                    />
                  </Box>

                  {/* <Box mt={3}>
                    <Select
                      options={(
                        Object.keys(PlayCastSize) as Array<
                          keyof typeof PlayCastSize
                        >
                      )
                        .map((key) => PlayCastSize[key])
                        .sort()}
                      placeholder="Select Cast Size..."
                      control={control}
                      name="castSize"
                      label="Cast Size"
                    />
                  </Box>
                 
                  <Box mt={3}>
                    <TextField
                      name="ensembleSize"
                      label="Ensemble Size"
                      control={control}
                    />
                  </Box>
                 */}

                  <Box mt={3}>
                    <TextField
                      name="licenseSourceId"
                      label="License Source Id"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="dataSource"
                      label="Data Source"
                      control={control}
                      type={'string'}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="sourceUrl"
                      label="Source Url"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="bodSourceId"
                      label="Bod Source Id"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="listingWebsite"
                      label="Listing Website"
                      control={control}
                      type={'string'}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="dataQualityStatus"
                      label="Data Quality Status"
                      control={control}
                    />
                  </Box>
                </Box>
                {/* <Box mt={'16px'} p={'32px'}>
                      <Details
                        title="Add Orchestrations"
                        onAddButton={() =>
                          setSideDrawerOn({
                            ...defaultSideBarState,
                            addOrchestrations:
                              !isSideDrawerOn.addOrchestrations,
                          })
                        }
                      >
                        {!isPlayLoaded ? (
                          <div>Loading Orchestrations...</div>
                        ) : (
                          play.orchestrations?.map(
                            (orchestration: Orchestration) => (
                              <Stack
                                display="flex"
                                direction="row"
                                style={{ marginBottom: '24px' }}
                                key={orchestration.id}
                              >
                                <ListItem>
                                  {orchestration.orchestrationName}
                                </ListItem>
                                <Button
                                  label="Edit"
                                  variant={'outlined'}
                                  color={'secondary'}
                                  onClick={() =>
                                    setSideDrawerOn({
                                      ...defaultSideBarState,
                                      addOrchestrations:
                                        !isSideDrawerOn.addOrchestrations,
                                      id: `${orchestration.id}`,
                                    })
                                  }
                                />
                              </Stack>
                            ),
                          )
                        )}
                      </Details>
                    </Box> */}
                {/* <Box mt={'16px'} p={'32px'}>
                      <Details
                        title="Add Characters"
                        onAddButton={() =>
                          setSideDrawerOn({
                            ...defaultSideBarState,
                            addCharacters: !isSideDrawerOn.addCharacters,
                          })
                        }
                      >
                        {!isPlayLoaded ? (
                          <div>Loading Characters...</div>
                        ) : (
                          play.characters?.map((character: Character) => (
                            <Stack
                              display="flex"
                              direction="row"
                              style={{ marginBottom: '24px' }}
                              key={character.id}
                            >
                              <ListItem>{character.name}</ListItem>
                              <Button
                                label="Edit"
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={() =>
                                  setSideDrawerOn({
                                    ...defaultSideBarState,
                                    addCharacters:
                                      !isSideDrawerOn.addCharacters,
                                    id: `${character.id}`,
                                  })
                                }
                              />
                            </Stack>
                          ))
                        )}
                      </Details>
                    </Box> */}
                {/* <Box mt={'16px'} p={'32px'}>
                      <Details
                        title="Add Songs"
                        onAddButton={() =>
                          setSideDrawerOn({
                            ...defaultSideBarState,
                            addSongs: !isSideDrawerOn.addSongs,
                          })
                        }
                      >
                        {!isPlayLoaded ? (
                          <div>Loading Songs...</div>
                        ) : (
                          play.songs?.map((song: Song) => (
                            <Stack
                              display="flex"
                              direction="row"
                              style={{ marginBottom: '24px' }}
                              key={song.id}
                            >
                              <ListItem>{song.name}</ListItem>
                              <Button
                                label="Edit"
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={() =>
                                  setSideDrawerOn({
                                    ...defaultSideBarState,
                                    addSongs: !isSideDrawerOn.addSongs,
                                    id: `${song.id}`,
                                  })
                                }
                              />
                            </Stack>
                          ))
                        )}
                      </Details>
                    </Box> */}
                {/*<Box mt={'16px'} p={'32px'}>*/}
                {/*  <Details*/}
                {/*    title="Featured Songs"*/}
                {/*    onAddButton={() =>*/}
                {/*      setSideDrawerOn({*/}
                {/*        ...defaultSideBarState,*/}
                {/*        addFeaturedArtists:*/}
                {/*          !isSideDrawerOn.addFeaturedArtists,*/}
                {/*      })*/}
                {/*    }*/}
                {/*  >*/}
                {/*    /!* TOD0: will be replaced with real data *!/*/}
                {/*    {[0, 1].map((e: any, index: number) => (*/}
                {/*      <>*/}
                {/*        <Stack*/}
                {/*          display="flex"*/}
                {/*          direction="row"*/}
                {/*          style={{ marginBottom: '24px' }}*/}
                {/*        >*/}
                {/*          <ListItem>Featured Songs</ListItem>*/}
                {/*          <Button*/}
                {/*            label="Delete"*/}
                {/*            variant="contained"*/}
                {/*            color="error"*/}
                {/*            onClick={() =>*/}
                {/*              setSideDrawerOn({*/}
                {/*                ...defaultSideBarState,*/}
                {/*                addPlayArtists:*/}
                {/*                  !isSideDrawerOn.addPlayArtists,*/}
                {/*                id: `${index}`,*/}
                {/*              })*/}
                {/*            }*/}
                {/*          />*/}
                {/*        </Stack>*/}
                {/*      </>*/}
                {/*    ))}*/}
                {/*  </Details>*/}
                {/*</Box>*/}
                {/* </>
                )} */}
                <PlaySideDrawers
                  sideDrawerState={isSideDrawerOn}
                  id={isSideDrawerOn.id}
                  defaultSideBarState={defaultSideBarState}
                  currentState={(state: any) => setSideDrawerOn(state)}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
