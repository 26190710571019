import React, { useEffect, useState } from 'react';
import { Alert, Autocomplete, Paper } from '../../../../components';
import { Box, CircularProgress, Divider } from '@mui/material';
import Loader from '../../../../components/Loader';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import DialogHeader from '../../../common/DialogHeader';
import { makeStyles } from '@mui/styles';
import { AddVenuePickerFormValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import { Venue } from '../../../../types/Venue';
import { useGetAllVenues } from '../../../../api/venues';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
}));

type Props = {
  title: string;
  currentVenue: Venue | null;
  handleClose: (venue: Venue, isEditMode?: boolean) => void;
  handleSave: (args: {
    venueId: string;
    featuredProductionMediaId?: string;
    isEditMode?: boolean;
  }) => Promise<Venue>;
};
const VenuePicker = ({
  title,
  currentVenue,
  handleClose,
  handleSave,
}: Props) => {
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });
  const [venues, setVenues] = useState<Venue[]>([]);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  const isEditMode = Boolean(currentVenue);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = AddVenuePickerFormValidation();
  const isError = alertState.active;
  const { isLoading: isFetching, data: venuesList } = useGetAllVenues();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await handleSave({
        venueId: data.venue.id,
        isEditMode: isEditMode,
      });
      setLoading(false);
      handleClose(
        {
          ...data.venue,
        },
        isEditMode,
      );
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message:
          'Something went wrong! Please ensure the venue is not added already!',
      });
    }
  };

  useEffect(() => {
    setVenues(venuesList || []);
  }, [venuesList]);

  useEffect(() => {
    if (!currentVenue) return;
    reset({
      venue: {
        id: currentVenue.id,
        label: `${currentVenue.name} - ${currentVenue.city || 'N/A'}, ${
          currentVenue.state || 'N/A'
        }`,
        ...currentVenue,
      },
    });
  }, [currentVenue]);

  return (
    <Box>
      <DialogHeader
        title={`${isEditMode ? 'Edit' : 'Add'} ${title}`}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            <Divider sx={{ marginTop: 0, marginBottom: '24px' }} />

            <Box my={3}>
              {isFetching ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Autocomplete
                    error={!!errors}
                    label={`Select Venue (${venues?.length})`}
                    placeholder="Select Venue"
                    name="venue"
                    control={control}
                    disabled={isEditMode}
                    options={venues.map((venue) => {
                      return {
                        id: venue.id,
                        label: `${venue.name} - ${venue.city || 'N/A'}, ${
                          venue.state || 'N/A'
                        }`,
                        ...venue,
                      };
                    })}
                  />
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default VenuePicker;
