import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PlaySummary from './plays/PlaySummary';
import StudioSummary from './studios/StudioSummary';
import VenueSummary from './venues/VenueSummary';
import HomeSummary from './home/HomeSummary';

const PAGES = ['Home', 'Plays', 'Studios', 'Venues'];
export default function SummaryPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    const path = location.pathname;
    const matchedIndex = PAGES.findIndex((p) =>
      path.includes(p.toLocaleLowerCase()),
    );
    setSelectedIndex(matchedIndex > 0 ? matchedIndex : 0);
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: '#e5e5e5',
        minHeight: 'calc(100vh - 48px)',
        flex: 1,
        padding: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: '1440px',
          mx: { xs: 2, md: 3, lg: 6, xl: 'auto' },
          my: 4,
          borderRadius: 2,
          backgroundColor: '#fff',
          flex: 1,
        }}
      >
        <Box
          sx={{
            padding: 4,
            display: 'flex',
            alignItems: 'stretch',
            height: 'inherit',
          }}
        >
          <Box
            sx={{
              minWidth: ['120px', '250px'],
              height: '100%',
            }}
          >
            {PAGES.map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() => {
                    setSelectedIndex(index);
                    navigate(
                      text === 'Home' ? '' : String(text).toLocaleLowerCase(),
                    );
                  }}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <Box
            sx={{
              borderLeft: '1px solid #eee',
              pl: 2,
              flex: 1,
            }}
          >
            <Routes>
              <Route path="/" element={<HomeSummary />} />
              <Route path="/plays" element={<PlaySummary />} />
              <Route path="/studios" element={<StudioSummary />} />
              <Route path="/venues" element={<VenueSummary />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
