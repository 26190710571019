import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from '@mui/material';
import Paper from '../Paper';
import UploadFileInput, { FileInputButton } from './UploadFileInput';
import { Button, SideDrawer } from '../index';
import { v4 as uuidv4 } from 'uuid';
import { extractNumberFromString, getExtFromFileName } from '../../utils/media';
import { bytesToMB } from '../../utils/units';
import ConfirmDialog, { useConfirmModal } from '../Modals/ConfirmDialog';

export interface FileInfo {
  fileURL?: string;
  fileUrl: string;
  fileName: string;
  fileSize: string;
  mediaDetails?: {
    id: string;
  };
}

interface MultiImageUploadProps {
  id?: string;
  title: string;
  message?: string;
  subtitle?: string;
  coverTitle?: string;
  photoTitle?: string;
  images?: FileInfo[];
  uploadCallback?: (files: FileInfo, mimeType: string) => void;
  deleteCallback?: (files: FileInfo) => void;
  validator?: (fileName: string, fileSizeInMB: number) => boolean;
}

export interface UploadedImage {
  id?: string;
  url?: string;
  title?: string;
}

export default function MultiImageUpload(props: MultiImageUploadProps) {
  const {
    id = `${uuidv4()}`,
    title,
    message = "These photos will be shown in a carousel on your studio's page.",
    subtitle = '',
    coverTitle = 'Cover Photo',
    photoTitle = 'Photo',
    images = [],
    validator = () => true,
    deleteCallback,
    uploadCallback,
  } = props;

  const [sideDrawerState, setSideDrawerState] = useState(false);
  const [sidedrawerPreview, setSideDrawerPreview] = useState<any>({});
  const {
    setShowDeleteModal,
    setShowDeleteModalLoader,
    showDeleteModal,
    showDeleteModalLoader,
  } = useConfirmModal();
  const [fileToDelete, setFileToDelete] = useState<FileInfo | null>(null);

  const handleChange = (event) => {
    const files = event.target.files;
    const fileCount: number = files.length;

    for (let i = 0; i < fileCount; i++) {
      const file = files[i];
      const isValidFile = validator(
        getExtFromFileName(file.name),
        bytesToMB(file.size),
      );
      if (!isValidFile) {
        alert('Please provide a valid file');
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const newFileInfo = {
            fileUrl: event.target.result as string,
            fileURL: event.target.result as string,
            fileName: file.name,
            fileSize: `${bytesToMB(file.size)}MB`,
          };
          uploadCallback(newFileInfo, file.type);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <Box id={uuidv4()} key={uuidv4()}>
        <Box key={uuidv4()} sx={{ marginBottom: '24px' }}>
          <Typography variant="h1" sx={{ marginBottom: '10px' }}>
            {title}
          </Typography>
          <Typography variant="h5">{message}</Typography>
          {subtitle && (
            <Typography variant="h3" textAlign="center">
              <br />
              <b>{subtitle}</b>
            </Typography>
          )}
        </Box>
        <FileInputButton
          key={uuidv4()}
          id={uuidv4()}
          handleChange={handleChange}
          sx={{ margin: '1rem 0' }}
        >
          Add Files
        </FileInputButton>
        <Box key={uuidv4()} id={uuidv4()}>
          {images.length == 0 || (
            <>
              <Box
                key={uuidv4()}
                id={uuidv4()}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 250px)',
                  gap: '2rem',
                }}
              >
                {images.map((file, index) => {
                  // const fileSizeInMB = extractNumberFromString(file?.fileSize);
                  return (
                    <>
                      <Box
                        key={index} //{`${file.fileName}${index}`}
                        sx={{
                          height: 'inherit',
                        }}
                      >
                        <Box key={uuidv4()} id={uuidv4()}>
                          <Box
                            key={uuidv4()}
                            id={uuidv4()}
                            sx={{
                              position: 'relative',
                              width: '250px',
                              height: '250px',
                              borderRadius: '1rem',
                              overflow: 'hidden',
                              background: '#eee',
                              '& > #detailsStack': {
                                opacity: 0,
                              },
                              '&:hover > #detailsStack': {
                                opacity: 1,
                              },
                            }}
                          >
                            <img
                              key={uuidv4()}
                              id={uuidv4()}
                              src={file.fileURL || file.fileUrl}
                              alt=""
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                gap: '1rem',
                                alignItems: 'center',
                              }}
                            />
                            <Box
                              key={uuidv4()}
                              id={uuidv4()} //"detailsStack"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                gap: '1rem',
                                flexWrap: 'wrap',
                                background: '#00000075',
                              }}
                            >
                              <Button
                                key={uuidv4()}
                                id={uuidv4()}
                                label={'View Details'}
                                variant={'contained'}
                                color={'primary'}
                                sx={{ maxWidth: '50%' }}
                                onClick={() => {
                                  setSideDrawerState(true);
                                  setSideDrawerPreview({
                                    imageSource: file.fileUrl || file.fileURL,
                                    imageDetails: {
                                      name: file.fileName,
                                      size: file.fileSize,
                                    },
                                  });
                                }}
                              />
                              <Button
                                key={uuidv4()}
                                id={uuidv4()}
                                label={'Delete'}
                                variant={'contained'}
                                color={'error'}
                                sx={{ maxWidth: '50%' }}
                                onClick={() => {
                                  setFileToDelete(file);
                                  setShowDeleteModal(true);
                                }}
                              />
                            </Box>
                          </Box>

                          <Typography
                            key={uuidv4()}
                            variant="h5"
                            sx={{ color: '#111827' }}
                          >
                            {file.fileName}
                          </Typography>
                          {/* {fileSizeInMB <= 0.5 &&
                          file?.fileSize?.includes('MB') ?  */}
                          {/* (
                            <Typography
                              key={uuidv4()}
                              variant="h5"
                              sx={{ color: '#6B7280' }}
                            >
                              {file.fileSize}
                              <br />
                              Lo-Res Carousel
                            </Typography>
                          ) : ( */}
                          <Typography
                            key={uuidv4()}
                            variant="h5"
                            sx={{ color: '#6B7280' }}
                          >
                            {file.fileSize}
                            <br />
                            Hi-Res Carousel
                          </Typography>
                        </Box>
                      </Box>
                      <SideDrawer
                        key={uuidv4()}
                        id={uuidv4()}
                        isActive={sideDrawerState}
                        toggleDrawer={() => {
                          setSideDrawerState(!sideDrawerState);
                        }}
                      >
                        <Typography
                          key={uuidv4()}
                          component={'h1'}
                          sx={{
                            paddingLeft: '2%',
                            paddingTop: '3%',
                            fontWeight: 'Strong',
                          }}
                        >
                          Preview
                        </Typography>
                        <Box
                          key={uuidv4()}
                          id={uuidv4()}
                          component={'img'}
                          src={sidedrawerPreview.imageSource}
                          alt="preview"
                          sx={{ padding: '2%', maxWidth: '60em' }}
                        />
                        <Typography
                          key={uuidv4()}
                          component={'h2'}
                          sx={{ paddingLeft: '2%' }}
                        >
                          {' '}
                          {sidedrawerPreview?.imageDetails?.name}
                        </Typography>
                        <Typography
                          key={uuidv4()}
                          component={'h4'}
                          sx={{ paddingLeft: '2%' }}
                        >
                          {' '}
                          {sidedrawerPreview?.imageDetails?.size}
                        </Typography>
                      </SideDrawer>
                    </>
                  );
                })}
              </Box>
            </>
          )}
        </Box>
      </Box>
      <ConfirmDialog
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        dialogOptions={{ isLoading: showDeleteModalLoader }}
        handleConfirmDelete={async () => {
          setShowDeleteModalLoader(true);
          await deleteCallback(fileToDelete);
          setShowDeleteModalLoader(false);
          setShowDeleteModal(false);
        }}
      />
    </>
  );
}
