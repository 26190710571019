import { Production } from './Production';
import { Artist } from './Artist';
import { Character } from './Character';
import { OrchestrationBook } from './OrchestrationBook';

interface CustomContributionName {
  custom: string;
}

interface ContributionFiles {
  fileName: string;
  fileUrl: string;
  fileSize: string;
}

export type ProductionContribution = {
  contributionFileURLs: ContributionFiles[];
  id: string;
  productionId: string;
  artistId: string;
  contributionType: ProductionContributionType;
  contributionName: ProductionContributionName;
  customContributionName?: string;
  award?: string;
  createdAt: Date;
  updatedAt: Date;
  production?: Production;
  artist?: Artist;
  character?: Character;
  orchestrationBook?: OrchestrationBook;
};

export enum ProductionContributionType {
  administrative = 'Administrative',
  artistic = 'Artistic',
  crew = 'Crew',
  faculty = 'Faculty',
  musician = 'Musician',
  performer = 'Performer',
  studio = 'Studio',
  venue = 'Venue',
}

export type ProductionContributionName =
  | AdministrativeProductionContribution
  | ArtisticProductionContribution
  | CrewProductionContribution
  | FacultyProductionContribution
  | MusicianProductionContribution
  | PerformerProductionContribution
  | StudioProductionContribution
  | VenueProductionContribution;

export enum AdministrativeProductionContribution {
  custom = 'Custom',
  acknowledgements = 'Acknowledgements',
  adminCoodinator = 'Admin Coodinator',
  administrativeAssistant = 'Administrative Assistant',
  administrativeAssistantStaff = 'Administrative Assistant - Staff',
  bookkeeper = 'Bookkeeper',
  coachingConsultant = 'Coaching Consultant',
  corporateLogo = 'Corporate Logo',
  coverDesign = 'Cover Design',
  coverIllustration = 'Cover Illustration',
  customsAssistant = 'Customs Assistant',
  customsOfficial = 'Customs Official',
  frontOfficeStudent = 'Front Office - Student',
  grantWriter = 'Grant Writer',
  headshotsAndCandidPhotography = 'Headshots and Candid Photography',
  instrumentals = 'Instrumentals',
  orchestraManager = 'Orchestra Manager',
  office = 'Office',
  officeAdministrator = 'Office Administrator',
  officeAssistant = 'Office Assistant',
  officeAssistantStudent = 'Office Assistant - Student',
  officeManager = 'Office Manager',
  pitOrchestraCoordinator = 'Pit Orchestra Coordinator',
  playbillCoordinators = 'Playbill Coordinators',
  playbillDesign = 'Playbill Design',
  posterDesign = 'Poster Design',
  productionAdministrator = 'Production Administrator',
  productionAssistant = 'Production Assistant',
  productionCoordinator = 'Production Coordinator',
  productionManager = 'Production Manager',
  programAndPosterDesign = 'Program and Poster Design',
  programCoodinator = 'Program Coodinator',
  programDesign = 'Program Design',
  projectManager = 'Project Manager',
  promotionalVideoVolunteer = 'Promotional Video - Volunteer ',
  publicity = 'Publicity',
  publicityStudent = 'Publicity - Student',
  publicityPhotographs = 'Publicity Photographs',
  schoolMatineeCoordinator = 'School Matinee Coordinator',
  scriptLibraryStudent = 'Script Library - Student',
  scriptLibraryPublicityStudent = 'Script Library & Publicity - Student',
  specialEventsCoordinator = 'Special Events Coordinator',
  stateThespianFestivalChaperone = 'State Thespian Festival Chaperone',
  studentMarketingManager = 'Student Marketing Manager',
  webMistress = 'Web Mistress',
  webmaster = 'Webmaster',
}

export enum ArtisticProductionContribution {
  custom = 'Custom',
  artDirection = 'Art Direction',
  assistantChoreographer = 'Assistant Choreographer',
  assistantCostumeDesigner = 'Assistant Costume Designer',
  assistantDirector = 'Assistant Director',
  assistantDramaturg = 'Assistant Dramaturg',
  assistantHairAndMakeupDesigner = 'Assistant Hair and Makeup Designer',
  assistantLightingDesigner = 'Assistant Lighting Designer',
  assistantMusicDirector = 'Assistant Music Director',
  assistantScenicDesigner = 'Assistant Scenic Designer',
  assistantSetDesigner = 'Assistant Set Designer',
  assistantSoundDesigner = 'Assistant Sound Designer',
  assistantToTheBookWriter = 'Assistant to the Book Writer',
  assistantToTheChoreographer = 'Assistant to the Choreographer',
  assistantToTheCostumeDesigner = 'Assistant to the Costume Designer',
  assistantToTheDirector = 'Assistant to the Director',
  assistantToTheHairMakeupDesigner = 'Assistant to the Hair & Makeup Designer',
  assistantToTheLightingDesigner = 'Assistant to the Lighting Designer',
  assistantToTheMusicDirector = 'Assistant to the Music Director',
  assistantToTheScenicDesigner = 'Assistant to the Scenic Designer',
  assistantToTheTechnicalDirector = 'Assistant to the Technical Director',
  associateCostumeDesigner = 'Associate Costume Designer',
  associateDirector = 'Associate Director',
  choreographer = 'Choreographer',
  costume = 'Costume',
  costumeAssistant = 'Costume Assistant',
  costumeDesign = 'Costume Design',
  costumeDesignConsultant = 'Costume Design Consultant',
  costumeDesigner = 'Costume Designer',
  costumer = 'Costumer',
  costumes = 'Costumes',
  creativeAssistantToTheDirector = 'Creative Assistant to the Director',
  creativeConsultant = 'Creative Consultant',
  danceCaptain = 'Dance Captain',
  dialectConsultant = 'Dialect Consultant',
  dialogueCoach = 'Dialogue Coach',
  director = 'Director',
  dramaturg = 'Dramaturg',
  dramaturgy = 'Dramaturgy',
  fightCaptain = 'Fight Captain',
  fightChoreographer = 'Fight Choreographer',
  hair = 'Hair',
  hairMakeup = 'Hair & Makeup',
  hairMakeupCoordinatorStudent = 'Hair & Makeup Coordinator -Student',
  hairAndMakeupDesigner = 'Hair and Makeup Designer',
  hairConsultant = 'Hair Consultant',
  hairDesign = 'Hair Design',
  hairDesigner = 'Hair Designer',
  intimacyDirector = 'Intimacy Director',
  lighting = 'Lighting',
  lightingStudent = 'Lighting - Student',
  lightingAssistant = 'Lighting Assistant',
  lightingDesign = 'Lighting Design',
  lightingDesigner = 'Lighting Designer',
  lightingIntern = 'Lighting Intern',
  makeupDesign = 'Makeup Design',
  musicDirector = 'Music Director',
  musicDirectorIntern = 'Music Director Intern',
  orchestrations = 'Orchestrations',
  personalAssistantToTheDirector = 'Personal Assistant to the Director',
  productionStageManager = 'Production Stage Manager',
  projectionDesigner = 'Projection Designer',
  propDesign = 'Prop Design',
  properties = 'Properties',
  propertiesArtisan = 'Properties Artisan',
  scenery = 'Scenery',
  scenicAdvisor = 'Scenic Advisor',
  scenicArtist = 'Scenic Artist',
  scenicArtistStudent = 'Scenic Artist - Student',
  scenicArtistCharge = 'Scenic Artist Charge',
  scenicAssistant = 'Scenic Assistant',
  scenicDesigner = 'Scenic Designer',
  setDesigner = 'Set Designer',
  sound = 'Sound  ',
  soundStudent = 'Sound - Student',
  soundDesigner = 'Sound Designer',
  soundEngineer = 'Sound Engineer',
  soundFX = 'Sound F/X ',
  stageCombat = 'Stage Combat',
  technicalAdviser = 'Technical Adviser',
  technicalDirector = 'Technical Director',
  vocalDirector = 'Vocal Director',
  wigsHairStylist = 'Wigs & Hair Stylist',
}

export enum CrewProductionContribution {
  custom = 'Custom',
  additionalPropsCostumes = 'Additional Props & Costumes',
  assistantMasterElectrician = 'Assistant Master Electrician',
  assistantOrphanWranglers = 'Assistant Orphan Wranglers',
  assistantProductionManager = 'Assistant Production Manager',
  assistantStageManager = 'Assistant Stage Manager',
  assitantDogCatcher = 'Assitant Dog Catcher',
  backstageCrew = 'Backstage Crew',
  backstageLead = 'Backstage Lead',
  carpenter = 'Carpenter',
  costumeVolunteer = 'Costume - Volunteer',
  costumeAndMakeupCrew = 'Costume and Makeup Crew',
  costumeBuildCrew = 'Costume Build Crew',
  costumeConstructionCrew = 'Costume Construction Crew',
  costumeCoordinator = 'Costume Coordinator',
  costumeCrew = 'Costume Crew',
  costumeHead = 'Costume Head',
  costumeIntern = 'Costume Intern',
  costumeRun = 'Costume Run',
  costumeRunCrew = 'Costume Run Crew',
  costumeStaff = 'Costume Staff',
  costumeStitcher = 'Costume Stitcher',
  costumeTechnician = 'Costume Technician ',
  costumeTechnicianStaff = 'Costume Technician - Staff',
  costumesVolunteer = 'Costumes - Volunteer ',
  deckHead = 'Deck Head',
  dishwashers = 'Dishwashers',
  draper = 'Draper',
  electrician = 'Electrician',
  electrics = 'Electrics',
  electricsStudent = 'Electrics - Student',
  firstHand = 'First Hand',
  followSpot = 'Follow spot',
  followSpotOperator = 'Follow Spot Operator',
  galaCrew = 'Gala Crew',
  hairCrew = 'Hair Crew',
  headOrphanWrangler = 'Head Orphan Wrangler',
  lightBoardOperator = 'Light Board Operator',
  lightBoardProgrammer = 'Light Board Programmer',
  lightCrew = 'Light Crew',
  lightHangCrew = 'Light Hang Crew',
  makeup = 'Makeup',
  masterCarpenter = 'Master Carpenter',
  masterElectrician = 'Master Electrician',
  microphoneWrangler = 'Microphone Wrangler',
  mixer = 'Mixer',
  movingLightProgrammer = 'Moving Light Programmer',
  paint = 'Paint',
  photographer = 'Photographer',
  productionTechnician = 'Production Technician',
  productionTechnicianStaff = 'Production Technician - Staff',
  productionsTechnician = 'Productions Technician',
  propertiesBuild = 'Properties Build',
  propertiesBuildCrew = 'Properties Build Crew',
  propertiesBuildCrewPosters = 'Properties Build Crew - Posters',
  propertiesManager = 'Properties Manager',
  propertiesMaster = 'Properties Master',
  propertiesRunCrew = 'Properties Run Crew',
  propertyAssistant = 'Property Assistant',
  propertyAssistantStudent = 'Property Assistant - Student',
  propertyCrew = 'Property Crew',
  props = 'Props',
  propsStudent = 'Props - Student',
  runningCrew = 'Running Crew',
  sceneryRunCrew = 'Scenery Run Crew',
  scenePaintingCharge = 'Scene Painting Charge',
  sceneShopCrew = 'Scene Shop Crew',
  sceneryCrewHead = 'Scenery Crew Head',
  sceneryHead = 'Scenery Head',
  scenicForeman = 'Scenic Foreman',
  scenicPaintCharge = 'Scenic Paint Charge',
  scenicPainter = 'Scenic Painter',
  scenicRun = 'Scenic Run',
  setVolunteer = 'Set - Volunteer ',
  setDressingRoomsVolunteer = 'Set & Dressing Rooms - Volunteer ',
  setBuildCrew = 'Set Build Crew',
  setConstruction = 'Set Construction',
  setDecoration = 'Set Decoration',
  setPaintingCrew = 'Set Painting Crew',
  soundAssistant = 'Sound Assistant',
  soundAssociate = 'Sound Associate',
  soundBoardOperator = 'Sound Board Operator',
  soundCoordinator = 'Sound Coordinator',
  soundCrew = 'Sound Crew',
  soundIntern = 'Sound Intern',
  soundMixer = 'Sound Mixer',
  spotlightOperator = 'Spotlight Operator',
  stageConstructionCrew = 'Stage Construction Crew',
  stageCrew = 'Stage Crew',
  stageEngineer = 'Stage Engineer',
  stageHand = 'Stage Hand',
  stageHandIntern = 'Stage Hand Intern',
  stageMakeup = 'Stage Makeup',
  stageManagement = 'Stage Management',
  stageManagementAdvisor = 'Stage Management Advisor',
  stageManager = 'Stage Manager',
  stagemanagementAdvisor = 'Stagemanagement Advisor',
  usher = 'Usher',
  usherCrew = 'Usher Crew',
  video = 'Video',
  videoAndMediaTechnician = 'Video and Media Technician',
  videoEngineer = 'Video Engineer',
  videoOperator = 'Video Operator',
  videoTechnologyVolunteer = 'Video Technology -  Volunteer ',
  videographer = 'Videographer',
  wardrobeDayCrew = 'Wardrobe Day Crew',
}

export enum FacultyProductionContribution {
  custom = 'Custom',
  acting = 'Acting',
  actingForTheCamera = 'Acting for the Camera',
  actingMusicalTheatre = 'Acting/Musical Theatre',
  communication = 'Communication',
  costumeDesignFaculty = 'Costume Design - Faculty',
  dance = 'Dance',
  design = 'Design',
  directing = 'Directing',
  directorOfConservatoryPrograms = 'Director of Conservatory Programs',
  directorOfUndergraduateStudies = 'Director of Undergraduate Studies',
  dramaticWriting = 'Dramatic Writing',
  facultyAssistantStudent = 'Faculty Assistant - Student',
  historyPerformance = 'History/Performance',
  lecturerFaculty = 'Lecturer - Faculty',
  lightingDesignFaculty = 'Lighting Design - Faculty',
  movement = 'Movement',
  musicalTheatre = 'Musical Theatre',
  musicalTheatreDanceFaculty = 'Musical Theatre Dance - Faculty',
  performance = 'Performance',
  performanceFaculty = 'Performance - Faculty',
  playwriting = 'Playwriting',
  playwritingScriptAnalysis = 'Playwriting, Script Analysis',
  productionTechnologyAndManagement = 'Production Technology and Management',
  scenicLightingDesign = 'Scenic & Lighting Design',
  scenicDesignFaculty = 'Scenic Design - Faculty',
  scenographyFaculty = 'Scenography - Faculty',
  scriptAnalysis = 'Script Analysis',
  soundDesignFaculty = 'Sound Design - Faculty',
  stageMakeupFaculty = 'Stage Makeup - Faculty',
  surveyOfTheatre = 'Survey of Theatre',
  surveyOfTheatreFaculty = 'Survey of Theatre - Faculty',
  technicalDirectorFaculty = 'Technical Director - Faculty',
  technicalProduction = 'Technical Production',
  theatre = 'Theatre',
  theatreHistory = 'Theatre History',
  theatreManagement = 'Theatre Management',
  theatreDance = 'Theatre/Dance',
  voiceAndSpeech = 'Voice and Speech',
}

export enum MusicianProductionContribution {
  custom = 'Custom',
  accompanist = 'Accompanist',
  altoSaxophone = 'Alto Saxophone',
  baritoneSaxophone = 'Baritone Saxophone',
  bass = 'Bass',
  bassClarinet = 'Bass Clarinet',
  bassGuitar = 'Bass Guitar',
  bassTrombone = 'Bass Trombone',
  bassoon = 'Bassoon',
  cello = 'Cello',
  clarinet = 'Clarinet',
  conductor = 'Conductor',
  conductorStudent = 'Conductor - Student',
  conductorSub = 'Conductor Sub',
  doubleBass = 'Double Bass',
  drums = 'Drums',
  drumsPercussion = 'Drums/Percussion',
  drumset = 'Drumset',
  englishHorn = 'English Horn',
  englishHornOboe = 'English Horn/Oboe',
  flute = 'Flute',
  frenchHorn = 'French Horn',
  guitar = 'Guitar',
  guitars = 'Guitars',
  harp = 'Harp',
  keyboardPageTurner = 'Keyboard - Page Turner',
  keyboards = 'Keyboards',
  malletPercussion = 'Mallet Percussion',
  mandolin = 'Mandolin',
  oboe = 'Oboe',
  percussion = 'Percussion',
  piano = 'Piano',
  piccolo = 'Piccolo',
  reeds = 'Reeds',
  rehearsalAccompanist = 'Rehearsal Accompanist',
  saxophone = 'Saxophone',
  sopranoSaxophone = 'Soprano Saxophone',
  synthesizer = 'Synthesizer',
  tenorSaxophone = 'Tenor Saxophone',
  trombone = 'Trombone  ',
  trumpet = 'Trumpet',
  trumpetFlugelhorn = 'Trumpet/Flugelhorn',
  trumpets = 'Trumpets',
  tuba = 'Tuba',
  ukulele = 'Ukulele',
  viola = 'Viola',
  violin = 'Violin',
  woodwinds = 'Woodwinds',
}

export enum PerformerProductionContribution {
  custom = 'Custom',
}

export enum StudioProductionContribution {
  custom = 'Custom',
  administrativeAssistantToTheDean = 'Administrative Assistant to the Dean',
  artisticDirector = 'Artistic Director',
  assistantBusinessManager = 'Assistant Business Manager',
  assistantToTheArtisticDirector = 'Assistant to the Artistic Director',
  associateChair = 'Associate Chair',
  associateHead = 'Associate Head',
  associateProducer = 'Associate Producer',
  associateProducingDirector = 'Associate Producing Director',
  boardOfDirectors = 'Board of Directors',
  boardOfDirectorsPresident = 'Board of Directors - President',
  boardOfDirectorsSecretary = 'Board of Directors - Secretary',
  boardOfDirectorsTreasurer = 'Board of Directors - Treasurer',
  boardOfDirectorsVicePresident = 'Board of Directors - Vice President',
  boardOfDirectorsYouthPresident = 'Board of Directors - Youth President',
  boardOfDirectorsYouthSecretary = 'Board of Directors - Youth Secretary',
  boardOfDirectorsYouthTreasurer = 'Board of Directors - Youth Treasurer',
  boardOfDirectorsYouthVP = 'Board of Directors - Youth VP',
  businessManager = 'Business Manager',
  businessManagerStudio = 'Business Manager - Studio',
  communicationsCoordinator = 'Communications Coordinator',
  costumeStudioStudent = 'Costume Studio - Student',
  costumeStudioManager = 'Costume Studio Manager',
  costumeStudioSupervisor = 'Costume Studio Supervisor',
  costumeStudioSupervisorStaff = 'Costume Studio Supervisor - Staff',
  dean = 'Dean',
  deanInterim = 'Dean - Interim',
  departmentAssistant = 'Department Assistant',
  departmentChair = 'Department Chair',
  departmentManager = 'Department Manager',
  developmentDirector = 'Development Director',
  directorOfTheatre = 'Director of Theatre',
  educationDirector = 'Education Director',
  educationProgramCoodinator = 'Education Program Coodinator',
  executiveArtisticDirector = 'Executive Artistic Director',
  executiveDirector = 'Executive Director',
  executiveProducer = 'Executive Producer',
  executiveVicePresident = 'Executive Vice President',
  generalManager = 'General Manager',
  homeschoolCoordinator = 'Homeschool Coordinator',
  jobLead = 'Job Lead',
  managingDirector = 'Managing Director',
  marketing = 'Marketing',
  marketingDirector = 'Marketing Director',
  outreachCoordinator = 'Outreach Coordinator',
  producer = 'Producer',
  producingArtisticDirector = 'Producing Artistic Director',
  propertiesStaff = 'Properties Staff',
  propsShopCrew = 'Props Shop Crew',
  registrar = 'Registrar',
  residentCostumeDesigner = 'Resident Costume Designer',
  residentLightingDesigner = 'Resident Lighting Designer',
  residentMusicDirector = 'Resident Music Director',
  residentScenicDesigner = 'Resident Scenic Designer',
  residentStageManager = 'Resident Stage Manager',
  scenicStudioStudent = 'Scenic Studio - Student',
  scenicStudioSupervisor = 'Scenic Studio Supervisor',
  scenicStudioSupervisorStaff = 'Scenic Studio Supervisor - Staff',
  seasonSubscriptionManager = 'Season Subscription Manager',
  seniorAcademicAdvisor = 'Senior Academic Advisor',
  seniorAssociateDean = 'Senior Associate Dean',
  snackCoordinator = 'Snack Coordinator',
  snackProvider = 'Snack Provider',
  stageConstructionConsultant = 'Stage Construction Consultant',
  subscriptionAssistant = 'Subscription Assistant',
  theatreComputerLabStudent = 'Theatre Computer Lab - Student',
  vocalCoach = 'Vocal Coach',
  voiceover = 'Voiceover',
  volunteerStudent = 'Volunteer - Student',
  volunteerCoordinator = 'Volunteer Coordinator',
  wardrobe = 'Wardrobe',
  wardrobeStockSupervisor = 'Wardrobe Stock Supervisor',
  wardrobeSupervisor = 'Wardrobe Supervisor',
}

export enum VenueProductionContribution {
  custom = 'Custom',
  boxOffice = 'Box Office',
  boxOfficeStudent = 'Box Office - Student',
  boxOfficeUsherStaff = 'Box Office & Usher Staff',
  boxOfficeCoordinator = 'Box Office Coordinator',
  boxOfficeCrew = 'Box Office Crew',
  boxOfficeManager = 'Box Office Manager',
  boxOfficeSupervisor = 'Box Office Supervisor',
  concessionsCoordinator = 'Concessions Coordinator',
  concessionsSales = 'Concessions Sales',
  costumeShopCrew = 'Costume Shop Crew',
  costumeShopStaff = 'Costume Shop Staff',
  costumeShopTechnician = 'Costume Shop Technician',
  directorOfStageServices = 'Director of Stage Services',
  facilitiesCoodinator = 'Facilities Coodinator',
  frontOfHouseAssistant = 'Front of House Assistant',
  houseManager = 'House Manager',
  lobbyArtist = 'Lobby Artist',
  paintShopSupervisor = 'Paint Shop Supervisor',
  patronServicesManager = 'Patron Services Manager',
  sceneShopStaff = 'Scene Shop Staff',
  scenicShopCrew = 'Scenic Shop Crew',
  shopSupervisor = 'Shop Supervisor',
  theaterSupervisor = 'Theater Supervisor',
}
