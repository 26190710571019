import React, { useEffect, useRef, useState } from 'react';
import { Alert, Autocomplete, Paper } from '../../../../components';
import { Box, CircularProgress, Divider } from '@mui/material';
import Loader from '../../../../components/Loader';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import DialogHeader from '../../../common/DialogHeader';
import { makeStyles } from '@mui/styles';
import { AddFeaturedStudioValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import {
  queryVenueProductionStudios,
  useCreateVenueFeaturedStudio,
} from '../../../../api/venues';
import { Studio } from '../../../../types/Studio';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
}));

type Props = {
  venueId: string;
  handleClose: (production: any) => void;
};
const AddFeaturedStudio = ({ venueId: venueId, handleClose }: Props) => {
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });
  const [studios, setStudios] = useState([]);
  const [isStudiosFetching, setStudiosFetching] = useState(false);
  const [studiosCount, setStudiosCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const postMutation = useCreateVenueFeaturedStudio();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = AddFeaturedStudioValidation();
  const isError = alertState.active;

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await postMutation.mutateAsync({
        venueId,
        studioId: data.studio.id,
      });
      setLoading(false);
      handleClose(data.studio);
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message:
          'Something went wrong! Please make sure the studio has already featured.',
      });
    }
  };

  async function init() {
    reset({
      studio: null,
    });
    setStudiosFetching(true);
    const studios = await queryVenueProductionStudios({
      venueId,
    });
    const studiosCount = studios.length;
    setStudiosCount(studiosCount);
    setStudiosFetching(false);
    setStudios(studios);
  }
  const debounceUpdateLastName = useRef(() => {
    init();
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      debounceUpdateLastName.current();
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <Box>
      <DialogHeader
        title={'Add Featured Studio'}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            Total No. of Studios ({studiosCount})
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <Box my={3}>
              {isStudiosFetching ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Autocomplete
                  error={!!errors}
                  label={`Select studio (${studios.length})`}
                  placeholder="Select studio"
                  name="studio"
                  control={control}
                  options={((studios as Studio[]) || []).map((studio) => {
                    return {
                      id: studio.id,
                      label: `${studio.name} - ${studio.city}, ${studio.state}`,
                      ...studio,
                    };
                  })}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddFeaturedStudio;
