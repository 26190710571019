import React, { useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../types/User';
import { PaginationTable } from '../../components/Table/PaginationTable';

export default function StudiosView() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Institution',
        accessorKey: 'institution.name',
        cell: (info: any) =>
          info?.row?.original?.institution
            ? info?.row?.original?.institution?.name
            : 'N/A',
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'Street',
        accessorKey: 'street',
      },
      {
        header: 'City',
        accessorKey: 'city',
      },
      {
        header: 'State',
        accessorKey: 'state',
      },
      {
        header: 'Zipcode',
        accessorKey: 'zipcode',
      },
      {
        header: 'Country',
        accessorKey: 'country',
      },
      {
        header: 'Updated',
        accessorKey: 'updatedAt',
        cell: (info: any) => moment.utc(info.getValue()).format('MM/DD/YYYY'),
      },
    ],
    [],
  );

  return (
    <>
      <Box
        sx={{
          maxWidth: '1440px',
          mx: { xs: 2, md: 3, lg: 6, xl: 'auto' },
          my: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Typography flexGrow="1" variant="h1" textAlign="center">
            Spectra Studios
          </Typography>
        </Box>
        <PaginationTable
          columns={columns}
          onEdit={(studios: string) => navigate(`/studios/${studios}/edit`)}
          tableName="Studios"
          route="studios/admin"
          studioIdKey="id"
          requiredEditScopes={[
            Role.spectraOperator,
            Role.studioAdmin,
            Role.studioManager,
          ]}
          searchStudioPlaceholder={null}
          searchPlaceholder="Search Studios by Name..."
        />
      </Box>
    </>
  );
}
