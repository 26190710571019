import axios from 'axios';

let BASE_URL = 'http://localhost:3500';

if (process.env.NODE_ENV === 'development') {
  console.log('development', process.env.REACT_APP_BASE_URL_DEV);

  BASE_URL = `${process.env.REACT_APP_BASE_URL_DEV}`;
}

if (process.env.NODE_ENV === 'production') {
  console.log(
    'production',
    process.env.REACT_APP_BASE_URL_PROD,
    'https://api-staging.spectramediacollective.link',
  );
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
}

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
});

const axiosS3 = axios.create({});

export { axiosS3 };
