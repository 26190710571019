import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Paper, TextField } from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { useStyles } from '../styles';
import { AddInstitutionProps } from '../types';
import { CreateInstitutionValidation } from '../../institutions/CreateInstitutions/InstitutionValidationSchema';
import Select from '../../../components/Select';
import { Institution, InstitutionType } from '../../../types/Institution';
import {
  useDeleteInstitution,
  useGetInstitution,
  usePostInstitution,
} from '../../../api/institutions';
import { useQueryClient } from 'react-query';
import SingleImageUpload, {
  QueueFileInfo,
} from '../../../components/Upload/SingleImageUpload';
import Loader from '../../../components/Loader';

export default function AddInstitution(props: AddInstitutionProps) {
  const { onClose, id } = props;
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { handleSubmit, control, reset } = CreateInstitutionValidation();

  const [singleFilesOnQueue, setSingleFilesOnQueue] = useState<QueueFileInfo[]>(
    [],
  );
  const [singleFilesToBeDelete, setSingleFilesToBeDelete] = useState<string[]>(
    [],
  );

  const { data: institution, isSuccess: isInstitutionLoaded } =
    useGetInstitution(id);

  const postMutation = usePostInstitution();
  const [savingLoader, setSavingLoader] = useState(false);

  useEffect(() => {
    if (id) {
      reset(institution);
    }
  }, [isInstitutionLoaded]);

  const onSubmit = async (data: any) => {
    setSavingLoader(true);
    try {
      const formData = { ...data };
      const institution = await postMutation.mutateAsync(formData);
      setSavingLoader(false);
      await handleClose(institution);
    } catch (e) {
      console.log(e);
      setSavingLoader(false);
    }
  };

  const handleClose = async (institution?: Institution) => {
    await queryClient.invalidateQueries(['allInstitutions']);
    reset();
    onClose(institution);
  };

  return (
    <Box>
      {savingLoader && <Loader isLoading={savingLoader} />}
      <DialogHeader
        title={id ? 'Edit Institution' : 'Create Institution'}
        handleSave={handleSubmit(onSubmit)}
        cancel={() => {
          reset();
          onClose();
        }}
      />
      <Box className={classes.content}>
        <Paper>
          <Box className={classes.subContent}>
            <Typography variant="h1">Institution Details</Typography>
            <Divider style={{ marginBottom: '24px' }} />
            <Box mb={3}>
              <TextField
                label="Institution Name"
                name="name"
                control={control}
              />
            </Box>
            <Box mb={3}>
              <Select
                options={(
                  Object.keys(InstitutionType) as Array<
                    keyof typeof InstitutionType
                  >
                ).map((key) => InstitutionType[key])}
                placeholder="Select Institution Type"
                control={control}
                name="type"
                label="Institution Type"
              />
            </Box>
            {/* <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} /> */}
            {/* <SingleImageUpload
              title="Institution Logo"
              initialUpload={''}
              validator={(fileExt) =>
                ['jpg', 'jpeg', 'png'].includes(fileExt)
              }
              uploadCallback={async (blob, file) => {
                setSingleFilesOnQueue(() => [
                  {
                    blob,
                    mimeType: file.type,
                    name: file.name,
                    type: 'logo',
                    file: file,
                  },
                ]);
              }}
              deleteCallback={() => {
                setSingleFilesToBeDelete((existingFiles) => [
                  ...existingFiles,
                  'logo.png',
                ]);
                setSingleFilesOnQueue((existingFiles) =>
                  existingFiles.filter(
                    (f) => !f.type && f.type !== 'logo',
                  ),
                );
              }}
            /> */}
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <Typography variant="h1">Institution Address</Typography>
            <Divider style={{ marginBottom: '24px' }} />
            <Box mb={3}>
              <TextField label="Street" name="street" control={control} />
            </Box>
            <Box mb={3}>
              <TextField label="City" name="city" control={control} />
            </Box>
            <Box mb={3}>
              <TextField label="State" name="state" control={control} />
            </Box>
            <Box mb={3}>
              <TextField label="Zip Code" name="zipcode" control={control} />
            </Box>
            <Box mb={3}>
              <TextField label="Website" name="website" control={control} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
