import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Alert, Paper, TextField } from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { useStyles } from '../styles';
import { CreateInstitutionValidation } from './InstitutionValidationSchema';
import Select from '../../../components/Select';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertState, QueuedFiles } from '../../../types/Global';
import Loader from '../../../components/Loader';
import {
  alertAutoHideDuration,
  GlobalMessages,
  LogoMaxFileSizeInMB,
  LogoSupportedFormat,
} from '../../../utils/globalConfig';
import {
  deleteMedia,
  uploadMediaMultiple,
  uploadMediaSingle,
  useDeleteInstitution,
  useGetInstitution,
  usePostInstitution,
  usePutInstitution,
} from '../../../api/institutions';
import { InstitutionType } from '../../../types/Institution';
import SingleImageUpload, {
  QueueFileInfo,
} from '../../../components/Upload/SingleImageUpload';
import MultiFileUpload, {
  FileInfo,
} from '../../../components/Upload/MultiFileUpload';
import { UploadMediaType } from '../../../api/utils';

import useErrorHandler from '../../../hooks/useErrorHandler';
const supportedInstitutionFileFormats = [
  'png',
  'pdf',
  'jpeg',
  'jpg',
  'svg',
  'csv',
  'mp4',
  'mp3',
];

export default function CreateInstitution() {
  const { id: institutionId } = useParams() || {};
  const navigate = useNavigate();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { handleSubmit, control, reset } = CreateInstitutionValidation();
  const {
    data: studio,
    isSuccess: isInstitutionLoaded,
    isLoading: isInstitutionLoading,
  } = useGetInstitution(institutionId);

  const updateMutation = usePutInstitution(institutionId);
  const {
    isSuccess: updateInstitutionMetaSuccess,
    isError: updateInstitutionMetaFailed,
    isLoading: updateInstitutionMetaLoading,
  } = updateMutation;

  const postMutation = usePostInstitution();
  const {
    isSuccess: postInstitutionMetaSuccess,
    isError: postInstitutionMetaFailed,
    isLoading: postInstitutionMetaLoading,
  } = postMutation;

  const deleteMutation = useDeleteInstitution(institutionId);
  const {
    isSuccess: deleteInstitutionMetaSuccess,
    isError: deleteInstitutionMetaFailed,
    isLoading: deleteInstitutionMetaLoading,
  } = deleteMutation;

  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });
  const [savingLoader, setSavingLoader] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);
  const { handleError } = useErrorHandler();
  const [institutionLogoMedia, setInstitutionLogoMedia] = useState<{
    institutionId: string;
    mediaId: string;
  }>();
  const [institutionFiles, setInstitutionFiles] = useState<FileInfo[]>([]);

  useEffect(() => {
    if (isInstitutionLoaded) {
      console.log(studio);
      setInstitutionFiles(studio?.institutionFiles || []);
      setInstitutionLogoMedia(studio?.institutionLogoMedia);
      reset({
        ...studio?.dataValues,
      });
    }
  }, [isInstitutionLoaded]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['selectedInstitution']);
    };
  }, []);

  const handleClose = () => {
    queryClient.removeQueries(['selectedInstitution']);
    queryClient.invalidateQueries(['allInstitutions']).then(() => {
      reset();
      navigate('/institutions');
    });
  };

  const [singleFileOnQueue, setSingleFileOnQueue] = useState<QueueFileInfo>();
  const [singleFileToBeDelete, setSingleFileToBeDelete] = useState<{
    institutionId: string;
    mediaId: string;
  }>();

  const [institutionFilesOnQueue, setInstitutionFilesOnQueue] = useState<
    QueuedFiles[]
  >([]);

  const [institutionFilesTobeDeleted, setInstitutionFilesToBeDeleted] =
    useState([]);

  async function updateFiles(institutionId: string) {
    if (!institutionId) return;
    let uploadSingleMediaResponsePromise,
      deleteSingleMediaResponsePromise,
      uploadMultipleFilesResponsePromises,
      deleteMultipleFilesResponsePromises;

    const errors = [];
    if (singleFileOnQueue) {
      uploadSingleMediaResponsePromise = uploadMediaSingle(
        institutionId,
        singleFileOnQueue,
        UploadMediaType.logo,
      ).catch((error) =>
        errors.push(`Error while uploading institution Logo: ${error.message}`),
      );
      setSingleFileOnQueue(null);
    }

    if (singleFileToBeDelete) {
      deleteSingleMediaResponsePromise = deleteMedia(
        institutionId,
        institutionLogoMedia ? institutionLogoMedia?.mediaId : '',
      ).catch((error) =>
        errors.push(`Error for deleting institution Logo: ${error.message}`),
      );
    }

    if (institutionFilesOnQueue.length > 0) {
      uploadMultipleFilesResponsePromises = institutionFilesOnQueue.map(
        (mediaFile) => {
          const uploadResponse = uploadMediaMultiple(
            institutionId,
            mediaFile,
            UploadMediaType.file,
          );
          return uploadResponse;
        },
      );
    }

    if (institutionFilesTobeDeleted.length > 0) {
      deleteMultipleFilesResponsePromises = institutionFilesTobeDeleted.map(
        (mediaFile) => {
          const deleteResponse = deleteMedia(
            institutionId,
            mediaFile.mediaDetails.id,
          );
          return deleteResponse;
        },
      );
    }

    const [
      uploadSingleMediaResponse,
      deleteSingleMediaResponse,
      uploadMultipleFilesResponse,
      deleteMultipleFilesResponse,
    ] = await Promise.all([
      uploadSingleMediaResponsePromise,
      deleteSingleMediaResponsePromise,
      uploadMultipleFilesResponsePromises,
      deleteMultipleFilesResponsePromises,
    ]);

    uploadMultipleFilesResponse?.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading institution files ${index + 1}: ${response.error}`,
        );
      }
    });

    deleteMultipleFilesResponse?.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error deleting institution files ${index + 1}: ${response.error}`,
        );
      }
    });
    setInstitutionFilesOnQueue([]);
    setInstitutionFilesToBeDeleted([]);

    return {
      uploadSingleMediaResponse,
      deleteSingleMediaResponse,
      uploadMultipleFilesResponse,
      deleteMultipleFilesResponse,
      errors,
    };
  }

  const onSubmit = async (data: any) => {
    const formData = { ...data, institutionId };
    setSavingLoader(true);
    setSaveDisable(true);
    try {
      const institution = institutionId
        ? await updateMutation.mutateAsync(formData)
        : await postMutation.mutateAsync(formData);

      const fileOpResults = await updateFiles(institution.id || institutionId);

      queryClient.refetchQueries();
      setSavingLoader(false);
      setSaveDisable(false);

      if (fileOpResults.errors.length > 0) {
        setAlertState({
          active: true,
          message:
            'File upload errors:\n• ' + fileOpResults.errors.join('\n• '),
          error: true,
        });
      } else {
        setAlertState({
          active: true,
          message: institutionId
            ? GlobalMessages.updateInstitutionSuccessMsg
            : GlobalMessages.addInstitutionSuccessMsg,
        });
      }

      if (!institutionId && institution && institution.id) {
        reset();
        navigate(`/institutions/${institution.id}/edit`);
      }
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
      setSavingLoader(false);
      setSaveDisable(false);
    }
  };

  const handleDeleteInstitution = async () => {
    try {
      await deleteMutation.mutateAsync();
      await queryClient.invalidateQueries(['selectedInstitution']);
      setAlertState({
        active: true,
        message: GlobalMessages.deleteInstitutionSuccessMsg,
      });
      handleClose();
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };

  const successAlert =
    updateInstitutionMetaSuccess ||
    postInstitutionMetaSuccess ||
    deleteInstitutionMetaSuccess;

  const errorAlert =
    postInstitutionMetaFailed ||
    updateInstitutionMetaFailed ||
    deleteInstitutionMetaFailed;

  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;

  return (
    <>
      <Alert
        message={alertState.message}
        open={showAlert}
        severity={alertState.error ? 'error' : 'success'}
        autoHideDuration={alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
      <Loader
        isLoading={
          postInstitutionMetaLoading ||
          updateInstitutionMetaLoading ||
          deleteInstitutionMetaLoading ||
          isInstitutionLoading ||
          savingLoader
        }
      />
      <Box className={classes.bodyBackground}>
        <DialogHeader
          title={institutionId ? 'Edit Institution' : 'Create Institution'}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={institutionId && handleDeleteInstitution}
          cancel={() => {
            reset();
            navigate('/institutions');
          }}
          primaryBtnDisable={saveDisable}
        />
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <Box className={classes.accountInfo}>
                <Typography variant="h1">Institution Details</Typography>
                <Typography variant="h4">
                  {' '}
                  Name and Institution Type are required.
                </Typography>
                <Divider sx={{ marginTop: '14px', marginBottom: '24px' }} />
                <Box mb={3}>
                  <TextField label="Name" name="name" control={control} />
                </Box>
                <Box mb={3}>
                  <Select
                    options={(
                      Object.keys(InstitutionType) as Array<
                        keyof typeof InstitutionType
                      >
                    ).map((key) => InstitutionType[key])}
                    placeholder="Institution Type"
                    control={control}
                    name="type"
                    label="Type"
                  />
                </Box>
                <SingleImageUpload
                  title="Institution Logo"
                  initialUpload={(studio && studio.institutionLogoURL) || ''}
                  maxFileSizeInMB={LogoMaxFileSizeInMB}
                  validator={(fileExt) => LogoSupportedFormat.includes(fileExt)}
                  uploadCallback={async (blob, file) => {
                    setSingleFileOnQueue({
                      blob,
                      mimeType: file.type,
                      name: file.name,
                      file: file,
                      type: 'logo',
                    });
                  }}
                  deleteCallback={() => {
                    setSingleFileToBeDelete(institutionLogoMedia);
                  }}
                />
                <Divider style={{ marginBottom: '20px', marginTop: '14px' }} />
                <MultiFileUpload
                  title={'Manage Institution Files'}
                  files={institutionFiles}
                  validator={(fileExt, sizeInMB) =>
                    supportedInstitutionFileFormats.includes(fileExt) &&
                    sizeInMB < 100
                  }
                  uploadCallback={(fileInfo, fileType) => {
                    setInstitutionFiles((existingFiles) => [
                      ...existingFiles,
                      fileInfo,
                    ]);
                    setInstitutionFilesOnQueue((existingFiles) => [
                      ...existingFiles,
                      { fileInfo, fileType },
                    ]);
                  }}
                  deleteCallback={(file) => {
                    setInstitutionFiles([
                      ...institutionFiles.filter(
                        (f) => f.mediaDetails.id !== file.mediaDetails.id,
                      ),
                    ]);
                    setInstitutionFilesToBeDeleted((existingFiles) => [
                      ...existingFiles,
                      file,
                    ]);
                  }}
                />
                <Divider style={{ marginBottom: '20px', marginTop: '14px' }} />
                <Typography variant="h1">Institution Address</Typography>
                <br />
                <Box mb={3}>
                  <TextField label="Street" name="street" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField label="City" name="city" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField label="State" name="state" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Zip Code"
                    name="zipcode"
                    control={control}
                  />
                </Box>
                <Box mb={3}>
                  <TextField label="Country" name="country" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField label="Website" name="website" control={control} />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
