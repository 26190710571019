import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import MarqueeProductions from './MarqueeProductions';
import FeaturedProductions from './FeaturedProductions';
import { MarqueeProductionResponse } from '../../../types/SummaryPage';
import { getHomeSummaryInfo } from '../../../api/summaryPage';
import { Play } from '../../../types/Play';
import FeaturedPlays from './FeaturedPlays';
import { Studio } from '../../../types/Studio';
import FeaturedStudios from './FeaturedStudios';
import { Venue } from '../../../types/Venue';
import FeaturedVenues from './FeaturedVenues';

const HomeSummary = () => {
  const [summaryInfo, setSummaryInfo] = useState<{
    marqueeProduction?: MarqueeProductionResponse | null;
    featuredProductions: MarqueeProductionResponse[];
    featuredPlays: Play[];
    featuredStudios: Studio[];
    featuredVenues: Venue[];
  }>({
    marqueeProduction: null,
    featuredProductions: [],
    featuredPlays: [],
    featuredStudios: [],
    featuredVenues: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const homeInfo = await getHomeSummaryInfo();
      setIsLoading(false);
      setSummaryInfo(homeInfo);
    }

    init();
  }, []);

  const {
    marqueeProduction,
    featuredProductions,
    featuredPlays,
    featuredStudios,
    featuredVenues,
  } = summaryInfo;

  if (isLoading)
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <MarqueeProductions marqueeProduction={marqueeProduction} />
      <FeaturedProductions productions={featuredProductions || []} />
      <FeaturedPlays plays={featuredPlays || []} />
      <FeaturedStudios studios={featuredStudios || []} />
      <FeaturedVenues venues={featuredVenues || []} />
    </Box>
  );
};

export default HomeSummary;
