import * as React from 'react';
import { SxProps, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

interface UploadFileInputProps {
  imageData: (data: string | ArrayBuffer | null, file: any) => void;
  id: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  uploadBtnLabel?: string;
  sx?: SxProps<Theme>;
}

export default function UploadFileInput(props: UploadFileInputProps) {
  const {
    imageData,
    id,
    buttonVariant = 'outlined',
    uploadBtnLabel = 'Select Files',
    sx,
  } = props;

  function encodeImageFileAsURL(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      imageData(reader.result, file);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event: any) => {
    encodeImageFileAsURL(event);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={sx}>
      <label
        htmlFor={id}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box alignContent="center">
          <Button
            variant={buttonVariant}
            component="span"
            sx={{
              color: '#374151',
              border: '1px solid #D1D5DB',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
              borderRadius: '6px',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#D1D5DB',
                borderColor: '#D1D5DB',
              },
              width: '15vw',
            }}
          >
            {uploadBtnLabel}
          </Button>
        </Box>
        <input
          id={id}
          multiple
          type="file"
          onChange={handleChange}
          aria-labelledby={id}
          style={{ display: 'none' }}
        />
      </label>
    </Stack>
  );
}

export const FileInputButton = ({
  handleChange,
  id,
  sx,
  children,
}: {
  id: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx: SxProps<Theme>;
  children: string | React.ReactElement;
}) => {
  return (
    <Box sx={sx}>
      <label
        htmlFor={id}
        style={{
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
        }}
      >
        <div>
          <Button
            variant={'outlined'}
            component="span"
            sx={{
              color: '#374151',
              border: '1px solid #D1D5DB',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
              borderRadius: '6px',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#D1D5DB',
                borderColor: '#D1D5DB',
              },
            }}
          >
            {children}
          </Button>
        </div>
        <input
          multiple
          type="file"
          id={id}
          style={{ opacity: 0 }}
          onChange={handleChange}
          aria-labelledby={id}
        />
      </label>
    </Box>
  );
};
