import React, { useEffect, useState } from 'react';
import { Alert, Autocomplete, Paper } from '../../../../components';
import { Box, CircularProgress, Divider } from '@mui/material';
import Loader from '../../../../components/Loader';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import DialogHeader from '../../../common/DialogHeader';
import { makeStyles } from '@mui/styles';
import { queryPlayProductions, queryPlays } from '../../../../api/plays';
import { AddPlayPickerFormValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import { Play } from '../../../../types/Play';
import { ProductionWithMediaPicker } from './ProductionPicker';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
}));

type Props = {
  title: string;
  currentPlay: Play | null;
  handleClose: (play: Play, isEditMode?: boolean) => void;
  handleSave: (args: {
    playId: string;
    featuredProductionMediaId?: string;
    isEditMode?: boolean;
  }) => Promise<Play>;
  requireProductionMedia?: boolean;
  mediaLimit?: number;
};

type PlayType = {
  id: string;
  licenseName: string;
};
const PlayPicker = ({
  title,
  currentPlay: currentPlay,
  handleClose,
  handleSave,
  requireProductionMedia = false,
  mediaLimit = 1,
}: Props) => {
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });

  const [plays, setPlays] = useState<PlayType[]>([]);
  const [isFetching, setFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [selectedPlay, setSelectedPlay] = useState<PlayType>();
  // Production media picker
  const [isProductionsFetching, setIsProductionsFetching] = useState(false);
  const [productions, setProductions] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState<{
    id: string;
    label: string;
  }>();
  const [selectedMediaToFeature, setSelectedMediaToFeature] = useState([]);

  const isEditMode = Boolean(currentPlay);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = AddPlayPickerFormValidation();
  const isError = alertState.active;

  const onSubmit = async (data) => {
    try {
      if (
        requireProductionMedia &&
        selectedMediaToFeature.length < mediaLimit
      ) {
        return alert(
          `Please select atleast ${mediaLimit} media to feature this play`,
        );
      }
      setLoading(true);
      await handleSave({
        playId: data.play.id,
        isEditMode: isEditMode,
        ...(requireProductionMedia
          ? {
              featuredProductionMediaId: selectedMediaToFeature[0].id,
            }
          : {}),
      });
      setLoading(false);
      handleClose(
        {
          ...data.play,
        },
        isEditMode,
      );
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message:
          'Something went wrong! Please ensure the play is not added already!',
      });
    }
  };

  async function init() {
    setFetching(true);
    const plays: Play[] = await queryPlays();
    setFetching(false);
    setPlays(plays);
  }

  async function fetchPlayProductions(play: PlayType) {
    try {
      setIsProductionsFetching(true);
      const { productions } = await queryPlayProductions({
        playId: play.id,
      });
      setIsProductionsFetching(false);
      const productionList = productions.map((fp) => {
        const { production } = fp;
        const productionYear = new Date(
          production.productionStartDate,
        ).getFullYear();
        const city = (production.venue && production.venue.city) || 'N/A';
        const state = (production.venue && production.venue.state) || 'N/A';
        return {
          id: production.id,
          label: `${production.play.licenseName}  - ${productionYear} - ${city} - ${state}`,
          city,
          state,
          productionYear,
          productionName: production.play.licenseName,
        };
      });
      setProductions(productionList);
    } catch (error) {
      setIsProductionsFetching(false);
      console.error(error);
    }
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!currentPlay) return;
    reset({
      play: {
        id: currentPlay.id,
        label: `${currentPlay.licenseName}`,
        ...currentPlay,
      },
    });
    setSelectedPlay(currentPlay);
  }, [currentPlay]);

  useEffect(() => {
    if (selectedPlay && requireProductionMedia) {
      fetchPlayProductions(selectedPlay);
    }
  }, [selectedPlay]);

  return (
    <Box>
      <DialogHeader
        title={`${isEditMode ? 'Edit' : 'Add'} ${title}`}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            <Divider sx={{ marginTop: 0, marginBottom: '24px' }} />
            <Box my={3}>
              {isFetching ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Autocomplete
                  error={!!errors}
                  label={`Select Play (${plays?.length})`}
                  placeholder="Select Play"
                  name="play"
                  control={control}
                  onValueChange={(option) => {
                    setSelectedPlay(option);
                  }}
                  disabled={isEditMode}
                  options={((plays as Play[]) || []).map((play) => {
                    return {
                      id: play.id,
                      label: `${play.licenseName}`,
                      ...play,
                    };
                  })}
                />
              )}
            </Box>
            {requireProductionMedia && selectedPlay && (
              <>
                <ProductionWithMediaPicker
                  isLoading={isProductionsFetching}
                  productions={productions}
                  onProductionChange={(option) => setSelectedProduction(option)}
                  onProductionMediaChange={(productionMedia) =>
                    setSelectedMediaToFeature(productionMedia)
                  }
                  defaultProduction={selectedProduction}
                  mediaLimit={1}
                  formFields={{
                    control: control,
                    errors: errors,
                  }}
                  isEditMode={false}
                  currentMarqueeProduction={null}
                />
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PlayPicker;
