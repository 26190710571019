import React from 'react';
import { ListItem, Stack } from '@mui/material';
import { Button } from '../../../../components';

export function ProductionRow({
  productionId,
  productionName,
  onEdit,
  onDelete,
  city = 'N/A',
  state = 'N/A',
  productionYear,
}: {
  productionId: string;
  studioName: string;
  productionName: string;
  productionYear: number;
  city: string;
  state: string;
  featuredProductionMediaId: string;
  onEdit: (productionId: string) => void;
  onDelete?: (productionId: string) => void;
}): JSX.Element {
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={productionId}>
          <b>{productionName}</b> {` - ${productionYear} - ${city} - ${state}`}
        </ListItem>
        <Button
          label="Edit"
          variant={'outlined'}
          color={'secondary'}
          onClick={() => {
            onEdit(productionId);
          }}
        />
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(productionId);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}
