import React, { useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import Details from '../../../common/Details';
import { Button, SideDrawer } from '../../../../components';
import AddFeaturedStudioProduction from './AddFeaturedStudioProduction';
import ConfirmDialog from '../../../../components/Modals/ConfirmDialog';
import { useDeleteFeaturedStudioProduction } from '../../../../api/studios';

export type Props = {
  productions: {
    city: string;
    country: string;
    productionId: string;
    institutionId: string;
    productionYear: number;
    state: string;
    studioName: string;
    venue: string;
  }[];
  productionCountByStudioType: { [key: string]: string };
  studioId: string;
  institutionId: string;
  getDisplayFeaturedMedia: (medias: any) => void;
  featuredMedia?: {
    id: string;
    mediaId: string;
    media: {
      id: string;
      renditions: { id: string; path: string }[];
    };
  }[];
  deleteDisplayFeaturedMedia?: (mediaId) => void;
};

const FeaturedStudioProductions = ({
  productions = [],
  studioId: studioId,
  institutionId: institutionId,
  getDisplayFeaturedMedia,
}: Props) => {
  const [openAddDrawer, setOpenDrawer] = useState(false);
  const [featuredProductions, setFeaturedProductions] = useState(productions);

  const handleDelete = (deletedProductionId) => {
    setFeaturedProductions((productions) =>
      productions.filter((p) => p.productionId !== deletedProductionId),
    );
  };
  const MAX_LIMIT = 11;

  if (!studioId) return <></>;

  return (
    <Box>
      <Details
        title="Featured Productions"
        subTitle={`Choose up to ${MAX_LIMIT} productions of this studio to feature on
        the page`}
        onAddButton={() => {
          if (featuredProductions.length < MAX_LIMIT) {
            setOpenDrawer(true);
          } else {
            alert(
              `Max. of ${MAX_LIMIT} productions can be featured. To add a new one, delete an existing featured production.`,
            );
          }
        }}
      >
        <>
          {featuredProductions.map(
            ({
              city,
              state,
              studioName,
              productionYear,
              productionId,
              venue,
              institutionId,
            }) => (
              <FeaturedProductionRow
                key={productionId}
                studioId={studioId}
                productionId={productionId}
                studioName={studioName}
                productionYear={productionYear}
                venue={venue}
                city={city}
                state={state}
                onDelete={handleDelete}
                institutionId={institutionId}
              />
            ),
          )}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawer}
        toggleDrawer={() => setOpenDrawer(false)}
        styles={{
          width: '550px',
        }}
      >
        <AddFeaturedStudioProduction
          addedFeaturedProductions={featuredProductions}
          studioId={studioId}
          institutionId={institutionId}
          handleClose={(production) => {
            setOpenDrawer(false);
            const isFeatured = featuredProductions.find(
              (prod) => prod?.productionId === production.productionId,
            );
            if (!isFeatured) {
              setFeaturedProductions((productions) => [
                ...productions,
                production,
              ]);
            }
          }}
          getDisplayFeaturedMedia={getDisplayFeaturedMedia}
        />
      </SideDrawer>
    </Box>
  );
};

export default FeaturedStudioProductions;
function FeaturedProductionRow({
  productionId,
  studioName,
  productionYear,
  venue,
  city,
  state,
  studioId,
  institutionId,
  onDelete,
}: {
  productionId: string;
  studioName: string;
  productionYear: number;
  venue: string;
  city: string;
  state: string;
  studioId: string;
  institutionId: string;
  onDelete: (productionId: string) => void;
}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deleteMutation = useDeleteFeaturedStudioProduction({
    studioId,
    institutionId,
    productionId,
  });

  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={productionId}>
          <b> {studioName}</b> - {productionYear} - {venue || 'N/A'} - {city},{' '}
          {state}
        </ListItem>
        <Button
          label="Delete"
          variant={'contained'}
          color={'error'}
          loading={isLoading}
          onClick={() => {
            setConfirmDelete(true);
          }}
        />
        <ConfirmDialog
          isOpen={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          dialogOptions={{ isLoading }}
          handleConfirmDelete={async () => {
            setLoading(true);
            await deleteMutation.mutateAsync();
            onDelete(productionId);
            setLoading(false);
            setConfirmDelete(false);
          }}
        />
      </Stack>
    </>
  );
}
