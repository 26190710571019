import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import {
  Alert,
  Paper,
  TextField,
  Select,
  Autocomplete,
  Dialog,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { AddArtistsValidation } from './ArtistsValidationSchema';
import { useStyles } from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetArtist,
  usePostArtist,
  usePutArtist,
  useDeleteArtist,
  inviteArtist,
  useGetInvitedArtists,
  useDeleteUserArtist,
  uploadMediaSingle,
  deleteMedia,
} from '../../../api/artists';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import {
  alertAutoHideDuration,
  GlobalMessages,
  LogoMaxFileSizeInMB,
  LogoSupportedFormat,
} from '../../../utils/globalConfig';
import { AlertState } from '../../../types/Global';
import { UnionAffiliation } from '../../../types/Artist';
import SingleImageUpload, {
  QueueFileInfo,
} from '../../../components/Upload/SingleImageUpload';
import moment from 'moment';
import { useDeleteUserProfile, useGetAllStudios } from '../../../api/studios';
import { Studio } from '../../../types/Studio';
import { useAuth } from '../../../hooks/useAuth';
import useErrorHandler from '../../../hooks/useErrorHandler';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import { Delete } from '@mui/icons-material';
import { UploadMediaType } from '../../../api/utils';

export interface artistInviteType {
  usersInvited: any;
  awsUsers: any;
}

export default function AddArtist() {
  const { id } = useParams() || {};
  const navigate = useNavigate();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { handleSubmit, control, reset } = AddArtistsValidation();
  const {
    data: artist,
    isSuccess: isArtistLoaded,
    isLoading: isArtistLoading,
  } = useGetArtist(id, (artist) => {
    updateForm(artist);
  });
  const {
    data: studios,
    isSuccess: areStudiosLoaded,
    isLoading: studiosLoading,
  } = useGetAllStudios();
  const updateMutation = usePutArtist(id);
  const postMutation = usePostArtist();
  const inviteMutation = inviteArtist();
  const deleteMutation = useDeleteArtist(id);

  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });

  const [checked, setChecked] = React.useState(false);

  const [savingLoader, setSavingLoader] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [fileToBeDeleted, setFileToBeDeleted] = useState<{
    artistId: string;
    mediaId: string;
  }>();

  const [artistInvites, setArtistInvites] = useState<artistInviteType>();
  const [artistInvitesLoaded, setArtistInvitesLoaded] = useState(false);
  const [invitedArtists, setInvitedArtists] = useState([]);
  const [artistAlreadyConfirmed, setArtistAlreadyConfirmed] = useState(false);
  const [invitedArtistFeaturesLoading, setInvitedArtistFeaturesLoading] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({});
  const [deleteAlertState, setDeleteAlertState] = useState<AlertState>({
    active: false,
    message: '',
  });
  const [deleteUserResponseLoading, setDeleteUserResponseLoading] =
    useState(false);
  const [deleteUserArtistResponseLoading, setDeleteUserArtistResponseLoading] =
    useState(false);

  const [fileOnQueue, setFileOnQueue] = useState<QueueFileInfo>();
  const [artistProfilePicture, setArtistProfilePicture] = useState<{
    artistId: string;
    mediaId: string;
  }>();

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['selectedArtist']);
    };
  }, []);

  useEffect(() => {
    updateForm(artist);
  }, [isArtistLoaded, areStudiosLoaded]);

  const deleteUser = (id, studioId) => {
    useDeleteUserProfile({
      userId: id,
      studioId: studioId,
    }).then(() => setDeleteUserResponseLoading(false));
  };

  useEffect(() => {
    if (artistInvitesLoaded) {
      setInvitedArtistFeaturesLoading(true);
      const confirmedUser = artistInvites?.usersInvited.find(
        (user) => user.status == 'Active',
      );
      if (confirmedUser !== undefined) {
        setArtistAlreadyConfirmed(true);
      } else {
        setArtistAlreadyConfirmed(false);
      }
      let finals = artistInvites?.usersInvited.map((user) => {
        const foundUser = artistInvites?.awsUsers.Users.find(
          (cognitoUser) => cognitoUser.Username == user.userId,
        );
        if (foundUser == undefined) {
          setDeleteUserResponseLoading(true);
          deleteUser(user.userId, user.studioId);
        }
        if (foundUser && artistAlreadyConfirmed && user.status === 'Invited') {
          setDeleteUserArtistResponseLoading(true);
          useDeleteUserArtist({ userId: user.userId }).then(() =>
            setDeleteUserArtistResponseLoading(false),
          );
        }
        const tempAttr = {};
        foundUser?.Attributes.forEach((attr) => {
          tempAttr[`${attr.Name}`] = attr.Value;
        });
        tempAttr['userId'] = user.userId;
        tempAttr['userAccountStatus'] = user.status;
        tempAttr['permissionLevel'] = user.permissionLevel;
        tempAttr['status'] = user.status;
        return tempAttr;
      });
      finals = finals.filter((value) => Object.keys(value).length !== 0);
      setInvitedArtists(finals);
      setInvitedArtistFeaturesLoading(false);
    }
  }, [artistInvites, artistAlreadyConfirmed]);

  useEffect(() => {
    invitedArtists.forEach((invitee) => {
      openDeleteDialog[invitee?.userId] = false;
      setOpenDeleteDialog({ ...openDeleteDialog });
    });
  }, [invitedArtists]);

  const onInviteSubmit = async (data: any) => {
    setSavingLoader(true);
    setSaveDisable(true);
    try {
      const formData: any = {
        email: data.email.toLowerCase(),
        artistId: id,
        studioId: data.studioId,
        name: data.firstName + ' ' + data.lastName,
        role: 'spectra:user',
      };
      await inviteMutation.mutateAsync(formData).then((state) => {
        console.log(state);
        window.location.reload();
        setAlertState({
          active: true,
          message: 'Artist Invitation Sent',
        });
        setSaveDisable(false);
        setSavingLoader(false);
      });
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
      setSavingLoader(false);
    }
  };

  const { user } = useAuth();

  function updateForm(artist) {
    if (artist) {
      const artistStudio = studios?.find(({ id }) => id == artist.studioId);
      setChecked(artist.privacyEnabled);
      const { unionAffiliation = [] } = artist;
      const unionAffiliationArr = Array.isArray(unionAffiliation)
        ? unionAffiliation
        : typeof unionAffiliation === 'string'
        ? unionAffiliation.split(', ')
        : [];
      let selectedSudio = null;
      if (isArtistLoaded && areStudiosLoaded && artistStudio != undefined) {
        selectedSudio = {
          id: artist.studioId,
          label: `${artistStudio.name} - ${artist.studioId}`,
        };
      }

      setArtistProfilePicture(artist?.profilePictureMedia);

      reset({
        ...artist,
        dob: artist.dob ? moment(artist.dob).format('yyyy-MM-DD') : null,
        selectedStudio: selectedSudio,
        unionAffiliation: unionAffiliationArr.filter(
          // to filter out affiliations matching current affiliation list
          (ua) => ua in UnionAffiliation,
        ),
      });
    }
    useGetInvitedArtists(artist?.id).then((res) => {
      setArtistInvites(res);
      setArtistInvitesLoaded(true);
    });
  }

  async function updateFiles(artistId: string) {
    if (!artistId)
      return {
        uploadResponse: null,
        deleteResponse: null,
        errors: ['No Artist Id is provided'],
      };
    let uploadResponse, deleteResponse;
    const errors = [];
    if (fileOnQueue) {
      uploadResponse = await uploadMediaSingle(
        artistId,
        fileOnQueue,
        UploadMediaType.profilePicture,
      ).catch((error) =>
        errors.push(`Error uploading artist profile picture: ${error.message}`),
      );
      setFileOnQueue(null);
    }

    if (fileToBeDeleted) {
      deleteResponse = await deleteMedia(
        artistId,
        artistProfilePicture ? artistProfilePicture?.mediaId : '',
      ).catch((error) =>
        errors.push(`Error deleting artist profile picture: ${error.message}`),
      );
    }
    return { uploadResponse, deleteResponse, errors };
  }

  function redirectToEdit(artistId: string) {
    reset();
    navigate(`/artists/${artistId}/edit`);
  }

  const onSubmit = async (data: any) => {
    setSavingLoader(true);
    setSaveDisable(true);
    try {
      const formData = {
        ...data,
        artistId: id,
        privacyEnabled: checked,
        studioId: data.studioId,
        unionAffiliation: (data.unionAffiliation || []).join(', '),
      };
      if (user.studioId == undefined && user.isOperator) {
        formData.studioId = data.selectedStudio ? data.selectedStudio.id : null;
      }
      const artist = id
        ? await updateMutation.mutateAsync(formData)
        : await postMutation.mutateAsync(formData);

      const fileOpResults = await updateFiles(id || artist.id);

      if (fileOpResults.errors.length > 0) {
        setAlertState({
          active: true,
          message:
            'File upload errors:\n• ' + fileOpResults.errors.join('\n• '),
          error: true,
        });
      } else {
        setAlertState({
          active: true,
          message: GlobalMessages.updateArtistSuccessMsg,
        });
        setSaveDisable(false);
        setSavingLoader(false);

        if (!id && artist && artist.id) {
          redirectToEdit(artist.id);
          return;
        }

        await queryClient.invalidateQueries(['selectedArtist']);
        reset();
      }
      setTimeout(() => {
        setSaveDisable(false);
        setSavingLoader(false);
        setAlertState({ active: false });
        return;
      }, 2000);
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
      setSaveDisable(false);
      setSavingLoader(false);
      setAlertState({ active: false });
      return;
    }
  };

  const handleDeleteArtist = async () => {
    try {
      await deleteMutation.mutateAsync();
      await queryClient.invalidateQueries(['selectedArtist']).then(() =>
        setAlertState({
          active: true,
          message: GlobalMessages.deleteArtistsMsg,
        }),
      );
      reset();
      setTimeout(() => {
        navigate('/artists');
      }, 2000);
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };

  const handleDelete = (invite) => {
    console.log(invite);
    openDeleteDialog[invite?.userId] = true;
    setOpenDeleteDialog({ ...openDeleteDialog });
  };

  const handleClose = (id) => {
    openDeleteDialog[id] = false;
    setOpenDeleteDialog({ ...openDeleteDialog });
  };

  const handlePermanentDelete = async (data) => {
    const deleteResp = await useDeleteUserArtist({
      userId: data.userId,
    }).then((response) => {
      console.log(response);
      return response;
    });
    console.log(deleteResp);
    if (deleteResp) {
      handleClose(data.userId);
      window.location.reload();
      setDeleteAlertState({
        active: true,
        message: GlobalMessages.successDeleteUser,
      });
    } else {
      setDeleteAlertState({
        active: true,
        message: GlobalMessages.successDeleteUser,
      });
    }
  };

  const {
    isError: postError,
    isLoading: postLoading,
    isSuccess: postSuccess,
  } = postMutation;
  const {
    isError: updateError,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
  } = updateMutation;
  const {
    isError: deleteArtistsError,
    isSuccess: deleteArtistsSuccess,
    isLoading: deleteArtistsLoading,
  } = deleteMutation;

  const {
    isError: inviteArtistError,
    isSuccess: inviteArtistSuccess,
    isLoading: inviteArtistLoading,
  } = inviteMutation;

  const successAlert =
    postSuccess || updateSuccess || deleteArtistsSuccess || inviteArtistSuccess;
  const errorAlert =
    postError || updateError || deleteArtistsError || inviteArtistError;
  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;

  return (
    <>
      <Alert
        message={alertState.message}
        open={showAlert}
        severity={alertState.error ? 'error' : 'success'}
        autoHideDuration={alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
      <Loader
        isLoading={
          postLoading ||
          updateLoading ||
          deleteArtistsLoading ||
          savingLoader ||
          isArtistLoading ||
          deleteUserResponseLoading ||
          deleteUserArtistResponseLoading
        }
      />
      <Box className={classes.bodyBackground}>
        <DialogHeader
          title={id ? 'Edit Artist' : 'Create Artist'}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={id && handleDeleteArtist}
          cancel={() => {
            reset();
            navigate('/artists');
          }}
          primaryBtnDisable={saveDisable}
        />
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <Box className={classes.subContent}>
                {artist ? (
                  <Typography variant="h1" sx={{ marginBottom: '24px' }}>
                    {artist.firstName} {artist.lastName}&apos;s Profile Settings
                  </Typography>
                ) : (
                  <Typography variant="h1" sx={{ marginBottom: '24px' }}>
                    Artist&apos;s Profile Settings
                  </Typography>
                )}
                <FormGroup>
                  <FormControlLabel
                    control={<Switch name="privacyEnabled" checked={checked} />}
                    onChange={handleChange}
                    label={
                      isArtistLoaded && checked
                        ? 'Privacy Enabled'
                        : 'Privacy Disabled (Public)'
                    }
                  />
                </FormGroup>
                <br />
                <SingleImageUpload
                  title={'Artist Profile Picture'}
                  maxFileSizeInMB={LogoMaxFileSizeInMB}
                  validator={(fileExt, fileSizeInMB) => {
                    return (
                      LogoSupportedFormat.includes(fileExt) &&
                      fileSizeInMB < LogoMaxFileSizeInMB
                    );
                  }}
                  initialUpload={artist ? artist?.headshotURL : ''}
                  uploadCallback={async (blob, file) => {
                    setFileOnQueue({
                      blob,
                      mimeType: file.type,
                      name: file.name,
                      file: file,
                      type: 'profile-pic',
                    });
                  }}
                  deleteCallback={() => {
                    setFileToBeDeleted(artistProfilePicture);
                  }}
                />
                <Divider sx={{ marginBottom: '24px' }} />
                {artistAlreadyConfirmed || (
                  <Box>
                    <Typography variant="h1" sx={{ marginBottom: '8px' }}>
                      Invite Artist to Spectra
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                      Enter an valid email and to invite this Artist to create a
                      Spectra account where they can view play/production
                      contributions.
                    </Typography>
                    <TextField
                      name="email"
                      label="Artist Email"
                      control={control}
                    />
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      label="Send Invitation"
                      onClick={handleSubmit(onInviteSubmit)}
                    />
                    <br />
                    <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
                  </Box>
                )}
                {artistInvitesLoaded && invitedArtists.length > 0 && (
                  <Box>
                    <Typography variant="h1">
                      Invite Status for Artist Account
                    </Typography>
                    <br />
                    <br />
                    {invitedArtists.map((invitedArtist) => {
                      return (
                        <Box
                          key={invitedArtist.userId}
                          sx={{
                            display: 'flex',
                            paddingTop: '0px',
                            marginBottom: '2vh',
                          }}
                        >
                          {invitedArtist.email ? (
                            <Typography
                              variant="h4"
                              sx={{
                                paddingRight: '10%',
                                paddingTop: '0px',
                                width: '50%',
                              }}
                            >
                              <Typography variant="h3">
                                {invitedArtist.name}
                              </Typography>
                              {invitedArtist.email}
                            </Typography>
                          ) : (
                            <Typography
                              variant="h4"
                              sx={{
                                paddingRight: '10%',
                                paddingTop: '0px',
                                width: '50%',
                              }}
                            >
                              {invitedArtist.userId}
                            </Typography>
                          )}
                          <br />
                          {invitedArtist?.userAccountStatus == 'Active' ? (
                            <Box
                              alignContent="right"
                              alignSelf="right"
                              sx={{
                                paddingTop: '12px',
                                textTransform: 'capitalize',
                                width: '50%',
                              }}
                            >
                              <Typography variant="h3">
                                <b>Artist Confirmed</b>
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              alignContent="right"
                              alignSelf="right"
                              sx={{
                                paddingTop: '12px',
                                textTransform: 'capitalize',
                                width: '50%',
                              }}
                            >
                              <Typography variant="h3">
                                <b>
                                  Invited as{' '}
                                  {invitedArtist.permissionLevel.split(':')[1]}
                                </b>
                              </Typography>
                            </Box>
                          )}
                          <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={() => handleDelete(invitedArtist)}
                            sx={{}}
                          >
                            <Delete />
                          </IconButton>
                          <Dialog
                            isOpen={openDeleteDialog[invitedArtist?.userId]}
                            handleClose={() =>
                              handleClose(invitedArtist?.userId)
                            }
                            primaryBtnLabel={'Delete'}
                            secondaryBtnLabel={'Cancel'}
                            handlePrimaryBtn={() =>
                              handlePermanentDelete(invitedArtist)
                            }
                            primaryVariantColor={'error'}
                            secondaryVariantColor={'secondary'}
                            title={'Delete user'}
                            primaryVariant={'contained'}
                            secondaryVariant={'contained'}
                            content={`Click Delete if you wish to remove ${invitedArtist.name} with email ${invitedArtist.email}`}
                          ></Dialog>
                          <Alert
                            message={deleteAlertState.message}
                            open={deleteAlertState.active}
                            autoHideDuration={alertAutoHideDuration}
                            onClose={() => setAlertState({ active: false })}
                          />
                        </Box>
                      );
                    })}
                    <Divider sx={{ marginBottom: '24px' }} />
                  </Box>
                )}
                {(user.isOperator ?? false) && (
                  <Box>
                    <Typography variant="h1" sx={{ marginBottom: '24px' }}>
                      Assign Studio to Artist
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        label={'Studio'}
                        placeholder="Selected Studio"
                        name="selectedStudio"
                        control={control}
                        options={((studios as Studio[]) || []).map(
                          (studio: Studio) => {
                            return {
                              id: studio.id,
                              label: `${studio.name} - ${studio.city} - ${studio.state} - ${studio.id}`,
                            };
                          },
                        )}
                      />
                    </Box>
                    <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
                  </Box>
                )}
                <Box>
                  <Typography variant="h1" sx={{ marginBottom: '24px' }}>
                    Artist Details
                  </Typography>
                  <CopyToClipboardButton
                    id={isArtistLoaded ? (artist == null ? '' : artist.id) : ''}
                  />
                </Box>
                <Box>
                  <TextField
                    name="firstName"
                    label="First Name"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField
                    name="dob"
                    label="Date of Birth"
                    type="date"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField name="bio" label="Bio" control={control} />
                </Box>
                <Box className={classes.mt}>
                  <TextField
                    name="hometown"
                    label="Home Town"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField
                    name="homeState"
                    label="Home State"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField
                    name="homeCountry"
                    label="Home Country"
                    control={control}
                  />
                </Box>
                {id && (
                  <Box className={classes.mt}>
                    <Select
                      label="UnionAffiliation"
                      placeholder="UnionAffiliation"
                      control={control}
                      name="unionAffiliation"
                      multiple
                      options={(
                        Object.keys(UnionAffiliation) as Array<
                          keyof typeof UnionAffiliation
                        >
                      )
                        .map((key) => UnionAffiliation[key])
                        .sort()}
                    />
                  </Box>
                )}
                <Box className={classes.mt}>
                  <TextField
                    name="professionalName"
                    label="Professional Name"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField name="awards" label="Award" control={control} />
                </Box>
                <Box className={classes.mt}>
                  <TextField
                    name="resourceLocation"
                    label="Resource Location"
                    control={control}
                  />
                </Box>
                <Box className={classes.mt}>
                  <TextField name="website" label="Website" control={control} />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
