import React, { useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PaginationTable } from '../../components/Table/PaginationTable';
import { Role } from '../../types/User';

export default function ArtistsView() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: 'First Name',
        accessorKey: 'firstName',
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
      },
      {
        header: 'Studio',
        accessorKey: 'studio.name',
        cell: (info: any) =>
          info?.row?.original?.studio ? info.row.original.studio.name : 'N/A',
      },
      {
        header: 'Updated At',
        accessorKey: 'updatedAt',
        cell: (info: any) => moment.utc(info.getValue()).format('MM/DD/YYYY'),
      },
    ],
    [],
  );

  return (
    <>
      <Box
        sx={{
          maxWidth: '1440px',
          mx: { xs: 2, md: 3, lg: 6, xl: 'auto' },
          my: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Typography flexGrow="1" variant="h1" textAlign="center">
            Studio Artists
          </Typography>
        </Box>
        <PaginationTable
          columns={columns}
          onEdit={(artistId: string) => navigate(`/artists/${artistId}/edit`)}
          tableName="Artists"
          route="artists"
          requiredEditScopes={[
            Role.spectraOperator,
            Role.studioAdmin,
            Role.studioManager,
          ]}
          studioIdKey={'studioId'}
          searchStudioPlaceholder={null}
          searchPlaceholder="Search Artists by Name..."
          fetchingMsg="Artist records loading, Please wait..."
          loadingMsg="Artist records loading, Please wait..."
        />
      </Box>
    </>
  );
}
