import axios from '../../config/http-common';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Role, User } from '../../types/User';
import {
  AuthenticationResponse,
  SetupAccountBody,
  CookieKeys,
  GrantType,
  SpectraIdTokenPayload,
  UserProfileResponse,
} from './auth.types';
import {
  useLoginRequest,
  useRefreshRequest,
  useSetupAccount,
  userProfileRequest,
} from '../../api/auth';
import jwtDecode from 'jwt-decode';

const cookies = new Cookies();

export interface IAuthContext {
  login: (username: string, password: string) => void;
  getUserProfile: () => void;
  setupAccount: (body: SetupAccountBody) => void;
  logout: () => void;
  isAuthenticated?: boolean;
  errorMessage?: string;
  user?: User;
}

export const AuthContext = React.createContext<IAuthContext>(null);

export default function AuthProvider(props: any) {
  const navigate = useNavigate();
  const accessTokenRef = useRef<string>();
  const expiresInRef = useRef<number>();
  const refreshTokenRef = useRef<string>();
  const newPasswordSessionRef = useRef<string>();
  const userRef = useRef<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const [loading, setIsLoading] = useState(true);

  const loginQuery = useMutation(useLoginRequest, {
    onSuccess: (loginData: AuthenticationResponse) => {
      console.log(loginData);
      if (!loginData) {
        return setErrorMessage('Please enter valid credentials');
      }
      if (loginData.challengeName === GrantType.ChallengeNewPassword) {
        newPasswordSessionRef.current = loginData.session;
        navigate('/login/setup');
      } else {
        setRefsAndCookies(loginData);
      }
    },
    onError: (error: any) => {
      accessTokenRef.current = null;
      cookies.remove(CookieKeys.accessToken);
      cookies.remove(CookieKeys.refreshToken);
      cookies.remove(CookieKeys.email);
      cookies.remove(CookieKeys.studioID);
      cookies.remove(CookieKeys.studioName);

      setErrorMessage(
        error.response.data?.message ?? 'Oops something went wrong!',
      );
    },
  });

  const getProfileQuery = useMutation(userProfileRequest, {
    onSuccess: (data: UserProfileResponse) => {
      if (!data) return;
      cookies.set(CookieKeys.studioName, data.studioName, { path: '/' });
      console.log(data);
      setIsLoading(false);
    },
    onError: (error: any) => {
      console.log(error);
      setIsLoading(false);
    },
  });

  function onRefreshTokenError(error: any) {
    console.error(error);
    setAxiosConfig();
    setIsLoading(false);
    navigate('/login');
  }

  function onRefreshTokenSuccess(data: AuthenticationResponse) {
    setRefsAndCookies(data);
    setAxiosConfig();
    setIsLoading(false);
  }

  const refreshQuery = useMutation(useRefreshRequest, {
    onSuccess: onRefreshTokenSuccess,
    onError: onRefreshTokenError,
  });

  const setupAccountQuery = useMutation(useSetupAccount, {
    onSuccess: (data: AuthenticationResponse) => {
      setRefsAndCookies(data);
    },
    onError: (error: any) => {
      setErrorMessage(
        error.response.data?.message ?? 'Oops something went wrong!',
      );
    },
  });

  const setRefsAndCookies = (data: AuthenticationResponse) => {
    if (data.idToken) {
      const idTokenData: SpectraIdTokenPayload =
        jwtDecode<SpectraIdTokenPayload>(data.idToken);

      userRef.current = {
        email: idTokenData.email as string,
        name: idTokenData.name as string,
        userId: idTokenData.sub as string,
        studioId: idTokenData['custom:studioId'] as string,
        artistId: idTokenData['custom:artistId'] as string,
      };

      userRef.current.primaryRole = idTokenData['cognito:groups'][0] as Role;
      userRef.current.isOperator =
        userRef.current.primaryRole === Role.spectraOperator;
      userRef.current.studioName = cookies.get(CookieKeys.studioName);
    }
    accessTokenRef.current = data.accessToken;
    expiresInRef.current = data.expiresIn;
    if (data.refreshToken) {
      refreshTokenRef.current = data.refreshToken;
      cookies.set(CookieKeys.refreshToken, data.refreshToken, { path: '/' });
      cookies.set(CookieKeys.userID, userRef.current.userId, { path: '/' });
      cookies.set(CookieKeys.email, userRef.current.email, { path: '/' });
      cookies.set(CookieKeys.studioID, userRef.current.studioId, { path: '/' });
    }

    setIsAuthenticated(true);
    cookies.set(CookieKeys.accessToken, data.accessToken, { path: '/' });
  };

  const login = async (email: string, password: string) => {
    return loginQuery.mutateAsync({ email, password });
  };

  const getUserProfile = async () => {
    return getProfileQuery.mutateAsync();
  };

  const setupAccount = (body: SetupAccountBody) => {
    return setupAccountQuery.mutateAsync({
      session: newPasswordSessionRef.current,
      ...body,
    });
  };

  const logout = () => {
    cookies.remove(CookieKeys.accessToken, { path: '/' });
    cookies.remove(CookieKeys.refreshToken, { path: '/' });
    cookies.remove(CookieKeys.userID, { path: '/' });
    cookies.remove(CookieKeys.studioID, { path: '/' });
    cookies.remove(CookieKeys.studioName, { path: '/' });
    cookies.remove(CookieKeys.email, { path: '/' });

    userRef.current = null;
    accessTokenRef.current = null;
    refreshTokenRef.current = null;
    setIsAuthenticated(false);
    navigate('/login');
  };

  const setAxiosConfig = () => {
    axios.interceptors.request.use(
      (config: AxiosRequestConfig): AxiosRequestConfig => {
        config.headers.authorization = `Bearer ${accessTokenRef.current}`;
        // this is important to include the cookies when we are sending the requests to the backend.
        config.withCredentials = true;
        return config;
      },
    );
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.error &&
          String(error.response.data.error).includes('Expired token') &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          const cookies = new Cookies();
          return axios
            .post('/auth/oauth/token', {
              grantType: GrantType.RefreshToken,
              authParams: {
                refreshToken: cookies.get(CookieKeys.refreshToken),
                userId: cookies.get(CookieKeys.userID),
              },
            })
            .then(({ data }) => {
              onRefreshTokenSuccess(data);
              originalRequest.headers[
                'authorization'
              ] = `Bearer ${data.accessToken}`;
              return axios(originalRequest);
            })
            .catch((error) => {
              onRefreshTokenError(error);
            });
        }
        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    console.log('access: ', cookies.get(CookieKeys.accessToken));
    console.log('refresh: ', cookies.get(CookieKeys.refreshToken));
    if (cookies.get(CookieKeys.accessToken)) {
      refreshQuery.mutate();
      accessTokenRef.current = cookies.get(CookieKeys.accessToken);
      expiresInRef.current = cookies.get(CookieKeys.accessToken)?.expires;
      refreshTokenRef.current = cookies.get(CookieKeys.refreshToken);
    } else {
      setAxiosConfig();
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setAxiosConfig();
  }, [accessTokenRef]);

  if (loading) {
    return null;
  }

  console.log(userRef.current);
  console.log(isAuthenticated);

  return (
    <AuthContext.Provider
      value={{
        login,
        getUserProfile,
        setupAccount,
        logout,
        isAuthenticated,
        errorMessage,
        user: userRef.current,
        ...props,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
