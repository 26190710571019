import { User } from '../../types/User';
import { CognitoIdTokenPayload } from 'aws-jwt-verify/jwt-model';
import { Email } from '@mui/icons-material';

export enum CookieKeys {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  userID = 'userID',
  studioID = 'studioID',
  email = 'email',
  studioName = 'studioName',
}

export enum GrantType {
  UserPassword = 'USER_PASSWORD_AUTH',
  RefreshToken = 'REFRESH_TOKEN_AUTH',
  ChallengeNewPassword = 'NEW_PASSWORD_REQUIRED',
}

export interface SpectraIdTokenPayload extends CognitoIdTokenPayload {
  name: string;
  email: string;
  ['custom:studioId']: string;
}

export interface LoginBody {
  email: string;
  password: string;
}

export interface UserProfileResponse {
  name: string;
  email: string;
  studioId: string;
  studioName: string;
}

export interface SetupAccountBody {
  name: string;
  email: string;
  newPassword: string;
}

export interface AuthenticationResponse {
  challengeName?: string;
  session?: string;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
  user: User;
}
