import { string } from 'yup/lib/locale';
import { Institution } from './Institution';

export type Studio = {
  id: string;
  studioId: string;
  institutionId?: string;
  type: StudioType;
  subtype: StudioSubtype;
  name: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  website?: string;
  institution?: Institution;
  institutionsLogoURL?: string;
  logoURL?: string;
  productionFiles?: StudioFileType[];
  carouselPhotos?: StudioFileType[];
  institutionFiles?: StudioFileType[];
};

export type StudioFileType = {
  fileURL: string;
  fileName: string;
  fileSize: string;
};

export type StudioInvite = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  permissions: string;
};

export enum StudioType {
  educational = 'Educational',
  community = 'Community',
  professional = 'Professional',
}

export enum StudioSubtype {
  university = 'University',
  communityCollege = 'Community College',
  universityHBCU = 'University-HBCU',
  highSchool = 'High School',
  middleSchool = 'Middle School',
  elementarySchool = 'Elementary School',
  allAges = 'All Ages',
  youth = 'Youth',
  blackCommunity = 'Black Community',
  hispanicCommunity = 'Hispanic Community',
  dinner = 'Dinner',
  camp = 'Camp',
}
