import React, { useEffect, useState } from 'react';
import { Alert, Autocomplete, Paper } from '../../../../components';
import { Box, CircularProgress, Divider } from '@mui/material';
import Loader from '../../../../components/Loader';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import DialogHeader from '../../../common/DialogHeader';
import { makeStyles } from '@mui/styles';
import { queryPlayProductions, queryStudios } from '../../../../api/plays';
import { AddStudioPickerFormValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import { Studio } from '../../../../types/Studio';
import { ProductionWithMediaPicker } from './ProductionPicker';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
}));

type Props = {
  title: string;
  currentStudio: Studio | null;
  handleClose: (studio: Studio, isEditMode?: boolean) => void;
  handleSave: (args: {
    studioId: string;
    featuredProductionMediaId?: string;
    isEditMode?: boolean;
  }) => Promise<Studio>;
  requireProductionMedia?: boolean;
  mediaLimit?: number;
};
const StudioPicker = ({
  title,
  currentStudio,
  handleClose,
  handleSave,
  requireProductionMedia = false,
  mediaLimit = 1,
}: Props) => {
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });
  const [studios, setStudios] = useState<Studio[]>([]);
  const [isFetching, setFetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [selectedStudio, setSelectedStudio] = useState<Studio>();
  // Production media picker
  const [isProductionsFetching, setIsProductionsFetching] = useState(false);
  const [productions, setProductions] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState<{
    id: string;
    label: string;
  }>();
  const [selectedMediaToFeature, setSelectedMediaToFeature] = useState([]);

  const isEditMode = Boolean(currentStudio);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = AddStudioPickerFormValidation();
  const isError = alertState.active;

  const onSubmit = async (data) => {
    try {
      if (
        requireProductionMedia &&
        selectedMediaToFeature.length < mediaLimit
      ) {
        return alert(
          `Please select atleast ${mediaLimit} media to feature this studio`,
        );
      }
      setLoading(true);
      await handleSave({
        studioId: data.studio.id,
        isEditMode: isEditMode,
        ...(requireProductionMedia
          ? {
              featuredProductionMediaId: selectedMediaToFeature[0].id,
            }
          : {}),
      });
      setLoading(false);
      handleClose(
        {
          ...data.studio,
        },
        isEditMode,
      );
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message:
          'Something went wrong! Please ensure the studio is not added already!',
      });
    }
  };

  async function init() {
    setFetching(true);
    const studios: Studio[] = await queryStudios();
    setFetching(false);
    setStudios(studios);
  }

  async function fetchPlayProductions(studio: Studio) {
    try {
      setIsProductionsFetching(true);
      const { productions } = await queryPlayProductions({
        studioId: studio.id,
      });
      setIsProductionsFetching(false);
      const productionList = productions.map((fp) => {
        const { production } = fp;
        const productionYear = new Date(
          production.productionStartDate,
        ).getFullYear();
        const city = (production.venue && production.venue.city) || 'N/A';
        const state = (production.venue && production.venue.state) || 'N/A';
        return {
          id: production.id,
          label: `${production.play.licenseName}  - ${productionYear} - ${city} - ${state}`,
          city,
          state,
          productionYear,
          productionName: production.play.licenseName,
        };
      });
      setProductions(productionList);
    } catch (error) {
      setIsProductionsFetching(false);
      console.error(error);
    }
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!currentStudio) return;
    reset({
      studio: {
        id: currentStudio.id,
        label: `${currentStudio.name} - ${currentStudio.city || 'N/A'}, ${
          currentStudio.state || 'N/A'
        }`,
        ...currentStudio,
      },
    });
    setSelectedStudio(currentStudio);
  }, [currentStudio]);

  useEffect(() => {
    if (selectedStudio && requireProductionMedia) {
      fetchPlayProductions(selectedStudio);
    }
  }, [selectedStudio]);

  return (
    <Box>
      <DialogHeader
        title={`${isEditMode ? 'Edit' : 'Add'} ${title}`}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            <Divider sx={{ marginTop: 0, marginBottom: '24px' }} />

            <Box my={3}>
              {isFetching ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Autocomplete
                  error={!!errors}
                  label={`Select Studio (${studios?.length})`}
                  placeholder="Select Studio"
                  name="studio"
                  control={control}
                  onValueChange={(option) => {
                    setSelectedStudio(option);
                  }}
                  disabled={isEditMode}
                  options={studios.map((studio) => {
                    return {
                      id: studio.id,
                      label: `${studio.name} - ${studio.city || 'N/A'}, ${
                        studio.state || 'N/A'
                      }`,
                      ...studio,
                    };
                  })}
                />
              )}
            </Box>
            {requireProductionMedia && selectedStudio && (
              <>
                <ProductionWithMediaPicker
                  isLoading={isProductionsFetching}
                  productions={productions}
                  onProductionChange={(option) => setSelectedProduction(option)}
                  onProductionMediaChange={(productionMedia) =>
                    setSelectedMediaToFeature(productionMedia)
                  }
                  defaultProduction={selectedProduction}
                  mediaLimit={1}
                  formFields={{
                    control: control,
                    errors: errors,
                  }}
                  isEditMode={false}
                  currentMarqueeProduction={null}
                />
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default StudioPicker;
