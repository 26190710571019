import React from 'react';
import { MuiChipsInput, MuiChipsInputProps } from 'mui-chips-input';
import { Controller } from 'react-hook-form';

export const ChipsInput = (props: MuiChipsInputProps) => {
  return <MuiChipsInput {...props} sx={{ '& input': { height: '4rem' } }} />;
};

type ChipsInputCustomProps = {
  label?: string;
  name: string;
  control: any;
  fullWidth?: boolean;
  placeholder?: string;
  type?: string;
} & MuiChipsInputProps;

export default function ChipsInputField(props: ChipsInputCustomProps) {
  const {
    label,
    fullWidth = true,
    placeholder = label,
    type = 'text',
    name = 'element',
    control,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ChipsInput
          error={!!error}
          hiddenLabel
          name={name}
          fullWidth={fullWidth}
          value={value || []}
          onChange={onChange}
          placeholder={placeholder}
          helperText={error ? error.message : null}
          label={label}
        />
      )}
    />
  );
}
