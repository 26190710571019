import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import {
  Autocomplete,
  Paper,
  Select,
  SideDrawer,
  TextField,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { AddPlayContributionValidation } from './PlaysValidationSchema';
import AddIcon from '@mui/icons-material/Add';
import AddArtists from './AddArtists';
import { useStyles } from '../styles';
import { SideBarArtistsState } from '../types';
import { Play } from '../../../types/Play';
import { Artist } from '../../../types/Artist';
import {
  PlayContribution,
  PlayContributionName,
  PlayContributionType,
} from '../../../types/PlayContribution';
import MultiFileUpload from '../../../components/Upload/MultiFileUpload';
import axios from '../../../config/http-common';

import Loader from '../../../components/Loader';
import { QueuedFiles } from '../../../types/Global';

export type PlayContributionWithFiles = PlayContribution & {
  contributionFiles: QueuedFiles[];
};

export default function AddPlayContribution(props: {
  contribution: null | PlayContribution;
  onSubmit: (data: PlayContributionWithFiles) => void;
  onDelete: (data: PlayContribution) => void;
  onClose: () => void;
}) {
  const {
    onClose,
    contribution: initContributionValue,
    onSubmit,
    onDelete,
  } = props;
  const classes = useStyles();
  const queryClient = useQueryClient();
  const defaultSideBarState = {
    addPlayArtists: false,
  };
  const { handleSubmit, control, reset, setValue } =
    AddPlayContributionValidation();
  // const { isLoading: artistLoading, data: artists } =
  //   useGetAllArtistsPagination('');
  const play: Play = queryClient.getQueryData(['selectedPlay']);
  const [contributionId, setContributionId] = useState(null);

  const [filesOnQueue, setFilesOnQueue] = useState<QueuedFiles[]>([]);
  const [loaderMsg, setLoaderMsg] = useState('');

  const [playContributionFiles, setPlayContributionFiles] = useState([]);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
  const [artistSearchText, setArtistSearchText] = useState('');
  const [artistData, setArtistData] = useState([]);
  const [artistLoading, setArtistLoading] = useState(false);

  useEffect(() => {
    setArtistLoading(true);
    const fetchArtists = async () => {
      const data = await axios
        .get(`/artists?searchText=${artistSearchText}&page=0&pageLimit=100`)
        .then((response) => response?.data?.data);
      setArtistData(data);
    };

    fetchArtists();
    setTimeout(() => {
      setArtistLoading(false);
    }, 1000);
  }, [artistSearchText]);

  useEffect(() => {
    async function init() {
      if (initContributionValue) {
        setLoaderMsg('Loading...');
        setLoaderMsg('');

        reset({
          ...initContributionValue,
          artistId: initContributionValue.artist.id,
          artist: {
            id: initContributionValue.artist.id,
            label: `${initContributionValue.artist.firstName} ${initContributionValue.artist.lastName} ${initContributionValue.artist.id}`,
            ...initContributionValue.artist,
          },
          contributionName: { label: initContributionValue.contributionName },
        });
        if (initContributionValue.contributionFileURLs) {
          setPlayContributionFiles(initContributionValue.contributionFileURLs);
        }
        setContributionId(initContributionValue.id);
      }
    }

    init();
  }, [initContributionValue]);

  const [isSideDrawerOn, setSideDrawerOn] =
    useState<SideBarArtistsState>(defaultSideBarState);
  const [isCustomContributionType, setIsCustomContributionType] =
    useState(false);

  return (
    <Box>
      <DialogHeader
        title={
          initContributionValue
            ? 'Edit Play Contribution'
            : 'Add Play Contribution'
        }
        handleSave={handleSubmit((data) =>
          onSubmit({
            ...(data as PlayContribution),
            contributionFiles: filesOnQueue,
          }),
        )}
        handleDelete={() =>
          initContributionValue && onDelete(initContributionValue)
        }
        cancel={() => {
          reset();
          onClose();
        }}
      />
      <Box className={classes.content}>
        <Paper>
          {loaderMsg && <Loader isLoading={!!loaderMsg} message={loaderMsg} />}
          <Box className={classes.subContent}>
            <Typography variant="h2">Details</Typography>
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <MultiFileUpload
              title="Play Contribution Files"
              files={playContributionFiles}
              uploadCallback={(fileInfo, fileType) => {
                setPlayContributionFiles((files) => [...files, fileInfo]);
                setFilesOnQueue((existingFiles) => [
                  ...existingFiles,
                  { fileInfo, fileType },
                ]);
              }}
              deleteCallback={(file) => {
                setPlayContributionFiles([
                  ...playContributionFiles.filter(
                    (f) => f?.mediaDetails?.id !== file?.mediaDetails?.id,
                  ),
                ]);
                setFilesToBeDeleted((existingFiles) => [
                  ...existingFiles,
                  file,
                ]);
              }}
            />
            <Divider style={{ marginBottom: '24px' }} />
            <Typography variant="h1">Play Contribution Details</Typography>
            <Box mt={3}>
              <Select
                label="Contribution Type"
                placeholder="Contribution Type"
                control={control}
                name="contributionType"
                options={(
                  Object.keys(PlayContributionType) as Array<
                    keyof typeof PlayContributionType
                  >
                )
                  .map((key) => PlayContributionType[key])
                  .sort()}
              />
            </Box>
            <Divider style={{ marginBottom: '24px' }} />
            <Box mt={3}>
              <Autocomplete
                label="Contribution Name"
                control={control}
                placeholder="Contribution Name"
                name="contributionName"
                options={(
                  Object.keys(PlayContributionName) as Array<
                    keyof typeof PlayContributionName
                  >
                )
                  .map((key) => {
                    const pc = {
                      id: PlayContributionName[key],
                      label: PlayContributionName[key],
                    };
                    return pc;
                  })
                  .sort()}
                getSelectedOption={(field: any) => {
                  console.log('get selected option: ' + JSON.stringify(field));
                  if (field.value != null) {
                    setIsCustomContributionType(field.value.label == 'Custom');
                  }
                }}
              />
            </Box>
            {isCustomContributionType && (
              <Box mt={3}>
                <TextField
                  label="Custom Contribution Name"
                  control={control}
                  name="customContributionName"
                />
              </Box>
            )}

            <Box mt={3} style={{ display: 'flex' }}>
              <Box style={{ width: '100%' }}>
                <Autocomplete
                  label="Artist"
                  placeholder="Select Artist"
                  name="artist"
                  control={control}
                  options={((artistData as any) || [])?.map(
                    (artist: Artist) => {
                      return {
                        id: artist.id,
                        label: `${artist.firstName} ${artist.lastName} - ${artist.id}`,
                        ...artist,
                      };
                    },
                  )}
                  getSearchValue={(searchTextVal) =>
                    setArtistSearchText(searchTextVal)
                  }
                  loading={artistLoading}
                />
              </Box>
              <Box>
                <IconButton
                  color="primary"
                  aria-label="addArtists"
                  component="div"
                  onClick={() =>
                    setSideDrawerOn({
                      ...defaultSideBarState,
                      addPlayArtists: !isSideDrawerOn.addPlayArtists,
                    })
                  }
                  sx={{ marginTop: '12px' }}
                >
                  <Typography variant="h6">New Artist</Typography>
                  <AddIcon />
                </IconButton>
                <SideDrawer
                  isActive={isSideDrawerOn.addPlayArtists}
                  toggleDrawer={() =>
                    setSideDrawerOn({
                      ...defaultSideBarState,
                      addPlayArtists: !isSideDrawerOn.addPlayArtists,
                    })
                  }
                  styles={{
                    width: '650px',
                  }}
                >
                  <AddArtists
                    onClose={(artist) => {
                      if (artist) {
                        setValue('artist', {
                          id: artist.id,
                          label: `${artist.firstName} ${artist.lastName} - ${artist.id}`,
                          ...artist,
                        });
                      }

                      setSideDrawerOn({
                        ...defaultSideBarState,
                        addPlayArtists: !isSideDrawerOn.addPlayArtists,
                      });
                    }}
                  />
                </SideDrawer>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
