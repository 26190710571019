import {
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useDeleteUser,
  useDeleteUserProfile,
  useGetInvitedUsers,
  useGetUserGroup,
  useInviteUserToStudio,
  useUpdateUserGroup,
  useUpdateUserProfile,
} from '../../../api/studios';
import { Alert, Button, Dialog, Select, TextField } from '../../../components';
import { useAuth } from '../../../hooks/useAuth';
import { AlertState } from '../../../types/Global';
import {
  alertAutoHideDuration,
  GlobalMessages,
} from '../../../utils/globalConfig';
import { inviteUserValidationSchema } from './StudioValidationSchema';
import { Role } from '../../../types/User';
import { Delete } from '@mui/icons-material';
import { alignProperty } from '@mui/material/styles/cssUtils';

export enum NonInvitees {
  operator = 'spectra:operator',
  user = 'spectra:user',
}

export interface inviteUsertoStudio {
  name: string;
  email: string;
  studioId: string;
  role: string;
}

export default function InviteUser(props) {
  const { studioId } = props;
  const { handleSubmit, control } = inviteUserValidationSchema();
  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
  });
  const [errorAlertState, setErrorAlertState] = useState<AlertState>({
    active: false,
    message: '',
  });
  const [updateAlertState, setUpdateAlertState] = useState<AlertState>({
    active: false,
    message: '',
  });
  const [deleteAlertState, setDeleteAlertState] = useState<AlertState>({
    active: false,
    message: '',
  });
  const [permissionOptions, setPermissionOptions] = useState([]);
  const { user } = useAuth();

  const { data: studioInvitees, isSuccess: UsersLoaded } =
    useGetInvitedUsers(studioId);
  const [invitees, setInvitees] = useState([]);

  // const postMutation = useInviteUserToStudio();
  // const { isSuccess: postStudioMetaSuccess, isError: postStudioMetaFailed} =
  //   postMutation;

  const [permissions, setPermissions] = useState({});
  const [changedPermissions, setChangedPermissinons] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({});

  // const permissionDescription: { Role : string; } = //{};
  // {
  //   Role.studioAdmin : 'user will be able to create, edit, and delete all studio content',
  //   Role.studioManager : 'user will be a view to view all studio content only',
  //   Role.studioMember : 'user will be a view to view all studio content only'
  // }

  useEffect(() => {
    if (
      user.primaryRole == Role.spectraOperator ||
      user.primaryRole == Role.studioAdmin
    ) {
      setPermissionOptions(['admin', 'manager', 'member', 'user']);
    } else if (user.primaryRole == Role.studioManager) {
      setPermissionOptions(['manager', 'member', 'user']);
    } else if (user.primaryRole == Role.studioMember) {
      setPermissionOptions(['user']);
    } else {
      setPermissionOptions([]);
    }
  }, []);

  const getGroupUsers = async () => {
    const finalList = await useGetUserGroup(studioInvitees?.usersInvited);
    for (const ele in finalList) {
      finalList[ele].then((res) => {
        console.log(`res: ${res}`);
        permissions[res.userId] = res?.group?.data[0]?.GroupName;
        setPermissions({ ...permissions });
      });
    }
  };

  const deleteUser = (id) => {
    useDeleteUserProfile({
      userId: id,
      studioId: studioId,
    });
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    const newData: inviteUsertoStudio = {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email.toLowerCase(),
      studioId: studioId,
      role: `spectra:studio:${data.permissions}`,
    };
    try {
      useInviteUserToStudio(newData).then((response) => {
        console.log(response.data);
        if (response.data.errorStatus) {
          setErrorAlertState({
            active: true,
            message: GlobalMessages.userAlreadyExists,
          });
        } else {
          setAlertState({
            active: true,
            message: GlobalMessages.successInviteToStudioMsg,
          });
        }
        window.location.reload();
      });
    } catch (e) {
      setErrorAlertState({
        active: true,
        message: GlobalMessages.errorInviteTostudioMsg,
      });
    }
  };

  useEffect(() => {
    if (UsersLoaded) {
      console.log(studioInvitees);
      let finals = studioInvitees?.usersInvited?.map((user) => {
        const foundUser = studioInvitees?.awsUsers.Users.find(
          (cognitoUser) => cognitoUser.Username == user.userId,
        );
        const tempAttr = {};
        if (foundUser == undefined) {
          deleteUser(user.userId);
        } else if (
          user.permissionLevel !== NonInvitees.operator &&
          user.permissionLevel !== NonInvitees.user
        ) {
          foundUser?.Attributes.forEach((attr) => {
            tempAttr[`${attr.Name}`] = attr.Value;
          });
          tempAttr['userId'] = user.userId;
          tempAttr['userAccountStatus'] = user.status;
        }
        return tempAttr;
      });
      getGroupUsers();
      finals = finals?.filter((value) => Object.keys(value).length !== 0);
      setInvitees(finals || []);
    }
  }, [UsersLoaded]);

  useEffect(() => {
    studioInvitees?.usersInvited?.forEach((invitee) => {
      if (permissions[invitee?.userId] != invitee?.permissionLevel) {
        useUpdateUserProfile({
          userId: invitee?.userId,
          updatedUserGroup: permissions[invitee?.userId],
        });
      }
    });
  }, [permissions, UsersLoaded]);

  const handleGroupChange = (event, userId) => {
    const newUserGroup = event.target.value;
    const oldUsergroup = permissions[userId];
    permissions[userId] = newUserGroup;
    setPermissions({ ...permissions });
    setChangedPermissinons((changedPermissions) => [
      ...changedPermissions,
      {
        userId: userId,
        oldUserGroup: oldUsergroup,
        newUserGroup: newUserGroup,
      },
    ]);
  };

  const handleUpdate = () => {
    try {
      changedPermissions?.forEach((ele) => {
        useUpdateUserGroup(ele);
      });
      setUpdateAlertState({
        active: true,
        message: GlobalMessages.successUpdateUserPermission,
      });
    } catch (e) {
      console.log(e);
      setUpdateAlertState({
        active: true,
        message: GlobalMessages.errorUpdateUserPermissions,
      });
    }
  };

  useEffect(() => {
    invitees.forEach((invitee) => {
      openDeleteDialog[invitee?.userId] = false;
      setOpenDeleteDialog({ ...openDeleteDialog });
    });
  }, [invitees]);

  console.log(openDeleteDialog);

  const handleDelete = (invite) => {
    console.log(invite);
    openDeleteDialog[invite?.userId] = true;
    setOpenDeleteDialog({ ...openDeleteDialog });
  };

  const handleClose = (id) => {
    openDeleteDialog[id] = false;
    setOpenDeleteDialog({ ...openDeleteDialog });
  };

  const handlePermanentDelete = async (data) => {
    const deleteResp = await useDeleteUser({
      userId: data.userId,
      studioId: studioId,
    }).then((response) => {
      console.log(response);
      return response;
    });
    console.log(deleteResp);
    if (deleteResp) {
      handleClose(data.userId);
      window.location.reload();
      setDeleteAlertState({
        active: true,
        message: GlobalMessages.successDeleteUser,
      });
    } else {
      setDeleteAlertState({
        active: true,
        message: GlobalMessages.successDeleteUser,
      });
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h1">Invite New Studio Member</Typography>
        <br />
        <Container maxWidth="lg">
          <Grid
            container
            sx={
              {
                // marginTop: '20px',
                // marginBotton: '20px'
              }
            }
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                paddingRight: '20px',
              }}
            >
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                control={control}
              />
              <br />
              <TextField
                fullWidth
                label="Email"
                name="email"
                control={control}
              />
              <br />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                paddingRight: '20px',
              }}
            >
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                control={control}
              />
              <br />
              <TextField
                fullWidth
                label="Phone Number (optional)"
                name="phoneNumber"
                control={control}
              />
              <br />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Typography variant="h2" lineHeight="2.5" textAlign="center">
          <b>Spectra Member Permissions Guide</b>
        </Typography>
        <Typography variant="h5" textAlign="center">
          All users must be given an access level that controls what they can do
          on our platform, please read the options below then select a level for
          your invitee.
        </Typography>

        <Container maxWidth="lg">
          <Grid
            container
            sx={{
              marginTop: '10px',
              // marginBotton: '20px'
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={
                {
                  // marginTop: '20px',
                  // marginBotton: '20px'
                }
              }
            >
              <Typography lineHeight="2" alignItems="flex-end">
                <ul>
                  <Typography variant="h4">
                    <b>Admin</b>
                  </Typography>
                  <ul>
                    <li>
                      Admin Portal <u>create, edit, and delete</u> access.
                    </li>
                    <li>Can invite members at any permission level</li>
                    <li>Spectra User Web App access.</li>
                  </ul>
                  <br />
                  <Typography variant="h4">
                    <b>Member</b>
                  </Typography>
                  <ul>
                    <li>
                      Admin Portal <u>view only</u> access.
                    </li>
                    <li>
                      Can only invite members at the &apos;User&apos; permission
                      level
                    </li>
                    <li>Spectra User Web App access.</li>
                  </ul>
                </ul>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                marginTop: '0px',
                // marginBotton: '20px'
              }}
            >
              <Typography lineHeight="2">
                <ul>
                  <Typography variant="h4">
                    <b>Manager</b>
                  </Typography>
                  <ul>
                    <li>
                      Admin Portal <u>view only</u> access (more permissions
                      coming soon).
                    </li>
                    <li>
                      Can invite members at the &apos;Member&apos; and
                      &apos;User&apos; permission level
                    </li>
                    <li>Spectra User Web App access.</li>
                  </ul>
                  <br />
                  <Typography variant="h4">
                    <b>User</b>
                  </Typography>
                  <ul>
                    <li>
                      <u>No Admin Portal access</u>.
                    </li>
                    <li>No invite abilities.</li>
                    <li>Spectra User Web App access.</li>
                  </ul>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Box mb={3} sx={{ margin: '20px' }}>
          <Select
            options={permissionOptions}
            placeholder="Select Invitee Permission Level"
            control={control}
            name="permissions"
          />
        </Box>

        <Alert
          message={errorAlertState.message}
          open={errorAlertState.active}
          severity="error"
          autoHideDuration={alertAutoHideDuration}
          onClose={() => setErrorAlertState({ active: false })}
        />
        <Alert
          message={alertState.message}
          open={alertState.active}
          autoHideDuration={alertAutoHideDuration}
          onClose={() => setAlertState({ active: false })}
        />
        <Grid container xs={12} md={12}>
          <Box sx={{ marginLeft: '20px' }}>
            <Button
              variant="contained"
              color="primary"
              label="Send Invitation"
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </Grid>
        <br />
        {UsersLoaded && invitees.length > 0 && (
          <Box sx={{}}>
            <Divider style={{ marginBottom: '24px', marginTop: '24px' }} />
            <Typography variant="h1">
              Manage Studio Member Permissions
            </Typography>
            <br />
            <br />
          </Box>
        )}
        {UsersLoaded && (
          <Box sx={{}}>
            {invitees.map((invite) => {
              return (
                <Box
                  key={invite.userId}
                  sx={{
                    display: 'flex',
                    paddingTop: '0px',
                    marginBottom: '25px',
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      paddingRight: '10%',
                      paddingTop: '0px',
                      width: '50%',
                    }}
                  >
                    <Typography variant="h3">{invite.name}</Typography>
                    {invite.email}
                  </Typography>
                  <br />
                  {invite?.userAccountStatus == 'Active' ? (
                    <Box
                      sx={{
                        paddingLeft: '0s%',
                        paddingTop: '5px',
                        width: '50%',
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="userGroupId"
                          name="userGroups"
                          value={`${permissions[invite.userId]}`}
                          onChange={() =>
                            handleGroupChange(event, invite.userId)
                          }
                        >
                          <FormControlLabel
                            value="spectra:studio:admin"
                            control={<Radio />}
                            label="Admin"
                          />
                          <FormControlLabel
                            value="spectra:studio:manager"
                            control={<Radio />}
                            label="Manager"
                          />
                          <FormControlLabel
                            value="spectra:studio:member"
                            control={<Radio />}
                            label="Member"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box
                      alignContent="right"
                      alignSelf="right"
                      sx={{
                        paddingTop: '12px',
                        textTransform: 'capitalize',
                        width: '50%',
                      }}
                    >
                      <Typography variant="h3">
                        <b>
                          Invited as{' '}
                          {permissions[invite.userId] != undefined
                            ? permissions[invite.userId].split(':').length > 2
                              ? permissions[invite.userId].split(':')[2]
                              : permissions[invite.userId].split(':')[1]
                            : ' '}
                        </b>
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => handleDelete(invite)}
                    sx={{}}
                  >
                    <Delete />
                  </IconButton>
                  <Alert
                    message={updateAlertState.message}
                    open={updateAlertState.active}
                    autoHideDuration={alertAutoHideDuration}
                    onClose={() => setAlertState({ active: false })}
                  />
                  <Dialog
                    isOpen={openDeleteDialog[invite?.userId]}
                    handleClose={() => handleClose(invite?.userId)}
                    primaryBtnLabel={'Delete'}
                    secondaryBtnLabel={'Cancel'}
                    handlePrimaryBtn={() => handlePermanentDelete(invite)}
                    primaryVariantColor={'error'}
                    secondaryVariantColor={'secondary'}
                    title={'Delete user'}
                    primaryVariant={'contained'}
                    secondaryVariant={'contained'}
                    content={`Click Delete if you wish to remove ${invite.name} with email ${invite.email}`}
                  ></Dialog>
                  <Alert
                    message={deleteAlertState.message}
                    open={deleteAlertState.active}
                    autoHideDuration={alertAutoHideDuration}
                    onClose={() => setAlertState({ active: false })}
                  />
                </Box>
              );
            })}
            {UsersLoaded && invitees.length > 0 && (
              <Button
                label={'update permissions'}
                variant={'contained'}
                color={'error'}
                onClick={handleUpdate}
                sx={{ marginLeft: '20px', marginTop: '10px' }}
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
