import React, { useCallback, useState } from 'react';
import {
  CircularProgress,
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import debounce from 'lodash/debounce';
import { Controller } from 'react-hook-form';

export function LazyAutocomplete<T>({
  name,
  control,
  handleSelect,
  getOptionsList,
  getOptionLabel,
  placeholder,
  label,
}: {
  name: string;
  placeholder: string;
  label: string;
  control: any;
  handleSelect: (option: T) => void;
  getOptionsList: (searchText: string) => Promise<{ data: T[] }>;
  getOptionLabel: (option: T) => string;
}) {
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  // Debounce function to limit API calls while typing
  const debouncedFetchOptions = useCallback(
    debounce(async (searchText) => {
      setLoading(true);
      try {
        const { data } = (await getOptionsList(searchText)) as { data: T[] };
        setOptions(data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
      setLoading(false);
    }, 1000),
    [],
  );

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    if (searchText.length >= 3) {
      debouncedFetchOptions(searchText);
    } else {
      setOptions([]);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <>
            <InputLabel>{label}</InputLabel>
            <MuiAutocomplete
              options={options}
              disableClearable
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.id === value.id;
              }}
              onChange={(event, value) => handleSelect(value)}
              renderInput={(params) => (
                <MuiTextField
                  placeholder={placeholder}
                  fullWidth
                  {...params}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: loading ? (
                      <CircularProgress size={24} sx={{ marginRight: '8px' }} />
                    ) : (
                      <></>
                    ),
                  }}
                />
              )}
              noOptionsText={
                searchText.length > 3 && !loading
                  ? 'No matching found'
                  : 'Enter atleast 3 characters to start searching...'
              }
              loading={loading}
            />
            {error ? (
              <FormHelperText error>{error.message}</FormHelperText>
            ) : null}
          </>
        );
      }}
    />
  );
}
