/**
 * Check if a given date string is valid.
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} Returns true if the date is valid, otherwise false.
 */
export function isValidDate(dateString: string): boolean {
  // Create a new Date object with the provided date string
  const date = new Date(dateString);

  // Check if the date is valid by comparing the getTime() result to NaN
  return !isNaN(date.getTime());
}

/**
 * Check if a given string represents a valid number.
 * @param {string} str - The string to validate.
 * @returns {boolean} Returns true if the string represents a valid number, otherwise false.
 */
export function isValidNumber(str: string): boolean {
  // Use the unary plus operator (+) to convert the string to a number
  // If the result is NaN, it means the string is not a valid number
  return !isNaN(+str);
}
