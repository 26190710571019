import React, { useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import Details from '../../../common/Details';
import { Button, SideDrawer } from '../../../../components';
import ConfirmDialog from '../../../../components/Modals/ConfirmDialog';
import AddFeaturedStudioContribution from './AddFeaturedStudioContribution';
import { useDeleteFeaturedStudioContribution } from '../../../../api/studios';

type Props = {
  contributions: {
    artistName: string;
    contributionType: string;
    id: string;
  }[];
  studioId: string;
  institutionId: string;
};

const FeaturedStudioProductionContributions = ({
  contributions = [],
  studioId,
  institutionId,
}: Props) => {
  const [openAddDrawer, setOpenDrawer] = useState(false);
  const [featuredContributions, setFeaturedContributions] =
    useState(contributions);

  const handleDelete = (deletedContributionId) => {
    setFeaturedContributions((contributions) =>
      contributions.filter((p) => p.id !== deletedContributionId),
    );
  };

  if (!studioId) return <></>;

  return (
    <Box>
      <Details
        title="Featured Production Contributions"
        subTitle="Choose up to 12 artists to feature at the top of the page"
        onAddButton={() => {
          if (featuredContributions.length < 12) {
            setOpenDrawer(true);
          } else {
            alert(
              'Max. of 12 artists can be featured. To add a new one, delete an existing featured contribution.',
            );
          }
        }}
      >
        <>
          {featuredContributions.map(({ artistName, contributionType, id }) => {
            return (
              <FeaturedContributionRow
                artistName={artistName}
                contributionType={contributionType}
                key={id}
                id={id}
                studioId={studioId}
                institutionId={institutionId}
                onDelete={handleDelete}
              />
            );
          })}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawer}
        toggleDrawer={() => setOpenDrawer(false)}
        styles={{
          width: '550px',
        }}
      >
        <AddFeaturedStudioContribution
          studioId={studioId}
          institutionId={institutionId}
          handleClose={(contribution) => {
            setOpenDrawer(false);
            setFeaturedContributions((contributions) => [
              ...contributions,
              contribution,
            ]);
          }}
          featuredContributionsCount={featuredContributions.length}
        />
      </SideDrawer>
    </Box>
  );
};

export default FeaturedStudioProductionContributions;
function FeaturedContributionRow({
  id: contributionId,
  artistName: artistName,
  contributionType: contributionType,
  studioId,
  institutionId,
  onDelete,
}: {
  id: string;
  artistName: string;
  contributionType: string;
  studioId: string;
  institutionId: string;
  onDelete: (contributionId: string) => void;
}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deleteMutation = useDeleteFeaturedStudioContribution({
    studioId,
    institutionId,
    productionContributionId: contributionId,
  });

  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={contributionId}>
          <b> {artistName}</b> - {contributionType}
        </ListItem>
        <Button
          label="Delete"
          variant={'contained'}
          color={'error'}
          loading={isLoading}
          onClick={() => {
            setConfirmDelete(true);
          }}
        />
        <ConfirmDialog
          isOpen={confirmDelete}
          handleClose={() => setConfirmDelete(false)}
          dialogOptions={{ isLoading }}
          handleConfirmDelete={async () => {
            setLoading(true);
            await deleteMutation.mutateAsync();
            onDelete(contributionId);
            setLoading(false);
            setConfirmDelete(false);
          }}
        />
      </Stack>
    </>
  );
}
