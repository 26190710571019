import {
  Box,
  Typography,
  Divider,
  ImageList,
  ImageListItem,
} from '@mui/material';
import React, { useState } from 'react';
import Details from '../../../common/Details';
import { Button, SideDrawer } from '../../../../components';
import { Props } from './FeaturedStudioProductions';
import AddFeaturedStudioProduction from './AddFeaturedStudioProduction';

export default function DisplaySelectedFeaturedMedia(props: Props) {
  const {
    featuredMedia,
    productions,
    productionCountByStudioType,
    studioId,
    institutionId,
    getDisplayFeaturedMedia,
    deleteDisplayFeaturedMedia,
  } = props;

  const [openAddDrawer, setOpenDrawer] = useState(false);
  const [featuredProductions, setFeaturedProductions] = useState(productions);
  const productionCount = Object.values(productionCountByStudioType).reduce(
    (sum, value) => sum + Number(value),
    0,
  );

  return (
    <Box>
      <Details
        title="Featured Media"
        subTitle="Choose up to 10 photos from the productions to feature on the page"
        onAddButton={() => {
          setOpenDrawer(true);
        }}
      >
        <ImageList
          sx={{ width: 'inherit', maxHeight: 400, borderRadius: '5px' }}
          cols={5}
          rowHeight={164}
        >
          {featuredMedia?.map((item) => (
            <Box
              key={item.mediaId}
              sx={{
                '&:hover': {
                  '& div': {
                    opacity: 1,
                  },
                },
              }}
            >
              <ImageListItem>
                <img
                  src={item?.media?.renditions[0]?.path}
                  alt={'title'}
                  loading="lazy"
                  style={{ borderRadius: '10px' }}
                />

                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    transition: 'opacity 0.2s ease-in-out',
                  }}
                >
                  <Button
                    label={'Delete'}
                    variant={'contained'}
                    color={'error'}
                    onClick={() => deleteDisplayFeaturedMedia(item?.mediaId)}
                  />
                </Box>
              </ImageListItem>
            </Box>
          ))}
        </ImageList>
        <SideDrawer
          isActive={openAddDrawer}
          toggleDrawer={() => setOpenDrawer(false)}
          styles={{
            width: '550px',
          }}
        >
          <AddFeaturedStudioProduction
            addedFeaturedProductions={featuredProductions}
            studioId={studioId}
            institutionId={institutionId}
            handleClose={(production) => {
              setOpenDrawer(false);
              const isFeatured = featuredProductions.find(
                (prod) => prod?.productionId === production.productionId,
              );
              if (!isFeatured) {
                setFeaturedProductions((productions) => [
                  ...productions,
                  production,
                ]);
              }
            }}
            getDisplayFeaturedMedia={getDisplayFeaturedMedia}
          />
        </SideDrawer>
      </Details>
    </Box>
  );
}
