import { useMutation, useQuery } from 'react-query';
import axios from '../config/http-common';
import { Artist } from '../types/Artist';
import { UploadMediaType, uploadMediaToS3_File } from './utils';

import { getExtFromFileName } from '../utils/media';
import { QueuedFiles } from '../types/Global';

export function useAllProductionContributions(playId: string) {
  return useQuery<Artist[], Error>(['allProductionContributions'], () =>
    axios
      .get(`/plays/${playId}/productionContributions`)
      .then((response) => response.data),
  );
}

export function usePostProductionContribution(productionId: string) {
  return useMutation((data) =>
    axios
      .post(`/productions/${productionId}/contributions`, data)
      .then((response) => response.data),
  );
}

export function usePutProductionContribution(
  productionId: string,
  contributionId: string,
) {
  return useMutation((data) =>
    axios
      .put(`/productions/${productionId}/contributions/${contributionId}`, data)
      .then((response) => response.data),
  );
}

export function useDeleteProductionContribution(
  productionId: string,
  contributionId: string,
) {
  return useMutation<Artist[], Error>(() =>
    axios
      .delete(`/productions/${productionId}/contributions/${contributionId}`)
      .then((response) => response.data),
  );
}

export async function deleteMedia(
  productionId: string,
  productionContributionId: string,
  mediaId: string,
) {
  return await axios.delete(
    `productions/${productionId}/contributions/${productionContributionId}/${mediaId}/delete`,
  );
}

export async function uploadMediaMultiple(
  productionId,
  productionContributionId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  const { signedUrl, fileName } = (
    await axios.get(`productions/${productionId}/contributions/uploadMedia`, {
      params: {
        contentType: mediaData.fileType,
        fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_File(
    signedUrl,
    mediaData,
  );

  const productionContributionMediaResponse = await axios.post(
    `productions/${productionId}/contributions/${productionContributionId}/process-media`,
    {
      mediaName: mediaData.fileInfo.fileName,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return productionContributionMediaResponse.data;
}
