import React, { useContext, useState } from 'react';
import { Box, ListItem, Stack } from '@mui/material';
import { Button, SideDrawer } from '../../../../components';
import Details from '../../../common/Details';
import { Character } from '../../../../types/Character';
import AddCharacters from '../AddCharacters';
import { deleteCharacter } from '../../../../api/characters';
import { useMutation } from 'react-query';
import { NotificationContext } from '../../../../utils/Notification/NotificationProvider';

export function PlayCharacters({
  isLoading,
  characters,
  onAdd,
}: {
  isLoading: boolean;
  characters: Character[];
  onAdd: (characters: Character[]) => void;
}) {
  const [isAddDrawerOpen, setAddDrawerOpen] = useState(false);
  const [activeCharacter, setActiveCharacter] = useState<null | Character>(
    null,
  );
  const [_characters, _setCharacters] = useState(characters);
  const { showToast } = useContext(NotificationContext);

  const deleteCharacterMutation = useMutation({
    mutationFn: deleteCharacter,
  });

  const onCharacterSubmit = (data: Character) => {
    let newCharacters = [];
    if (activeCharacter && activeCharacter.id === data.id) {
      newCharacters = _characters.map((c) => {
        if (activeCharacter.id === c.id) {
          return data;
        }
        return c;
      });
      _setCharacters(newCharacters);
    } else {
      newCharacters = [..._characters, data];
      _setCharacters(newCharacters);
    }
    setAddDrawerOpen(false);
    onAdd(newCharacters);
  };

  const onCharacterDelete = async () => {
    const playId = activeCharacter?.playId;
    const characterId = activeCharacter?.id;
    if (!playId || !characterId) {
      _setCharacters((characters) =>
        characters.filter((c) => c.name !== activeCharacter.name),
      );
      setAddDrawerOpen(false);
      return;
    }
    try {
      await deleteCharacterMutation.mutateAsync({ playId, characterId });
      _setCharacters((characters) =>
        characters.filter((c) => c.id !== characterId),
      );
      setAddDrawerOpen(false);
    } catch (e) {
      showToast("Couldn't delete the character. Try again later!", 'error');
    }
  };

  return (
    <Box mt={'40px'} mb={'40px'}>
      <Details
        title={
          _characters.length == 0 ? 'Add Play Characters' : 'Play Characters'
        }
        onAddButton={() => {
          setActiveCharacter(null);
          setAddDrawerOpen(true);
        }}
      >
        {isLoading ? (
          <div>Loading Characters...</div>
        ) : (
          _characters.map((character, index) => (
            <Stack
              display="flex"
              direction="row"
              style={{ marginBottom: '24px' }}
              key={`${character.id}-${index}`}
            >
              <ListItem>{character.name}</ListItem>
              <Button
                label="Edit"
                variant={'outlined'}
                color={'secondary'}
                onClick={() => {
                  setActiveCharacter(character);
                  setAddDrawerOpen(true);
                }}
              />
            </Stack>
          ))
        )}
      </Details>
      <SideDrawer
        isActive={isAddDrawerOpen}
        toggleDrawer={() => setAddDrawerOpen((state) => !state)}
        styles={{
          width: '650px',
        }}
      >
        <AddCharacters
          onClose={() => {
            setAddDrawerOpen(false);
          }}
          character={activeCharacter}
          onCharacterSubmit={onCharacterSubmit}
          onCharacterDelete={onCharacterDelete}
        />
      </SideDrawer>
    </Box>
  );
}
