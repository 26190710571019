import { useMutation, useQuery } from 'react-query';
import { Venue } from '../types/Venue';
import axios from '../config/http-common';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { Studio } from '../types/Studio';
import { getExtFromFileName } from '../utils/media';
import { UploadMediaType, uploadMediaToS3 } from './utils';

export function useGetAllVenues() {
  return useQuery(['allVenues'], () =>
    axios.get('venues').then((response) => {
      return response.data && response.data.venuesModel
        ? response.data.venuesModel
        : [];
    }),
  );
}

export function useGetVenue(id: string) {
  if (!id) {
    return { data: null, isSuccess: true, isLoading: false };
  }
  return useQuery<Venue, Error>(['selectedVenue'], () =>
    axios.get(`venues/${id}`).then((response) => response.data),
  );
}
export function usePostVenue() {
  return useMutation((data) =>
    axios.post('/venues', data).then((response) => response.data),
  );
}
export function usePutVenue(venueId: string) {
  return useMutation((data) =>
    axios.put(`/venues/${venueId}`, data).then((response) => response.data),
  );
}
export function useDeleteVenue(venueId: string) {
  return useMutation<Venue[], Error>(() =>
    axios.delete(`/venues/${venueId}`).then((response) => response.data),
  );
}

export function useCreateVenueFeaturedProduction() {
  return useMutation((args: { venueId: string; productionId: string }) => {
    return axios
      .post(`/venues/${args.venueId}/featured-productions`, args)
      .then((response) => response.data);
  });
}

export function useCreateVenueFeaturedStudio() {
  return useMutation((args: { venueId: string; studioId: string }) => {
    return axios
      .post(`/venues/${args.venueId}/featured-studios`, args)
      .then((response) => response.data);
  });
}

export function useDeleteFeaturedVenueProduction({
  venueId,
  productionId,
}: {
  venueId: string;
  productionId: string;
}) {
  return useMutation<0 | 1, Error>(() =>
    axios
      .delete(`/venues/${venueId}/featured-productions/${productionId}`)
      .then((response) => response.data),
  );
}
export function useDeleteFeaturedVenueStudio({
  venueId,
  studioId,
}: {
  venueId: string;
  studioId: string;
}) {
  return useMutation<0 | 1, Error>(() =>
    axios
      .delete(`/venues/${venueId}/featured-studios/${studioId}`)
      .then((response) => response.data),
  );
}

export async function queryVenueProductionStudios(queryBody: {
  venueId: string;
}): Promise<Studio[]> {
  return axios
    .post(`venues/${queryBody.venueId}/production-studios`)
    .then((response) => response.data);
}

export async function getVenueMedia(
  venueId: string,
  mediaType: UploadMediaType,
) {
  return await axios.get(`/venues/${venueId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(venueId: string, mediaId: string) {
  return await axios.delete(`/venues/${venueId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  venueId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  const associatedMedia = await getVenueMedia(venueId, uploadMediaType);

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.venueId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('/venues/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const venueMediaResponse = await axios.post(
    `/venues/${venueId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return venueMediaResponse.data;
}

export async function uploadFeaturedMedia(venueId: string, mediaId: string) {
  return await axios
    .post(`/venues/${venueId}/featured-media/${mediaId}`)
    .then((response) => response.data);
}

export async function deleteFeaturedMedia(venueId: string, mediaId: string) {
  return await axios
    .delete(`/venues/${venueId}/featured-media/${mediaId}`)
    .then((response) => response.data);
}

export async function getVenueFeaturedMedia(venueId: string) {
  return await axios
    .get(`/venues/${venueId}/featured-media`)
    .then((response) => response.data);
}

export async function queryVenueProductions(queryBody: {
  year: string;
  venueId: string;
}) {
  return axios
    .post('productions/searchForVenues', queryBody)
    .then((response) => response.data);
}
