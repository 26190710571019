import React from 'react';

import {
  Box,
  Button,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { useStyles } from './styles';

interface TableProps {
  table: any;
  response: any;
  user: any;
  hasEditRole: boolean;
  studioIdKey: string;
  onEdit: (val: string) => void;
}

function Table(props: TableProps) {
  const classes = useStyles();
  const { hasEditRole, studioIdKey, user, onEdit, response } = props;
  return (
    <MuiTable className={classes.Table}>
      <TableHead>
        {props.table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} className={classes.TableRow}>
            {headerGroup.headers.map((header) => {
              return (
                <TableCell
                  key={header.id}
                  colSpan={header.colSpan}
                  className={classes.TableHead}
                >
                  {header.isPlaceholder ? null : (
                    <>
                      <Box
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        onKeyDown={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: ' ▲',
                          desc: ' ▼',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    </>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {props.table.getRowModel().rows.map((row) => {
          // console.log(`${JSON.stringify(row.original)}`)
          // console.log(`user: ${JSON.stringify(user.studioId)}`)
          // console.log(`row key: ${row.original[studioIdKey]}`)
          // console.log(user.studioId)
          const canEditRow =
            user?.isOperator ||
            (hasEditRole && row.original[studioIdKey] === user.studioId);
          const highlightUserStudio =
            (user?.primaryRole === 'spectra:studio:admin' ||
              user?.primaryRole === 'spectra:studio:manager') &&
            row.original[studioIdKey] === user.studioId;
          return (
            <TableRow
              key={row.id}
              sx={{
                backgroundColor: `${highlightUserStudio ? '#F9F6EE' : ''}`,
              }}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
              {onEdit && canEditRow && (
                <TableCell>
                  <Button
                    onClick={() => onEdit(response.data[row.index].id)}
                    role="gridcell"
                    className={classes.TableEditButton}
                    color="secondary"
                    variant="outlined"
                  >
                    Edit
                  </Button>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </MuiTable>
  );
}

export { Table };
