import React, { useState, useEffect } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import Paper from '../Paper';
import UploadFileInput from './UploadFileInput';
import DropDownMenu from '../DropDown';
import ConfirmDialog, { useConfirmModal } from '../Modals/ConfirmDialog';

interface SingleVideoUploadProps {
  title: string;
  id?: string;
  message?: string;
  coverTitle?: string;
  videoTitle?: string;
  initialUpload?: any;
  uploadCallback?: (blob: string, file) => void;
  deleteCallback?: any;
}

export default function SingleVideoUpload(props: SingleVideoUploadProps) {
  const {
    id,
    title,
    message = 'Please upload a video. Only Mp4 types are supported.',
    coverTitle = 'Video',
    videoTitle = '',
    initialUpload,
    uploadCallback,
    deleteCallback,
  } = props;

  const [fileSrc, setFileSrc] = React.useState<string>(null);
  const [allFiles, setAllFiles] = useState(null);
  const { showDeleteModal, setShowDeleteModal } = useConfirmModal();

  useEffect(() => {
    setFileSrc(initialUpload);
  }, [initialUpload]);

  const dropDownMenuOptions = [
    // {
    //   label: 'edit',
    //   id: 'editButton',
    //   onClick: () => console.log('edit'),
    // },
    // {
    //   label: 'Duplicate',
    //   id: 'duplicateButton',
    //   onClick: () => console.log('duplicate'),
    // },
    // {
    //   label: 'Archive',
    //   id: 'archiveButton',
    //   onClick: () => console.log('Archive'),
    // },
    // {
    //   label: 'Move',
    //   id: 'moveButton',
    //   onClick: () => console.log('Move'),
    // },
    {
      label: 'Delete',
      id: 'deleteButton',
      onClick: () => {
        setShowDeleteModal(true);
      },
    },
  ];

  const fileUploadComplete = (blob: string, file: any) => {
    setFileSrc(blob);
    setAllFiles(file);
    uploadCallback(blob, file);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ marginBottom: '24px' }}>
            <Typography variant="h1">{title}</Typography>
            <p style={{ margin: 0 }}>{message}</p>
          </Box>
          <Box>
            {fileSrc && (
              <DropDownMenu
                label="options"
                id="optionsmenu"
                options={dropDownMenuOptions}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ marginBottom: '24px' }}>
          <Typography variant="h5" sx={{ marginBottom: '4px' }}>
            {videoTitle}
          </Typography>
          <UploadFileInput
            imageData={fileUploadComplete}
            id={id}
            uploadBtnLabel="Select Video"
          />
        </Box>
        {fileSrc && (
          <Box sx={{ marginBottom: '2rem' }}>
            <CardMedia
              sx={{
                maxWidth: '30%',
                maxHeight: '20%',
              }}
              component="video"
              src={fileSrc}
              controls
            />
            {allFiles?.name && (
              <Typography component={'h2'} sx={{ marginTop: '0.5rem' }}>
                File Name: {allFiles?.name}
              </Typography>
            )}
            {allFiles?.size && (
              <Typography component={'h4'} sx={{ marginTop: '0.5rem' }}>
                File size: {allFiles?.size}
              </Typography>
            )}
          </Box>
        )}
        <ConfirmDialog
          isOpen={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          handleConfirmDelete={() => {
            setFileSrc(null);
            deleteCallback();
            setShowDeleteModal(false);
          }}
        />
      </Box>
    </>
  );
}
