import { useMutation, useQuery } from 'react-query';
import { Studio } from '../types/Studio';
import axios from '../config/http-common';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { getExtFromFileName } from '../utils/media';
import {
  UploadMediaType,
  uploadMediaToS3,
  uploadMediaToS3_File,
} from './utils';
import { QueuedFiles } from '../types/Global';

export function useGetAllInstitutions() {
  return useQuery(['allInstitutions'], () =>
    axios.get('institutions').then((response) => response.data),
  );
}

export function useGetInstitution(institutionId: string) {
  if (!institutionId) {
    return { data: null, isSuccess: true, isLoading: false };
  }
  return useQuery<Studio, Error>(['selectedInstitution'], () =>
    axios
      .get(`institutions/${institutionId}`)
      .then((response) => response.data),
  );
}
export function usePostInstitution() {
  return useMutation((data) =>
    axios.post('/institutions', data).then((response) => response.data),
  );
}
export function usePutInstitution(institutionId: string) {
  return useMutation((data) =>
    axios
      .put(`/institutions/${institutionId}`, data)
      .then((response) => response.data),
  );
}
export function useDeleteInstitution(institutionId: string) {
  return useMutation<Studio[], Error>(() =>
    axios
      .delete(`/institutions/${institutionId}`)
      .then((response) => response.data),
  );
}

export async function getInstitutionMedia(
  institutionId: string,
  mediaType: UploadMediaType,
) {
  return await axios.get(`institutions/${institutionId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(institutionId: string, mediaId: string) {
  return await axios.delete(`/institutions/${institutionId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  institutionId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  const associatedMedia = await getInstitutionMedia(
    institutionId,
    uploadMediaType,
  );

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.institutionId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('institutions/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const institutionMediaResponse = await axios.post(
    `/institutions/${institutionId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return institutionMediaResponse.data;
}

export async function uploadMediaMultiple(
  institutionId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  const { signedUrl, fileName } = (
    await axios.get('institutions/uploadMedia', {
      params: {
        contentType: mediaData.fileType,
        fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_File(
    signedUrl,
    mediaData,
  );

  const institutionMediaResponse = await axios.post(
    `/institutions/${institutionId}/process-media`,
    {
      mediaName: mediaData.fileInfo.fileName,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return institutionMediaResponse.data;
}
