import { useQuery } from 'react-query';
import { Brand } from '../types/Brand';
import axios from '../config/http-common';
import { axiosS3 } from '../config/http-common';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { getExtFromFileName } from '../utils/media';

import {
  UploadMediaType,
  uploadMediaToS3,
  uploadMediaToS3_File,
} from './utils';
import { QueuedFiles } from '../types/Global';

export function useGetAllBrands() {
  return useQuery<Brand[], Error>(
    ['brands'],
    () => axios.get('brands').then((response) => response.data),
    {
      staleTime: Infinity,
    },
  );
}

export function useGetBrand(brandId: string) {
  return useQuery<Brand[], Error>(
    ['selectedBrand'],
    () => axios.get(`brands/${brandId}`).then((response) => response.data),
    {
      staleTime: Infinity,
    },
  );
}

export async function uploadFileToS3(
  singedPutURL: string,
  file: UploadFileInfo,
) {
  const config = {
    method: 'put',
    url: singedPutURL,
    headers: {
      'Content-Type': file.mimeType,
      'Content-Disposition': 'attachment',
    },
    data: file.blob,
  };

  return await axiosS3(config);
}

//Have to delete the useMultiMediaUpload first and delete this
export async function generateSignedURLs(apiPrefix: string) {
  return await axios.get(`${apiPrefix}`);
}

//Have to delete the useMultiMediaUpload first and delete this
export async function deleteFileFromS3(apiPrefix: string) {
  return await axios.delete(apiPrefix);
}

export async function getBrandMedia(
  brandId: string,
  mediaType: UploadMediaType,
) {
  return await axios.get(`/brands/${brandId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(brandId: string, mediaId: string) {
  return await axios.delete(`/brands/${brandId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  brandId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  const associatedMedia = await getBrandMedia(brandId, uploadMediaType);

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.brandId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('brands/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const BrandMediaResponse = await axios.post(
    `/brands/${brandId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return BrandMediaResponse.data;
}

export async function uploadMediaMultiple(
  brandId,
  mediaData: QueuedFiles,
  uploadMediaType: UploadMediaType,
) {
  const { signedUrl, fileName } = (
    await axios.get('brands/uploadMedia', {
      params: {
        contentType: mediaData.fileType,
        fileExtension: getExtFromFileName(mediaData.fileInfo.fileName),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3_File(
    signedUrl,
    mediaData,
  );

  const BrandMediaResponse = await axios.post(
    `/brands/${brandId}/process-media`,
    {
      mediaName: mediaData.fileInfo.fileName,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return BrandMediaResponse.data;
}
