import React, { createContext, useContext, useState } from 'react';

// Create a context to hold the modal state
const ModalContext = createContext(null);

// Create a custom hook to access the modal state
export const useErrorModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

// Create a provider to manage the modal state
export const ErrorModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState<{
    openState: boolean;
    request: string;
    title: string;
    response: string;
  }>({
    openState: false,
    title: '',
    request: '',
    response: '',
  });

  const closeModal = () => {
    setModalState({
      openState: false,
      title: '',
      request: '',
      response: '',
    });
  };

  const value = {
    modalState,
    setModalState,
    closeModal,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
