import React, { useState } from 'react';
import { Play } from '../../../types/Play';
import { Box, ListItem, Stack } from '@mui/material';
import { addFeaturedPlayInHomePage } from '../../../api/summaryPage';
import { Button, SideDrawer } from '../../../components';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';
import Details from '../../common/Details';
import axios from '../../../config/http-common';
import PlayPicker from './shared/PlayPicker';

const MAX_COUNT = 6;
const FeaturedPlays = ({ plays = [] }: { plays: Play[] }) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<boolean>(false);
  const [featuredPlays, setFeaturedPlays] = useState(plays);
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedPlayId?: string;
  }>({
    open: false,
    deleteFeaturedPlayId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteProductionCallback = async () => {
    try {
      const playId = confirmDelete.deleteFeaturedPlayId;
      setIsDeleting(true);
      await axios.delete(`/home/featured-play/${playId}`);
      setFeaturedPlays((plays) => plays.filter((p) => p.id !== playId));
      setIsDeleting(false);
      setConfirmDelete({ open: false });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <Box>
      <Details
        title="Featured Plays"
        onAddButton={() => {
          if (featuredPlays.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} productions can be featured.`);
            return;
          }
          setOpenDrawerState(true);
        }}
      >
        <>
          <p>Choose {MAX_COUNT} plays to display on the home page</p>
          {featuredPlays.map((play) => (
            <PlayRow
              key={play.id}
              play={play}
              onDelete={(playId) => {
                setConfirmDelete({
                  open: true,
                  deleteFeaturedPlayId: playId,
                });
              }}
            />
          ))}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState}
        toggleDrawer={() => setOpenDrawerState(false)}
        styles={{
          width: '550px',
        }}
      >
        <PlayPicker
          currentPlay={null}
          handleClose={(play) => {
            if (play) {
              setFeaturedPlays((plays) => [...plays, play]);
            }
            setOpenDrawerState(false);
          }}
          handleSave={(args) => addFeaturedPlayInHomePage(args.playId)}
          title="Featured Play"
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteProductionCallback}
      />
    </Box>
  );
};

function PlayRow({
  play,
  onDelete,
}: {
  play: Partial<Play>;
  onDelete: (playId: string) => void;
}): JSX.Element {
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={play.id}>
          <b>{play.licenseName}</b>
        </ListItem>
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(play.id);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}

export default FeaturedPlays;
