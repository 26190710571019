import React, { useEffect, useState } from 'react';
import { getFeatuedStudiosInSummaryPage } from '../../../api/summaryPage';
import FeaturedStudios from './FeaturedStudios';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

const StudioSummary = () => {
  const [studios, setFeaturedStudios] = useState([]);
  const [isLoading, setLoading] = useState(false);

  async function init() {
    setLoading(true);
    const featuredStudios = await getFeatuedStudiosInSummaryPage();
    setLoading(false);
    setFeaturedStudios(featuredStudios);
  }
  useEffect(() => {
    init();
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <FeaturedStudios studios={studios} />
    </>
  );
};

export default StudioSummary;
