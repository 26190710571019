import { useErrorModal } from '../components/Modals/ErrorModalProvider';

const useErrorHandler = () => {
  const { setModalState } = useErrorModal();

  const handleError = (error) => {
    if (error.name === 'AxiosError') {
      setModalState({
        openState: true,
        title: `${error.config.url} failed - ${error.response.status}`,
        request: error.config,
        response: error.response.data,
      });
    }
  };

  return {
    handleError,
  };
};

export default useErrorHandler;
