import * as React from 'react';
import { Typography, CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/system';
import { GlobalMessages } from '../utils/globalConfig';

interface LoaderProps {
  isLoading: boolean;
  message?: string;
  children?: any;
}

export default function Loader(props: LoaderProps) {
  const {
    isLoading = false,
    message = GlobalMessages.loadingMsg,
    children,
  } = props;

  return (
    <Backdrop
      sx={{
        backgroundColor: 'rbga(0,0,0,0.0)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <Box
        sx={{
          padding: '30px',
          borderRadius: '15px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h4">{message}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          {children}
        </Box>
      </Box>
    </Backdrop>
  );
}
