import React from 'react';
import { Autocomplete, TextField, styled, Box } from '@mui/material';
import { SvgSearchIcon } from '../utils/svg';

const MuiTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    padding: '0 !important',
  },
  '& .MuiAutocomplete-input': {
    border: 'none',
  },
}));

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Autocomplete
      freeSolo
      id="search-field"
      disableClearable
      options={[]}
      style={props.style}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          placeholder={props.placeholder}
          value={value}
          InputProps={{
            ...params.InputProps,
            type: props.type,
            startAdornment: (
              <Box style={{ display: 'flex', paddingLeft: 15 }}>
                <SvgSearchIcon />
              </Box>
            ),
          }}
          onChange={(event) => setValue(event.target.value)}
        />
      )}
    />
  );
}

export { DebouncedInput };
