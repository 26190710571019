import { useMutation, useQuery } from 'react-query';
import { Artist } from '../types/Artist';
import axios from '../config/http-common';
import { UploadFileInfo } from '../views/plays/CreatePlays/CreatePlay';
import { UploadMediaType, uploadMediaToS3 } from './utils';
import { getExtFromFileName } from '../utils/media';

export function useGetAllArtists() {
  return useQuery(['allArtists'], () =>
    axios.get('artists').then((response) => response.data),
  );
}

export function useGetArtist(id: string, successCB?: (artist: Artist) => void) {
  if (!id) {
    return { data: null, isSuccess: true, isLoading: false };
  }
  return useQuery<Artist, Error>(
    ['selectedArtist'],
    () => axios.get(`artists/${id}`).then((response) => response.data),
    {
      onSuccess(data) {
        if (successCB) {
          successCB(data);
        }
      },
    },
  );
}

export function usePostArtist() {
  return useMutation((data) =>
    axios.post('/artists', data).then((response) => response.data),
  );
}

export function inviteArtist() {
  return useMutation((data) =>
    axios.post('/auth/invite-user', data).then((response) => response.data),
  );
}

export function usePutArtist(artistId: string) {
  return useMutation((data) =>
    axios.put(`/artists/${artistId}`, data).then((response) => response.data),
  );
}
export function useDeleteArtist(artistId: string) {
  return useMutation<Artist[], Error>(() =>
    axios.delete(`/artists/${artistId}`).then((response) => response.data),
  );
}

export async function useGetInvitedArtists(artistId: string) {
  return await axios
    .get(`/user-profiles/artist/${artistId}`)
    .then((response) => response.data);
}

export async function useDeleteUserArtist(deleteUserBody) {
  console.log(deleteUserBody);
  return await axios
    .delete('/user-profiles/deleteUserArtist', { data: deleteUserBody })
    .then((response) => response.data);
}

export async function getArtistMedia(
  artistId: string,
  mediaType: UploadMediaType,
) {
  return await axios.get(`/artists/${artistId}/media`, {
    params: {
      mediaType: mediaType,
    },
  });
}

export async function deleteMedia(artistId: string, mediaId: string) {
  return await axios.delete(`/artists/${artistId}/${mediaId}/delete`);
}

export async function uploadMediaSingle(
  artistId: string,
  mediaData: UploadFileInfo,
  uploadMediaType: UploadMediaType,
) {
  const associatedMedia = await getArtistMedia(artistId, uploadMediaType);

  if (associatedMedia.data.length > 0) {
    await Promise.all(
      associatedMedia.data.map((media) => {
        deleteMedia(media.artistId, media.mediaId);
      }),
    );
  }

  const { signedUrl, fileName } = (
    await axios.get('/artists/uploadMedia', {
      params: {
        contentType: mediaData.mimeType,
        fileExtension: getExtFromFileName(mediaData.name),
      },
    })
  ).data;

  const uploadMediaToS3Response = await uploadMediaToS3(signedUrl, mediaData);

  const artistMediaResponse = await axios.post(
    `/artists/${artistId}/process-media`,
    {
      mediaName: mediaData.name,
      fileName: fileName,
      uploadMediaType: uploadMediaType,
    },
  );

  return artistMediaResponse.data;
}
