import { Studio } from './Studio';

export type Venue = {
  id: string;
  studioId?: string;
  studio?: Studio;
  name: string;
  locationName?: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  website?: string;
  stageConfiguration?: VenueStageConfiguration[];
  specialFeatures?: string;
  venueCapacity?: string;
  completionYear?: string;
  resourceLocation?: string;
  createdAt: Date;
  updatedAt: Date;
  venueImageURL: string;
};

export enum VenueStageConfiguration {
  prosceniumArch = 'Proscenium Arch',
  endEndOn = 'End/End-on',
  thrust = 'Thrust',
  traverseProfile = 'Traverse/Profile',
  inTheRound = 'In-the-round',
  concertHall = 'Concert Hall',
  blackBox = 'Black Box',
}
