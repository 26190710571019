import React from 'react';
import { useQuery } from 'react-query';
import { Box, Grid } from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
} from '@tanstack/react-table';
import { useStyles } from './styles';
import Loader from '../Loader';
import { fetchPagination } from '../../api/utils';
import { Role } from '../../types/User';
import { useAuth } from '../../hooks/useAuth';
import { DebouncedInput } from '../DebouncedInput';
import { Table } from './Table';
import TableFooter from './TableFooter';

export type PageLimitOptions = 250 | 500 | 1000 | 2000;
const PaginationSizeOptions: PageLimitOptions[] = [250, 500, 1000, 2000];

interface TablePlotProps {
  data?: any;
  tableName?: string;
  onEdit?: (tableId: any) => void;
  route?: string;
  model?: any;
  enableSearch?: boolean;
  requiredEditScopes?: Role[];
  isScoped?: boolean;
  studioIdKey?: string;
  rowsPerPageOptions?: number[];
  defaultPageSize?: PageLimitOptions;
  columns: any;
  fetchingMsg?: string;
  loadingMsg?: string;
  searchPlaceholder?: string;
  searchInputType?: string;
  searchStudioPlaceholder?: string;
  searchStudioInputType?: string;
}

function PaginationTable(props: TablePlotProps) {
  const classes = useStyles();
  const {
    onEdit,
    route,
    columns,
    enableSearch = true,
    rowsPerPageOptions = PaginationSizeOptions,
    defaultPageSize = 250,
    fetchingMsg = 'Fetching the Data, Please wait...',
    loadingMsg = 'Loading data, Please wait...',
    searchPlaceholder = 'Search...',
    searchInputType = 'search',
    searchStudioPlaceholder = 'Search by Studio...',
    searchStudioInputType = 'search',
  } = props;

  const [searchQuery, setSearchQueryText] = React.useState('');
  const [secondarySearchQuery, setSecondarySearchQueryText] =
    React.useState('');

  const defaultData = React.useMemo(() => [], []);
  const { isAuthenticated, user } = useAuth();

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });

  React.useEffect(() => {
    if (isAuthenticated) {
      Promise.all([refetchData]).then(() => {
        if (process.env.NODE_ENV === 'development')
          console.log('Data refreshed');
      });
    }
  }, [isAuthenticated]);

  const {
    isLoading,
    isFetching,
    data: response,
    refetch: refetchData,
    isError,
  } = useQuery(
    [route, pageIndex, pageSize, searchQuery, secondarySearchQuery],
    () =>
      fetchPagination(
        route,
        pageIndex,
        pageSize,
        searchQuery,
        secondarySearchQuery,
      ),
    {
      keepPreviousData: true,
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    },
  );

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const hasEditRole =
    isAuthenticated && props.requiredEditScopes?.includes(user?.primaryRole);

  // console.log(`scopes: ${props.requiredEditScopes}`)
  // console.log(`user: ${user.primaryRole}`)
  // console.log(`user studioId: ${user.studioId}`)
  // console.log(`props key: ${props.studioIdKey}`)

  const table = useReactTable({
    data: response && response.data ? response.data : defaultData,
    columns,
    pageCount: response?.lastPage,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Grid container alignItems="center">
      {isFetching && (
        <Loader
          isLoading={isLoading || isFetching}
          message={`${isLoading ? fetchingMsg : loadingMsg}`}
        />
      )}

      <Box />
      <Grid
        item
        md={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.SearchBar}
      ></Grid>
      <Grid
        item
        sm={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.SearchBar}
      >
        {enableSearch && (
          <DebouncedInput
            type={searchInputType}
            value={(searchQuery ?? '') as string}
            onChange={(value: any) => setSearchQueryText(value)}
            placeholder={searchPlaceholder}
          />
        )}
        <br />
        {enableSearch && searchStudioPlaceholder && (
          <DebouncedInput
            type={searchStudioInputType}
            value={(secondarySearchQuery ?? '') as string}
            onChange={(value: any) => setSecondarySearchQueryText(value)}
            placeholder={searchStudioPlaceholder}
          />
        )}
        <br />
      </Grid>
      <Grid
        item
        md={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.SearchBar}
      ></Grid>
      <Table
        table={table}
        hasEditRole={hasEditRole}
        studioIdKey={props.studioIdKey}
        user={user}
        onEdit={onEdit}
        response={response}
      />
      {isError && (
        <Grid item xs={12} sx={{ m: 2, color: 'red' }}>
          Error in fetching the data. Refresh again. <br />
        </Grid>
      )}
      <TableFooter table={table} rowsPerPageOptions={rowsPerPageOptions} />
    </Grid>
  );
}

export { PaginationTable };
