import React from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';

interface TableFooterProps {
  table: any;
  rowsPerPageOptions: number[];
}

function TableFooter(props: TableFooterProps) {
  const classes = useStyles();
  const { table, rowsPerPageOptions } = props;
  return (
    <Box className={classes.TablePagination}>
      <Button
        className={classes.PaginationButton}
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
        variant="outlined"
      >
        {'<<'}
      </Button>
      <Button
        className={classes.PaginationButton}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        variant="outlined"
      >
        {'<'}
      </Button>
      <Box className={classes.PageDetails}>
        <Box>Page</Box>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </Box>
      <Button
        className={classes.PaginationButton}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        variant="outlined"
      >
        {'>'}
      </Button>
      <Button
        className={classes.PaginationButton}
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
        variant="outlined"
      >
        {'>>'}
      </Button>

      <select
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
        className={classes.SelectDropdown}
      >
        {rowsPerPageOptions.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize} Rows
          </option>
        ))}
      </select>
    </Box>
  );
}

export default TableFooter;
