import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Alert, Autocomplete, Paper, TextField } from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { useStyles } from '../styles';
import { CreateStudioValidation } from './StudioValidationSchema';
import Select from '../../../components/Select';
import { StudioSubtype, StudioType } from '../../../types/Studio';
import { useNavigate, useParams } from 'react-router-dom';
import CreateStudioSideDrawer from './CreateStudioSideDrawer';
import {
  deleteFeaturedMedia,
  deleteMedia,
  getStudioFeaturedMedia,
  uploadMediaMultiple,
  uploadMediaSingle,
  useDeleteStudio,
  useGetStudio,
  usePostStudio,
  usePutStudio,
} from '../../../api/studios';
import { SideBarState } from '../types';
import { AlertState, QueuedFiles } from '../../../types/Global';
import Loader from '../../../components/Loader';
import {
  alertAutoHideDuration,
  GlobalMessages,
  LogoMaxFileSizeInMB,
  PosterSupportedFormat,
} from '../../../utils/globalConfig';
import AddIcon from '@mui/icons-material/Add';
import { useGetAllInstitutions } from '../../../api/institutions';
import { Institution } from '../../../types/Institution';
import SingleImageUpload, {
  QueueFileInfo,
} from '../../../components/Upload/SingleImageUpload';
import MultiFileUpload, {
  FileInfo,
} from '../../../components/Upload/MultiFileUpload';
import InviteUser from './InviteUser';
import MultiImageUpload from '../../../components/Upload/MultiImageUpload';

import { UploadMediaType, getGeoCoordinates } from '../../../api/utils';
import useErrorHandler from '../../../hooks/useErrorHandler';
import FeaturedStudioProductions from './featured/FeaturedStudioProductions';
import FeaturedStudioProductionContributions from './featured/FeaturedStudioProductionContributions';
import DisplaySelectedFeaturedMedia from './featured/DisplaySelectedFeaturedMediaStudios';
const supportedStudioFileFormats = [
  'png',
  'pdf',
  'jpeg',
  'jpg',
  'svg',
  'csv',
  'mp4',
  'mp3',
];

export default function CreateStudio() {
  const { id: studioId } = useParams() || {};

  const navigate = useNavigate();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { handleSubmit, control, reset, setValue } = CreateStudioValidation();
  const {
    data: studio,
    isSuccess: isStudioLoaded,
    isLoading: isStudioLoading,
  } = useGetStudio(studioId);
  const { data: institutions, isSuccess: isInstitutionsLoaded } =
    useGetAllInstitutions();

  const [studioLogoMedia, setStudioLogoMedia] = useState<{
    studioId: string;
    mediaId: string;
  }>();
  const [studioLogoMediaOnQueue, setstudioLogoMediaOnQueue] =
    useState<QueueFileInfo>();

  const [studioLogoMediaToBeDeleted, setStudioLogoMediaToBeDeleted] = useState<{
    studioId: string;
    mediaId: string;
  }>();
  const [studioFiles, setStudioFiles] = useState<FileInfo[]>([]);

  const [studioFilesOnQueue, setStudioFilesOnQueue] = useState<QueuedFiles[]>(
    [],
  );
  const [studioFilesTobeDeleted, setStudioFilesToBeDeleted] = useState<
    FileInfo[]
  >([]);
  const [studioCarouselImages, setStudioCarouselImages] = useState<FileInfo[]>(
    [],
  );
  const [studioCarouselImageOnQueue, setStudioCarouselImagesOnQueue] = useState<
    QueuedFiles[]
  >([]);
  const [studioCarouselImagesToBeDeleted, setStudioCarouselImagesToBeDeleted] =
    useState([]);

  const [savingLoader, setSavingLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [saveDisable, setSaveDisable] = useState(false);
  const { handleError } = useErrorHandler();

  const updateMutation = usePutStudio(studioId);
  const {
    isSuccess: updateStudioMetaSuccess,
    isError: updateStudioMetaFailed,
    isLoading: updateStudioMetaLoading,
  } = updateMutation;

  const postMutation = usePostStudio();
  const {
    isSuccess: postStudioMetaSuccess,
    isError: postStudioMetaFailed,
    isLoading: postStudioMetaLoading,
  } = postMutation;

  const deleteMutation = useDeleteStudio(studioId);
  const {
    isSuccess: deleteStudioMetaSuccess,
    isError: deleteStudioMetaFailed,
    isLoading: deleteStudioMetaLoading,
  } = deleteMutation;

  const defaultSideBarState = {
    id: '',
    addInstitution: false,
  };
  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });

  const [isSideDrawerOn, setSideDrawerOn] =
    useState<SideBarState>(defaultSideBarState);

  const [displayFeaturedMedia, setDisplayFeaturedMedia] = useState([]);

  const deleteDisplayFeaturedMedia = async (mediaId) => {
    setDisplayFeaturedMedia([
      ...displayFeaturedMedia.filter((media) => media?.mediaId !== mediaId),
    ]);
    const deleteResponse = await deleteFeaturedMedia(studioId, mediaId);
    return deleteResponse;
  };

  const getInitFeaturedMedia = async () => {
    const allFeaturedMedia = await getStudioFeaturedMedia(studioId);
    setDisplayFeaturedMedia(allFeaturedMedia);
  };

  useEffect(() => {
    if (isStudioLoaded && isInstitutionsLoaded) {
      setStudioFiles(studio.studioFileObjects);
      setStudioCarouselImages(studio.studioCarouselObjects);
      setStudioLogoMedia(studio.studioLogoMediaDetails);
      reset({
        ...studio,
        institutionId: studio?.institution?.id,
        selectedInstitution: studio?.institution
          ? { id: studio.institution.id, label: studio.institution.name }
          : null,
      });
      getInitFeaturedMedia();
    }
    setInitialLoader(false);
  }, [isStudioLoaded, isInstitutionsLoaded]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['selectedStudio']);
    };
  }, []);

  const handleClose = () => {
    queryClient.removeQueries(['selectedStudio']);
    reset();
    navigate('/studios');
  };

  async function updateFiles(studioId: string) {
    if (!studioId) {
      console.log('no studio id');
      return { errors: ['No studio Id is provided'] };
    }

    let studioLogoUploadResponsePromise,
      studioLogoDeleteResponsePromise,
      studioFilesUploadResponsePromises,
      studioFilesDeleteResponsePromises,
      studioCarouselImagesUploadResponsePromises,
      studioCarouselImagesDeleteResponsePromises;

    const errors = [];

    if (studioLogoMediaOnQueue) {
      studioLogoUploadResponsePromise = uploadMediaSingle(
        studioId,
        studioLogoMediaOnQueue,
        UploadMediaType.logo,
      ).catch((error) =>
        errors.push(`Error while uploading studio logo: ${error.message}`),
      );
    }

    if (studioLogoMediaToBeDeleted) {
      studioLogoDeleteResponsePromise = deleteMedia(
        studioId,
        studioLogoMediaToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(`Error while deleting studio logo: ${error.message}`),
      );
    }

    if (studioFilesOnQueue.length > 0) {
      studioFilesUploadResponsePromises = studioFilesOnQueue.map(
        (mediaFile) => {
          const uploadResponse = uploadMediaMultiple(
            studioId,
            mediaFile,
            UploadMediaType.file,
          );
          return uploadResponse;
        },
      );
    }

    if (studioFilesTobeDeleted.length > 0) {
      studioFilesDeleteResponsePromises = studioFilesTobeDeleted.map(
        (mediaFile) => {
          const deleteResponse = deleteMedia(
            studioId,
            mediaFile.mediaDetails.id,
          );
          return deleteResponse;
        },
      );
    }

    if (studioCarouselImageOnQueue.length > 0) {
      studioCarouselImagesUploadResponsePromises =
        studioCarouselImageOnQueue.map((mediaFile) => {
          const uploadResponse = uploadMediaMultiple(
            studioId,
            mediaFile,
            UploadMediaType.carousel,
          );
          return uploadResponse;
        });
    }

    if (studioCarouselImagesToBeDeleted.length > 0) {
      studioCarouselImagesDeleteResponsePromises =
        studioCarouselImagesToBeDeleted.map((mediaFile) => {
          const deleteResponse = deleteMedia(
            studioId,
            mediaFile.mediaDetails.id,
          );
          return deleteResponse;
        });
    }

    const [
      studioLogoUploadResponse,
      studioLogoDeleteResponse,
      studioFilesUploadResponse,
      studioFilesDeleteResponse,
      studioCarouselImagesUploadResponse,
      studioCarouselImagesDeleteResponse,
    ] = await Promise.all([
      studioLogoUploadResponsePromise,
      studioLogoDeleteResponsePromise,
      studioFilesUploadResponsePromises,
      studioFilesDeleteResponsePromises,
      studioCarouselImagesUploadResponsePromises,
      studioCarouselImagesDeleteResponsePromises,
    ]);

    studioFilesUploadResponse?.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading studio files ${index + 1}: ${response.error}`,
        );
      }
    });

    studioFilesUploadResponse?.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error deleting studio files ${index + 1}: ${response.error}`,
        );
      }
    });

    studioCarouselImagesUploadResponse?.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading studio carousel images ${index + 1}: ${
            response.error
          }`,
        );
      }
    });

    studioCarouselImagesDeleteResponse?.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading studio carousel images ${index + 1}: ${
            response.error
          }`,
        );
      }
    });

    setStudioFilesOnQueue([]);
    setStudioFilesToBeDeleted([]);
    setStudioCarouselImagesOnQueue([]);
    setStudioCarouselImagesToBeDeleted([]);

    return {
      studioLogoUploadResponse,
      studioLogoDeleteResponse,
      studioFilesUploadResponse,
      studioFilesDeleteResponse,
      studioCarouselImagesUploadResponse,
      studioCarouselImagesDeleteResponse,
    };
  }

  const onSubmit = async (data: any) => {
    const geoCoordinateValues = await getGeoCoordinates({
      street: data.street,
      city: data.city,
      state: data.state,
      country: data.country,
      zipcode: data.zipcode,
    });
    const formData = {
      ...data,
      institutionId: data.selectedInstitution?.id,
      latitude: geoCoordinateValues?.lat,
      longitude: geoCoordinateValues?.long,
    };
    console.log(formData);
    setSavingLoader(true);
    setSaveDisable(true);
    try {
      const studio = studioId
        ? await updateMutation.mutateAsync(formData)
        : await postMutation.mutateAsync(formData);

      const results = await updateFiles(studio.id || studioId);
      if (results?.errors?.length > 0) {
        setAlertState({
          error: true,
          active: true,
          message: 'Media upload errors:\n• ' + results.errors.join('\n• '),
        });
      } else {
        const successMsg = studioId
          ? GlobalMessages.updateStudioSuccessMsg
          : GlobalMessages.addStudioSuccessMsg;

        setAlertState({
          active: true,
          message: successMsg,
        });
      }

      queryClient.refetchQueries();
      setSavingLoader(false);
      setSaveDisable(false);
      if (!studioId && studio && studio.id != null) {
        reset();
        navigate(`/studios/${studio.id}/edit`);
      }
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
      setSavingLoader(false);
      setSaveDisable(false);
    }
  };

  const handleDeleteStudio = async () => {
    try {
      await deleteMutation.mutateAsync();
      await queryClient.invalidateQueries(['selectedStudio']);
      setAlertState({
        active: true,
        message: GlobalMessages.deleteStudioSuccessMsg,
      });
      handleClose();
      setSavingLoader(false);
      setSaveDisable(false);
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
      setSavingLoader(false);
      setSaveDisable(false);
    }
  };

  const successAlert =
    updateStudioMetaSuccess || postStudioMetaSuccess || deleteStudioMetaSuccess;
  const errorAlert =
    postStudioMetaFailed || updateStudioMetaFailed || deleteStudioMetaFailed;
  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;

  return (
    <>
      <Alert
        message={alertState.message}
        open={showAlert}
        severity={alertState.error ? 'error' : 'success'}
        autoHideDuration={alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
      <Loader
        isLoading={
          initialLoader ||
          postStudioMetaLoading ||
          updateStudioMetaLoading ||
          deleteStudioMetaLoading ||
          isStudioLoading ||
          savingLoader
        }
      />
      <Box className={classes.bodyBackground}>
        <DialogHeader
          title={studioId ? 'Edit Studio' : 'Create Studio'}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={studioId && handleDeleteStudio}
          cancel={() => {
            reset();
            navigate('/studios');
          }}
        />
        <Grid
          container
          sx={{ justifyContent: 'center !important' }}
          className={classes.container}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <Box className={classes.accountInfo}>
                <Typography variant="h1">Studio Details</Typography>
                <br />
                <Box mb={3}>
                  <TextField label="Name" name="name" control={control} />
                </Box>
                <Box mb={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Box style={{ width: '100%' }}>
                    <Autocomplete
                      placeholder="Select Institution"
                      name="selectedInstitution"
                      label={'Select Institution'}
                      control={control}
                      options={((institutions as Institution[]) || []).map(
                        (institution: Institution) => {
                          return {
                            id: institution.id,
                            label: `${institution.name} - ${institution.id}`,
                          };
                        },
                      )}
                    />
                  </Box>
                  <Box>
                    <IconButton
                      color="primary"
                      aria-label="addInstitution"
                      component="div"
                      onClick={() =>
                        setSideDrawerOn({
                          ...isSideDrawerOn,
                          addInstitution: !isSideDrawerOn.addInstitution,
                        })
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box mb={3}>
                  <Select
                    options={(
                      Object.keys(StudioType) as Array<keyof typeof StudioType>
                    ).map((key) => StudioType[key])}
                    placeholder="Select Type..."
                    control={control}
                    name="type"
                    label="Studio Type"
                  />
                </Box>
                <Box mb={3}>
                  <Select
                    options={(
                      Object.keys(StudioSubtype) as Array<
                        keyof typeof StudioSubtype
                      >
                    ).map((key) => StudioSubtype[key])}
                    placeholder="Select Subtype..."
                    control={control}
                    name="subtype"
                    label="Studio Subtype"
                  />
                </Box>
                {/* <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} /> */}
                <SingleImageUpload
                  title="Studio Logo"
                  maxFileSizeInMB={LogoMaxFileSizeInMB}
                  initialUpload={(studio && studio.studioLogoURL) || ''}
                  validator={(fileExt) =>
                    PosterSupportedFormat.includes(fileExt)
                  }
                  uploadCallback={async (blob, file) => {
                    setstudioLogoMediaOnQueue({
                      blob,
                      mimeType: file.type,
                      name: file.name,
                      type: 'logo',
                      file: file,
                    });
                  }}
                  deleteCallback={() => {
                    setStudioLogoMediaToBeDeleted(studioLogoMedia);
                  }}
                />
                <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
                <InviteUser studioId={studioId} />
                <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
                <MultiImageUpload
                  title={'Featured Studio Production Photos'}
                  message="Select up to 10 photos for your production page."
                  images={studioCarouselImages}
                  validator={(fileExt) =>
                    ['png', 'jpeg', 'jpg'].includes(fileExt)
                  }
                  uploadCallback={(fileInfo, fileType) => {
                    setStudioCarouselImages((existingFiles) => [
                      ...existingFiles,
                      fileInfo,
                    ]);
                    setStudioCarouselImagesOnQueue((existingFiles) => [
                      ...existingFiles,
                      { fileInfo, fileType },
                    ]);
                  }}
                  deleteCallback={(file) => {
                    setStudioCarouselImages([
                      ...studioCarouselImages.filter(
                        (f) => f.mediaDetails.id !== file.mediaDetails.id,
                      ),
                    ]);
                    setStudioCarouselImagesToBeDeleted((existingFiles) => [
                      ...existingFiles,
                      file,
                    ]);
                  }}
                />
                <Divider sx={{ marginTop: '40px', marginBottom: '24px' }} />
                {studioId && studio && (
                  <>
                    <Box my={3}>
                      <FeaturedStudioProductions
                        productions={studio?.featuredProductions || []}
                        productionCountByStudioType={
                          studio?.productionCountByStudioType || {}
                        }
                        studioId={studioId}
                        institutionId={studio.institutionId}
                        getDisplayFeaturedMedia={(medias) => {
                          setDisplayFeaturedMedia(medias);
                        }}
                      />
                    </Box>
                    <Box my={3}>
                      <FeaturedStudioProductionContributions
                        contributions={studio?.featuredContributors || []}
                        studioId={studioId}
                        institutionId={studio.institutionId}
                      />
                    </Box>
                    <Box>
                      <DisplaySelectedFeaturedMedia
                        featuredMedia={displayFeaturedMedia}
                        productions={studio?.featuredProductions || []}
                        productionCountByStudioType={
                          studio?.productionCountByStudioType || {}
                        }
                        studioId={studioId}
                        institutionId={studio.institutionId}
                        getDisplayFeaturedMedia={(medias) => {
                          setDisplayFeaturedMedia(medias);
                        }}
                        deleteDisplayFeaturedMedia={deleteDisplayFeaturedMedia}
                      />
                    </Box>
                  </>
                )}
                <MultiFileUpload
                  title={'Manage Studio Files'}
                  files={studioFiles}
                  validator={(fileExt, sizeInMB) =>
                    supportedStudioFileFormats.includes(fileExt) &&
                    sizeInMB < 100
                  }
                  uploadCallback={(fileInfo, fileType) => {
                    setStudioFiles((existingFiles) => [
                      ...existingFiles,
                      fileInfo,
                    ]);
                    setStudioFilesOnQueue((existingFiles) => [
                      ...existingFiles,
                      { fileInfo, fileType },
                    ]);
                  }}
                  deleteCallback={(file) => {
                    setStudioFiles([
                      ...studioFiles.filter(
                        (f) => f.mediaDetails.id !== file.mediaDetails.id,
                      ),
                    ]);
                    setStudioFilesToBeDeleted((existingFiles) => [
                      ...existingFiles,
                      file,
                    ]);
                  }}
                />
                <Divider sx={{ marginTop: '40px', marginBottom: '24px' }} />
                <Typography variant="h1">Address</Typography>
                <br />
                <Box mb={3}>
                  <TextField label="Street" name="street" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField label="City" name="city" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField label="State" name="state" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField label="Country" name="country" control={control} />
                </Box>
                <Box mb={3}>
                  <TextField
                    label="Zip Code"
                    name="zipcode"
                    control={control}
                  />
                </Box>
                <Box mb={3}>
                  <TextField label="Website" name="website" control={control} />
                </Box>
              </Box>
              <CreateStudioSideDrawer
                sideDrawerState={isSideDrawerOn}
                id={isSideDrawerOn.id}
                defaultSideBarState={defaultSideBarState}
                currentState={(state: any) => setSideDrawerOn(state)}
                onClose={(institution) => {
                  if (institution) {
                    setValue('selectedInstitution', {
                      id: institution.id,
                      label: `${institution.name} - ${institution.id}`,
                    });
                  }
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
