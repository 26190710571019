import { OrchestrationBook } from './OrchestrationBook';
import { Play } from './Play';

export type Orchestration = {
  id: string;
  playId: string;
  orchestrationName: string;
  orchestrationSize?: string;
  orchestrationDescription?: string;
  orchestrationType?: OrchestrationTypes;
  musicStyle?: OrchestrationMusicStyle;
  musicMoodStyle?: OrchestrationMusicMoodStyle;
  musicGenrePrimaryCategory?: OrchestrationMusicGenre;
  musicGenrePrimarySubcategory?: OrchestrationMusicGenreSubcategory;
  musicGenreSecondaryCategory?: OrchestrationMusicGenre;
  musicGenreSecondarySubcategory?: OrchestrationMusicGenreSubcategory;
  play?: Play;
  createdAt: Date;
  updatedAt: Date;
  orchestrationBooks?: OrchestrationBook[];
};

export enum OrchestrationTypes {
  fullOrchestration = 'Full Orchestration',
  reducedOrchestration = 'Reduced Orchestration',
  pianoVocal = 'Piano/Vocal',
  rhythmSection = 'Rhythm Section',
  fullOrchestrationWithElectronicTracks = 'Full Orchestration with Electronic Tracks',
  reducedOrchestrationWithElectronicTracks = 'Reduced Orchestration with Electronic Tracks',
  pianoVocalWithElectronicTracks = 'Piano/Vocal with Electronic Tracks',
  rhythmSectionWithElectronicTracks = 'Rhythm Section with Electronic Tracks',
  electronicTracks = 'Electronic Tracks',
}

export enum OrchestrationMusicMoodStyle {
  acapella = 'Acapella',
  christian = 'Christian',
  featuringBass = 'Featuring Bass',
  featuringDrums = 'Featuring Drums',
  featuringGuitar = 'Featuring Guitar',
  featuringPiano = 'Featuring Piano',
  featuringSaxophone = 'Featuring saxophone',
  instrumental = 'Instrumental',
  moodAngry = 'Mood: Angry',
  moodBrooding = 'Mood: Brooding',
  moodChristmas = 'Mood: Christmas',
  moodDreamy = 'Mood: Dreamy',
  moodFun = 'Mood: Fun',
  moodFunny = 'Mood: Funny',
  moodIntellectual = 'Mood: Intellectual',
  moodPartyMusic = 'Mood: Party Music',
  moodPatriotic = 'Mood: Patriotic',
  moodQuirky = 'Mood: Quirky',
  moodSeasonal = 'Mood: Seasonal',
  moodSexMusic = 'Mood: Sex Music',
  moodUpbeat = 'Mood: Upbeat',
  moodVirtuoso = 'Mood: Virtuoso',
  moodWeird = 'Mood: Weird',
  outAndProud = 'Out-and-Proud',
  soloFemaleArtist = 'Solo Female Artist',
  soloMaleArtist = 'Solo Male Artist',
  soloNonBinaryArtist = 'Solo Non-Binary Artist',
  spiritual = 'Spiritual',
  typeAcoustic = 'Type: Acoustic',
  typeBackgroundMusic = 'Type: Background Music',
  typeCompilations = 'Type: Compilations',
  typeExperimental = 'Type: Experimental',
  typeImprovisational = 'Type: Improvisational',
  typeInstrumental = 'Type: Instrumental',
  typeLoFi = 'Type: Lo-Fi',
  typeLyrical = 'Type: Lyrical',
  typePolitical = 'Type: Political',
  typeSonic = 'Type: Sonic',
  typeSoundEffects = 'Type: Sound Effects',
  typeTributes = 'Type: Tributes',
  typeVocal = 'Type: Vocal',
}

export enum OrchestrationMusicStyle {
  avantGarde = 'Avant Garde',
  blues = 'Blues',
  brazilian = 'Brazilian',
  classical = 'Classical',
  country = 'Country',
  easyListening = 'Easy Listening',
  electronic = 'Electronic',
  folk = 'Folk',
  hipHopRap = 'Hip-Hop/Rap',
  holiday = 'Holiday',
  jazz = 'Jazz',
  kidsFamily = 'Kids/Family',
  latin = 'Latin',
  metalPunk = 'Metal/Punk',
  newAge = 'New Age',
  pop = 'Pop',
  rbSoul = 'R&B/Soul',
  reggae = 'Reggae',
  rock = 'Rock',
  spiritual = 'Spiritual',
  spokenWord = 'Spoken Word',
  world = 'World',
}

export enum OrchestrationMusicGenre {
  avantGarde = 'Avant Garde',
  blues = 'Blues',
  classical = 'Classical',
  country = 'Country',
  easyListening = 'Easy Listening',
  electronic = 'Electronic',
  folk = 'Folk',
  hipHopRap = 'Hip-Hop/Rap',
  jazz = 'Jazz',
  kidsFamily = 'Kids/Family',
  latin = 'Latin',
  metalPunk = 'Metal/Punk',
  moods = 'Moods',
  newAge = 'New Age',
  pop = 'Pop',
  reggae = 'Reggae',
  rock = 'Rock',
  spiritual = 'Spiritual',
  spokenWord = 'Spoken Word',
  urbanRB = 'Urban/R&B',
  world = 'World',
}

export type OrchestrationMusicGenreSubcategory =
  | AvantGardeGenre
  | BluesGenre
  | BrazilianGenre
  | ClassicalGenre
  | CountryGenre
  | EasyListeningGenre
  | ElectronicGenre
  | FolkGenre
  | HipHopRapGenre
  | JazzGenre
  | KidsFamilyGenre
  | LatinGenre
  | MetalPunk
  | NewAgeGenre
  | PopGenre
  | RBSoulGenre
  | ReggaeGenre
  | RockGenre
  | SpiritualGenre
  | SpokenWordGenre
  | WorldGenre;

export enum AvantGardeGenre {
  atonal = 'Atonal',
  avantAmericana = 'Avant-Americana',
  classicalAvantGarde = 'Classical Avant-Garde',
  computerMusic = 'Computer Music',
  electroAcoustic = 'Electro-Acoustic',
  electronicAvantGarde = 'Electronic Avant-Garde',
  experimental = 'Experimental',
  filmBaby = 'FilmBaby',
  freeImprovisation = 'Free Improvisation',
  lowerCaseMusic = 'Lower-case Music',
  microsound = 'Microsound',
  microtonal = 'Microtonal',
  mixedMedia = 'Mixed Media',
  modernComposition = 'Modern Composition',
  musiqueConcrete = 'Musique Concrete',
  noise = 'Noise',
  processGenerated = 'Process-Generated',
  psychedelia = 'Psychedelia',
  soundArt = 'Sound Art',
  soundCollage = 'Sound Collage',
  soundSculpture = 'Sound Sculpture',
  structuredImprovisation = 'Structured Improvisation',
  tapeMusic = 'Tape Music',
}

export enum BluesGenre {
  acousticBlues = 'Acoustic Blues',
  bluesGospel = 'Blues Gospel',
  bluesVocals = 'Blues Vocals',
  bluesRock = 'Blues-Rock',
  carolinaBeachShag = 'Carolina Beach / Shag',
  chicagoStyle = 'Chicago Style',
  classicFemaleBlues = 'Classic Female Blues',
  countryBlues = 'Country Blues',
  deltaStyle = 'Delta Style',
  detroitBlues = 'Detroit Blues',
  dirtyBlues = 'Dirty Blues',
  eastCoastBlues = 'East Coast Blues',
  electricBlues = 'Electric Blues',
  englishStyle = 'English Style',
  fingerPickedGuitar = 'Finger-Picked Guitar',
  folkBlues = 'Folk-Blues',
  funkyBlues = 'Funky Blues',
  guitarBlues = 'Guitar Blues',
  harmonicaBlues = 'Harmonica Blues',
  jazzyBlues = 'Jazzy Blues',
  jukeJointBlues = 'Juke Joint Blues',
  jumpBlues = 'Jump Blues',
  louisianaBlues = 'Louisiana Blues',
  mellowBlues = 'Mellow Blues',
  memphisBlues = 'Memphis Blues',
  newOrleansBlues = 'New Orleans Blues',
  newYorkBlues = 'New York Blues',
  pianoBlues = 'Piano Blues',
  piedmontBlues = 'Piedmont Blues',
  prewarBlues = 'Prewar Blues',
  rhythmBlues = 'Rhythm & Blues',
  rockinBlue = 'Rockin Blue',
  slideGuitarBlues = 'Slide Guitar Blues',
  soulBlues = 'Soul-Blues',
  stLouisBlues = 'St. Louis Blues',
  swampBlues = 'Swamp Blues',
  texasStyle = 'Texas Style',
  urbanBlues = 'Urban Blues',
  westCoastBlues = 'West Coast Blues',
  workSongs = 'Work Songs',
}

export enum BrazilianGenre {
  arrocha = 'Arrocha',
  axe = 'Axé',
  baiao = 'Baião',
  bandanejo = 'Bandanejo',
  batucada = 'Batucada',
  boiBumba = 'Boi-Bumba',
  bossaNova = 'Bossa Nova',
  brega = 'Brega',
  cacuria = 'Cacuriá',
  caipira = 'Caipira',
  cancioneiro = 'Cancioneiro',
  carimbo = 'Carimbó',
  chamame = 'Chamamé',
  chorinho = 'Chorinho',
  chula = 'Chula',
  ciranda = 'Ciranda',
  coco = 'Coco',
  embolada = 'Embolada',
  forro = 'Forró',
  frevo = 'Frevo',
  funkCarioca = 'Funk Carioca',
  gospel = 'Gospel',
  guitarrada = 'Guitarrada',
  maracatu = 'Maracatu',
  modaDeViola = 'Moda de Viola',
  mPB = 'MPB',
  pagode = 'Pagode',
  rasqueado = 'Rasqueado',
  samba = 'Samba',
  sambaDePartidoAlto = 'Samba de partido-alto',
  sambaCancao = 'Samba-canção',
  sambaEnredo = 'Samba-enredo',
  seresta = 'Seresta',
  sertanejo = 'Sertanejo',
  sertanejoUniversitário = 'Sertanejo Universitário',
  tcheMusic = 'Tchê Music',
  tecnoBrega = 'TecnoBrega',
  toada = 'Toada',
  trovaGalponeira = 'Trova Galponeira',
  vanerao = 'Vanerão',
}

export enum ClassicalGenre {
  arias = 'Arias',
  artSongs = 'Art Songs',
  bach = 'Bach',
  ballet = 'Ballet',
  bandMusic = 'Band Music',
  baroque = 'Baroque',
  brassQuintet = 'Brass quintet',
  cantata = 'Cantata',
  chamberMusic = 'Chamber Music',
  choralMusic = 'Choral Music',
  classicalEra = 'Classical era',
  concerto = 'Concerto',
  contemporary = 'Contemporary',
  earlyMusic = 'Early Music',
  filmMusic = 'Film Music',
  gregorianChant = 'Gregorian Chant',
  impressionism = 'Impressionism',
  keyboardMusic = 'Keyboard Music',
  madrigals = 'Madrigals',
  mass = 'Mass',
  medieval = 'Medieval',
  minimalism = 'Minimalism',
  modernist = 'Modernist',
  musicalTheater = 'Musical Theater',
  newAge = 'New Age',
  newMusicEnsemble = 'New Music Ensemble',
  opera = 'Opera',
  operetta = 'Operetta',
  oratorio = 'Oratorio',
  orchestral = 'Orchestral',
  organ = 'Organ',
  percussionEnsemble = 'Percussion Ensemble',
  postmodern = 'Postmodern',
  renaissance = 'Renaissance',
  rockOpera = 'Rock Opera',
  romanticEra = 'Romantic Era',
  serialist = 'Serialist',
  sinfonietta = 'Sinfonietta',
  tonePoem = 'Tone Poem',
  traditional = 'Traditional',
  twentiethCentury = 'Twentieth Century',
  vocalMusic = 'Vocal Music',
}

export enum CountryGenre {
  altCountry = 'Alt-Country',
  americana = 'Americana',
  bakersfieldSound = 'Bakersfield Sound',
  bluegrass = 'Bluegrass',
  contemporaryCountry = 'Contemporary Country',
  countryBlues = 'Country Blues',
  countryBoogie = 'Country Boogie',
  countryFolk = 'Country Folk',
  countryGospel = 'Country Gospel',
  countryPop = 'Country Pop',
  countryRock = 'Country Rock',
  countrypolitan = 'Countrypolitan',
  cowboy = 'Cowboy',
  honkyTonk = 'Honky Tonk',
  modernCountry = 'Modern Country',
  oldTimey = 'Old-Timey',
  outlawCountry = 'Outlaw Country',
  progressiveBluegrass = 'Progressive Bluegrass',
  progressiveCountry = 'Progressive Country',
  rodeo = 'Rodeo',
  squareDance = 'Square Dance',
  traditionalBluegrass = 'Traditional Bluegrass',
  traditionalCountry = 'Traditional Country',
  urbanCowboy = 'Urban Cowboy',
  western = 'Western',
  westernSwing = 'Western Swing',
  yodeling = 'Yodeling',
}

export enum EasyListeningGenre {
  adultContemporary = 'Adult Contemporary',
  americanPopularSong = 'American Popular Song',
  backgroundMusic = 'Background Music',
  ballads = 'Ballads',
  ballroom = 'Ballroom',
  barbershopQuartet = 'Barbershop Quartet',
  cabaret = 'Cabaret',
  classicalPop = 'Classical Pop',
  croonersVocals = 'Crooners/Vocals',
  easyPop = 'Easy Pop',
  exotica = 'Exotica',
  lounge = 'Lounge',
  mature = 'Mature',
  moodMusic = 'Mood Music',
  musicHall = 'Music Hall',
  musicalsBroadway = 'Musicals/Broadway',
  nostalgia = 'Nostalgia',
  orchestralPop = 'Orchestral Pop',
  polka = 'Polka',
  showTunes = 'Show Tunes',
  softRock = 'Soft Rock',
  spaceAgePop = 'Space Age Pop',
  tinPanAlley = 'Tin Pan Alley',
  torchSongs = 'Torch Songs',
  vaudeville = 'Vaudeville',
}

export enum ElectronicGenre {
  acidHouse = 'Acid House',
  acidJazz = 'Acid Jazz',
  acidTechno = 'Acid Techno',
  alternativeDance = 'Alternative Dance',
  ambient = 'Ambient',
  baileFunk = 'Baile Funk',
  bigBeat = 'Big Beat',
  bmoreBaltimoreClub = 'Bmore/Baltimore Club',
  breakbeatBreaks = 'Breakbeat/Breaks',
  breakcore = 'Breakcore',
  brokenBeat = 'Broken Beat',
  chicagoHouse = 'Chicago House',
  chillOut = 'Chill Out',
  chiptune = 'Chiptune',
  dance = 'Dance',
  darkTerrorSpeedCore = 'Dark/Terror/Speed-core',
  deepHouse = 'Deep House',
  detroitTechno = 'Detroit Techno',
  disco = 'Disco',
  downTempo = 'Down Tempo',
  drumNBass = "Drum /'n/' Bass",
  jungle = 'Jungle',
  dub = 'Dub',
  dubstep = 'Dubstep',
  electro = 'Electro',
  electroHouse = 'Electro House',
  electroclash = 'Electroclash',
  electronica = 'Electronica',
  euroDance = 'Euro-Dance',
  experimental = 'Experimental',
  folktronic = 'Folktronic',
  freestyle = 'Freestyle',
  funk = 'Funk',
  gabbr = 'Gabbr',
  ghettoHouseTech = 'Ghetto House/Tech',
  glitch = 'Glitch',
  happyHardcore = 'Happy Hardcore',
  hardHouse = 'Hard House',
  hardcoreTechno = 'Hardcore Techno',
  hiNRG = 'Hi-NRG',
  house = 'House',
  iDM = 'IDM',
  illbient = 'Illbient',
  industrial = 'Industrial',
  latinDance = 'Latin Dance',
  latinFreestyle = 'Latin Freestyle',
  lounge = 'Lounge',
  microhouse = 'Microhouse',
  minimalTechno = 'Minimal Techno',
  nuSkoolBreaks = 'Nu skool breaks',
  nujazz = 'Nujazz',
  popCrossover = 'Pop Crossover',
  progressiveHouse = 'Progressive House',
  progressiveTrance = 'Progressive Trance',
  psyTrance = 'Psy-Trance',
  schranz = 'Schranz',
  soundscapes = 'Soundscapes',
  synthpop = 'Synthpop',
  techHouse = 'Tech-House',
  techno = 'Techno',
  technoDub = 'Techno-Dub',
  trance = 'Trance',
  tribalHouse = 'Tribal House',
  tripHop = 'Trip Hop',
}

export enum FolkGenre {
  acidFolk = 'Acid Folk',
  alternativeFolk = 'Alternative Folk',
  angry = 'Angry',
  antiFolk = 'Anti-Folk',
  appalachianFolk = 'Appalachian Folk',
  britishFolk = 'British Folk',
  celticFolk = 'Celtic Folk',
  celticFusion = 'Celtic Fusion',
  celticPop = 'Celtic Pop',
  childrensFolk = "Children's Folk",
  contemporaryCeltic = 'Contemporary Celtic',
  contraDance = 'Contra Dance',
  drinkingSongs = 'Drinking Songs',
  fingerstyle = 'Fingerstyle',
  folkBlues = 'Folk Blues',
  folkPop = 'Folk Pop',
  folkJazz = 'Folk-Jazz',
  folkRock = 'Folk-Rock',
  freeFolk = 'Free-folk',
  gentle = 'Gentle',
  irishContemporary = 'Irish Contemporary',
  irishTraditional = 'Irish Traditional',
  jazzyFolk = 'Jazzy Folk',
  jugBand = 'Jug Band',
  likeAni = 'like Ani',
  likeJoni = 'like Joni',
  minstrel = 'Minstrel',
  modernFolk = 'Modern Folk',
  musette = 'Musette',
  pipeBand = 'Pipe Band',
  political = 'Political',
  politicalFolk = 'Political Folk',
  powerFolk = 'Power-folk',
  progressiveFolk = 'Progressive Folk',
  psychFolk = 'Psych-folk',
  scottishContemporary = 'Scottish Contemporary',
  scottishTraditional = 'Scottish Traditional',
  seaShanties = 'Sea Shanties',
  singerSongwriter = 'Singer/Songwriter',
  skiffle = 'Skiffle',
  stringBand = 'String Band',
  traditionalFolk = 'Traditional Folk',
  urbanFolk = 'Urban Folk',
}

export enum HipHopRapGenre {
  alternativeHipHop = 'Alternative Hip Hop',
  bassMusic = 'Bass Music',
  beatTape = 'Beat Tape',
  britishRap = 'British Rap',
  choppedAndScrewed = 'Chopped and Screwed',
  comedyRap = 'Comedy Rap',
  dirtySouth = 'Dirty South',
  eastCoast = 'East Coast',
  gangstaRap = 'Gangsta Rap',
  gFunk = 'G-Funk',
  hardcoreRap = 'Hardcore Rap',
  hipHop = 'Hip Hop',
  horrorcore = 'Horrorcore',
  hyphy = 'Hyphy',
  jazzRap = 'Jazz-Rap',
  latinRap = 'Latin Rap',
  nerdcore = 'Nerdcore',
  oldSchoolRap = 'Old-School Rap',
  partyRap = 'Party Rap',
  politicalRap = 'Political Rap',
  popRap = 'Pop-Rap',
  rap = 'Rap',
  reggaeton = 'Reggaeton',
  southernStyle = 'Southern Style',
  spiritualRap = 'Spiritual Rap',
  streetRap = 'Street Rap',
  tripHop = 'Trip Hop',
  turntablism = 'Turntablism',
  undergroundRap = 'Underground Rap',
  westCoastRap = 'West Coast Rap',
}

export enum JazzGenre {
  acidJazz = 'Acid Jazz',
  africanJazz = 'African Jazz',
  afroCubanJazz = 'Afro-Cuban Jazz',
  avantGardeJazz = 'Avant-Garde Jazz',
  ballroomDance = 'Ballroom Dance',
  bebop = 'Bebop',
  bigBand = 'Big Band',
  boogieWoogie = 'Boogie-Woogie',
  bossaNova = 'Bossa Nova',
  chamberJazz = 'Chamber Jazz',
  contemporaryJazz = 'Contemporary Jazz',
  coolJazz = 'Cool Jazz',
  crossoverJazz = 'Crossover Jazz',
  danceBand = 'Dance Band',
  dixieland = 'Dixieland',
  experimentalBigBand = 'Experimental Big Band',
  freeJazz = 'Free Jazz',
  gypsyJazz = 'Gypsy Jazz',
  hardBop = 'Hard Bop',
  jazzFusion = 'Jazz Fusion',
  jazzFunk = 'Jazz-Funk',
  jazzPop = 'Jazz-Pop',
  jazzRock = 'Jazz-Rock',
  jive = 'Jive',
  latinJazz = 'Latin Jazz',
  mainstreamJazz = 'Mainstream Jazz',
  modernBigBand = 'Modern Big Band',
  modernCreativeJazz = 'Modern Creative Jazz',
  modernFreeJazz = 'Modern Free Jazz',
  neoBop = 'Neo-Bop',
  newOrleansJazz = 'New Orleans Jazz',
  orchestralJazz = 'Orchestral Jazz',
  postBop = 'Post-Bop',
  progressiveJazz = 'Progressive Jazz',
  ragtime = 'Ragtime',
  retroSwing = 'Retro Swing',
  smoothJazz = 'Smooth Jazz',
  soulJazz = 'Soul-Jazz',
  stride = 'Stride',
  swingBigBand = 'Swing/Big Band',
  thirdStream = 'Third Stream',
  weirdJazz = 'Weird Jazz',
  westCoastJazz = 'West Coast Jazz',
  worldFusion = 'World Fusion',
}

export enum KidsFamilyGenre {
  childrensPop = "Children/'s Pop",
  childrensStorytelling = "Children's Storytelling",
  educational = 'Educational',
  generalChildrensMusic = "General Children's Music",
  kidFriendly = 'Kid Friendly',
  lullabies = 'Lullabies',
  singAlongInteractive = 'Sing-Along/Interactive',
}

export enum LatinGenre {
  afroPeruvian = 'Afro Peruvian',
  afroCuban = 'Afro-Cuban',
  bachata = 'Bachata',
  banda = 'Banda',
  beguine = 'Beguine',
  bigBandLatino = 'Big Band Latino',
  bolero = 'Bolero',
  boogaloo = 'Boogaloo',
  brazilianFolk = 'Brazilian Folk',
  brazilianJazz = 'Brazilian Jazz',
  brazilianPop = 'Brazilian Pop',
  chaCha = 'Cha-Cha',
  charanga = 'Charanga',
  conjunto = 'Conjunto',
  corrido = 'Corrido',
  cuatro = 'Cuatro',
  cuban = 'Cuban',
  cubanJazz = 'Cuban Jazz',
  cubanPop = 'Cuban Pop',
  cumbia = 'Cumbia',
  danzon = 'Danzon',
  flamenco = 'Flamenco',
  forro = 'Forró',
  guaguanco = 'Guaguancó',
  lambada = 'Lambada',
  latinFolk = 'Latin Folk',
  latinJazz = 'Latin Jazz',
  latinPop = 'Latin Pop',
  latinRap = 'Latin Rap',
  latinSoul = 'Latin Soul',
  mambo = 'Mambo',
  mariachi = 'Mariachi',
  merengue = 'Merengue',
  mPB = 'MPB',
  newYorkSalsa = 'New York Salsa',
  norteno = 'Norteño',
  nueavaCancion = 'Nueava Cancion',
  pachanga = 'Pachanga',
  plena = 'Plena',
  quechua = 'Quechua',
  ranchera = 'Ranchera',
  rhumba = 'Rhumba',
  rockEnEspañol = 'Rock en Español',
  salsa = 'Salsa',
  son = 'Son',
  sonero = 'Sonero',
  tango = 'Tango',
  tejano = 'Tejano',
  tropicalia = 'Tropicalia',
  trova = 'Trova',
  vallenato = 'Vallenato',
}

export enum MetalPunk {
  seventiesMetal = '70s Metal',
  eightiesMetal = '80s Metal',
  nintiesMetal = '90s Metal',
  alternativeMetal = 'Alternative Metal',
  americanPunk = 'American Punk',
  blackMetal = 'Black Metal',
  britishMetal = 'British Metal',
  britishPunk = 'British Punk',
  darkAmbientNoise = 'Dark Ambient/Noise',
  deathMetal = 'Death Metal',
  doomStonerMetal = 'Doom/Stoner Metal',
  funkMetal = 'Funk Metal',
  garagePunk = 'Garage Punk',
  gothicMetal = 'Gothic Metal',
  grindcore = 'Grindcore',
  hairMetal = 'Hair Metal',
  hardcorePunk = 'Hardcore Punk',
  heavyMetal = 'Heavy Metal',
  industrialMetal = 'Industrial Metal',
  laPunk = 'L.A. Punk',
  metalcore = 'Metalcore',
  neoClassicalMetal = 'Neo-Classical Metal',
  newYorkPunk = 'New York Punk',
  oi = 'Oi!',
  popMetal = 'Pop-Metal',
  postGrunge = 'Post-Grunge',
  postHardcore = 'Post-Hardcore',
  postPunk = 'Post-Punk',
  powerMetal = 'Power Metal',
  progressiveMetal = 'Progressive Metal',
  protoPunk = 'Proto-Punk',
  punkMetal = 'Punk Metal',
  queercore = 'Queercore',
  rapcore = 'Rapcore',
  scandinavianMetal = 'Scandinavian Metal',
  skaPunk = 'Ska-Punk',
  skatepunk = 'Skatepunk',
  sludgeMetal = 'Sludge Metal',
  speedMetal = 'Speed Metal',
  stonerMetal = 'Stoner Metal',
  straightEdge = 'Straight-Edge',
  thrashSpeedMetal = 'Thrash/Speed Metal',
}

export enum NewAgeGenre {
  adultAlternative = 'Adult Alternative',
  ambient = 'Ambient',
  celticNewAge = 'Celtic New Age',
  contemporary = 'Contemporary',
  energyHealing = 'Energy Healing',
  environmental = 'Environmental',
  ethnicFusion = 'Ethnic Fusion',
  healing = 'Healing',
  meditation = 'Meditation',
  nature = 'Nature',
  neoClassical = 'Neo-Classical',
  newAge = 'New Age',
  progressiveAlternative = 'Progressive Alternative',
  progressiveElectronic = 'Progressive Electronic',
  relaxation = 'Relaxation',
  selfHelp = 'Self-Help',
  shamanic = 'Shamanic',
  space = 'Space',
  spiritual = 'Spiritual',
  technoTribal = 'Techno-Tribal',
  yoga = 'Yoga',
}

export enum PopGenre {
  fiftiesPop = '50s Pop',
  sixtiesPop = '60s Pop',
  seventiesPop = '70s Pop',
  eightiesPop = '80s Pop',
  ninetiesPop = '90s Pop',
  twothousandsPop = '00s Pop',
  ambientPop = 'Ambient Pop',
  asianPop = 'Asian Pop',
  baroquePop = 'Baroque Pop',
  beatlesPop = 'Beatles-pop',
  brillBuildingPop = 'Brill Building Pop',
  britishPop = 'British Pop',
  britpop = 'Britpop',
  bubblegumPop = 'Bubblegum Pop',
  californiaPop = 'California Pop',
  chamberPop = 'Chamber Pop',
  darkWave = 'Dark Wave',
  delicate = 'Delicate',
  dooWop = 'Doo Wop',
  dreamPop = 'Dream Pop',
  euroPop = 'Euro-Pop',
  folkyPop = 'Folky Pop',
  frenchPop = 'French Pop',
  garagePop = 'Garage Pop',
  hongKongPop = 'Hong Kong Pop',
  italianPop = 'Italian Pop',
  janglePop = 'Jangle Pop',
  japanesePop = 'Japanese Pop',
  merseybeat = 'Merseybeat',
  newRomantic = 'New Romantic',
  newWave = 'New Wave',
  noisePop = 'Noise Pop',
  partyPop = 'Party Pop',
  pop = 'Pop',
  popUnderground = 'Pop Underground',
  popRock = 'Pop/Rock',
  powerPop = 'Power Pop',
  psychedelicPop = 'Psychedelic Pop',
  quirky = 'Quirky',
  sunshinePop = 'Sunshine Pop',
  swampPop = 'Swamp Pop',
  swedishPopRock = 'Swedish Pop/Rock',
  synthPop = 'Synth Pop',
  teenIdol = 'Teen Idol',
  top40 = 'Top 40',
  tweePop = 'Twee Pop',
  withElectronicProduction = 'with Electronic Production',
  withLiveBandProduction = 'with Live-band Production',
}

export enum RBSoulGenre {
  blaxploitation = 'Blaxploitation',
  blueEyedSoul = 'Blue-Eyed Soul',
  brownEyedSoul = 'Brown-Eyed Soul',
  chicagoSoul = 'Chicago Soul',
  contemporaryRBSoul = 'Contemporary R&B/Soul',
  countrySoul = 'Country-Soul',
  deepFunk = 'Deep Funk',
  deepSoul = 'Deep Soul',
  disco = 'Disco',
  funk = 'Funk',
  goGo = 'Go-Go',
  goldenAge = 'Golden Age',
  memphisSoul = 'Memphis Soul',
  motown = 'Motown',
  neoSoul = 'Neo-Soul',
  newJackSwing = 'New Jack Swing',
  northernSoul = 'Northern Soul',
  phillySoul = 'Philly Soul',
  psychedelicSoul = 'Psychedelic Soul',
  quietStorm = 'Quiet Storm',
  rBPopCrossover = 'R&B Pop Crossover',
  rBRapMix = 'R&B Rap mix',
  retroSoul = 'Retro-Soul',
  rhythmBlues = 'Rhythm & Blues',
  smoothSoul = 'Smooth Soul',
  southernSOul = 'Southern SOul',
  traditionalRB = 'Traditional R&B',
  urban = 'Urban',
}

export enum ReggaeGenre {
  bluebeat = 'Bluebeat',
  calypso = 'Calypso',
  dancehall = 'Dancehall',
  dub = 'Dub',
  dubPoetry = 'Dub Poetry',
  mento = 'Mento',
  popReggae = 'Pop-Reggae',
  ragga = 'Ragga',
  reggaeRock = 'Reggae rock',
  rocksteady = 'Rocksteady',
  rootsReggae = 'Roots Reggae',
  ska = 'Ska',
  smoothReggae = 'Smooth Reggae',
  soca = 'Soca',
  steelBand = 'Steel Band',
}

export enum RockGenre {
  fiftiesRock = '50s Rock',
  sixtiesRock = '60s Rock',
  seventiesRock = '70s Rock',
  eightiesRock = '80s Rock',
  ninetiesRock = '90s Rock',
  acidRock = 'Acid Rock',
  acoustic = 'Acoustic',
  adultAlternativePopRock = 'Adult Alternative Pop/Rock',
  adultContemporary = 'Adult Contemporary',
  albumRock = 'Album Rock',
  americanTradRock = 'American Trad Rock',
  americanUnderground = 'American Underground',
  americana = 'Americana',
  arenaRock = 'Arena Rock',
  aussieRock = 'Aussie Rock',
  avantProg = 'Avant-Prog',
  boogieRock = 'Boogie Rock',
  britishBlues = 'British Blues',
  britishInvasion = 'British Invasion',
  celticRock = 'Celtic Rock',
  classicRock = 'Classic Rock',
  collegeRock = 'College Rock',
  comedyRock = 'Comedy Rock',
  countryRock = 'Country-Rock',
  cowpunk = 'Cowpunk',
  detroitRock = 'Detroit Rock',
  emo = 'Emo',
  euroRock = 'Euro-Rock',
  experimentalRock = 'Experimental Rock',
  folkRock = 'Folk Rock',
  freakbeat = 'Freakbeat',
  frenchRock = 'French Rock',
  funkRock = 'Funk Rock',
  garageRock = 'Garage Rock',
  glam = 'Glam',
  glitter = 'Glitter',
  goth = 'Goth',
  grunge = 'Grunge',
  hardRock = 'Hard Rock',
  heartlandRock = 'Heartland Rock',
  hotRod = 'Hot Rod',
  industrialRock = 'Industrial Rock',
  jamBand = 'Jam-band',
  japaneseRock = 'Japanese Rock',
  krautRock = 'Kraut Rock',
  latinRock = 'Latin Rock',
  loFi = 'Lo-Fi',
  madchester = 'Madchester',
  mathRock = 'Math Rock',
  mod = 'Mod',
  modernRock = 'Modern Rock',
  noWave = 'No Wave',
  noise = 'Noise',
  paisleyUnderground = 'Paisley Underground',
  postRockExperimental = 'Post-Rock/Experimental',
  progressiveRock = 'Progressive Rock',
  psychedelic = 'Psychedelic',
  psychobilly = 'Psychobilly',
  pubRock = 'Pub Rock',
  punk = 'Punk',
  punkPop = 'Punk-Pop',
  rapRock = 'Rap-Rock',
  retroRock = 'Retro-Rock',
  riotGrrrl = 'Riot Grrrl',
  rockRoll = 'Rock & Roll',
  rockEnEspañol = 'Rock en Español',
  rockOpera = 'Rock Opera',
  rockabilly = 'Rockabilly',
  rootsRock = 'Roots Rock',
  sadcore = 'Sadcore',
  shoegaze = 'Shoegaze',
  ska = 'Ska',
  slowcore = 'Slowcore',
  softRock = 'Soft Rock',
  southernRock = 'Southern Rock',
  spaceRock = 'Space Rock',
  surfRock = 'Surf Rock',
  texMex = 'Tex-Mex',
  tropicalRock = 'Tropical Rock',
}

export enum SpiritualGenre {
  alternativeCCM = 'Alternative CCM',
  chassidic = 'Chassidic',
  christianMetal = 'Christian Metal',
  christianRap = 'Christian Rap',
  christianRock = 'Christian Rock',
  contemporaryChristian = 'Contemporary Christian',
  contemporaryGospel = 'Contemporary Gospel',
  countryGospel = 'Country Gospel',
  hebrew = 'Hebrew',
  inspirational = 'Inspirational',
  jewishFolk = 'Jewish Folk',
  judaica = 'Judaica',
  lDS = 'LDS',
  praiseWorship = 'Praise & Worship',
  qawwali = 'Qawwali',
  reggaeGospel = 'Reggae Gospel',
  southernGospel = 'Southern Gospel',
  traditionalGospel = 'Traditional Gospel',
}

export enum SpokenWordGenre {
  audiobook = 'Audiobook',
  comedy = 'Comedy',
  educational = 'Educational',
  inspirational = 'Inspirational',
  instructional = 'Instructional',
  musicalComedy = 'Musical Comedy',
  novelty = 'Novelty',
  poetry = 'Poetry',
  prose = 'Prose',
  radioDrama = 'Radio Drama',
  religiousTexts = 'Religious Texts',
  satire = 'Satire',
  sketchComedy = 'Sketch Comedy',
  songParody = 'Song Parody',
  standupComedy = 'Standup Comedy',
  storytelling = 'Storytelling',
}

export enum WorldGenre {
  aboriginal = 'Aboriginal',
  acadian = 'Acadian',
  african = 'African',
  africanCentral = 'African-Central',
  africanEast = 'African-East',
  africanFolk = 'African Folk',
  africanNorth = 'African-North',
  africanSouth = 'African-South',
  africanWest = 'African-West',
  afroBeat = 'Afro-Beat',
  afroBrazilian = 'Afro-Brazilian',
  afroPop = 'Afro-Pop',
  andalusian = 'Andalusian',
  andeanFolk = 'Andean Folk',
  apala = 'Apala',
  armenian = 'Armenian',
  asian = 'Asian',
  asianCentral = 'Asian-Central',
  asianEast = 'Asian-East',
  asianNorth = 'Asian-North',
  asianSoutheast = 'Asian-Southeast',
  australian = 'Australian',
  balkan = 'Balkan',
  bellyDancing = 'Belly Dancing',
  benga = 'Benga',
  berber = 'Berber',
  bhangra = 'Bhangra',
  bikutsi = 'Bikutsi',
  bollywood = 'Bollywood',
  bulgarianFolk = 'Bulgarian Folk',
  caribbean = 'Caribbean',
  carnatic = 'Carnatic',
  celtic = 'Celtic',
  centralAmerican = 'Central American',
  chants = 'Chants',
  chimurenga = 'Chimurenga',
  chineseTraditional = 'Chinese Traditional',
  creole = 'Creole',
  dhrupad = 'Dhrupad',
  drumming = 'Drumming',
  easternEuropean = 'Eastern European',
  fado = 'Fado',
  flamenco = 'Flamenco',
  folklore = 'Folklore',
  fuji = 'Fuji',
  gamelan = 'Gamelan',
  greekFolk = 'Greek Folk',
  gypsy = 'Gypsy',
  hawaiian = 'Hawaiian',
  highlife = 'Highlife',
  hungarianFolk = 'Hungarian Folk',
  indianClassical = 'Indian Classical',
  indianPop = 'Indian Pop',
  indonesian = 'Indonesian',
  inuit = 'Inuit',
  island = 'Island',
  japaneseContemporary = 'Japanese Contemporary',
  japaneseTraditional = 'Japanese Traditional',
  jibaro = 'Jibaro',
  jit = 'Jit',
  juju = 'Juju',
  junkanoo = 'Junkanoo',
  kabuki = 'Kabuki',
  kecak = 'Kecak',
  klezmer = 'Klezmer',
  kompa = 'Kompa',
  kora = 'Kora',
  laika = 'Laika',
  makossa = 'Makossa',
  mbalax = 'Mbalax',
  mbaqanga = 'Mbaqanga',
  mbira = 'Mbira',
  mediterranean = 'Mediterranean',
  middleEastContemporary = 'Middle East Contemporary',
  middleEastTraditional = 'Middle East Traditional',
  nativeAmerican = 'Native American',
  noh = 'Noh',
  persianContemporary = 'Persian Contemporary',
  persianTraditional = 'Persian Traditional',
  pipeBand = 'Pipe Band',
  polka = 'Polka',
  raga = 'Raga',
  rai = 'Rai',
  reggae = 'Reggae',
  rembetika = 'Rembetika',
  samba = 'Samba',
  scandinavian = 'Scandinavian',
  seanNos = 'Sean-nos',
  slackKeyGuitar = 'Slack-Key Guitar',
  soukous = 'Soukous',
  southAmerican = 'South American',
  southPacific = 'South Pacific',
  swedishFolk = 'Swedish Folk',
  taarab = 'Taarab',
  throatSinging = 'Throat Singing',
  tibetan = 'Tibetan',
  traditionalCajun = 'Traditional Cajun',
  turkishClassical = 'Turkish Classical',
  turkishContemporary = 'Turkish Contemporary',
  westernEuropean = 'Western European',
  worldBeat = 'World Beat',
  worldFusion = 'World Fusion',
  worldTraditions = 'World Traditions',
  yiddish = 'Yiddish',
  zouk = 'Zouk',
  zydeco = 'Zydeco',
}
