import { Box, ListItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Button, SideDrawer } from '../../../components';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';
import Details from '../../common/Details';
import VenuePicker from './shared/VenuePicker';
import {
  addFeaturedVenue,
  removeFeaturedVenue,
} from '../../../api/summaryPage';
import { Venue } from '../../../types/Venue';

const MAX_COUNT = 3;
export default function FeaturedVenues(props) {
  const { venues } = props;
  const [featuredVenues, setFeaturedVenues] = useState(venues);
  const [openDrawerState, setOpenDrawerState] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedVenueId?: string;
  }>({
    open: false,
    deleteFeaturedVenueId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteVenueCallback = async () => {
    try {
      const venueId = confirmDelete.deleteFeaturedVenueId;
      setIsDeleting(true);
      await removeFeaturedVenue(venueId);
      setFeaturedVenues((venues) => venues.filter((v) => v.id !== venueId));
      setIsDeleting(false);
      setConfirmDelete({ open: false });
    } catch (error) {
      setIsDeleting(false);
    }
  };

  return (
    <Box>
      <Details
        title="Featured Venues"
        onAddButton={() => {
          if (featuredVenues.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} venues can be featured.`);
            return;
          }
          setOpenDrawerState(true);
        }}
      >
        <>
          <p>Choose {MAX_COUNT} venues to display on the home page</p>
          {featuredVenues.map((venue) => (
            <VenueRow
              key={venue.id}
              venue={venue}
              onDelete={(venueId) => {
                setConfirmDelete({
                  open: true,
                  deleteFeaturedVenueId: venueId,
                });
              }}
            />
          ))}
        </>
      </Details>
      <SideDrawer
        isActive={openDrawerState}
        toggleDrawer={() => setOpenDrawerState(false)}
        styles={{
          width: '550px',
        }}
      >
        <VenuePicker
          title="Featured Venues"
          currentVenue={undefined}
          handleSave={(args) => addFeaturedVenue(args.venueId)}
          handleClose={(venue) => {
            if (venue) {
              setFeaturedVenues((venues) => [...venues, venue]);
            }
            setOpenDrawerState(false);
          }}
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteVenueCallback}
      />
    </Box>
  );
}

function VenueRow({
  venue,
  onDelete,
}: {
  venue: Partial<Venue>;
  onDelete: (venueId: string) => void;
}): JSX.Element {
  return (
    <>
      <Stack display="flex" direction="row" style={{ marginBottom: '24px' }}>
        <ListItem key={venue.id}>
          <b>{venue.name}</b>
        </ListItem>
        {onDelete && (
          <Button
            label="Delete"
            variant={'contained'}
            color={'error'}
            onClick={() => {
              onDelete(venue.id);
            }}
            sx={{ ml: 2 }}
          />
        )}
      </Stack>
    </>
  );
}
