import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FileInputButton } from './UploadFileInput';
import { bytesToMB } from '../../utils/units';
import { getExtFromFileName } from '../../utils/media';
import { v4 as uuidv4 } from 'uuid';
import ConfirmDialog, { useConfirmModal } from '../Modals/ConfirmDialog';

export interface FileInfo {
  fileUrl: string;
  fileName: string;
  fileSize: string;
  mediaDetails?: {
    id: string;
  };
}
interface MultiFileUploadProps {
  id?: string;
  title: string;
  message?: string;
  coverTitle?: string;
  photoTitle?: string;
  files?: FileInfo[];
  uploadCallback?: (files: FileInfo, mimeType: string) => void;
  deleteCallback?: (files: FileInfo) => void;
  validator?: (fileName: string, fileSizeInMB: number) => boolean;
}

export interface UploadedFile {
  id?: string;
  url?: string;
  title?: string;
}

export default function MultiFileUpload(props: MultiFileUploadProps) {
  const {
    id = `${uuidv4()}`,
    title,
    message = 'Only PDFs, JPGs, JPEGs, PNGs, and CSVs are supported. Click SAVE above to upload.',
    // coverTitle = 'Cover Photo',
    // photoTitle = 'Photo',
    files = [],
    uploadCallback,
    deleteCallback,
    validator = () => true,
  } = props;

  const {
    showDeleteModal,
    setShowDeleteModal,
    showDeleteModalLoader,
    setShowDeleteModalLoader,
  } = useConfirmModal();
  const [fileToDelete, setFileToDelete] = useState<FileInfo | null>(null);

  const handleChange = (event) => {
    const files = event.target.files;
    const fileCount: number = files.length;

    for (let i = 0; i < fileCount; i++) {
      const file = files[i];
      const isValidFile = validator(
        getExtFromFileName(file.name),
        bytesToMB(file.size),
      );
      if (!isValidFile) {
        alert('Please provide a valid file');
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const newFileInfo = {
            fileUrl: event.target.result as string,
            fileName: file.name,
            fileSize: `${bytesToMB(file.size)}MB`,
          };
          uploadCallback(newFileInfo, file.type);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Typography variant="h1">{title}</Typography>
          <p style={{ margin: 0, marginTop: '10px' }}>{message}</p>
        </Box>
        {files.length != 0 && (
          <Box
            sx={{
              borderRadius: '7.5px',
              border: '1px solid #E5E7EB',
              marginTop: '24px',
              '& > div:not(:last-child)': {
                borderBottom: '1px solid #E5E7EB',
              },
            }}
          >
            {files.map((file, i) => (
              <Box key={i}>
                <Box
                  sx={{
                    padding: '8px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: 1,
                    }}
                  >
                    <Box
                      component={'img'}
                      src={'/images/paperclip-icon.png'}
                      sx={{
                        padding: '0 8px 0 0',
                        maxHeight: '16px',
                      }}
                    />
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Typography
                        component={'h2'}
                        sx={{
                          maxWidth: '500px',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {file.fileName}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography component={'h4'} sx={{ color: '#BDBDBD' }}>
                        {file.fileSize}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    <Box
                      component="a"
                      download={true}
                      sx={{
                        color: '#4F46E5',
                        borderRight: '1px solid #E5E7EB',
                        padding: ' 0 1rem',
                        cursor: 'pointer',
                      }}
                      href={file.fileUrl}
                    >
                      Download
                    </Box>
                    <Box
                      sx={{
                        color: '#4F46E5',
                        padding: ' 0 1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFileToDelete(file);
                        setShowDeleteModal(true);
                      }}
                    >
                      Remove
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <FileInputButton
        id={id}
        handleChange={handleChange}
        sx={{ margin: '1rem 0' }}
      >
        Add Files
      </FileInputButton>
      <ConfirmDialog
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        dialogOptions={{ isLoading: showDeleteModalLoader }}
        handleConfirmDelete={async () => {
          setShowDeleteModalLoader(true);
          await deleteCallback(fileToDelete);
          setShowDeleteModalLoader(false);
          setShowDeleteModal(false);
        }}
      />
    </>
  );
}
