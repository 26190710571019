import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  Table: {
    fontSize: '14px',
    borderCollapse: 'collapse',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    'overflow-x': 'auto',
  },
  'TableHead , TableCell': {
    textAlign: 'left',
  },
  TableCell: {
    padding: '16px 24px',
    fontSize: '14px',
    color: '#111827',
    fontWeight: '500',
    justifyContent: 'center',
    borderBottom: '1px solid #E5E7EB',
  },
  TableHead: {
    backgroundColor: '#F9FAFB',
    color: '#6B7280',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500',
    padding: '12px 24px',
    borderRadius: '8px',
    borderBottom: '1px solid #E5E7EB',
  },
  TableRow: {
    backgroundColor: 'fff',
  },
  ' TableCell:last-child': {
    borderBottom: 'none',
  },
  TableFooter: {
    backgroundColor: '#fff',
  },
  TablePagination: {
    display: 'flex',
    padding: '16px 0px',
    justifyContent: 'right',
    gap: '4px',
  },
  PageDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
  },
  TableEditButton: {
    cursor: 'pointer',
    color: '#4F46E5 !important',
  },
  SelectDropdown: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  PaginationButton: {
    padding: '0px 0px !important',
  },
  SearchBar: {
    marginBottom: '12px',
  },
}));

export { useStyles };
