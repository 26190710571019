import React, { useState } from 'react';
import Dialog from '../Dialog';

interface DialogOptions {
  title: string;
  content: string;
  primaryBtnLabel: string;
  secondaryBtnLabel: string;
  isLoading: boolean;
}

const defaultConfirmDialogOptions: DialogOptions = {
  title: "Are you should you'd like to delete this?",
  content: 'This action cannot be undone and data will be permanently lost.',
  primaryBtnLabel: 'Yes, permanently delete this.',
  secondaryBtnLabel: 'Cancel',
  isLoading: false,
};

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirmDelete: () => void;
  dialogOptions?: Partial<DialogOptions>;
};

export default function ConfirmDialog({
  isOpen,
  handleClose,
  handleConfirmDelete,
  dialogOptions = defaultConfirmDialogOptions,
}: Props) {
  const options = {
    ...defaultConfirmDialogOptions,
    ...dialogOptions,
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      handlePrimaryBtn={handleConfirmDelete}
      handleSecondaryBtn={handleClose}
      title={options.title}
      content={options.content}
      primaryBtnLabel={options.primaryBtnLabel}
      secondaryBtnLabel={options.secondaryBtnLabel}
      isLoading={options.isLoading}
      primaryVariantColor={'error'}
      secondaryVariantColor="info"
      primaryVariant="contained"
      secondaryVariant="outlined"
    />
  );
}

export const useConfirmModal = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModalLoader, setShowDeleteModalLoader] = useState(false);

  return {
    showDeleteModal,
    setShowDeleteModal,
    showDeleteModalLoader,
    setShowDeleteModalLoader,
  };
};
