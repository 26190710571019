import React, { createContext, useState } from 'react';
import { Alert } from '../../components';

const NotificationContext = createContext(null);

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const showToast = (message, type: 'success' | 'error') => {
    const newNotification = { id: Date.now(), message, type };
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
  };

  const clearToast = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id),
    );
  };

  return (
    <NotificationContext.Provider value={{ showToast, clearToast }}>
      {children}
      {notifications.map((notification) => (
        <Alert
          key={notification.id}
          message={notification.message}
          open={true}
          severity={notification.type === 'error' ? 'error' : 'success'}
          autoHideDuration={3000}
          onClose={() => {
            setNotifications((state) =>
              state.filter((s) => s.id !== notification.id),
            );
          }}
        />
      ))}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
