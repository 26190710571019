import React, { useState } from 'react';
import { Box } from '@mui/material';
import { SideDrawer } from '../../../components';
import Details from '../../common/Details';
import { MarqueeProductionResponse } from '../../../types/SummaryPage';
import ProductionPicker from './shared/ProductionPicker';
import {
  deleteHomeFeaturedProduction,
  addHomeFeaturedProduction,
  editHomeFeaturedProduction,
} from '../../../api/summaryPage';
import { ProductionRow } from './shared/ProductionRow';
import ConfirmDialog from '../../../components/Modals/ConfirmDialog';

export type Props = {
  productions: MarqueeProductionResponse[];
};
const MAX_COUNT = 2;
const FeaturedProductions = (props: Props) => {
  const [openAddDrawerState, setOpenDrawerState] = useState<{
    open: boolean;
    activeProduction: MarqueeProductionResponse | null;
  }>({ open: false, activeProduction: null });
  const [featuredProductions, setFeaturedProductions] = useState(
    props.productions,
  );
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    deleteFeaturedProductionId?: string;
  }>({
    open: false,
    deleteFeaturedProductionId: null,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const onMarqueeEdit = (productionId) => {
    setOpenDrawerState({
      open: true,
      activeProduction: featuredProductions.find(
        (p) => p.productionId === productionId,
      ),
    });
  };

  const onMarqueeDelete = (productionId) => {
    setConfirmDelete({
      open: true,
      deleteFeaturedProductionId: productionId,
    });
  };

  const deleteProductionCallback = () => {
    setIsDeleting(true);
    deleteHomeFeaturedProduction(confirmDelete.deleteFeaturedProductionId);
    setIsDeleting(false);
    setFeaturedProductions((productions) =>
      productions.filter(
        (p) => p.productionId !== confirmDelete.deleteFeaturedProductionId,
      ),
    );
    setConfirmDelete({
      open: false,
    });
  };

  return (
    <Box>
      <Details
        title="Featured Productions"
        onAddButton={() => {
          if (featuredProductions.length >= MAX_COUNT) {
            alert(`Max. of only ${MAX_COUNT} productions can be featured.`);
            return;
          }
          setOpenDrawerState({
            open: true,
            activeProduction: null,
          });
        }}
      >
        <>
          <p>Choose two productions to display on the home page</p>
          {featuredProductions.map(
            ({
              city,
              state,
              studioName,
              productionName,
              productionYear,
              productionId,
              featuredProductionMediaId,
            }) => (
              <ProductionRow
                key={productionId}
                productionId={productionId}
                productionName={productionName}
                studioName={studioName}
                productionYear={productionYear}
                city={city}
                state={state}
                featuredProductionMediaId={featuredProductionMediaId}
                onEdit={onMarqueeEdit}
                onDelete={onMarqueeDelete}
              />
            ),
          )}
        </>
      </Details>
      <SideDrawer
        isActive={openAddDrawerState.open}
        toggleDrawer={() =>
          setOpenDrawerState({ open: false, activeProduction: null })
        }
        styles={{
          width: '550px',
        }}
      >
        <ProductionPicker
          title="Featured Production"
          currentMarqueeProduction={openAddDrawerState.activeProduction}
          handleSave={({
            productionId,
            featuredProductionMediaId,
            isEditMode,
          }) => {
            if (isEditMode) {
              return editHomeFeaturedProduction({
                productionId,
                featuredProductionMediaId,
              });
            } else {
              return addHomeFeaturedProduction({
                productionId,
                featuredProductionMediaId,
              });
            }
          }}
          handleClose={(production) => {
            if (production) {
              if (openAddDrawerState.activeProduction) {
                setFeaturedProductions((productions) =>
                  productions.map((p) => {
                    if (
                      p.productionId ===
                      openAddDrawerState.activeProduction.productionId
                    ) {
                      return {
                        ...openAddDrawerState.activeProduction,
                        featuredProductionMediaId:
                          production.featuredProductionMediaId,
                      };
                    } else {
                      return p;
                    }
                  }),
                );
              } else {
                setFeaturedProductions((productions) => [
                  ...productions,
                  {
                    id: production.id,
                    city: production.city,
                    state: production.state,
                    featuredProductionMediaId:
                      production.featuredProductionMediaId,
                    productionId: production.id,
                    productionName: production.label,
                    productionYear: production.productionYear,
                    studioName: production.studioName,
                    label: production.label,
                  },
                ]);
              }
            }
            setOpenDrawerState({
              open: false,
              activeProduction: null,
            });
          }}
          mediaLimit={1}
        />
      </SideDrawer>
      <ConfirmDialog
        isOpen={confirmDelete.open}
        handleClose={() =>
          setConfirmDelete({
            open: false,
          })
        }
        dialogOptions={{ isLoading: isDeleting }}
        handleConfirmDelete={deleteProductionCallback}
      />
    </Box>
  );
};

export default FeaturedProductions;
