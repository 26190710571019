import React, { useEffect, useRef, useState } from 'react';
import { Alert, Autocomplete, Paper } from '../../../../components';
import { Box, CircularProgress, Divider } from '@mui/material';
import Loader from '../../../../components/Loader';
import { alertAutoHideDuration } from '../../../../utils/globalConfig';
import DialogHeader from '../../../common/DialogHeader';
import { makeStyles } from '@mui/styles';
import { queryPlayProductions } from '../../../../api/plays';
import { SelectBox } from '../../../../components/Select';
import { TextBox } from '../../../../components/TextField';
import { Production } from '../../../../types/Production';
import { AddFeaturedProductionValidation } from '../../../plays/CreatePlays/PlaysValidationSchema';
import {
  deleteFeaturedMedia,
  getStudioFeaturedMedia,
  uploadFeaturedMedia,
  useCreateStudioFeaturedProduction,
} from '../../../../api/studios';
import SelectionOfFeaturedMedia from '../../../../components/FeaturedMedia/SelectionOfFeaturedMedia';
import { getAllProductionMedia } from '../../../../api/production';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
  subContent: {
    padding: '32px',
  },
  mt: {
    marginTop: '16px',
  },
  bodyBackground: {
    backgroundColor: '#E5E5E5',
  },
  container: {
    padding: '32px 16px',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1440,
    margin: 'auto',
  },
  accountInfo: {
    padding: '24px 24px 24px 24px',
  },
  description: {
    width: '100%',
    height: 133,
  },
  item: {
    paddingTop: 0,
  },
}));

type Props = {
  studioId: string;
  institutionId: string;
  handleClose: (production: any) => void;
  getDisplayFeaturedMedia: (medias: any) => void;
  addedFeaturedProductions: {
    city: string;
    country: string;
    productionId: string;
    productionYear: number;
    state: string;
    studioName: string;
    venue: string;
  }[];
};
const AddFeaturedPlayProduction = ({
  studioId,
  institutionId,
  handleClose,
  addedFeaturedProductions,
  getDisplayFeaturedMedia,
}: Props) => {
  const [alertState, setAlertState] = useState({
    message: '',
    active: false,
  });
  const [queryState, setQueryState] = useState({
    state: '',
    studioType: 'All',
  });
  const [productions, setProductions] = useState([]);
  const [isProductionsFetching, setProductionsFetching] = useState(false);
  const [productionCountWithStudioType, setProductionCountWithStudioType] =
    useState([]);
  const [productionsCount, setProductionsCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const postMutation = useCreateStudioFeaturedProduction();
  const [selectedProduction, setSelectedProduction] = useState<Production>();
  const [selectedProductionMedia, setSelectedProductionMedia] = useState();
  const [initMediaToFeature, setInitMediaToFeature] = useState([]);
  const [selectedMediaToFeature, setSelectedMediaToFeature] = useState([]);
  const [removedMediaFromFeature, setRemovedMediaFromFeature] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = AddFeaturedProductionValidation();
  const isError = alertState.active;

  const onFilterChange = (name, value) => {
    setQueryState((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const isFeatured = addedFeaturedProductions.find(
        (prod) => prod?.productionId === data.production.productionId,
      );
      if (!isFeatured) {
        await postMutation.mutateAsync({
          institutionId,
          productionId: data.production.productionId,
        });
      }
      await updateMedia();
      setRemovedMediaFromFeature([]);
      afterUpdateFeaturedMedia();
      setLoading(false);
      handleClose(data.production);
    } catch (error) {
      setLoading(false);
      setAlertState({
        active: true,
        message: 'Something went wrong! Please try again later.',
      });
    }
  };

  async function init(studioState, studioType) {
    reset({
      production: null,
    });
    setProductionsFetching(true);
    const type = studioType.split(' (')[0];
    const { productions, proudctionCountByStudioType } =
      await queryPlayProductions({
        studioType: type === 'All' ? null : type,
        studioState,
        studioId,
      });
    const productionCount = productions.length;
    const productionCountWithStudioType = [
      ['All', productionCount],
      ...Object.entries(proudctionCountByStudioType),
    ];
    setQueryState({
      studioType: `All (${productionCount})`,
      state: '',
    });
    setProductionsCount(productionCount);
    setProductionCountWithStudioType(productionCountWithStudioType);
    setProductionsFetching(false);
    setProductions(productions);
  }
  const debounceUpdateLastName = useRef((studioState, studioType) => {
    init(studioState, studioType);
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      debounceUpdateLastName.current(queryState.state, queryState.studioType);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [queryState.state, queryState.studioType]);

  const getProdMedia = async () => {
    const allMedia = await getAllProductionMedia(selectedProduction?.id);
    setSelectedProductionMedia(allMedia);

    const allFeaturedMedia = await getStudioFeaturedMedia(studioId);
    setInitMediaToFeature(allFeaturedMedia);
    setSelectedMediaToFeature(allFeaturedMedia);
  };

  useEffect(() => {
    getProdMedia();
  }, [selectedProduction]);

  const onSelectionOfMedia = (media) => {
    setSelectedMediaToFeature((existingMedia) => [...existingMedia, media]);
  };

  const onRemovingOfMedia = (media) => {
    setSelectedMediaToFeature([
      ...selectedMediaToFeature.filter((m) => m?.mediaId !== media?.mediaId),
    ]);
    setRemovedMediaFromFeature((existingMedia) => [...existingMedia, media]);
  };

  const updateMedia = async () => {
    let uploadMediaResponse, deleteMediaResponse;
    const toUploadMedia = selectedMediaToFeature.filter(
      (m) => !initMediaToFeature.some((x) => x?.mediaId === m?.mediaId),
    );

    if (toUploadMedia.length > 0) {
      uploadMediaResponse = await Promise.all(
        toUploadMedia.map(async (media) => {
          const uploadResponse = await uploadFeaturedMedia(
            studioId,
            media?.mediaId,
          );
          return uploadResponse;
        }),
      );
    }
    const toDeleteMedia = removedMediaFromFeature.filter((m) =>
      initMediaToFeature.some((x) => x?.mediaId === m?.mediaId),
    );

    if (toDeleteMedia.length > 0) {
      deleteMediaResponse = await Promise.all(
        toDeleteMedia.map(async (media) => {
          const deleteResponse = await deleteFeaturedMedia(
            studioId,
            media?.mediaId,
          );
          return deleteResponse;
        }),
      );
    }

    return {
      uploadMediaResponse,
      deleteMediaResponse,
    };
  };

  const afterUpdateFeaturedMedia = async () => {
    const allFeaturedMedia = await getStudioFeaturedMedia(studioId);
    getDisplayFeaturedMedia(allFeaturedMedia);
  };

  return (
    <Box>
      <DialogHeader
        title={'Featured Production'}
        handleSave={handleSubmit(onSubmit)}
        cancel={handleClose}
      />
      <Box className={classes.content}>
        <Paper>
          {isLoading && <Loader isLoading={isLoading} />}
          {isError && (
            <Alert
              message={alertState.message}
              open={alertState.active}
              severity="error"
              autoHideDuration={alertAutoHideDuration}
              onClose={() => setAlertState({ active: false, message: '' })}
            />
          )}
          <Box className={classes.subContent}>
            Total No. of Productions ({productionsCount})
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <Box my={3}>
              <SelectBox
                value={queryState.studioType}
                onChange={(e) => {
                  onFilterChange(e.target.name, e.target.value);
                }}
                options={productionCountWithStudioType.map(
                  ([type, count]) => `${type} (${count})`,
                )}
                placeholder="Select Production Type"
                label="Production Type"
                multiple={false}
                name="studioType"
              />
            </Box>
            <Box my={3}>
              <TextBox
                label="State"
                name="state"
                placeholder="Select state"
                onChange={(e) => {
                  onFilterChange(e.target.name, e.target.value);
                }}
              />
            </Box>
            <Box my={3}>
              {isProductionsFetching ? (
                <Box display="flex" justifyContent="center" my={5}>
                  <CircularProgress />
                </Box>
              ) : (
                <Autocomplete
                  error={!!errors}
                  label={`Select Production (${productions?.length})`}
                  placeholder="Select Production"
                  name="production"
                  control={control}
                  getSelectedOption={(option) => {
                    setSelectedProduction(option?.value);
                  }}
                  options={((productions as Production[]) || []).map(
                    (production: any) => {
                      return {
                        id: production.productionId,
                        label: `${production.studioName} ${
                          production.productionYear
                        } - ${production.venue || 'N/A'} - ${
                          production.city
                        }, ${production.state}`,
                        ...production,
                      };
                    },
                  )}
                />
              )}
            </Box>
            <Box>
              {selectedProduction && (
                <SelectionOfFeaturedMedia
                  allFeaturedMedia={selectedProductionMedia}
                  selectedMedia={selectedMediaToFeature}
                  onSelectMedia={onSelectionOfMedia}
                  onRemovingMedia={onRemovingOfMedia}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddFeaturedPlayProduction;
