import React, { useMemo } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Role } from '../../types/User';
import { PaginationTable } from '../../components/Table/PaginationTable';

export default function ProductionsView() {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: 'Brand',
        accessorKey: 'play.brand.name',
      },
      {
        header: 'License',
        accessorKey: 'play.licenseName',
      },
      {
        header: 'Studio',
        accessorKey: 'studio.name',
      },
      {
        header: 'Start',
        accessorKey: 'productionStartDate',
        cell: (info: any) =>
          info.getValue()
            ? moment.utc(info.getValue()).format('MM/DD/YYYY')
            : null,
      },
      {
        header: 'End',
        accessorKey: 'productionEndDate',
        cell: (info: any) =>
          info.getValue()
            ? moment.utc(info.getValue()).format('MM/DD/YYYY')
            : null,
      },
      {
        header: 'Updated',
        accessorKey: 'updatedAt',
        cell: (info: any) =>
          info.getValue()
            ? moment.utc(info.getValue()).format('MM/DD/YYYY')
            : null,
      },
    ],
    [],
  );
  return (
    <>
      <Box
        sx={{
          maxWidth: '1440px',
          mx: { xs: 2, md: 3, lg: 6, xl: 'auto' },
          my: 6,
          //backgroundColor: '#1E1E1E'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Typography flexGrow="1" variant="h1" textAlign="center">
            Studio Productions
          </Typography>
        </Box>
        <PaginationTable
          columns={columns}
          onEdit={(productionId: string) =>
            navigate(`/productions/${productionId}/edit`)
          }
          tableName="Productions"
          route="productions"
          requiredEditScopes={[
            Role.spectraOperator,
            Role.studioAdmin,
            Role.studioManager,
          ]}
          studioIdKey="studioId"
          searchPlaceholder="Search License Names..."
          searchInputType="Search..."
          searchStudioPlaceholder="Search Studio Names..."
          searchStudioInputType="Search..."
        />
      </Box>
    </>
  );
}
